<template lang="pug">
DetailSmall(:detail="detail")
  template(slot="title")
    | production instance location.
  template(slot="form")
    DetailForm
  template(slot="controls")
    v-btn(@click="save") OK
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import DetailSmall from '@/components/base/detail-small-modal'
import DetailForm from './detail-form'

export default {
  components: {
    DetailForm,
    DetailSmall
  },
  computed: {
    ...mapFields('productionInstances', {
      detail: 'detail',
      location: 'input.location'
    })
  },
  methods: {
    async save () {
      // create a new PI
      await this.$store.dispatch('productionInstances/_CREATE', this.location)
      // close the detail dialog
      this.detail = false
    }
  }
}
</script>
