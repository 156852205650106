import common from '@/store/common'
import { ProductGroup as Model } from '@/models'
import crud from './crud'
import actions from './actions'
import helpers from './helpers'
import int from './interface'
import lineItems from './line-items'

export default {
  namespaced: true,
  state: {
    path: 'product-groups',
    ...common.state(Model),
    ...lineItems.state
  },
  getters: {
    ...common.getters(),
    ...lineItems.getters,
    quantity (state) { return state.item.quantity },
    quantityOrdered (state) { return state.item.quantityOrdered },
    quantityReceived (state) { return state.item.quantityReceived },
    quantityFinal (state) { return state.item.quantityFinal },
  },
  mutations: {
    ...common.mutations(),
    ...lineItems.mutations,
    quantity (state, payload) { state.item.quantity = payload },
    quantityOrdered (state, payload) { state.item.quantityOrdered = payload },
    quantityReceived (state, payload) { state.item.quantityReceived = payload },
    quantityFinal (state, payload) { state.item.quantityFinal = payload },
  },
  actions: {
    ...common.actions(Model),
    ...lineItems.actions,
    ...crud,
    ...helpers,
    ...int,
    ...actions,
    quantity ({ commit }, payload) { commit('quantity', payload) },
    quantityOrdered ({ commit }, payload) { commit('quantityOrdered', payload) },
    quantityReceived ({ commit }, payload) { commit('quantityReceived', payload) },
    quantityFinal ({ commit }, payload) { commit('quantityFinal', payload) },
  }
}
