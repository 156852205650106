<template lang="pug">
v-container()
  FwdHeadingLarge orders
  div(v-for='(item, i) in itemsSorted' :key='i')
    v-layout(row)
      v-flex(xs8)
        span.font-weight-bold {{item.companyName}}
        |  - {{item.name}}
      v-flex(xs2) {{item.orderNumber}}
      v-flex(xs2) {{item.status.name}}
    div.grey.lighten-3
      v-layout(row v-for='(transaction, i) in item.transactions' :key='i')
        v-flex(xs4).pl-3 {{transaction.date}}
        v-flex(xs4) {{transaction.type.type}}
        v-flex(xs4).text-xs-right {{transaction.amount | currency}}

</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  computed: {
    ...mapFields('orders', {
      items: 'items',
      item: 'item',
      search: 'search'
    }),
    itemsSorted () {
      let list = this.items.filter(x => x.status.name === 'paid')
      // filter by search term
      list = this.filterByProps(list, ['displayName'], this.search)
      // sort list by name
      return this.sortByString(list, 'displayName')
    }

  },
  methods: {
    async add () {
      const add = await this.$store.dispatch('orders/_ADD')
      this.detail(add._id)
    },
    select (item) {
      this.detail(item._id)
    },
    detail (_id) {
      this.$router.push({ path: '/orders/' + _id })
    },
    setSearch (search) {
      this.search = search
    }
  },

  created () {
    this.$store.dispatch('orders/_READ_ALL')
  }
}
</script>
