<template lang="pug">
div(v-if='isAdjustments').text-xs-right
  div.mb-3
    v-layout(row v-if='order.isStatusFinal')
      v-flex.font-weight-bold.text-xs-right(xs8) adjustment
      v-flex(xs4) {{costs.adjustmentFinal | currency}}

    v-layout(row v-else)
      v-flex.font-weight-bold.text-xs-right(xs8) adjustment
      v-flex(xs4) {{costs.adjustment | currency}}
  v-divider.mb-3
</template>

<script>
export default {
  props: {
    order: { required: true, type: Object },
    costs: { required: true, type: Object }
  },
  computed: {
    isAdjustments () {
      return this.costs.adjustment || (this.order.isStatusFinal && this.costs.adjustmentFinal)
    }
  }
}
</script>
