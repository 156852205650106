<template lang="pug">
div
  ImageInput(
    :item="item"
    :upload="upload"
    @upload="uploadImage"
  )
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import ImageInput from '@/components/base/image-input-fb'

export default {
  components: {
    ImageInput
  },
  computed: {
    ...mapFields('products', {
      item: 'item',
      upload: 'upload'
    })
  },
  methods: {
    async uploadImage (upload) {
      this.upload = upload
      await this.$store.dispatch('products/saveThumb')
    }
  }
}
</script>
