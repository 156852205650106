/** Model definition file for the Upload Class **/

export default class Upload {
  constructor ({
    formData = {},
    url = ''
  } = {}) {
    this.formData = formData
    this.url = url
  }
}
