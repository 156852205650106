import { ProductionInstance } from '@/models'

export default async ({ commit, dispatch, state, rootState }) => {
  dispatch('setup')
  await dispatch('designs/_LOAD', rootState.designs.item._id, { root: true })
  // delete the database copies + local
  dispatch('productionInstances/deleteMany', { _id: state.item._id, endpoint: 'job' }, { root: true })
  commit('productionInstances/items', [], { root: true })
  // add new PI's 
  await dispatch('productionInstances/_CREATE_BATCH', { design: rootState.designs.item, order: rootState.orders.item }, { root: true })
  
  // update the job
  state.item.design = rootState.designs.item
  // update PG pricing
  await dispatch('productGroups/updateAll', null, { root: true })
  dispatch('_SAVE')
  dispatch('cleanup')
}
