/** Model definition file for the DesignScreen Class **/
import uuid from 'uuid/v4'

export default class DesignLaser {
  constructor ({
    _id = uuid(),
    number = null,
    description = ''

  } = {}) {
    this._id = _id
    this.number = number
    this.description = description
  }
}
