<template lang="pug">
v-container(fluid fill-height)
  v-layout(column justify-center align-center)
    v-flex(xs6)
      v-card(flat)
        h2.display-4.font-weight-black fwd
        div.pl-1
          a(href='https://forwardprinting.slack.com/archives/CS7U959UJ' target='_blank') slack : fwd : heroes channel
          br
          a(href='https://fwd-docs.herokuapp.com/' target='_blank') partial application documentation
        //- div
        //-   pre {{ state.users }}

</template>

<script>
export default {
  name: 'home',
  data: () => ({
    quote: ''
  }),
  computed: {
    admin () { return this.$store.getters['admin/item'] },
    state () { return this.$store.state },
  },
  methods: {

  }
}
</script>
