<template lang="pug">
div
  ListAddOn(
    :items="itemsSorted"
    @select="select"
    @add="add"
  )
    template(slot-scope="{ item }")
      v-layout(row)
        v-flex(xs8) {{item.addOn.name}}
        v-flex(xs4).text-xs-right {{item.quantity}}
    template(slot="title") add-ons 
  DetailSimple(
    :detail="detail"
    @save="save"
    @remove="remove"
    @cancel="cancel"
  )
</template>

<script>
import { mapFields  } from 'vuex-map-fields'
import { DesignAddOn } from '@/models'
import ListAddOn from './list'
import DetailSimple from './detail'

export default {
  components: { ListAddOn, DetailSimple },
  data: () => ({ detail: false }),
  computed: {
    ...mapFields('designs', {
      addOns: 'selected.location.addOns',
      addOn: 'selected.addOn'
    }),
    itemsSorted () {
      return this.sortByString(this.addOns, 'name')
    }
  },
  methods: {
    add () {
      this.addOn = new DesignAddOn()
      this.detail = true
    },
    select (item) {
      this.addOn = new DesignAddOn(item)
      this.detail = true
    },
    async save () {
      this.$store.commit('designs/itemsUpdate', { list: 'selected.location.addOns', item: this.addOn})
      await this.$store.dispatch('designs/updateLocation')
      this.$store.dispatch('designs/_SAVE')
    },
    cancel () { this.detail = false },
    async remove (item) {
      this.$store.commit('designs/itemsRemove', { list: 'selected.location.addOns', item: this.addOn})
      this.$store.dispatch('designs/_SAVE')
      this.cancel()
    }
  }
}
</script>
