<template lang="pug">
DetailSmall(:detail="detail")
  template(slot="title")
    | mockup page order.
  template(slot="form")
    v-list
      draggable(
        v-model="items"
        @end="checkMove"
      )
        v-list-tile.black-border(
          v-for="item in items"
          :key='item._id'
        )
          v-list-tile-content {{item.name}}
    
  template(slot="controls")
    v-btn(@click="cancel") ok
</template>

<script>
import draggable from 'vuedraggable'
import { mapFields } from 'vuex-map-fields'
import DetailSmall from '@/components/base/detail-small-modal'

export default {
  components: {
    DetailSmall,
    draggable
  },
  props: ['detail'],
  computed: {
    ...mapFields('designs', {
      items: 'item.mockups',
    })
  },
  methods: {
    cancel () {
      this.$emit('cancel')
    },

    checkMove (evt) {
      this.$store.dispatch('designs/_SAVE')
      // set indices
      // let newItems = []
      // this.items.forEach((item, index) => {
      //   item.sortOrder = index
      //   newItems.push(item)
      // })
      // this.items = newItems
    }
  }
}
</script>
