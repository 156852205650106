<template lang="pug">
div.mb-3
  DocHeading shipments
  v-layout(row)
    v-flex(v-for="(shipment, i) in shipments" :key="i")
      ShipmentCard(
        :shipment="shipment"
        @select="select(shipment)"
      )

</template>

<script>
import DocHeading from '../_heading'
import ShipmentCard from '../../shipments/shipment'

export default {
  computed: {
    shipments () {
      const order = this.$store.getters['orders/item']
      return order.shipments
    }
  },
  components: { ShipmentCard, DocHeading }
}
</script>
