<template lang="pug">
div
  v-flex
    pre.headline(
      style="{white-space: pre-wrap; white-space: pre-line"
    ) {{design.notes.public}}
  //- v-flex.mt-4
  //-   h3.headline.font-weight-bold.mb-3 notes (private):
  //-   pre.headline(style="{white-space: pre-wrap; white-space: pre-line") {{design.notesPrivate}}
</template>

<script>
export default {
  computed: {
    design () { return this.$store.getters['designs/item'] }
  }
}
</script>
