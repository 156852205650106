// return a unique list of colors across all subproducts
export default (items) => {
  let temp = []
  items.forEach(sp => {
    sp.colors.forEach(color => { temp.push(color) })
  })
  function uniq (a) {
    var seen = []
    a.forEach(function (item) {
      let found = seen.find(el => {
        return item._id === el._id
      })
      if (!found) { seen.push(item) }
    })
    return seen
  }
  let list = uniq(temp)

  // sort list by name
  list.sort((a, b) => {
    const A = a.name.toUpperCase()
    const B = b.name.toUpperCase()
    if (A > B) return 1
    if (A < B) return -1
    return 0
  })

  // give up the result
  return list
}
