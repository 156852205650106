<template lang="pug">
v-layout.line-item.body-1(row)
  v-flex(xs4)
    v-layout(row)
      v-flex.pa-1
        v-avatar(size=28 tile)
          img(:src="line.color.image.url")
      v-flex {{line.color.name}}

  v-flex(xs2).text-xs-center.text-uppercase {{line.size.name}}

  v-flex(xs6)
    v-layout(row).text-xs-center
      v-flex(xs4) {{ line.quantity }}
      v-flex(xs4) {{ line.quantityOrdered }}
      v-flex(xs4) {{ line.quantityReceived }}

</template>
<script>
import Quantity from './quantity'
import QuantityOrdered from './quantity-ordered'
import QuantityReceived from './quantity-received'
import QuantityFinal from './quantity-final'

export default {
  props: ['line', 'readOnly'],
  methods: {
    changeQuantity (payload) { this.$emit('changeQuantity', payload) }
  },

  components: { Quantity, QuantityOrdered, QuantityReceived, QuantityFinal }
}
</script>

<style lang="stylus" scoped>
.line-item
  border-bottom solid #CFD8DC 1px
</style>
