<template lang="pug">
v-container
  h3.display-2.font-weight-black.mt-5 pricing
  Pricing
  v-layout(row)
    v-flex(xs6)
</template>

<script>
import Pricing from '../../components/pricing'

export default {
  components: { Pricing }
}
</script>

<style>

</style>
