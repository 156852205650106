<template lang="pug">
DetailSmall(:detail="detail")
  template(slot="title")
    | Mockup detail.
  template(slot="form")
    v-layout(row)
      v-flex(xs10)
        v-text-field(
          label="name"
          v-model="name"
        )
      v-flex(xs2 pl-3)
        v-text-field(
          label="sort order"
          type="number"
          v-model.number="sortOrder"
        )
    v-textarea(
      label="description or notes"
      v-model="description"
      auto-grow
      rows=2
    )
    ImageInput(
      :item="item"
      :upload="upload"
      @upload="uploadImage"
    )
  template(slot="controls")
    DetailControls(
      @save="save"
      @cancel="cancel"
      @remove="remove"
    )
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import DetailSmall from '@/components/base/detail-small-modal'
import DetailControls from '@/components/base/detail-controls-simple'
import ImageInput from '@/components/base/image-input-fb'

export default {
  components: {
    DetailControls,
    DetailSmall,
    ImageInput
  },
  props: ['detail'],
  computed: {
    ...mapFields('designs', {
      item: 'selected.mockup',
      name: 'selected.mockup.name',
      sortOrder: 'selected.mockup.sortOrder',
      description: 'selected.mockup.description',
      upload: 'selected.mockupUpload',
    })
  },
  methods: {
    save () {
      this.$store.dispatch('designs/updateMockups')
      this.$store.dispatch('designs/_SAVE')
      this.cancel()
    },
    cancel () {
      this.$emit('cancel')
    },
    remove () {
      this.$store.dispatch('designs/removeMockup')
      this.$store.dispatch('designs/_SAVE')
      this.cancel()
    },
    async uploadImage(upload) {
      this.$store.commit('designs/mockupUpload', upload)
      await this.$store.dispatch('designs/saveMockupFile')
      this.$store.dispatch('designs/_SAVE')
    }
  }
}
</script>
