import Vue from 'vue'
import Vuex from 'vuex'

// pricing
import tiers from '@/modules/tiers/store'
import decorationCategories from '@/modules/decoration-categories/store'
import decorationCodes from '@/modules/decoration-codes/store'
import decorations from '@/modules/decorations/store'

// product
import products from '@/modules/products/store'
import productColors from '@/modules/product-colors/store'

// product settings
import brands from '@/modules/brands/store'
import sizeCategories from '@/modules/size-categories/store'
import subproductNames from '@/modules/subproduct-names/store'
import sizes from '@/modules/sizes/store'
import productTags from '@/modules/product-tags/store'

// companies
import companies from '@/modules/companies/store'
// designs
import designs from '@/modules/designs/store'
// design settings
import designLocationTemplates from '@/modules/design-location-templates/store'
import screenSizes from '@/modules/screen-sizes/store'
import inkColors from '@/modules/ink-colors/store'
import inkTypes from '@/modules/ink-types/store'
import designLocationAddOns from '@/modules/design-location-add-ons/store'

// orders
import orders from '@/modules/orders/store'
import jobs from '@/modules/jobs/store'
import productGroups from '@/modules/product-groups/store'
import productionInstances from '@/modules/production-instances/store'
import presses from '@/modules/presses/store'
import productionInstanceTypes from '@/modules/production-instance-types/store'
import scheduling from '@/modules/scheduling/store'
import dashboards from '@/modules/dashboards/store'

// purchasing
import vendors from '@/modules/vendors/store'
import { stores as purchasing } from '@/modules/purchase-orders'

// utility
import core from './modules/core'
import menu from './modules/menu'
import loader from './modules/loader'
import helpDialog from './modules/help-dialog'

// admin
import admin from '@/modules/admin/store'
import users from '@/modules/users/store'
const { purchaseOrders, poPoolLines } = purchasing


Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    core,
    tiers,
    decorations,
    decorationCategories,
    decorationCodes,
    brands,
    sizeCategories,
    subproductNames,
    sizes,
    productColors,
    products,
    productTags,
    companies,
    designs,
    designLocationTemplates,
    screenSizes,
    inkColors,
    inkTypes,
    designLocationAddOns,
    orders,
    jobs,
    productGroups,
    admin,
    users,
    productionInstances,
    presses,
    productionInstanceTypes,
    scheduling,
    dashboards,
    // purchasing
    vendors,
    purchaseOrders,
    poPoolLines,
    menu,
    loader,
    helpDialog
  }
})
