<template lang="pug">
div(class="text-xs-center")
  v-dialog(
    v-model="loading"
    persistent
    width="250"
  )
    v-card(
      color="black"
      dark
    )
      template(v-if="isInit")
        v-img(:src="require('@/assets/loader/screwface.jpg')")
        v-card-text
          v-layout(column)
            v-flex.caption.green--text.text--accent-3.text-lowercase(v-for="(item, i) in initialized" :key="i") {{item}}
          v-progress-linear(
            indeterminate
            color="red"
            class="mb-0"
          )
      template(v-else)
        v-img(:src="require('@/assets/loader/miyagi.jpg')")
        v-card-text
          v-progress-linear(
            indeterminate
            color="white"
            class="mb-0"
          )

</template>

<script>
import { mapFields } from "vuex-map-fields";

export default {
  computed: {
    ...mapFields("loader", {
      loading: "loading",
      initialized: "initialized",
      isInit: "isInit",
    }),
  },
};
</script>
