<template lang="pug">
DetailSmall(:detail="detail")
  template(slot="title")
    | placement detail
  template(slot="form")
    v-text-field(
      label="name"
      v-model="name"
    )
    v-textarea(
      grow
      label="details"
      v-model="details"
    )
  template(slot="controls")
    DetailControls(
      @save="save"
      @cancel="cancel"
      @remove="remove"
    )
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import DetailSmall from '@/components/base/detail-small-modal'
import DetailControls from '@/components/base/detail-controls-simple'

export default {
  components: {
    DetailControls,
    DetailSmall
  },
  props: ['detail'],
  computed: {
    ...mapFields('designs', {
      name: 'selected.placement.name',
      details: 'selected.placement.details'
    })
  },
  methods: {
    save () {
      this.$emit('save', this.form)
      this.cancel()
    },
    cancel () { this.$emit('cancel') },
    remove () {
      this.$emit('remove', this.form)
      this.cancel()
    }
  }
}
</script>
