<template lang="pug">
div
  v-container
    v-layout(row)
      v-flex
        v-btn(@click='tempupdatedecorationcosts' color='red') tempupdatedecorationcosts

</template>

<script>

export default {
  methods: {
    tempupdatedecorationcosts() {
      this.$store.dispatch('productionInstances/tempupdatedecorationcosts')
    }
  }
}
</script>
