<template lang="pug">
div
  v-layout(row).display-3.font-weight-bold
    v-flex
      h3.mb-3 job ticket
    v-flex.text-xs-right
      | job # {{job.jobNumber}} 

  v-layout(row)
    v-flex 
      span.font-weight-bold company name: 
      | {{company.name}}
  v-layout(row)
    v-flex
      span.font-weight-bold job name: 
      | {{job.design.name}}
      span(v-if="job.subtitle") 
        | - {{job.subtitle}}  
  v-layout(row)
    v-flex
      span.font-weight-bold job description: 
      | {{job.design.subtitleText}}
  v-layout(row)
    v-flex
      span.font-weight-bold order name: 
      | {{order.name}} / order # {{order.orderNumber}}

  v-layout(row mt-3 v-if="order.dates.inHands")
    v-flex()
      span.font-weight-bold in hands date: 
      | {{order.dates.inHands}}
  v-layout(row v-if="order.dates.dropDead")
    v-flex()
      span.font-weight-bold drop dead date: 
      | {{order.dates.dropDead}}
  v-layout(row v-if="order.owner.username")
    v-flex()
      span.font-weight-bold owner: 
      | {{order.owner.username}}
  v-layout(row v-if="order.contact.fullName.trim()")
    v-flex()
      span.font-weight-bold point of contact: 
      | {{order.contact.fullName}} - {{order.contact.email}}

</template>

<script>
export default {
  computed: {
    job () { return this.$store.getters['jobs/item'] },
    order () { return this.$store.getters['orders/item'] },
    company () { return this.$store.getters['companies/item'] }
  }
}
</script>
