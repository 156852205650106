/** Model definition file for the Company Class **/
import { Contact } from '@/models'
export default class Company {
  constructor ({
    _id = '',
    name = '',
    website = '',
    contacts = [],
    addresses =  [],
    taxable = {
      isTaxable: false,
      reason: '',
      notes: '',
      image: {}
    },
    isDepositRequired = true,
    pricingTier = {},

  } = {}) {
    this._id = _id
    this.name = name
    this.website = website
    this.contacts = contacts.map(contact => new Contact(contact))
    this.addresses = addresses
    this.taxable = taxable
    this.isDepositRequired = isDepositRequired
    this.pricingTier = pricingTier
  }
  get taxExemptReasons () {
    return [
      'out of state',
      'on file',
      'non-profit exemption'
    ]
  }

  get displayName () { return this.name }
}
