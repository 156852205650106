/** Model definition file for the PurchaseOrder Class **/
import { PurchaseOrderLine } from '@/models'
// TODO: get the model constructors working again (had to remove because webpack build wouldn't work)

export default class PurchaseOrder {
  constructor ({
    _id = '',
    number = '',
    vendor = {},
    lines = [],
    dateCreated = Date.now(),
    ordered = false,
    dateOrdered = '',
    dateToArrive = '',
    received = false,
    dateReceived = '',
    shipTo = {},
    quantity = 0,
    quantityOrdered = 0,
    cog = 0
  } = {}) {
    this._id = _id
    this.number = number
    this.vendor = vendor
    this.lines = lines.map(x => new PurchaseOrderLine(x))
    this.dateCreated = dateCreated
    this.ordered = ordered
    this.dateOrdered = dateOrdered
    this.dateToArrive = dateToArrive
    this.received = received
    this.dateReceived = dateReceived
    this.shipTo = shipTo
    this.quantity = quantity
    this.quantityOrdered = quantityOrdered
    this.cog = cog
  }
  
  get sortString () {
    let string = !this.received ? '00' : '10'
    string += !this.ordered ? '00' : '10'
    string += String(Date.now() - this.dateCreated).padStart(200, '0')
    return string
  }

  get displayName () {
    let string = `${this.number} - ${this.vendor.name}`
    return string
  }

  get vendorName () { return this.vendor._id ? this.vendor.name : '' }

  get jobsOnThisPo () {
    const jobs = []
    this.lines.forEach(line => {
      const instance = {
        order_id: line.order_id,
        orderName: line.orderName,
        orderNumber: line.orderNumber,
        company_id: line.company_id,
        companyName: line.companyName,
        job_id: line.job_id,
        jobName: line.jobName,
        jobNumber: line.jobNumber,
      }
      const find = jobs.find(j => j.job_id === instance.job_id)
      const isDuplicate = find === undefined ? false : true
      if (!isDuplicate ) { jobs.push(instance) }
      
    })
    return jobs
  }
 
}
