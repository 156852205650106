import { ProductionInstance as Model, Design, Job, ProductGroup, Company } from '@/models'
import axios from 'axios'

export default {
  async _CREATE ({ commit, dispatch, rootState }, { location, order }) {
    dispatch('setup')
    // build an instance of model based on current state
    const item = await dispatch('_buildInstance', location)
    // post record and add to state
    const post = await dispatch('postOne', { data: item })
    // update item
    const quantity = rootState.jobs.item.quantity
    const updated = await dispatch('updateItem', { quantity, item: post, order })
    commit('itemsUpdate', { list: 'items', item: updated })

    dispatch('cleanup')
  },

  // add a new batch of PI's for a given design
  async _CREATE_BATCH ({ state, commit, dispatch }, { design, order }) {
    for (const location of design.locations) {
      await dispatch('_CREATE', { location, order })
    }
    // update items
    commit('item', state.items[0])
    dispatch('updateMany', { data: state.items })
  },

  async _READ ({ state, commit }, _id) {
    const item = state.items.find(x => x._id === _id)
    if (item) { commit('item', item) }
  },

  async _READ_FROM_DB ({ commit, dispatch }, _id) {
    dispatch('setup')
    const item = await dispatch('getOne', _id)
    await dispatch('designs/_LOAD', item.design, { root: true })
    await dispatch('jobs/_LOAD', item.job, { root: true })
    commit('item', item)
    dispatch('cleanup')
  },

  async _READ_PRODUCTION ({ commit, dispatch }, _id) {
    dispatch('setup')
    // expect: selected production instance, job, design
    const results = await axios.get('/production-instances/production/' + _id)
    const data = results.data
    // data.productGroups = data.productGroups.filter(x => x.meta.retired !== true)

    // add job, company, design and production instance to state
    commit('companies/item', new Company(data.company), { root: true })

    data.design.company = data.company
    commit('designs/item', new Design(data.design), { root: true })
    commit('jobs/item', new Job(data.job), { root: true })
    const pgs = data.productGroups.map(x => new ProductGroup(x))
    commit('productGroups/items', pgs, { root: true })
    commit('productGroups/item', pgs[0], { root: true })
    // console.log('get production:', pgs)
    commit('item', data.productionInstance)

    dispatch('cleanup')
  },

  async _READ_FANGAMER ({ commit, dispatch }) {
    dispatch('setup')
    // expect: selected production instance, job, design
    console.log('read fangamer')
    const results = await axios.get('/production-instances/fangamer/')
    const data = results.data
    console.log(data)
    commit('items', data)

    dispatch('cleanup')
  },

  async _READ_WIP ({ commit, dispatch }) {
    dispatch('setup')
    const results = await axios.get('/production-instances/wip/')
    const data = results.data
    commit('items', data)

    dispatch('cleanup')
  },

  async _READ_JOB_ITEMS ({ state, commit, dispatch }, job) {
    // get items
    const items = await dispatch('getCollectionFiltered', { endpoint: 'job', _id: job._id })
    commit('items', items)
    // update items
    commit('item', state.items[0])
  },

  async _READ_ART_PENDING ({ state, commit, dispatch }, job) {
    // get items
    const results = await axios.get('/production-instances/art-pending/')
    const data = results.data.map(x => new Model(x))
    commit('items', data)
    // update items
    commit('item', state.items[0])
  },

  async _UPDATE ({ state, commit, dispatch }) {
    const data = Object.assign({}, state.item)
    delete data.dates
    dispatch('updateOne', { data })
    // update items array (local state)
    commit('itemsUpdate', { list: 'items', item: state.item })
  },

  async _DELETE ({ state, commit, dispatch }) {
    dispatch('deleteOne', { _id: state.item._id })
    // remove the item from local state
    commit('itemsRemove', { list: 'items', item: state.item })
  },

  // build an instance of the current model for use in _CREATE
  // TODO: could this be less dependent on root state? and cleaner?
  async _buildInstance ({ rootState }, location) {
    // grab the current order, design and job id's
    const order = rootState.jobs.item.order
    const job = rootState.jobs.item._id
    const design = rootState.designs.item._id
    // create a blank record
    const item = new Model({
      order,
      job,
      design,
      // these NEED to be collected first
      location: location,
      type: rootState.productionInstanceTypes.items.find(x => x.name === 'screen print'),
      status: 'nip'
    })
    const dashboard = item.dashboard
    dashboard.designName = rootState.designs.item.name
    dashboard.jobNumber = rootState.jobs.item.jobNumber
    dashboard.jobSubtitle = rootState.jobs.item.subtitle
    dashboard.orderDisplayName = rootState.orders.item.displayName
    dashboard.image = rootState.designs.item.image
    return item
  },

  async updateItem ({ rootState }, { item, quantity, order }) {
    // calculate minutes
    const minutes = quantity / item.location.decoration.quantityPerHour * 60
    item.minutes.default = Math.ceil(minutes)
    // transfer drop dead date
    item.dates.dropDead = order.dates.dropDead
    return item
  },

  async updateJobProgress ({ dispatch }, { progress, job_id }) {
    try {
      axios.post('/production-instances/update', {
        filter: { job: job_id, status: { $in: ['wip', 'nip'] }, 'meta.retired': { $ne: true } },
        data: { 'dashboard.jobProgress': progress }
      })
    } catch (error) {
      console.log('updateJobProgress failed', error)
      return Promise.reject(error)
    }
  },

  async updateOrderStatus ({ dispatch }, { status, order_id }) {
    const path = '/production-instances/update/order-status/'
    try {
      await axios.patch(path, { status, order_id })
    } catch (error) {
      console.log(path + ' failed', error)
      return Promise.reject(error)
    }
  },

  async updateToWip ({ dispatch }, { order_id }) {
    const path = '/production-instances/update/status/'
    try {
      await axios.patch(path, { order_id, status: 'wip' })
    } catch (error) {
      console.log(path + ' failed', error)
      return Promise.reject(error)
    }
  },

  async updateToComplete ({ dispatch }, { order_id }) {
    const path = '/production-instances/update/status/'
    try {
      await axios.patch(path, { order_id, status: 'completed' })
    } catch (error) {
      console.log(path + ' failed', error)
      return Promise.reject(error)
    }
  },

  async updatePiProgress ({ state }) {
    console.log('updatePiProgress')
    const path = '/production-instances/update/pi-progress'
    try {
      await axios.patch(path, {
        design_id: state.item.design,
        location_id: state.item.location._id,
        progress: state.item.progress
      })
    } catch (error) {
      console.log(path + ' failed', error)
      return Promise.reject(error)
    }
  },

  async updateDates ({ state, commit, dispatch, rootState }, { dates, jobIds }) {
    const path = '/production-instances/update/dates/'
    try {
      await axios.patch(path, { dates, jobIds })
    } catch (error) {
      console.log(path + ' failed', error)
      return Promise.reject(error)
    }
  },

  async updateSubtitle ({ state, commit, dispatch, rootState }, payload) {
    const path = '/production-instances/update/subtitle/'
    try {
      await axios.patch(path, payload)
    } catch (error) {
      console.log(path + ' failed', error)
      return Promise.reject(error)
    }
  },

  async updateOrderDisplayName ({ state, commit, dispatch, rootState }, payload) {
    const path = '/production-instances/update/order-name/'
    try {
      await axios.patch(path, payload)
    } catch (error) {
      console.log(path + ' failed', error)
      return Promise.reject(error)
    }
  },

  async getCollectionCompleted ({ dispatch, commit }) {
    dispatch('setup')
    const path = '/production-instances/completed'
    try {
      const items = await axios.get(path)
      const processed = items.data.map(item => new Model(item))
      commit('items', processed)
    } catch (error) {
      console.log(path + ' failed', error)
      return Promise.reject(error)
    }
    dispatch('cleanup')
  },

  async getcompletedlastmonth ({ dispatch, commit }) {
    dispatch('setup')
    const path = '/production-instances/completedlastmonth'
    try {
      const items = await axios.get(path)
      const processed = items.data.map(item => new Model(item))
      commit('items', processed)
    } catch (error) {
      console.log(path + ' failed', error)
      return Promise.reject(error)
    }
    dispatch('cleanup')
  }

}
