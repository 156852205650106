<template lang="pug">
div
  v-layout(row)
    v-flex quote last sent on: {{quoteDateDisplay}}
  v-layout(row).mt-4
    v-flex
      h3.title pending checklist
      v-checkbox(
        v-model="isDatesOk"
        label="are the due dates OK by mgmt?"
        color="pink accent-1"
        @change="checkPending"
        :disabled="!pendingAllowed.includes(status.name)"
      )
      v-checkbox(
        v-model="isShippingOk"
        label="do we have shipping info?"
        color="pink accent-1"
        @change="checkPending"
        :disabled="!pendingAllowed.includes(status.name)"
      )
      v-checkbox(
        v-model="isDepositOk"
        label="deposit OK by mgmt?"
        color="pink accent-1"
        @change="checkPending"
        :disabled="!pendingAllowed.includes(status.name)"
      )
  v-layout(row).mt-2
    v-flex
      h3.title completion
      v-checkbox(
        v-model="isShipped"
        label="has the order shipped?"
        color="pink accent-1"
        @change="toggleIsShipped"
        :disabled="status.name !== 'ready to ship'"
      )
      v-checkbox(
        v-model="isComplete"
        label="has the order been completed?"
        color="pink accent-1"
        @change="completeOrder"
        :disabled="status.name !== 'confirmed'"
      )
  v-layout(row).mt-2
    v-flex
      h3.title.mb-3 status log
      p.underline(v-for="item in logSorted") {{item.text}} - {{formatDate(item.time)}}

</template>

<script>
import { mapFields } from 'vuex-map-fields'
import moment from 'moment'

export default {
  data: () => ({ pendingAllowed: ['pending', 'estimate', 'quote']}),
  computed: {
    ...mapFields('orders', {
      status: 'item.status',
      isComplete: 'item.statusData.isComplete',
      isShipped: 'item.statusData.isShipped',
      quoteDate: 'item.statusData.quoteDate',
      log: 'item.statusData.log',
      pendingChecklist: 'item.statusData.pendingChecklist',
      isDatesOk: 'item.statusData.pendingChecklist.isDatesOk',
      isShippingOk: 'item.statusData.pendingChecklist.isShippingOk',
      isDepositOk: 'item.statusData.pendingChecklist.isDepositOk'
    }),
    quoteDateDisplay () {
      if(!this.quoteDate) { return }
      return moment(this.quoteDate).format('LLL')
    },
    logSorted () {
      return this.sortByPropNumber(this.log, 'time')
    },
    isPendingOk () { return this.isDatesOk && this.isShippingOk && this.isDepositOk }
  },
  methods: {
    save () { this.$store.dispatch('orders/_SAVE') },
    formatDate (date) {
      if (!date) { return }
      return moment(date).format('L')
    },
    toggleIsShipped () {
      this.isShipped = ! this.isShipped
      if (this.isShipped && status.name === 'ready to ship') {
        this.$store.dispatch('orders/allShipmentsComplete')
      } else {
        this.save()
      }
    },
    checkPending () {
      if (this.isPendingOk && this.status.name === 'pending') {
        this.$store.dispatch('orders/confirmOrder')
      } else {
        this.save()
      }
    },
    completeOrder () {
      if (this.status.name === 'confirmed' && this.isComplete) {
        this.$store.dispatch('orders/completeOrder')
      } else {
        this.save()
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.underline
  border-bottom 1px solid #E0E0E0
</style>