/** Model definition file for the DesignLocationColorway Class **/
// import uuid from 'uuid/v4'
import { Colorway, DesignScreen, Image } from '@/models'

export default class DesignLocationColorway {
  constructor ({
    colorway = {},
    production = {
      platen: null,
      lasers: [],
      image: new Image()
    },
    screens = []
  } = {}) {
    this.colorway = new Colorway(colorway)
    this.screens = screens.map(screen => {
      return new DesignScreen(screen)
    })
    this.production = production
  }
}
