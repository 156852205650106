<template lang="pug">
div
  v-layout(row)
    v-flex
      v-btn(@click="submit") submit
      v-btn(@click="reset") reset
      v-btn(@click="cancel") cancel
  v-list
    v-list-tile.list-item(
        v-for='item in itemsSorted'
        :key='item._id'
        avatar
      )
      v-list-tile-avatar(v-if="item.image.url" tile)
        img(:src="item.image.url")
      v-list-tile-content
        v-list-tile-title
          | {{item.name}}
      v-list-tile-action
        v-checkbox(v-model="item.selected")
</template>
<script>
import sortItems from './list-sort'

export default {
  props: ['items'],
  computed: {
    itemsSorted () {
      return sortItems(this.items, this.search, this.filter)
    },
    selectedColors () {
      return this.itemsSorted.filter(color => {
        return color.selected
      })
    },
    baseURL () {
      return this.axios.defaults.baseURL + '/img'
    }
  },
  methods: {
    submit () {
      let selectedColors = this.itemsSorted.filter(color => {
        return color.selected
      })
      this.$emit('submit', selectedColors)
    },
    reset () {
      this.$emit('reset')
    },
    cancel () {
      this.$emit('cancel')
    }
  }
}
</script>
