export default [
  {
    title: "Scheduling",
    icon: "schedule",
    route: { activate: "schedule" },
    top: true,
    role: ["Admin", "User", "ProductionLead"],
  },
  {
    title: "Schedule",
    icon: "schedule",
    route: { path: "/dashboards/schedule" },
    module: "schedule",
    role: ["Admin", "User", "ProductionLead"],
  },
  {
    title: "Profit",
    icon: "attach_money",
    route: { path: "/dashboards/schedule-profit" },
    module: "schedule",
    role: ["Admin", "User", "ProductionLead"],
  },
  
];
