/** Model definition file for the OrderCosts Class **/

export default class OrderCosts {
    constructor({
        quantity = 0,
        quantityFinal = 0,
        quantityTrue = 0,
        subtotal = 0,
        subtotalFinal = 0,
        jobsSubtotal = 0,
        jobsSubtotalFinal = 0,
        otherChargesSubtotal = 0,
        shippingSubtotal = 0,
        tax = 0,
        taxFinal = 0,
        taxOverride = 0,
        adjustment = 0,
        adjustmentFinal = 0,
        adjustmentPercentage = 0,
        adjustmentAmount = 0,
        total = 0,
        totalFinal = 0,
        amountPaid = 0,
        cog = 0,
        cogTrue = 0,
        productionCost = 0
    } = {}) {
        this.quantity = quantity
        this.subtotal = subtotal
        this.jobsSubtotal = jobsSubtotal
        this.quantityFinal = quantityFinal
        this.quantityTrue = quantityTrue
        this.subtotalFinal = subtotalFinal
        this.jobsSubtotalFinal = jobsSubtotalFinal
        this.otherChargesSubtotal = otherChargesSubtotal
        this.shippingSubtotal = shippingSubtotal
        this.tax = tax
        this.taxFinal = taxFinal
        this.taxOverride = taxOverride
        this.adjustment = adjustment
        this.adjustmentFinal = adjustmentFinal
        this.adjustmentPercentage = adjustmentPercentage
        this.adjustmentAmount = adjustmentAmount
        this.total = total
        this.totalFinal = totalFinal
        this.amountPaid = amountPaid
        this.cog = cog
        this.cogTrue = cogTrue
        this.productionCost = productionCost
    }
}
