/** Model definition file for the Pricing Class **/
import { Tier, JobCosts } from '@/models'

export default class Pricing {
  constructor ({
    tier = {
      profitPerHour: 350,
      markup: 20
    },
    decorationCodes = [],
    quantityPerHour = 0,
    jobCosts = {}
  } = {}) {
    this.tier = new Tier(tier)
    this.decorationCodes = decorationCodes
    this.quantityPerHour = quantityPerHour
    this.jobCosts = new JobCosts(jobCosts)
  }
}
