<template lang="pug">
div
  div
    v-layout(row)
      v-flex.font-weight-bold(xs8) job subtotal
      v-flex(xs4).text-xs-right {{costs.jobsSubtotal | currency}}
    v-layout(row v-if='order.isStatusFinal')
      v-flex.font-weight-bold(xs8) job subtotal (final)
      v-flex(xs4).text-xs-right {{costs.jobsSubtotalFinal | currency}}

  v-layout(row)
    v-flex.font-weight-bold(xs8) shipping subtotal
    v-flex(xs4).text-xs-right {{costs.shippingSubtotal | currency}}

  v-layout(row)
    v-flex.font-weight-bold(xs8) other charges subtotal
    v-flex(xs4).text-xs-right {{costs.otherChargesSubtotal | currency}}

  v-divider
</template>

<script>
export default {
  props: {
    order: { required: true, type: Object },
    costs: { required: true, type: Object }
  }
}
</script>
