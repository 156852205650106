<template lang="pug">
v-container(grid-list-xl)
  v-layout(row wrap)
    v-flex(xs6)
      FwdCard
        template(slot="title") details
        template(slot="content")
          Meta
      FwdCard
        template(slot="title") point of contact
        template(slot="content")
          PointOfContact
    v-flex(xs6)
      FwdCard.transparent
        template(slot="img")
          Thumb
      
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import Meta from './meta'
import PointOfContact from './contact'
import Thumb from './thumb'
import Approval from './approval'

export default {
  components: { 
    Meta,
    PointOfContact,
    Thumb,
    Approval,
  },
  computed: {
    ...mapFields('designs', {
      item: 'item',
      admin: 'item.admin'
    })
  }
}
</script>
