export const namespaced = true

export const state = {
  orders: [],
  designs: [],
  searchDesigns: '',
  searchOrders: ''
}

export const getters = {
  orders (state) { return state.orders },
  designs (state) { return state.designs }
}

export const mutations = {
  orders (state, payload) { state.orders = payload },
  designs (state, payload) { state.designs = payload }
}

export const actions = {
  orders ({ commit }, payload) { commit('orders', payload) },
  designs ({ commit }, payload) { commit('designs', payload) },
}