<template lang="pug">
div
  div
    v-layout(row)
      v-flex.font-weight-bold(xs8) total
      v-flex(xs4).text-xs-right {{costs.total | currency}}

    v-layout(row v-if='order.isStatusFinal').pink.lighten-5
      v-flex.font-weight-bold(xs8) total (final)
      v-flex(xs4).text-xs-right {{costs.totalFinal | currency}}

  v-divider
</template>

<script>
export default {
  props: {
    order: { required: true, type: Object },
    costs: { required: true, type: Object }
  }
}
</script>
