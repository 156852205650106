export default [
  {
    path: '/dashboards/schedule',
    name: 'schedule',
    component: () => import('../views/schedule'),
    meta: { authorize: ['Admin', 'User', 'ProductionLead'] }
  },
  {
    path: '/dashboards/schedule-profit',
    name: 'schedule-profit',
    component: () => import('../views/list-profit'),
    meta: { authorize: ['Admin', 'User', 'ProductionLead'] }
  },
  {
    path: '/dashboards/schedule/:id',
    name: 'dashboards detail - schedule',
    component: () => import('../views/schedule/detail'),
    meta: { authorize: ['Admin', 'User'] }
  },
]
