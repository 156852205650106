<template lang="pug">
v-container(grid-list-lg fluid)
  v-layout(row)
    v-flex()
      FwdCard
        template(slot="title") products with only meta info
        template(slot="content")
          pre {{products}}
          
      
</template>

<script>
export default {
  computed: {
    products () {
      const list = this.$store.getters['products/items']
      return JSON.stringify(list, undefined, 2)
    }
  }
}
</script>

style <style lang="stylus">
pre
  white-space pre-wrap
</style>