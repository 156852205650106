<template lang="pug">
v-layout.body-1.font-weight-bold.pink--text(row px-4 pt-3)
  v-flex(xs3) 
  v-flex(xs1) 
  v-flex(xs5)
    v-layout(row)
      v-flex(xs3).text-xs-center 
        template(v-if='settings.quantity.show') {{ productGroup.quantity }}
      v-flex(xs3).text-xs-center 
        template(v-if='settings.quantityOrdered.show') {{ productGroup.quantityOrdered }}
      v-flex(xs3).text-xs-center 
        template(v-if='settings.quantityReceived.show') {{ productGroup.quantityReceived }}
      v-flex(xs3).text-xs-center 
        template(v-if='settings.quantityFinal.show') {{ productGroup.quantityFinal }}
  v-flex.text-xs-right(xs1) 
  v-flex.text-xs-right(xs2) 
    template(v-if='settings.costExtend.show') {{ subtotal | currency }}
</template>

<script>
export default {
  computed: {
    settings () { return this.$store.getters['productGroups/settings'] },
    productGroup () { return this.$store.getters['productGroups/item'] },
    subtotal () { return this.isFinal ? this.productGroup.subtotalFinal : this.productGroup.subtotal },
    isFinal () {
      // if order in confirmed or later state, we can now use final column
      const order = this.$store.getters['orders/item']
      const orderStatus = order.status.name
      return !['estimate', 'quote', 'pending'].includes(orderStatus)
    },
  }
}
</script>
