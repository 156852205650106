<template lang="pug">
BaseListExtended(
  :items="itemsSorted"
  :filterToggle='false'
  searchLabel="search products"
  @search="setSearch"
  @select="select"
  @add="add"
  :avatar="true"
)
  template(slot-scope="{ item }")
    v-layout(row)
      v-flex(xs6) {{item.displayName}}
      v-flex(xs6) {{item.brand.name}}
  template(slot="title")
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import BaseListExtended from '@/components/base/list-extended'
import { listSort } from '../helpers'

export default {
  components: { BaseListExtended },
  computed: {
    ...mapFields('products', {
      items: 'items',
      item: 'item',
      detail: 'detail',
      filter: 'filter',
      search: 'search',
    }),
    brands () { return this.$store.getters['brands/items']},
    itemsSorted () { return listSort({
        items: this.items,
        search: this.search,
        filter: this.filter,
      })
    }
  },
  methods: {
    async add () {
      const add = await this.$store.dispatch('products/_ADD')
      this.detail(add._id)
    },
    select (item) { this.detail(item._id) },
    detail (_id) { this.$router.push({ path: '/products/' + _id }) },
    setFilter (filter) { this.filter = filter },
    setSearch (search) { this.search = search },
  },
  // created () {
  //   this.filter = this.brands[0]
  // }
}
</script>
