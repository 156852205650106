<template lang="pug">
v-layout(row)
  v-flex(xs4)
    v-dialog(
      ref="dialogShipBy"
      v-model="modalShipBy"
      :return-value.sync="dates.shipBy"
      persistent
      lazy
      full-width
      width="290px"
    )
      template(v-slot:activator="{ on }")
        v-text-field(
          v-model="shipByDisplay"
          label="drop dead date"
          prepend-icon="event"
          readonly
          v-on="on"
        )
      v-date-picker(v-model="dates.shipBy" scrollable)
        v-spacer
        v-btn(flat color="primary" @click="modalShipBy = false") Cancel
        v-btn(flat color="primary" @click="$refs.dialogShipBy.save(dates.shipBy)") OK
  v-flex(xs4 pl-3)
    v-dialog(
      ref="dialogReceiveBy"
      v-model="modalReceiveBy"
      :return-value.sync="dates.receiveBy"
      persistent
      lazy
      full-width
      width="290px"
    )
      template(v-slot:activator="{ on }")
        v-text-field(
          v-model="receiveByDisplay"
          label="in hands date"
          prepend-icon="event"
          readonly
          v-on="on"
        )
      v-date-picker(v-model="dates.receiveBy" scrollable)
        v-spacer
        v-btn(flat color="primary" @click="modalReceiveBy = false") Cancel
        v-btn(flat color="primary" @click="$refs.dialogReceiveBy.save(dates.receiveBy)") OK
 
  v-flex(xs4 pl-3)
    v-dialog(
      ref="dialogShippedOn"
      v-model="modalShippedOn"
      :return-value.sync="dates.shippedOn"
      persistent
      lazy
      full-width
      width="290px"
    )
      template(v-slot:activator="{ on }")
        v-text-field(
          v-model="shippedOnDisplay"
          label="in hands date"
          prepend-icon="event"
          readonly
          v-on="on"
        )
      v-date-picker(v-model="dates.shippedOn" scrollable)
        v-spacer
        v-btn(flat color="primary" @click="modalShippedOn = false") Cancel
        v-btn(flat color="primary" @click="$refs.dialogShippedOn.save(dates.shippedOn)") OK
</template>

<script>
import moment from 'moment'

export default {
  props: ['item'],
  data: () => ({
    modalShipBy: false,
    modalReceiveBy: false,
    modalShippedOn: false,
    dates: {
      shipBy: '',
      receiveBy: '',
      shippedOn: ''
    }
  }),
  computed: {
    shipByDisplay () {
      return moment(this.dates.shipBy).format('LL')
    },
    receiveByDisplay () {
      return moment(this.dates.receiveBy).format('LL')
    },
    shippedOnDisplay () {
      return moment(this.dates.shippedOn).format('LL')
    }
  },
  // watch: {
  //   dates: {
  //     handler (dates) { this.$emit('updateDates', this.dates) },
  //     deep: true
  //   }
  // },
  mounted() {
    this.$set(this.dates, 'shipBy', this.item.shipBy)
    this.$set(this.dates, 'receiveBy', this.item.receiveBy)
    this.$set(this.dates, 'shippedOn', this.item.shippedOn)
    // this.dates = JSON.stringify(this.dates)
  }
}
</script>
