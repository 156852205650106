<template lang="pug">
DetailSmall(:detail="detail")
  template(slot="title") company contacts
  template(slot="form")
    Contacts
  template(slot="controls")
    v-btn(@click="cancel") ok
</template>

<script>
import DetailSmall from '@/components/base/detail-small-modal'
import { contacts as Contacts } from '@/modules/companies'

export default {
  components: { DetailSmall, Contacts },
  props: ['detail'],
  methods: {
    cancel () { this.$emit('cancel') }
  }
}
</script>
