<template lang="pug">
div(v-if='isAdjustments')
  div
    v-layout(row v-if="costs.adjustment")
      v-flex.font-weight-bold(xs8) adjustment
      v-flex(xs4).text-xs-right {{costs.adjustment | currency}}

    v-layout(row v-if='order.isStatusFinal').pink.lighten-5
      v-flex.font-weight-bold(xs8) adjustment (final)
      v-flex(xs4).text-xs-right {{costs.adjustmentFinal | currency}}

  v-divider
</template>

<script>
export default {
  props: {
    order: { required: true, type: Object },
    costs: { required: true, type: Object }
  },
  computed: {
    isAdjustments () {
      return this.costs.adjustment || (this.order.isStatusFinal && this.costs.adjustmentFinal)
    }
  }
}
</script>
