<template lang="pug">
div
  v-layout(v-if="productGroups.length" row)
    v-flex(xs3)
      v-layout(row pl-5 py-2).body-2.pink.lighten-5
        v-flex.title {{product.code}}
      div.caption.pl-5.mt-4 {{product.brand.name}}
      div.pl-5 {{product.name}}
      div
        v-img(height="250px" contain :src="product.image.url")
    v-flex(xs9)
      LineItems(
        :lines="lineItems"
      )
  v-layout(row)
    v-flex(xs12).pa-5
      v-btn(@click='unlockAllLineItems') unlock all lines
      p if you click this you'll have to reload to restore order later
</template>

<script>
import { LineItem } from '@/models'
import { mapFields } from 'vuex-map-fields'
import LineItems from './line-items'

export default {
  components: { LineItems },
  computed: {
    ...mapFields('productGroups', {
      productGroup: 'item',
      productGroups: 'items',
      product: 'item.product',
      lineItems: 'item.lineItems',
    })
  },
  methods: {
    unlockAllLineItems() {
      const settings = {
        quantity: { show: true, input: true },
        quantityOrdered: { show: true, input: true },
        quantityReceived: { show: true, input: true },
        quantityFinal: { show: true, input: true },
        cost: { show: true },
        costExtend: { show: true },
      }

      this.$store.dispatch('productGroups/settings', settings)
    }
  },
  beforeMount() {
    const settings = {
      quantity: { show: true, input: false },
      quantityOrdered: { show: true, input: false },
      quantityReceived: { show: true, input: false },
      quantityFinal: { show: true, input: false },
      cost: { show: true },
      costExtend: { show: true },
    }
    const orderStatus = this.$store.state.orders.item.status.name
    switch (orderStatus) {
      case 'estimate' || 'quote':
        settings.quantity.show = true
        settings.quantity.input = true
        break
      case 'pending':
        settings.quantity.show = true
        settings.quantity.input = true
        break
      case 'confirmed':
        settings.quantity.show = true
        settings.quantity.input = false
        settings.quantityOrdered.show = true
        settings.quantityReceived.show = true
        settings.quantityFinal.show = true
        settings.quantityFinal.input = true
        break
      case 'invoice' || 'paid':
        settings.quantity.show = true
        settings.quantity.input = false
        settings.quantityOrdered.show = true
        settings.quantityOrdered.input = false
        settings.quantityReceived.show = true
        settings.quantityReceived.input = false
        settings.quantityFinal.show = true
        settings.quantityFinal.input = true
        break
    }
    this.$store.dispatch('productGroups/settings', settings)
  }
}
</script>