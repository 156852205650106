import commonActions from '@/store/common/actions'
import commonState from '@/store/common/state'
import commonGetters from '@/store/common/getters'
import commonMutations from '@/store/common/mutations'
import init from '@/store/init'
import { Admin as Model } from '@/models'

export default {
  namespaced: true,
  state: {
    path: 'admin',
    ...commonState(Model)
  },
  getters: {
    ...commonGetters()
  },
  mutations: {
    ...commonMutations(),
    orderNumber(state, payload) {
      state.item.orderNumber = payload
    },
    jobNumber(state, payload) {
      state.item.jobNumber = payload
    }
  },
  actions: {
    ...commonActions(Model),
    
    async init ({ dispatch, commit }) {
      let admin = await dispatch('getOne', 'admin')
      commit('item', admin)
    },

    async _LOAD ({ dispatch, commit, state, rootState }) {
      // setup
      state.loaded = false
      
      // get current document
      const document = await dispatch('getOne', 'admin' )
      commit('item', document)
      
      // cleanup
      state.loaded = true
    },

    async _UPDATE ({ state, commit, dispatch }) {
      dispatch('updateOne', { data: state.item })
    },

    async getNextJobNumber ({ state, commit, dispatch }) {
      // get next job number
      const nextJobNumber = state.item.jobNumber
      // update the admin record for next job number
      state.item.jobNumber += 1
      dispatch('_UPDATE')
      return nextJobNumber
    },

    async getNextOrderNumber ({ state, commit, dispatch }) {
      // get next order number
      const nextOrderNumber = state.item.orderNumber
      // update the admin record for next order number
      state.item.orderNumber += 1
      dispatch('_UPDATE')
      return nextOrderNumber
    },
    
  }
}
