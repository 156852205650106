/** Model definition file for the SizeCategory Class **/

export default class SizeCategory {
  constructor ({
    _id = '',
    name = ''
  } = {}) {
    this._id = _id
    this.name = name
  }
}
