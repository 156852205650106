<template lang="pug">
v-container
  v-layout(row)
    v-flex(xs8)
      v-layout(row)
        v-flex
          v-btn(@click="add" dark) add comment
      v-layout(row wrap)
        v-flex(v-for="(comment, i) in items" :key="i").mb-4
          v-card.grey.lighten-2(@click="select(comment)" flat)
            v-card-title.font-weight-bold.subheading.grey.white--text()
              | type:
              span.font-weight-light.pl-2  {{comment.type}}
              span.pl-4 subject:  
              span.font-weight-light.pl-2 {{comment.subject}}
            v-card-text
              v-layout(row style="{white-space: pre-line; white-space: pre-wrap;}")
                v-flex {{comment.text}}
            v-card-actions.grey.white--text.ma-0
              v-layout(row)
                v-flex(xs6)
                  span.font-weight-bold submitted on: 
                  | {{formatDate(comment.time)}}
  DetailSimple(
    :detail="detail"
    :item="item"
    @detail="detailToggle"
  )
</template>

<script>
import moment from 'moment'
import { Comment } from '@/models'
import DetailSimple from './detail'

export default {
  components: { DetailSimple },
  data: () => ({
    detail: false,
    item: {}
  }),
  computed: {
    items () {
      const order = this.$store.getters['orders/item']
      return this.sortByPropNumber(order.comments, 'time').reverse()
    }
  },
  methods: {
    add () {
      this.item = new Comment()
      this.detailToggle()
    },
    select (item) {
      this.item = new Comment(item)
      this.detailToggle()
    },
    detailToggle () {
      this.detail = !this.detail
    },
    formatDate (date) {
      return moment(date).format('LLL')
    }
  }
}
</script>
