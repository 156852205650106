<template lang="pug">
div(v-if="valid")
  DocHeading approval/changes
  v-container(fluid).grey.pa-3
    v-layout(row mb-3).white--text
      v-flex do you want to approve or request changes?
    v-layout(row)
      v-flex
        v-btn(
          v-if="!approved"
          dark
          @click="openapproval"
        ) approve
        v-btn(
          dark
          @click="wantToRequestChanges = true; wantToApprove = false, goto()"
        ) request changes

  v-container(fluid).pa-3.mt-4(v-if="wantToApprove && !approved")
    v-layout(row)
      v-flex
        p If the information on this order is correct please mark as approved below.
    v-layout(row)
      v-flex(xs8)
        Approval
  v-container(fluid).pa-3.mt-4(v-if="wantToRequestChanges")
    v-layout(row)
      p If changes are needed please leave a change request and we will submit an updated estimate for review.
    v-layout(row)
      v-flex(xs8)
        Comment

</template>

<script>
import DocHeading from '../_heading'
import Approval from './approval'
import Comment from './comment'
import Vue from 'vue'

export default {
  components: {
    Approval,
    Comment,
    DocHeading
  },
  computed: {
    valid () {
      const isClient = this.$route.path.includes('/client/')
      const isQuote = this.order.status.name === 'quote'
      const isValid = isClient && isQuote
      return isValid
    },
    order () { return this.$store.getters['orders/item'] },
    approved () { return this.order.approval.approved }
  },
  data: () => ({
    wantToApprove: false,
    wantToRequestChanges: false
  }),
  methods: {
    openapproval () {
      this.wantToApprove = true
      this.wantToRequestChanges = false
      // Vue.nextTick()
      this.goto()
    },
    goto () {
      this.$vuetify.goTo(50000, {
        duration: 300,
        offset: 0,
        easing: 'easeInOutCubic'
      })
    }
  }
}
</script>

const goto = () => {

}
