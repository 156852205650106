import markOrdered from '../markOrdered'

export default {
  async sendBackToPool ({ commit, dispatch }, line) {
    // remove the po info
    line.PONumber = ''
    line.PO_id = ''
    // add line to po pool
    dispatch('poPoolLines/postOne', { data: line }, { root: true })
    commit('poPoolLines/itemsUpdate', { list: 'items', item: line }, { root: true })
    // remove from current po and
    commit('itemsRemove', { list: 'item.lines', item: line })
    dispatch('_UPDATE')
  },

  

  ...markOrdered,

  async lineItemReceived ({ state, commit, dispatch, rootState }, line) {
    // update the po line item
    const findPurchaseOrderLine = state.item.lines.find(x => x.lineItem._id === line.lineItem._id)
    findPurchaseOrderLine.lineItem.quantityReceived = line.lineItem.quantityReceived
    dispatch('_UPDATE')

    // go update the line in the product group
    dispatch('productGroups/_UPDATE_ONE_LINE', line.lineItem, { root: true })

    // update local job's copy
    const currentJob = rootState.jobs.items.find(x => x._id === line.job_id)
    currentJob.productGroups = currentJob.productGroups.map(pg => {
      const lineItems = pg.lineItems.map(li => {
        if (li._id === line.lineItem._id) {
          return line.lineItem
        } else { return li }
      })
      pg.lineItems = lineItems
      return pg
    })
    commit('jobs/itemsUpdate', { list: 'items', item: currentJob }, { root: true })

  },


}