<template lang="pug">
v-container()
  FwdHeadingLarge fangamer garments ordered
  div(v-for='(item, i) in lineitems' :key='i')
    v-layout(row)
      v-flex(xs4) {{item.size}}
      v-flex(xs4).text-xs-right {{item.color}}
      v-flex(xs4).text-xs-right {{item.quantity}}

</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  computed: {
    ...mapFields('orders', {
      items: 'items',
      item: 'item'
    }),
    itemsSorted () {
      // sort list by name
      return this.sortByString(this.items, 'name')
    },
    lineitems () {
      const items = []
      for (const item of this.items) {
        // process each line item
        for (const lineitem of item.lineItems) {
          const newrecord = {
            ordername: item.name,
            orderNumber: item.orderNumber,
            design: item.design,
            productname: item.productname,
            productbrand: item.productbrand,
            productcode: item.productcode,
            size: lineitem.size.name,
            color: lineitem.color.name,
            quantity: lineitem.quantity,
          }
          items.push(newrecord)
        }
      }
      return items
    }

  },

  async created () {
    await this.$store.dispatch('orders/reportfangamergarments')
    // const saveTemplateAsFile = (filename, dataObjToWrite) => {
    //   const blob = new Blob([JSON.stringify(dataObjToWrite)], { type: "text/json" });
    //   const link = document.createElement("a");

    //   link.download = filename;
    //   link.href = window.URL.createObjectURL(blob);
    //   link.dataset.downloadurl = ["text/json", link.download, link.href].join(":");

    //   const evt = new MouseEvent("click", {
    //       view: window,
    //       bubbles: true,
    //       cancelable: true,
    //   });

    //   link.dispatchEvent(evt);
    //   link.remove()
    // }
    // saveTemplateAsFile('fangamergarments', this.lineitems)
  }
}
</script>
