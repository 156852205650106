export default (items, search, filter) => {
  // make a mutable copy
  let list = items.map(a => { return a })
  // filter by search against code and name
  if (search.length > 0) {
    list = list.filter(item => {
      return item.name.toUpperCase().includes(search.toUpperCase()) ||
        item.code.toUpperCase().includes(search.toUpperCase())
    })
  }
  // filter by selected brand
  if (filter) {
    list = list.filter(item => {
      return item.brand._id === filter._id
    })
  }
  // sort list by code
  list.sort((a, b) => {
    const A = a.code.toUpperCase()
    const B = b.code.toUpperCase()
    return A <= B ? -1 : 1
  })
  return list
}
