<template lang="pug">
div(id="product-details")
  v-text-field(
    label="code"
    v-model="code"
    @change="save"
  )
  v-text-field(
    label="name"
    v-model="name"
    @change="save"
  )
  v-select(
    label="brand"
    v-model="brand"
    :items="brands"
    item-text="name"
    return-object
    @change="save"
  )
  v-select(
    label="size category"
    v-model="sizeCategory"
    :items="sizeCategories"
    item-text="name"
    return-object
    @change="addAvailableSizes"
  )
  v-checkbox(v-model='isPreferred' label='preferred garment?' @change="save")
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  computed: {
    ...mapFields('products', {
      name: 'item.name',
      description: 'item.description',
      code: 'item.code',
      sizeCategory: 'item.sizeCategory',
      brand: 'item.brand',
      isPreferred: 'item.isPreferred',
      sizesAvailable: 'item.sizesAvailable'
    }),
    sizeCategories () {
      const items = this.$store.getters['sizeCategories/items']
      return this.sortByString(items, 'name')
    },
    brands () {
      const items = this.$store.getters['brands/items']
      return this.sortByString(items, 'name')
    },
    availableSizes () {
      const items = this.$store.getters['sizes/items']

      let list = items.map(a => { return a })
      // filter by selected category
      if (this.sizeCategory._id) {
        list = list.filter(item => {
          return item.category._id === this.sizeCategory._id
        })
      }
      // sort list by name
      return this.sortByString(list, 'name')
    }
  },
  methods: {
    addAvailableSizes () {
      this.sizesAvailable = this.availableSizes
      this.save()
    },
    save () { this.$store.dispatch('products/_UPDATE') }
  }
}
</script>
