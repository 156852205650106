<template lang="pug">
div
  h3.title.mb-3 subproducts
  AddSubproduct
  DuplicateSubproduct
  DeleteSubproduct
  v-divider.my-3
  h3.title.mb-3 product colors
  AddProductColor
  v-divider.my-3
  h3.title.mb-3 this product
  DeleteProduct
</template>

<script>
import AddSubproduct from './AddSubproduct'
import DuplicateSubproduct from './DuplicateSubproduct'
import DeleteSubproduct from './DeleteSubproduct'
import AddProductColor from './AddProductColor'
import DeleteProduct from './DeleteProduct'
export default {
  components: {
    AddSubproduct,
    DuplicateSubproduct,
    DeleteSubproduct,
    AddProductColor,
    DeleteProduct
  }
}
</script>
