<template lang="pug">
v-container
  LineItemHeader
  div(v-for="(line, i) in linesSorted" :key="i")
    LineItem(:line="line")
</template>
<script>
import { PurchaseOrderLine } from '@/models'
import { subsummaryProductNames } from '../../helpers'
import { mapFields } from 'vuex-map-fields'
import LineItemHeader from './line-item-header'
import LineItem from './line-item'

export default {
  computed: {
    ...mapFields('purchaseOrders',{
      item: 'item',
      lines: 'item.lines',
    }),
    ...mapFields('jobs',{
      job: 'item',
      productGroups: 'item.productGroups',
    }),
    lineItems () {
      const lines = []
      this.productGroups.forEach(pg => {
        pg.lineItems.forEach(line => {
          // exit if no quantity
          if (line.quantity === 0) { return }

          const find = this.lines.find(x => x.lineItem._id === line._id )
          const readonly = find === undefined ? true : false
          const newLine = new PurchaseOrderLine({
            lineItem: line,
            productName: pg.product.displayName,
            product_id: pg.product._id,
            job_id: this.job._id
          })
          newLine.readonly = readonly
          lines.push(newLine)
        })
      })
      return lines
    },
    linesSorted () {
      const sorted = this.sortByString(this.lineItems, 'sortStringProduct')

      return subsummaryProductNames(sorted)
    }
  },
  
  components: { LineItem, LineItemHeader }  
  
}
</script>
