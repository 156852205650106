<template lang="pug">
DetailSmall(:detail="detail")
  template(slot="title") contact detail.
  template(slot="form")
    v-layout(row)
      v-flex(xs6)
        v-text-field(label="title" v-model="title")
      v-flex(xs6 pl-3)
        v-checkbox(v-model="isPrimary" label="primary contact?")

    v-layout(row)
      v-flex(xs6)
        v-text-field(label="first name" v-model="nameFirst")
      v-flex(xs6 pl-3)
        v-text-field(label="last name" v-model="nameLast")

    v-layout(row)
      v-flex(xs6)
        v-text-field(label="email" v-model="email")
      v-flex(xs6 pl-3)
        v-text-field(label="phone" v-model="phone")

    v-layout(row)
      v-flex
        v-textarea(label="notes" v-model="notes")

    h3.title.font-weight-bold.error--text.py-3 spekkio use only
    v-layout(row)
      v-flex(xs6)
        v-text-field(label="spekkio id" v-model="spekkioId")
      v-flex(xs6 pl-3)
        v-text-field(label="spekkio name" v-model="spekkioName")
  template(slot="controls")
    DetailControls(
      @save="save"
      @cancel="cancel"
      @remove="remove"
    )
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import DetailControls from '@/components/base/detail-controls-simple'
import DetailSmall from '@/components/base/detail-small-modal'

export default {
  props: {
    detail: { type: Boolean, required: true }
  },
  computed: {
    ...mapFields('companies', {
      item: 'contact',
      title: 'contact.title',
      isPrimary: 'contact.isPrimary',
      nameFirst: 'contact.nameFirst',
      nameLast: 'contact.nameLast',
      email: 'contact.email',
      phone: 'contact.phone',
      notes: 'contact.notes',
      spekkioId: 'contact.options.spekkio.id',
      spekkioName: 'contact.options.spekkio.name'
    })
  },
  methods: {
    save () {
      this.$store.commit('companies/itemsUpdate', { list: 'item.contacts', item: this.item })
      this.$store.dispatch('companies/_SAVE')
      this.cancel()
    },

    remove () {
      this.$store.commit('companies/itemsRemove', { list: 'item.contacts', item: this.item })
      this.$store.dispatch('companies/_SAVE')
      this.cancel()
    },

    cancel () { this.$emit('cancel') }

  },
  components: {
    DetailSmall,
    DetailControls
  }
}
</script>
