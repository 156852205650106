<template lang="pug">
div
  v-list-tile()
    v-list-tile-content
      v-list-tile-title
        v-layout(row)
          v-flex {{otherCharge.name}}
          v-flex(xs2).text-xs-right {{otherCharge.quantity}}
          v-flex(xs2 pl-3).text-xs-right {{otherCharge.costExtend | currency}}
  v-divider

</template>

<script>
export default {
  props: {
    order: { required: true, type: Object },
    otherCharge: { required: true, type: Object }
  }
}
</script>
