<template lang="pug">
div
  v-layout(row)
    v-flex
      v-text-field(
        label="name"
        v-model="name"
        @change="save"
      )
  v-layout(row)
    v-flex
      v-text-field(
        label="serial"
        v-model="serial"
        @change="save"
      )
  v-layout(row)
    v-flex related jobs: {{ jobNumbers }}
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  computed: {
    ...mapFields('designs', {
      name: 'item.name',
      serial: 'item.serial',
      jobs: 'item.jobs',
      // controls
      editState: 'editState'
    }),
    jobNumbers () { return this.jobs.map(job => job.jobNumber) }
  },
  methods: {
    save () { this.$store.dispatch('designs/_SAVE') }
  }
}
</script>
