<template lang="pug">
div
  InitializeQuantities
  v-divider.my-3
  MarkOrdered
  v-divider.my-3
  MarkReceived
  v-divider.my-3
  DeletePurchaseOrder
</template>

<script>
import InitializeQuantities from './InitializeQuantities'
import MarkOrdered from './MarkOrdered'
import MarkReceived from './MarkReceived'
import DeletePurchaseOrder from './DeletePurchaseOrder'

export default {
  components: {
    InitializeQuantities,
    MarkOrdered,
    MarkReceived,
    DeletePurchaseOrder,
  }
}
</script>
