/** Model definition file for the Contact Class **/
import uuid from 'uuid/v4'

export default class Contact {
  constructor ({
    _id = uuid(),
    nameFirst = '',
    nameLast = '',
    title = '',
    email = '',
    phone = '',
    notes = '',
    contactInfo = [],
    isPrimary = false,
    options = {
      spekkio: {
        id: null,
        name: ''
      }
    }
  } = {}) {
    this._id = _id
    this.nameFirst = nameFirst
    this.nameLast = nameLast
    this.title = title
    this.email = email
    this.phone = phone
    this.notes = notes
    this.contactInfo = contactInfo
    this.isPrimary = isPrimary
    this.options = options
  }
  get fullName () {
    let full = ''
    full += this.nameFirst ? this.nameFirst : ''
    full += this.nameLast ? ' ' + this.nameLast : ''
    return full
  }
}
