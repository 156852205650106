<template lang="pug">
v-container()
  v-toolbar(flat)
    v-toolbar-title users
    v-spacer
    v-btn(@click='add') add user
  v-list()
    v-list-tile(
      v-for='(item, i) in items'
      :key='i'
      @click='select(item)'
    )
      v-list-tile-title
        v-layout
          v-flex(xs3) {{ item.username }}
          v-flex(xs6) {{ item.email }}
          v-flex(xs3) {{ item.role }}

</template>

<script>
export default {
  computed: {
    items () { return this.$store.state.users.items }
  },
  methods: {
    async add () {
      const newUser = await this.$store.dispatch('users/create')
      this.select(newUser)
    },

    select (item) { this.$router.push({ path: '/users/' + item._id }) }
  },
  async created () {
    await this.$store.dispatch('users/readall')
  }
}
</script>
