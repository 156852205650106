import axios from 'axios'
import common from '@/store/common'
import crud from './crud'
import helpers from './helpers'
import { PurchaseOrder as Model } from '../../'
import { PurchaseOrderLine } from '@/models'

export default {
  namespaced: true,
  state: {
    path: 'purchase-orders',
    ...common.state(Model),
  },
  getters: { ...common.getters() },
  mutations: { ...common.mutations() },
  actions: {
    ...common.actions(Model),
    ...crud,
    ...helpers,

    senddiscrepancy({ state, dispatch, rootState }, { notes, discrepancies}) {
      const data = {
        po: state.item._id,
        ponumber:state.item.number,
        job: rootState.jobs.item._id,
        jobname: rootState.jobs.item.design.name,
        notes,
        discrepancies
      }

      console.log(data)

      return axios.post('/purchase-orders/reportdiscrepancy', data)
    },

    spekkioreceivingdiscrepancy({ state, dispatch, rootState }, payload) {
      const { notes, discrepancies } = payload
      const data = {
        po: state.item._id,
        job: rootState.jobs.item._id,
        notes,
        discrepancies
      }
  
      return axios.post('/purchase-orders/spekkioreceivingdiscrepancy', data)
    },

    async getdiscrepancylineitems ({ state, dispatch, rootState }) {
      // generate lines
      const lines = []
      console.log(rootState.jobs)
      rootState.jobs.item.productGroups.forEach(pg => {
        pg.lineItems.forEach(line => {
          // exit if no quantity
          if (line.quantity === 0) return null

          const newLine = new PurchaseOrderLine({
            lineItem: line,
            productName: pg.product.displayName,
            product_id: pg.product._id,
            job_id: rootState.jobs.item._id
          })
          lines.push(newLine)
        })
      })
      // find discrepancies
      const discrepancies = lines.filter(x => x.lineItem.quantityOrdered !== x.lineItem.quantityReceived)

      const mapped =  discrepancies.map(x => {
        return `${x.productName} - ${x.lineItem.color.name} - ${x.lineItem.size.name}: ordered ${x.lineItem.quantityOrdered}, received ${x.lineItem.quantityReceived}`
      })

      return mapped
    },

    discrepancieshtmllist(ctx, arr) {
      let results = '<ul>'
      for (const dis of arr) {
        results += `<li>${dis}</li>`
      }
      results += '</ul>'

      return results
    }
  }
}
