import list from '../views/list'
import detail from '../views/detail'
import LoginPage from '../views/login'

export default [
  { path: '/login', component: LoginPage },
  {
    path: '/users',
    name: 'users list',
    component: list,
    meta: { authorize: ['Admin'] }
  },
  {
    path: '/users/:id',
    name: 'users detail',
    component: detail,
    meta: { authorize: ['Admin'] }
  }
]