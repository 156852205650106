<template lang="pug">
div
  v-btn.white--text(
    dark
    color="pink accent-1"
    @click="addMockupPage"
  ) add mockup page

  DetailSimple(
    :detail="detail"
    @cancel="cancel"
  )
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { MockupPage } from '@/models'
import DetailSimple from '../mockups/detail'

export default {
  data: () => ({
    detail: false
  }),
  computed: {
    ...mapFields('designs', {
      selectedMockup: 'selected.mockup',
      actions: 'actions',
    })
  },
  methods: {
    addMockupPage () {
      this.selectedMockup = new MockupPage()
      this.$store.dispatch('designs/_SAVE')
      this.detail = true
    },

    cancel () {
      this.detail = false
      this.actions = false
    }

  },
  components: { DetailSimple }
}
</script>
