
export default {
  async _LOAD_JOBS_FOR_DOCUMENT ({ commit, dispatch, state, rootState }) {
    dispatch('setup')
    // loop through jobs, adding product groups
    const apicalls = []
    for (const job of rootState.jobs.items) {
      commit('jobs/item', job, { root: true })
      await dispatch('jobs/_LOAD_PRODUCT_GROUPS_FOR_DOCUMENT', null, { root: true })
    }
    dispatch('cleanup')
  },

  
}