<template lang="pug">
v-container(fluid)
  BaseListSearchOnly(
    :items="itemsSorted"
    searchLabel="search designs"
    avatar=true
    @search="setSearch"
    @select="select"
    @add="add"
  )
    template(slot-scope="{ item }")
      v-layout(row)
        v-flex(xs6) {{ item.serial }} - {{item.name}}
    template(slot="title")
      |
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import BaseListSearchOnly from '@/components/base/list-search-only'

export default {
  components: { BaseListSearchOnly },
  computed: {
    ...mapFields('companies', {
      items: 'lists.designs',
      search: 'lists.searchDesigns'
    }),
    itemsSorted () {
      // filter by search term
      let list = this.filterByProps(this.items, ['displayName'], this.search)
      // sort list by name
      return this.sortByString(list, 'displayName')
    }
  },
  methods: {
    async add () {
      const add = await this.$store.dispatch('designs/_ADD')
      this.detail(add._id)
    },
    select (item) {
      this.detail(item._id)
    },
    detail (_id) {
      this.$router.push({ path: '/designs/' + _id })
    },
    setSearch (search) {
      this.search = search
    }
  }
}
</script>
