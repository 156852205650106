export default [
  { 
    title: 'Shipments', 
    icon: 'next_week', 
    route: { activate: 'shipments' }, 
    top: true, 
    role: ['Admin', 'User', 'Purchasing', 'ProductionLead', 'Production'] 
  },
  { 
    title: 'Ready to ship', 
    icon: 'next_week', 
    route: { path: '/shipments' }, 
    module: 'shipments',
    role: ['Admin', 'User', 'Purchasing', 'ProductionLead', 'Production'] 
  },
  { 
    title: 'Fangamer pickup', 
    icon: 'local_shipping', 
    route: { path: '/shipments/fangamerpickup' }, 
    module: 'shipments',
    role: ['Admin', 'User', 'Purchasing', 'ProductionLead', 'Production'] 
  },
]
