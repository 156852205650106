<template lang="pug">
v-container
  v-layout(row wrap)
    v-flex(xs6)
      FwdCard(v-if="isPointOfContact")
        template(slot="title") order approval
        template(slot="content")
          Approval
      FwdCard
        template(slot="title") point of contact
        template(slot="content")
          PointOfContact        
    v-flex(xs6 pl-5)
      FwdCard(v-if="isPointOfContact")
        template(slot="title") status: 
          span.black--text {{ status.name }}
        template(slot="content")
          OrderStatus
      
</template>

<script>
import PointOfContact from './contact'
import Approval from './approval'
import OrderStatus from './order-status'

export default {
  computed: {
    order () { return this.$store.getters['orders/item'] },
    company () { return this.$store.getters['companies/item'] },
    status() { return this.order.status },
    isPointOfContact () { return this.order.contact.email }
  },
  components: {
    PointOfContact,
    Approval,
    OrderStatus
    }
}
</script>
