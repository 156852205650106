<template lang="pug">
v-layout.line-item.body-1(row px-4 align-center)
  v-flex(xs3)
    v-layout(row align-center)
      v-flex
        span.px-0
          v-avatar(size=31 tile)
            img(:src="line.color.image.url")
        span.pl-3 {{line.color.name}}

  v-flex(xs1).text-xs-center
    span.px-0.text-uppercase {{line.size.name}}

  v-flex(xs5)
    v-layout(row)
      v-flex(xs3)
        template(v-if='settings.quantity.show')
          Quantity(:line='line' type='quantity' :input='settings.quantity.input')
      v-flex(xs3)
        template(v-if='settings.quantityOrdered.show')
          Quantity(:line='line' type='quantityOrdered' :input='settings.quantityOrdered.input')
      v-flex(xs3)
        template(v-if='settings.quantityReceived.show')
          Quantity(:line='line' type='quantityReceived' :input='settings.quantityReceived.input')
      v-flex(xs3)
        template(v-if='settings.quantityFinal.show')
          Quantity(:line='line' type='quantityFinal' :input='settings.quantityFinal.input')
  v-flex(xs1).text-xs-right
    template(v-if='settings.cost.show') 
      span.px-0 {{line.cost | currency}}

  v-flex(xs2).text-xs-right
    template(v-if='settings.costExtend.show')
      span(v-if='line.costExtend !== 0').px-0 {{line.costExtend | currency}}

</template>
<script>
import Quantity from './quantity'

export default {
  props: ['line'],
  computed: {
    settings () { return this.$store.getters['productGroups/settings'] }
  },

  components: { Quantity }
}
</script>

<style lang="stylus" scoped>
.line-item
  border-bottom solid #FCE4EC 1px
</style>
