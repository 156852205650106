export default [ 
  {
    path: '/dashboards/schedule/view',
    name: 'schedule read only',
    component: () => import('../views/schedule-read-only'),
    meta: {
      authorize: [
        'Admin',
        'User',
        'Screens',
        'Inks',
        'Production',
        'Shipping',
        'ProductionLead',
        'Fangamer',
        'Purchasing'
      ]
    }
  },
  {
    path: '/dashboards/schedule/view/:id',
    name: 'schedule read only detail',
    component: () => import('../views/schedule-read-only/detail'),
    meta: {
      authorize: [
        'Admin',
        'User',
        'Screens',
        'Inks',
        'Production',
        'Shipping',
        'ProductionLead',
        'Fangamer',
        'Purchasing'
      ]
    }
  },
  {
    path: '/dashboards/art',
    name: 'dashboards art ',
    component: () => import('../views/art'),
    meta: { authorize: ['Admin', 'User', 'Screens', 'Inks', 'ProductionLead'] }
  },
  {
    path: '/dashboards/art-pending',
    name: 'dashboards art pending',
    component: () => import('../views/art-pending'),
    meta: { authorize: ['Admin', 'User', 'Screens', 'Inks', 'ProductionLead'] }
  },
  {
    path: '/dashboards/art/:id',
    name: 'dashboards detail - art',
    component: () => import('../views/art/detail'),
    meta: { authorize: ['Admin', 'User', 'Screens', 'Inks', 'ProductionLead'] }
  },
  {
    path: '/dashboards/screens',
    name: 'dashboards screens ',
    component: () => import('../views/screens'),
    meta: {
      authorize: ['Admin', 'User', 'Screens', 'Shipping', 'ProductionLead']
    }
  },
  {
    path: '/dashboards/screens/:id',
    name: 'dashboards detail - screens',
    component: () => import('../views/screens/detail'),
    meta: {
      authorize: ['Admin', 'User', 'Screens', 'Shipping', 'ProductionLead']
    }
  },
  {
    path: '/dashboards/inks',
    name: 'dashboards inks ',
    component: () => import('../views/inks'),
    meta: { authorize: ['Admin', 'User', 'Screens', 'Inks', 'ProductionLead'] }
  },
  {
    path: '/dashboards/inks/:id',
    name: 'dashboards detail - inks',
    component: () => import('../views/inks/detail'),
    meta: { authorize: ['Admin', 'User', 'Screens', 'Inks', 'ProductionLead'] }
  },
  {
    path: '/dashboards/preflight',
    name: 'dashboards preflight ',
    component: () => import('../views/preflight'),
    meta: { authorize: ['Admin'] }
  },
  {
    path: '/dashboards/production',
    name: 'production',
    component: () => import('../views/production'),
    meta: {
      authorize: [
        'Admin',
        'User',
        'Screens',
        'Inks',
        'Production',
        'Shipping',
        'ProductionLead'
      ]
    }
  },
  {
    path: '/dashboards/in-progress',
    name: 'in progress',
    component: () => import('../views/in-progress'),
    meta: {
      authorize: [
        'Admin',
        'User',
        'Screens',
        'Inks',
        'Production',
        'ProductionLead'
      ]
    }
  },
  {
    path: '/dashboards/completed',
    name: 'completed',
    component: () => import('../views/completed'),
    meta: {
      authorize: [
        'Admin',
        'User',
        'Screens',
        'Inks',
        'Production',
        'ProductionLead',
        'Fangamer'
      ]
    }
  },
  {
    path: '/dashboards/production/:id',
    name: 'dashboards detail - production',
    component: () => import('../views/production/'),
    meta: {
      authorize: [
        'Admin',
        'User',
        'Screens',
        'Inks',
        'Production',
        'ProductionLead',
        'Fangamer'
      ]
    }
  }
]
