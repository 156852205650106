import sortFilter from '@/mixins/sort-filter'
const sf = sortFilter.methods

export default (payload) => {

  // unpack
  const { items, search, filter } = payload

  // filter by search term
  let list = sf.filterByProps(items, ['displayName'], search)

  // filter by selected brand
  list = sf.filterByPropsId(list, ['brand'], filter)

  // sort list by name
  return sf.sortByString(list, 'name')

}
