<template lang="pug">
div
  v-list-tile()
    v-list-tile-content
      v-list-tile-title
        v-layout(row)
          v-flex {{job.displayName}}
          v-flex(xs2 v-if='!order.isStatusFinal').text-xs-right {{ job.quantity }}
          v-flex(xs2 v-else).text-xs-right {{ job.quantityFinal }}

          v-flex(xs2 pl-3 v-if='!order.isStatusFinal').text-xs-right {{ job.subtotal | currency }}
          v-flex(xs2 pl-3 v-else).text-xs-right {{ job.subtotalFinal | currency }}
      v-list-tile-sub-title {{job.design.subtitleText}}
  v-divider

</template>

<script>
export default {
  props: {
    order: { required: true, type: Object },
    job: { required: true, type: Object }
  }
}
</script>
