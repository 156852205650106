<template lang="pug">
v-container(grid-list-lg)
  v-layout(row)
    v-flex(xs6)
      FwdCard
        template(slot="title") design
        template(slot="img" v-if="design.image.url")
          v-img(
            :src="design.image.url"
            aspect-ratio=2.75
          )
        template(slot="content")
          v-layout.title(row)
            v-flex(xs10) {{ design.name }}
          v-layout(row)
            v-flex
              v-text-field(
                v-model="subtitle"
                label="subtitle"
                @change='updateSubtitle(subtitle)'
              )
          v-layout(row)
            v-flex(style="{white-space: pre-line; white-space: pre-wrap}") {{ design.subtitleText }}
        template(slot="actions")
          v-layout(row)
            v-flex
              v-btn(@click="changeDesign") change design

    v-flex(xs6)
      FwdCard
        template(slot="title") job info
        template(slot="content")
          v-layout.title(row)
            v-flex
              span.font-weight-bold job number:
              | {{jobNumber}}
          v-layout.mt-2.title(row)
            v-flex.font-weight-bold quantity (original): {{quantity}}
          v-layout.mt-2.title(row)
            v-flex.font-weight-bold quantity (final): {{quantityFinal}}
          v-layout.mt-2.title(row)
            v-flex
              v-text-field(
                v-model='quantityOverride'
                label='quantity override'
                @change='updateProductCost'
              )
          v-layout.mt-2.title(row)
            v-flex
              v-text-field(
                v-model.number='decorationCostOverride'
                label='decoration cost override'
                @change='updateProductCost'
              )
            v-flex
              v-btn(@click='zerodeccost' color='primary') zero decoration cost
          v-layout.mt-2.title(row)
            v-flex
              span.font-weight-bold total cost: {{subtotal | currency}}
          v-layout.mt-2.title(row)
            v-flex
              span.font-weight-bold total cost final: {{subtotalFinal | currency}}
          v-layout.mt-2.title(row)
            v-flex
              v-checkbox(
                v-model="removeProductCost"
                label="remove product cost?"
                color="pink"
                @change="updateProductCost"
              )
          v-layout.mt-2.title(row)
            v-flex PurchaseOrder's on this job: {{ pos }}

  v-layout(row)
    v-flex
      FwdCard
        template(slot="title") job progress
        template(slot="content")
          JobProgressBar(:progress="job.progress")
  v-layout(row)
    v-flex
      pre {{job.spekkio}}
  DetailDesign
</template>

<script>
import { Design } from '@/models'
import { mapFields } from 'vuex-map-fields'
import ProductGroups from '../product-groups'
import DetailDesign from '../design'
import JobProgressBar from '@/components/JobProgressBar'

export default {
  components: { ProductGroups, DetailDesign, JobProgressBar },
  computed: {
    ...mapFields('jobs', {
      job: 'item',
      removeProductCost: 'item.removeProductCost',
      subtitle: 'item.subtitle',
      jobNumber: 'item.jobNumber',
      quantity: 'item.quantity',
      quantityFinal: 'item.quantityFinal',
      quantityOverride: 'item.quantityOverride',
      decorationCostOverride: 'item.pricing.jobCosts.decorationCostOverride',
      subtotal: 'item.subtotal',
      subtotalFinal: 'item.subtotalFinal',
      quantityBreakGroup: 'item.quantityBreakGroup',
      loaded: 'loaded'
    }),
    ...mapFields('designs', {
      design: 'item',
      detail: 'detail',
      input: 'input'
    }),
    ...mapFields('companies', {
      company: 'item'
    }),
    ...mapFields('orders', {
      order: 'item'
    }),
    subtitleText () {
      return this.design.subtitleText
    },
    pos () {
      return this.job.posOnThisJob.map(x => x.poNumber)
    },
    isFinal () {
      // if order in confirmed or later state, we can now use final column
      const orderStatus = this.order.status.name
      console.log(!['estimate', 'quote', 'pending'].includes(orderStatus))
      return !['estimate', 'quote', 'pending'].includes(orderStatus)
    }
  },
  methods: {
    async updateProductCost () {
      await this.$store.dispatch('productGroups/updateAll')
    },
    async changeDesign () {
      this.design = new Design()
      await this.$store.dispatch('jobs/_LOAD_COMPANY_DESIGNS', this.company._id)
      this.input.method = 'update'
      this.detail = true
    },
    save () { this.$store.dispatch('jobs/_SAVE') },
    updateSubtitle (subtitle) {
      this.$store.dispatch('productionInstances/updateSubtitle', { subtitle, job: this.job._id })
      this.save()
    },
    zerodeccost () {
      this.decorationCostOverride = 0.0000001
      this.updateProductCost()
    }
  }
}
</script>
