<template lang="pug">
v-card.mb-3(flat @click="click")
  slot(name="img" fluid)
  v-card-title.pa-1.pb-2.text-lowercase.headline.font-weight-bold
    slot(name="title")
  v-card-text.pa-1
    slot(name="content")
  v-card-actions.pa-1
    slot(name="actions")
</template>

<script>
export default {
methods: {
  click () {
    this.$emit('clicked')
  }
}
}
</script>

<style lang="stylus" scoped>
.outline
  border-right 1px solid #FCE4EC
  border-bottom 1px solid #FCE4EC
</style>