import pdfmake from '@/util/pdfmake'
import constants from '@/assets/constants'

export default {
    download
}
// top level functions
function download(payload) {
    const { order, company } = payload
    createpdf(payload).download(
        `${order.status.name} ${order.orderNumber} - ${company.name} - ${order.name}`
    )
}

function getBase64(payload) {
    return createpdf(payload).getBase64()
}

// helpers

function isFangamer(payload) {
    const { company } = payload
    return company._id === constants.fangamerid
}

function createpdf({ order, company, jobs, otherCharges }) {
    return pdfmake.createPdf(
        docDefinition({ order, company, jobs, otherCharges })
    )
}
function docDefinition({ order, company, jobs, otherCharges }) {
    return {
        info: {
            title: `${order.orderNumber} - ${company.name} - ${order.name}`
        },
        content: [
            ...docheader({ order, company }),
            ...docsummary({ order, jobs, otherCharges }),
            ...docproducts({ order, jobs }),
            ...docCOGproducts({ order, jobs, company })
        ],
        pageBreakBefore: function(
            currentNode,
            followingNodesOnPage,
            nodesOnNextPage,
            previousNodesOnPage
        ) {
            return (
                currentNode.headlineLevel === 1 &&
                followingNodesOnPage.length === 0
            )
        }
    }
}

function docsummary({ order, jobs, otherCharges }) {
    // summary
    return [
        { text: `Order Summary`, bold: true, fontSize: 20 },
        costlist({ order, jobs, otherCharges }),
        costsummary({ order })
    ]
}

function docproducts({ order, jobs }) {
    return [
        // product breakdown
        {
            text: `Product breakdown (per design) is available on the following page(s)`,
            color: 'blue',
            fontSize: 12,
            margin: [0, 20, 0, 0]
        },
        ...productbreakdown({ order, jobs })
    ]
}

function docCOGproducts({ order, jobs, company }) {
    if (!isFangamer({ company })) return []
    console.log({ order })
    const internalCOG = Math.round(order.costs.cog * 100) / 100
    const trueCOG = Math.round(order.costs.cogTrue * 100) / 100
    return [
        // product breakdown
        {
            text: `Product breakdown with COG is available on the following page(s)`,
            bold: true,
            color: 'blue',
            fontSize: 36,
            margin: [0, 20, 0, 0],
            pageBreak: 'before'
        },
        {
            text: `internal COG + markup: $${internalCOG}`,
            bold: true,
            fontSize: 24,
            margin: [0, 20, 0, 0]
        },
        {
            text: `true COG: $${trueCOG}`,
            bold: true,
            fontSize: 24,
            margin: [0, 20, 0, 0]
        },
        ...productCOGbreakdown({ order, jobs })
    ]
}

function costlist({ order, jobs, otherCharges }) {
    const headers = [
        { text: 'item', bold: true },
        { text: 'quantity', bold: true },
        { text: 'cost', bold: true }
    ]
    const lines = jobs.map(x => {
        const lineresult = []
        lineresult.push(`${x.displayName} - ${x.design.subtitleText}`)
        const quantity = order.isStatusFinal ? x.quantityFinal : x.quantity
        lineresult.push(quantity)
        const subtotal = order.isStatusFinal ? x.subtotalFinal : x.subtotal
        lineresult.push(formatcurrency(subtotal))
        return lineresult
    })
    const othercharges = otherCharges.map(x => {
        const lineresult = []
        lineresult.push(`${x.name}`)
        const quantity = x.quantity
        lineresult.push(quantity)
        const subtotal = x.costExtend
        lineresult.push('$' + formatcurrency(subtotal))
        return lineresult
    })
    const body = [headers].concat(lines).concat(othercharges)
    return {
        layout: 'lightHorizontalLine',
        table: {
            headerRows: 1,
            widths: ['*', 'auto', 'auto'],
            body: body
        }
    }
}

function costsummary({ order }) {
    const ordercosts = order.costs
    const lines = []
    const jobssubtotal = order.isStatusFinal
        ? ordercosts.jobsSubtotalFinal
        : ordercosts.jobsSubtotal
    lines.push([
        { text: 'jobs subtotal', alignment: 'right', bold: true },
        { text: formatcurrency(jobssubtotal), alignment: 'right' }
    ])

    const shippingsubtotal = ordercosts.shippingSubtotal
    lines.push([
        { text: 'shipping subtotal', alignment: 'right', bold: true },
        { text: formatcurrency(shippingsubtotal), alignment: 'right' }
    ])

    const otherchargessubtotal = ordercosts.otherChargesSubtotal
    lines.push([
        { text: 'other charges subtotal', alignment: 'right', bold: true },
        { text: formatcurrency(otherchargessubtotal), alignment: 'right' }
    ])

    const isadjustment =
        ordercosts.adjustment ||
        (order.isStatusFinal && ordercosts.adjustmentFinal)
    const adjustmentsubtotal = order.isStatusFinal
        ? ordercosts.adjustmentFinal
        : ordercosts.adjustment
    if (isadjustment) {
        lines.push([
            { text: 'adjustment subtotal', alignment: 'right', bold: true },
            { text: formatcurrency(adjustmentsubtotal), alignment: 'right' }
        ])
    }

    const ordersubtotal = order.isStatusFinal
        ? ordercosts.subtotalFinal
        : ordercosts.subtotal
    lines.push([
        { text: 'order subtotal', alignment: 'right', bold: true },
        { text: formatcurrency(ordersubtotal), alignment: 'right' }
    ])

    let salestax = 0
    if (ordercosts.taxOverride) {
        salestax = ordercosts.taxOverride
    } else if (order.isStatusFinal) {
        salestax = ordercosts.taxFinal
    } else {
        salestax = ordercosts.tax
    }
    lines.push([
        { text: 'sales tax', alignment: 'right', bold: true },
        { text: formatcurrency(salestax), alignment: 'right' }
    ])

    const ordertotal = order.isStatusFinal
        ? ordercosts.totalFinal
        : ordercosts.total
    lines.push([
        { text: 'total', alignment: 'right', bold: true },
        { text: formatcurrency(ordertotal), alignment: 'right' }
    ])

    return {
        layout: 'lightHorizontalLine',
        table: {
            widths: ['*', 'auto'],
            body: lines
        }
    }
}

function docheader({ order, company }) {
    return [
        {
            text: `${company.name} - ${order.name}`,
            bold: true,
            fontSize: 24,
            margins: [0, 0, 30, 0]
        },
        {
            text: [
                { text: `status: `, bold: true },
                { text: `${order.status.name}` }
            ]
        },
        {
            text: [
                { text: `order number: `, bold: true },
                { text: `${order.orderNumber}` }
            ]
        },
        {
            text: [
                { text: `point of contact: `, bold: true },
                { text: `${order.contact.fullName} - ${order.contact.email}` }
            ]
        },
        {
            text: [
                { text: `owner: `, bold: true },
                { text: `${order.owner.username} - ${order.owner.email}` }
            ],
            margin: [0, 0, 0, 10]
        }
    ]
}

function productbreakdown({ order, jobs }) {
    const results = []

    for (const job of jobs) {
        const designname = job.design.subtitle
            ? `${job.design.name} - ${job.design.subtitle}`
            : `${job.design.name}`
        const table = []

        for (const pg of job.productGroups) {
            const lineitems = pg.lineItems.filter(line => line.quantity !== 0)
            lineitems.forEach(line => {
                table.push([
                    pg.product.displayName,
                    line.color.name,
                    line.size.name,
                    {
                        text: order.isStatusFinal
                            ? line.quantityFinal
                            : line.quantity,
                        alignment: 'right'
                    },
                    { text: formatcurrency(line.cost), alignment: 'right' },
                    {
                        text: order.isStatusFinal
                            ? formatcurrency(line.costExtendFinal)
                            : formatcurrency(line.costExtend),
                        alignment: 'right'
                    }
                ])
            })
        }
        results.push({
            text: designname,
            bold: true,
            fontSize: 16,
            margin: [0, 20, 0, 0],
            pageBreak: 'before'
        })
        results.push({
            text: `${job.design.subtitleText}`,
            fontSize: 12,
            margin: [0, 0, 0, 10]
        })
        results.push(formatproducttable(table))
        results.push({
            text: [
                { text: 'quantity: ', bold: true, fontSize: 12 },
                {
                    text: order.isStatusFinal
                        ? job.quantityFinal
                        : job.quantity,
                    fontSize: 12
                }
            ],
            margin: [0, 10, 0, 0]
        })
        results.push({
            text: [
                { text: 'cost: ', bold: true, fontSize: 12 },
                {
                    text: order.isStatusFinal
                        ? formatcurrency(job.subtotalFinal)
                        : formatcurrency(job.subtotal),
                    fontSize: 12
                }
            ],
            margin: [0, 10, 0, 0]
        })
    }
    return results
}

function productCOGbreakdown({ order, jobs }) {
    const results = []
    const total = order.costs.totalFinal
    const printCost = total - order.costs.cog
    const printCostPer = printCost / order.costs.quantityTrue

    for (const job of jobs) {
        const designname = job.design.subtitle
            ? `${job.design.name} - ${job.design.subtitle}`
            : `${job.design.name}`
        const table = []
        const markup = job.pricing.tier.markup * 0.01 + 1
        for (const pg of job.productGroups) {
            const lineitems = pg.lineItems.filter(line => line.quantity !== 0)
            lineitems.forEach(line => {
                table.push([
                    pg.product.displayName,
                    line.color.name,
                    line.size.name,
                    {
                        text: order.isStatusFinal
                            ? line.quantityFinal
                            : line.quantity,
                        alignment: 'right'
                    },
                    {
                        text: formatcurrency(line.costProductTrue),
                        alignment: 'right'
                    },
                    {
                        text: formatcurrency(line.costProduct * markup),
                        alignment: 'right'
                    },
                    {
                        text: formatcurrency(printCostPer),
                        alignment: 'right'
                    },
                    {
                        text: formatcurrency(
                            printCostPer + line.costProduct * markup
                        ),
                        alignment: 'right'
                    }
                ])
            })
        }
        results.push({
            text: `COG: ${designname}`,
            bold: true,
            fontSize: 16,
            margin: [0, 20, 0, 0],
            pageBreak: 'before'
        })
        results.push({
            text: `${job.design.subtitleText}`,
            fontSize: 12,
            margin: [0, 0, 0, 10]
        })
        results.push(formatCOGtable(table))
        results.push({
            text: [
                { text: 'quantity: ', bold: true, fontSize: 12 },
                {
                    text: order.isStatusFinal
                        ? job.quantityFinal
                        : job.quantity,
                    fontSize: 12
                }
            ],
            margin: [0, 10, 0, 0]
        })

        // handle the column legend
        results.push([
            {
                text: 'column cost legend: ',
                bold: true,
                fontSize: 24,
                margin: [0, 20, 0, 0]
            },
            {
                text: [
                    { text: 'true COG: ', bold: true },
                    {
                        text:
                            'the true cost paid for the product after placing the order with a distributor.'
                    }
                ],
                fontSize: 12,
                margin: [0, 10, 0, 0]
            },
            {
                text: [
                    { text: 'product cost: ', bold: true },
                    { text: 'our internal list price plus markup.' }
                ],
                fontSize: 12,
                margin: [0, 10, 0, 0]
            },
            {
                text: [
                    { text: 'print cost: ', bold: true },
                    {
                        text:
                            'total cost to decorate the garment, including ancillary costs like ink mixes, finishing and sample costs.'
                    }
                ],
                fontSize: 12,
                margin: [0, 10, 0, 0]
            },
            {
                text: [
                    { text: 'cost per: ', bold: true },
                    {
                        text:
                            'this is simply the sum of the print and product cost.'
                    }
                ],
                fontSize: 12,
                margin: [0, 10, 0, 0]
            }
        ])
    }
    return results
}

// utils

function formatproducttable(body) {
    return {
        layout: 'lightHorizontalLine',
        table: {
            headerRows: 1,
            widths: ['*', 'auto', 'auto', 'auto', 'auto', 'auto'],
            body: [
                [
                    { text: 'item', bold: true },
                    { text: 'color', bold: true },
                    { text: 'size', bold: true },
                    { text: 'quantity', bold: true },
                    { text: 'cost', bold: true },
                    { text: 'extend', bold: true }
                ]
            ].concat(body)
        }
    }
}

function formatCOGtable(body) {
    return {
        layout: 'lightHorizontalLine',
        table: {
            headerRows: 1,
            widths: [
                '*',
                'auto',
                'auto',
                'auto',
                'auto',
                'auto',
                'auto',
                'auto'
            ],
            body: [
                [
                    { text: 'item', bold: true },
                    { text: 'color', bold: true },
                    { text: 'size', bold: true },
                    { text: 'quantity', bold: true },
                    { text: 'true COG', bold: true },
                    { text: 'product cost', bold: true },
                    { text: 'print cost', bold: true },
                    { text: 'total per', bold: true }
                ]
            ].concat(body)
        }
    }
}

function formatcurrency(num) {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    })

    return formatter.format(num)
}
