<template lang="pug">
v-layout(row)
  v-flex(xs6)
    v-btn.white--text(
      color="pink accent-1"
      :disabled="!allowDelete"
      @click="deleteOrder"
    ) delete design
  v-flex(xs6 pl-3)
    v-checkbox(
      label="allow delete?"
      v-model="allowDelete"
      color="pink"
    )
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  computed: {
    ...mapFields('designs', {
      item: 'item',
      actions: 'actions',
      // controls
      editState: 'editState'
    })
  },
  data: () => ({ allowDelete: false }),
  methods: {
    async deleteOrder() {
      await this.$store.dispatch('designs/_REMOVE')
      this.editState = false
      this.actions = false
      this.$router.go(-1)
    }
  }
}
</script>

<style>

</style>
