<template lang="pug">
div
  FwdDetailPage(v-if="loaded")
    template(slot="title-prefix") order:
    template(slot="title") {{ item.displayName }}
    template(slot="controls")
      Controls
    template(slot="form")
      FwdTabs(
        v-model="tabs"
        :items="tabItems"
      )
  v-dialog(max-width=600 v-model="actions")
    FwdCard
      template(slot="title") actions
      template(slot="content")
        Actions
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import DetailForm from '../../components/form'
import Client from '../../components/client'
import Shipments from '@/modules/shipping/components/shipments'
import Comments from '../../components/comments'
import ClientId from '../../components/ClientId'
import Admin from '../../components/admin'
import Actions from '../../components/actions'
import Controls from './controls'
import constants from '@/assets/constants'

export default {
  components: {
    Controls,
    Actions,
    Document
  },
  data: () => ({
    tabItems: [
      { title: 'details', component: DetailForm },
      // { title: 'jobs/charges', component: JobsList },
      { title: 'client/status', component: Client },
      { title: 'shipments', component: Shipments },
      { title: 'comments', component: Comments },
      { title: 'admin', component: Admin }
    ]
  }),
  computed: {
    ...mapFields('orders', {
      item: 'item',
      tabs: 'tabs',
      loaded: 'loaded',
      actions: 'actions',
      editState: 'editState'
    })
  },
  async created() {
    await this.$store.dispatch('orders/_READ', this.$route.params.id)
    if (this.item.company === constants.fangamerid) {
      this.tabItems.push({ title: 'fangamer', component: ClientId })
    }
  }
}
</script>
