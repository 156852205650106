<template lang="pug">
v-container
  v-layout(row)
    v-flex(xs6)
      v-textarea(
        label=" new brief text description"
        v-model="descrip"
        auto-grow
        rows='3'
        box
        @change="save"
      )
      v-textarea(
        label=" new product details, written in **markdown**"
        v-model="text"
        auto-grow
        rows='14'
        box
        @change="save"
      )
    v-flex(xs6 ).ml-3
      DetailCard

</template>

<script>
import DetailCard from './Card'
import { mapFields } from 'vuex-map-fields'

export default {
  components: { DetailCard },
  computed: {
    ...mapFields('products', {
      description: 'item.description',
      descrip: 'item.descrip',
      text: 'item.text',
      image: 'item.image',
      brand: 'item.brand.name',
      code: 'item.code',
      name: 'item.name',
      isEdited: 'item.edits.phase1.isEdited'
    })

  },
  methods: {
    save () { this.$store.dispatch('products/_UPDATE') }
  },
  created () {
    if (this.text === '') {
      this.text = this.description
    }
  }
}
</script>
