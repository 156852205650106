<template lang="pug">
div
  v-container(v-if="productionInstance._id" grid-list-xl fluid).pa-0
    v-layout(row)
      v-flex(xs8).mr-3

        v-layout(row)
          v-flex(xs6 pr-4)
            h3.display-1.font-weight-bold.mb-4 status
            PiStatus
          v-flex(xs6 pr-4)
            h3.display-1.font-weight-bold.mb-4 dates
            PiDates

        v-layout(row)
          v-flex(xs6 pr-4)
            h3.display-1.font-weight-bold.mb-4 progress
            PiProgress
          v-flex(xs6 pr-4)
            h3.display-1.font-weight-bold.mb-4 production
            PiProduction

        v-layout(row)
          v-flex(xs6 pr-4)
            h3.display-1.font-weight-bold.mb-4 press check
            PressCheck

        v-layout(row)
          v-flex(xs12 pr-4)
            JobProgressBar(:progress="productionInstance.dashboard.jobProgress")

      v-flex(xs4).grey.lighten-2
        h3.display-1.font-weight-bold.mb-4 design
        PiDesign

  //- v-container()
  //-   pre {{ productionInstance }}
  //- v-container(v-else fill-height align-space-around justify-center)
  //-   v-layout(align-space-around justify-center column)
  //-     v-flex(align-self-center text-xs-center)
  //-       v-progress-circular(
  //-         :size="70"
  //-         :width="7"
  //-         color="pink"
  //-         indeterminate
  //-       )

</template>

<script>
import PiDates from './dates'
import PiStatus from './status'
import PiDesign from './design'
import PiProduction from './production'
import PiProgress from './progress'
import PressCheck from './press-check'
import JobProgressBar from '@/components/JobProgressBar'

export default {
  components: {
    PiDates,
    PiStatus,
    PiDesign,
    PiProduction,
    PiProgress,
    PressCheck,
    JobProgressBar
  },
  computed: {
    productionInstance () { return this.$store.getters['productionInstances/item'] }
  }
}
</script>
