import axios from 'axios'
import moment from 'moment'


export default {

  async senddropdeadtospekkio ({ state, dispatch, rootState }) {
    dispatch('setup')

    // ################# SEND TO SPEKKIO
    axios({
      method: 'post',
      url: 'orders/spekkio/orderconfirm',
      data: {
        data: { body: `
          <span>:  an order for this product has been confirmed! <a href='https://fwdio.forwardprinting.com/client/orders/${state.item._id}' target='_blank'>${state.item.name + ' - #' + state.item.orderNumber}</a> with an expected date of ${moment(state.item.dates.dropDead).format('LL')}</span>
          ` },
        product: state.item.clientProduct
      },
      headers: { 'Content-Type': 'application/json' }
    })
    dispatch('cleanup')
  },

  async sendestimatetospekkio ({ state, dispatch, rootState }) {
    dispatch('setup')

    // ################# SEND TO SPEKKIO
    axios({
      method: 'post',
      url: 'orders/spekkio/orderestimate',
      data: {
        data: { body: `
          <span>:  an estimate for this product has been prepared for review: <a href='https://fwdio.forwardprinting.com/client/orders/${state.item._id}' target='_blank'>${state.item.name + ' - #' + state.item.orderNumber}</a></span>
          ` },
        product: state.item.clientProduct
      },
      headers: { 'Content-Type': 'application/json' }
    })
    dispatch('cleanup')
  },

  async sendestimateapprovaltospekkio ({ state, dispatch, rootState }) {
    dispatch('setup')

    // ################# SEND TO SPEKKIO
    axios({
      method: 'post',
      url: 'orders/spekkio/orderestimateapproval',
      data: {
        data: { body: `
          <span>:  The estimate for this product has been approved.  We will review and confirm the order shortly: <a href='https://fwdio.forwardprinting.com/client/orders/${state.item._id}' target='_blank'>${state.item.name + ' - #' + state.item.orderNumber}</a></span>
          ` },
        product: state.item.clientProduct
      },
      headers: { 'Content-Type': 'application/json' }
    })
    dispatch('cleanup')
  },

  async spekkiodesignschedulechange ({ state, dispatch, rootState }, clientProduct) {
    const adminList = 'danc@forwardprinting.com' //, kmc@forwardprinting.com, lee@forwardprinting.com'

    let to = adminList
    console.log("client product", clientProduct)
    if(!clientProduct.slug) return
    const spekkioPage = 'https://spekkio.fangamer.com/admin/products/' + clientProduct.slug

    const mail = {
      from: 'info@forwardprinting.com', // sender address
      to, // list of receivers
      subject: 'schedule change alert: ' + clientProduct.name, // Subject line
      text: 'The License Coordinator(s) for this product was notified of a dropdead date change.' + '\n\nYou can view the Spekkio page here:' + spekkioPage // plain text body
    }
    axios({
      method: 'post',
      url: 'designs/sendmail',
      data: mail,
      headers: { 'Content-Type': 'application/json' }
    })

    // ################# SEND TO SPEKKIO
    axios({
      method: 'post',
      url: 'designs/spekkio/designschedulechange',
      data: {
        data: { body: `
          <span>:  The expected date for ${clientProduct.name} has been changed to <strong>${moment(state.item.dates.dropDead).format('LL')}</span>
          ` },
        product: clientProduct,
        date: state.item.dates.schedule
      },
      headers: { 'Content-Type': 'application/json' }
    })
  },

  async updatespekkioreceived ({ state, dispatch, rootState }, job) {
    
    console.log({
      order: job.order,
      job: job._id
    })
    // ################# SEND TO SPEKKIO
    axios({
      method: 'post',
      url: 'orders/spekkio/updatereceived',
      data: {
        order: job.order,
        job: job._id
      },
      headers: { 'Content-Type': 'application/json' }
    })
  },
}
