/** Model definition file for the Press Class **/

export default class Press {
  constructor ({
    _id = '',
    name = ''
  } = {}) {
    this._id = _id
    this.name = name
  }
}
