<template lang="pug">
v-container(fluid)
  v-layout(row mt-3 v-if="order.dates.inHands")
    v-flex()
      span.font-weight-bold in hands date: 
      | {{ formatDate(order.dates.inHands) }}
  v-layout(row v-if="order.dates.dropDead")
    v-flex()
      span.font-weight-bold drop dead date: 
      | {{ formatDate(order.dates.dropDead) }}
  v-layout(row v-if="order.owner.username")
    v-flex()
      span.font-weight-bold owner: 
      | {{order.owner.username}}
  v-layout(row v-if="order.contact.fullName.trim()")
    v-flex()
      span.font-weight-bold point of contact: 
      | {{order.contact.fullName}} - {{order.contact.email}}

</template>

<script>
import moment from 'moment'

export default {
  computed: {
    order () { return this.$store.getters['orders/item'] },
  },
  methods: {
    formatDate (date) { return moment(date).format('LL') }
  },
}
</script>
