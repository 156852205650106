<template lang="pug">
v-select(
  label="assign owner"
  v-model="owner"
  :items="owners"
  item-text="username"
  return-object
  @change="save"
)

</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  computed: {
    ...mapFields('orders', {
      owner: 'item.owner'
    }),
    owners () { return this.$store.state.users.items }
  },
  methods: {
    save () { this.$store.dispatch('orders/_SAVE') }
  }
}
</script>
