// return all items that match the brand and sort by name
export default (items, brand, assignedColors) => {
  // make a mutable copy
  let list = items.map(a => { return a })

  // filter out ones that don't match the brand
  list = list.filter(item => {
    return item.brand._id === brand._id
  })

  // filter out ones that are already assigned
  list = list.filter(item => {
    let assigned = assignedColors.findIndex(color => {
      return color._id === item._id
    })
    if (assigned >= 0) return false
    return true
  })

  // sort list by name
  list.sort((a, b) => {
    const A = a.name.toUpperCase()
    const B = b.name.toUpperCase()
    if (A > B) return 1
    if (A < B) return -1
    return 0
  })

  // give up the result
  return list
}
