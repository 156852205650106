import detail from '../views/detail'
import sampleTicket from '@/components/tickets/sample-ticket'
import jobTicket from '@/components/tickets/job-ticket'

export default [
  {
    path: '/jobs/:id',
    name: 'job detail',
    component: detail,
    meta: { authorize: ['Admin', 'User', 'ProductionLead'] }
  },
  {
    path: '/jobs/sample-ticket/print/:id',
    name: 'sample ticket',
    component: sampleTicket
  },
  {
    path: '/jobs/job-ticket/print/:id',
    name: 'job ticket',
    component: jobTicket,
    meta: { authorize: ['Admin', 'User', 'Purchasing', 'ProductionLead'] }
  },
  {
    path: '/jobs/ink-ticket/print/:id',
    name: 'ink ticket',
    component: () => import('@/components/tickets/ink-ticket'), 
    meta: { authorize: ['Admin', 'User', 'Screens', 'Inks', 'Production', 'Shipping', 'ProductionLead', 'Fangamer', 'Purchasing'] }
  },
  {
    path: '/jobs/screen-ticket/print/:id',
    name: 'screen ticket',
    component: () => import('@/components/tickets/screen-ticket'), 
    meta: { authorize: ['Admin', 'User', 'Screens', 'Inks', 'Production', 'Shipping', 'ProductionLead', 'Fangamer', 'Purchasing'] }
  },
]