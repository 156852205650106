/** Model definition file for the Transaction Class **/
import uuid from 'uuid/v4'

export default class Transaction {
  constructor ({
    _id = uuid(),
    date = '',
    type = '',
    amount = '',
    note = '',
    isCredit = true
    
  } = {}) {
    this._id = _id
    this.date = date
    this.type = type
    this.amount = amount
    this.note = note
    this.isCredit = isCredit
  }
  get types () {
    return [
       {type: 'paypal', isCredit: true},
       {type: 'paypal refund', isCredit: false},
       {type: 'ach', isCredit: true},
       {type: 'wire transfer', isCredit: true},
       {type: 'check', isCredit: true},
       {type: 'check refund', isCredit: false},
       {type: 'cash', isCredit: true},
       {type: 'cash refund', isCredit: false},
       {type: 'customer credit', isCredit: false},
    ]
  }

}
