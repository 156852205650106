<template lang="pug">
v-layout(row)
  v-flex(xs6)
    v-btn.white--text(
      color="pink accent-1"
      @click="duplicate"
    ) duplicate design
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  computed: {
    ...mapFields('designs', {
      item: 'item',
      actions: 'actions'
    })
  },
  data: () => ({ allowDelete: false }),
  methods: {
    async duplicate () {
      this.actions = false
      await this.$store.dispatch('designs/duplicateDesign')
    }
  }
}
</script>
