/** Model definition file for the JobProgress Class **/

export default class JobProgress {
  constructor ({
    isProductOk = false,
    isArtFiled = false,
    isDesignDetails = false,
    isPreflighted = false,
    isMockSent = false,
    isMockApproved = false,
    isProductsOrdered = false,
    isProductsReceived = false,
    isCompleted = false,
    isfangamerprintcheck = false
  } = {}) {
    this.isProductOk = isProductOk
    this.isArtFiled = isArtFiled
    this.isDesignDetails = isDesignDetails
    this.isPreflighted = isPreflighted
    this.isMockSent = isMockSent
    this.isMockApproved = isMockApproved
    this.isProductsOrdered = isProductsOrdered
    this.isProductsReceived = isProductsReceived
    this.isCompleted = isCompleted
    this.isfangamerprintcheck = isfangamerprintcheck
  }
}
