/** Model definition file for the Meta Class **/

export default class Meta {
  constructor ({
    dateCreated = '',
    retired = false
  } = {}) {
    this.dateCreated = dateCreated ? new Date(dateCreated) : new Date()
    this.retired = retired
  }
}
