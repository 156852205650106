import api from '@/api'

export default (model) => ({
  async save ({ dispatch, commit, rootState, state }, data) {
    if (data._id) {
      await dispatch('updateOne', { data })
    } else {
      const post = await dispatch('postOne', { data })
      commit('item', post)
    }
    // update items array (local state)
    commit('itemsUpdate', { list: 'items', item: state.item })
  },

  async remove ({ dispatch, commit, rootState, state }, _id) {
    await dispatch('deleteOne', { _id })
    // remove the item from local state
    commit('itemsRemove', { list: 'items', item: state.item })
  },

  // setup and cleanup
  setup({ commit }) {
    commit('loaded', false)
    commit('loader/loading', true, { root: true })
  },

  cleanup({ commit }) {
    commit('loaded', true) // TODO: can we remove this?
    commit('loader/loading', false, { root: true })
  },

  // CRUD
  async postOne ({ state }, { data }) {
    const response = await api.postOne({ path: state.path, data })
    return new model(response)
  },
  
  async postMany ({ state }, { data }) {
    return await api.postMany({ path: state.path, data })
  },
  
  async getOne ({ state }, _id) {
    const response = await api.getOne({ path: state.path, _id })
    return new model(response)
  },
  
  async getCollection ({ state }) { const response = await api.getCollection({ path: state.path })
    return response.map(item => new model(item))
  },

  async getCollectionFiltered ({ state }, { endpoint, _id }) {
    const response = await api.getCollectionFiltered({ path: state.path, endpoint, _id })
    return response.map(item => new model(item))
  },

  async getByManyIds ({ state }, _ids) {
    const response = await api.getByManyIds({ path: state.path + '/many-ids', _ids })
    return response.map(item => new model(item))
  },
  
  async updateOne ({ state }, { data }) { 
    return await api.updateOne({ path: state.path, data })
  },
  
  async updateMany ({ state }, { data }) {
    return await api.updateMany({ path: state.path, data })
  },
  
  async deleteOne ({ state }, { _id }) {
    return api.deleteOne({ path: state.path, _id })
  },
  
  async deleteMany ({ state }, { endpoint, _id }) {
    return await api.deleteMany({ path: state.path, endpoint, _id })
  },
  
  // handle files
  async postOneFile ({ state }, { data }) {
    return await api.postOneFile({ path: state.path, data })
  },


}) // export default
