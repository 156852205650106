<template lang="pug">
div.pa-3
  Quantity(:order='order' :costs='costs')
  Subtotals(:order='order' :costs='costs')
  Adjustments(:order='order' :costs='costs')
  OrderSubtotals(:order='order' :costs='costs')
  SalesTax(:order='order' :costs='costs')
  OrderTotal(:order='order' :costs='costs')
  AmountDue(:order='order' :costs='costs')

</template>

<script>
import { mapFields } from 'vuex-map-fields'
import Quantity from './quantity'
import Subtotals from './subtotals'
import Adjustments from './adjustments'
import OrderSubtotals from './order-subtotals'
import SalesTax from './sales-tax'
import OrderTotal from './order-total'
import AmountDue from './amount-due'

export default {
  computed: {
    ...mapFields('orders', {
      order: 'item',
      costs: 'item.costs'
    })
  },
  components: { Quantity, Subtotals, Adjustments, OrderSubtotals, SalesTax, OrderTotal, AmountDue }
}
</script>
