<template lang="pug">
div
  v-toolbar(
    dark
    app
    flat
    dense
  ).py-2.transparent
    v-btn(
      @click='toggleDrawer'
      small
      flat
      dark
      fab
      ).pink.accent-1
      v-icon() menu
    slot(name="controls")

    v-spacer
    v-toolbar-title
      v-layout(row)
        v-flex
          h2.subheading.font-weight-black.text-lowercase
            span.font-weight-bold.pink--text
              slot(name="title-prefix")
            span.font-weight-medium.black--text
              slot(name="title")
      v-layout(row)
        v-flex
          slot(name="subtitle")
  div
    slot(name="form")
</template>
<script>
export default {
  props: {
    toolbarClass: {
      type: String,
      default: "white",
    },
  },
  data: () => ({
    detail: true,
    tabs: 0,
  }),
  methods: {
    setTabs(tab) {
      this.tabs = tab;
    },
    toggleDrawer() {
      this.$store.dispatch("core/toggleDrawer");
    },
  },
};
</script>
