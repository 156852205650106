<template lang="pug">
div
  v-container(fluid pa-0 mb-3)
    v-layout(row px-3)
      v-flex
        Downloadpdf

</template>

<script>
import Downloadpdf from '../pdf/download.vue'

export default {
  components: {Downloadpdf}
}
</script>
