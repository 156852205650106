export default [
  {
    title: "Production",
    icon: "today",
    top: true,
    route: { path: "/dashboards/production" },
    role: ["Admin", "User", "Production", "ProductionLead", "Purchasing"],
  },
  {
    title: "Art",
    icon: "straighten",
    route: { path: "/dashboards/art" },
    top: true,
    role: ["Admin", "User"],
  },
  {
    title: "Mocks Pending",
    icon: "hourglass_empty",
    route: { path: "/dashboards/art-pending" },
    top: true,
    role: ["Admin", "User"],
  },
  {
    title: "Screens",
    icon: "grid_on",
    route: { path: "/dashboards/screens" },
    top: true,
    role: ["Admin", "User", "ProductionLead"],
  },
  {
    title: "Inks",
    icon: "local_drink",
    route: { path: "/dashboards/inks" },
    top: true,
    role: ["Admin", "User", "ProductionLead"],
  },
  {
    title: "Dashboards",
    icon: "calendar_today",
    route: { activate: "dashboard" },
    top: true,
    role: [
      "Admin",
      "User",
      "Screens",
      "Inks",
      "Production",
      "Shipping",
      "ProductionLead",
    ],
  },
  {
    title: "production (in progress)",
    icon: "list",
    route: { path: "/dashboards/in-progress" },
    module: "dashboard",
    role: ["Admin", "User"],
  },
  {
    title: "production (completed)",
    icon: "list",
    route: { path: "/dashboards/completed" },
    module: "dashboard",
    role: ["Admin", "User"],
  },
  {
    title: "preflight dashboard",
    icon: "list",
    route: { path: "/dashboards/preflight" },
    module: "dashboard",
    role: ["Admin", "User"],
  },
  {
    title: "Fangamer schedule",
    top: true,
    icon: "list",
    route: { path: "/dashboards/schedule/view" },
    module: "dashboard",
    role: ["Admin", "User", "Fangamer", "Production", "ProductionLead"],
  },
];
