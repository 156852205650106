import { updateField } from 'vuex-map-fields'
import { set, toggle } from '../util'

export default () => ({
  items: set('items'),
  item: set('item'),
  itemOriginal: set('itemOriginal'),
  input: set('input'),
  tabs: set('tabs'),
  actions: set('actions'),
  search: set('search'),
  filter: set('filter'),
  detail: set('detail'),
  loaded: set('loaded'),
  editState: set('editState'),
  saveFlag: set('saveFlag'),
  upload: set('upload'),
  loader: toggle('loader', null, { root: true }),
  updateField,

  // manipulate items

  itemsUpdate(state, { list, item }) {
    // list is expected to be a string in dot notation
    // first breakdown any potential dot notation
    let breakdown = list.split('.')
    let destination = ''
    breakdown.forEach(el => {
      destination += '["' + el + '"]'
    })
    destination = eval('state' + destination)
    // find index and update array
    let index = destination.findIndex(el => {
      return item._id === el._id
    })
    if (index !== -1) {
      destination.splice(index, 1, item)
    } else {
      destination.push(item)
    }
  },

  itemsRemove(state, { list, item }) {
    // list is expected to be a string in dot notation
    // first breakdown any potential dot notation
    let breakdown = list.split('.')
    let destination = ''
    breakdown.forEach(el => {
      destination += '["' + el + '"]'
    })
    destination = eval('state' + destination)
    // execute the removal
    let index = destination.findIndex(el => {
      return item._id === el._id
    })
    destination.splice(index, 1)
  },

})
