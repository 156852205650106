<template lang="pug">
v-container.pa-0

  v-layout(row).my-5
    v-flex(xs6)
      h3.display-1.mb-3 costs
      v-text-field(
        label='true COG'
        prepend-icon='attach_money'
        v-model='costs.cogTrue'
        type='number'
        @change='updateOrder'
      )
      v-text-field(
        label='internal COG'
        prepend-icon='attach_money'
        v-model='costs.cog'
        type='number'
        @change='updateOrder'
      )
      v-text-field(
        label='true quantity'
        v-model='costs.quantityTrue'
        type='number'
        @change='updateOrder'
      )


    
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  computed: {
    ...mapFields('orders', {
      costs: 'item.costs'
    }),
  },
  methods: {
    updateOrder() {
      return this.$store.dispatch('orders/_SAVE')
    },
  },
}
</script>
