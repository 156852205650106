<template lang="pug">
div(v-if="selectedLocation._id && locations.length")
  FwdCard
    template(slot="title") location/decoration
    template(slot="content")
      v-layout(column)
        v-flex
          v-select(
            label="location template"
            v-model="location"
            :items="locationTemplates"
            item-text="name"
            return-object
            @change="save"
          )
        v-flex
          v-autocomplete(
            label="category"
            v-model="decorationCategory"
            :items="categories"
            item-text="name"
            item-value="_id"
            persistent-hint
            @change="changecategory"
          )
        v-flex(v-if='decorationCategory')
          v-autocomplete(
            label="decoration"
            v-model="decoration"
            :items="decorations"
            item-text="name"
            persistent-hint
            return-object
            @change="save"
          )
      v-layout(row)
        v-flex
          Dimensions.mt-3
  div
    AddOns
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import Dimensions from './dimensions'
import AddOns from './list-add-ons'

export default {
  components: { Dimensions, AddOns },
  computed: {
    ...mapFields('designs', {
      locations: 'item.locations',
      location: 'selected.location.template',
      debugloc: 'selected.location',
      selectedLocation: 'selected.location',
      decorationCategory: 'selected.location.decorationCategory',
      decoration: 'selected.location.decoration',
    }),
    ...mapFields('decorationCategories', {
      categories: 'items',
    }),
    locationTemplates () { return this.$store.getters['designLocationTemplates/items'] },
    decorations () {
      let list = this.$store.getters['decorations/items']
      list = this.sortByString(list, 'name')
      return list.filter(x => x.decorationCategory === this.decorationCategory)
    }

  },
  methods: {
    save () { this.$store.dispatch('designs/_SAVE') },
    changecategory () {
      this.decoration = {}
      this.save()
    },
  }
}
</script>
