/** Model definition file for the Subproduct Class **/
import uuid from 'uuid/v4'
import { Size, ProductColor } from '@/models'

export default class Subproduct {
  constructor ({
    _id = uuid(),
    name = '',
    sizes = [],
    colors = [],
    cost = null
  } = {}) {
    this._id = _id
    this.name = name
    this.sizes = sizes.map(size => new Size(size))
    this.colors = colors.map(colors => new ProductColor(colors))
    this.cost = cost
  }

  get displayInline () {
    let sizesSorted = this.sizes.map(el => { return el })
    sizesSorted = sizesSorted.sort((a, b) => {
      return a.sortOrder > b.sortOrder ? 1 : -1
    })
    let list = []
    sizesSorted.forEach(size => {
      list.push(size.name)
    })
    let result = list.toString()
    result = result.replace(/,+/g, ', ')

    return result
  }
}
