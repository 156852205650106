<template lang="pug">
div
  v-card(
    elevation=3
  )
    v-card-title(primary-title)
      h3.headline.text-uppercase {{mockup.name}}
    v-img(
      v-if="mockup.image.url"
      :src="mockup.image.url"
      aspect-ratio=1
      contain
    )
    v-card-text
      | {{mockup.description}}
    v-divider
</template>
<script>

export default {
  props: {
    mockup: { type: Object }
  },
  computed: {
    baseURL () { return this.axios.defaults.baseURL + '/img' }
  },
  methods: {
  }
}
</script>
