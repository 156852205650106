export default [
  {
    title: 'decorations',
    icon: 'list',
    route: { path: '/decorations' },
    module: 'admin',
    top: false,
    role: ['Admin']
  },
  {
    title: 'pricing settings',
    icon: 'money',
    route: { path: '/pricing-settings' },
    module: 'admin',
    top: false,
    role: ['Admin']
  }
]