<template lang="pug">
v-container
  LineItemHeader
  div(v-for="(line, i) in linesSorted" :key="line._id")
    LineItem(:line="line")
  LineItemFooter(
    :subtotal="cog"
    :quantityOrdered="quantityOrdered"
    :quantity="quantity"
  )
</template>
<script>
import { subsummaryProductNames } from '../helpers'
import { PurchaseOrderLine } from '@/models'
import { mapFields } from 'vuex-map-fields'
import LineItemHeader from './line-item-header'
import LineItemFooter from './line-item-footer'
import LineItem from './line-item'

export default {
  computed: {
    ...mapFields('purchaseOrders', {
      item: 'item',
      lines: 'item.lines',
      cog: 'item.cog',
      quantity: 'item.quantity',
      quantityOrdered: 'item.quantityOrdered',
    }),
    linesSorted () {
      const lines = this.lines.map(a => new PurchaseOrderLine(a))
      const sorted = this.sortByString(lines, 'sortStringProduct')

      return subsummaryProductNames(sorted)
    }
  },
  watch: {
    lines: {
      handler() {
        this.cog = 0
        this.quantity = 0
        this.quantityOrdered = 0
        this.lines.forEach(line => {
          this.cog += line.lineItem.quantityOrdered * line.lineItem.costProduct
          this.quantity += line.lineItem.quantity
          this.quantityOrdered += line.lineItem.quantityOrdered
        })
      },
      immediate: true,
      deep: true
    }
  },
  components: { LineItem, LineItemHeader, LineItemFooter },
  
  
}
</script>
