<template lang="pug">
div(v-if="job.productGroups.length")
  v-container().title.font-weight-black jobs on this po
  v-container(py-0)
      JPGList
  v-container(fluid py-1)
    v-divider
  v-container(py-1 mt-3)
    v-layout(row)
      v-flex(xs8)
        h2.title.font-weight-black job detail:
          span.pink--text {{ job.displayName }}
      v-flex(xs4).text-xs-right
        span.grey.lighten-2.black--text.pa-2 grey
        span  lines are on a different Purchase Order

  v-layout(row)
    v-flex(xs12)
      LineItems
  v-container(grid-list-md).mb-5
    v-layout(row)
      v-flex(xs3 px-4)
        v-text-field(
          v-model="numberOfBoxes"
          label="number of boxes"
          @change='save'
        )
    v-layout(row)
      v-flex(xs4 px-3)
        v-card(outlined dark)
          v-card-title.title received?
          v-card-text.caption this marks the entire job received.  only use this if items on other po's (shown in grey if they exist) are also received.
          v-card-actions
            v-btn(@click="markReceived" :disabled="isProductsReceived").pink.accent-2.white--text mark this job received
      v-flex(xs4 px-3)
        v-card(outlined dark)
          v-card-title.title report discrepancy
          v-card-text.caption this will notify management about a shipping discrepancy for this PO.
          v-card-actions
            v-btn(dark @click="opendiscrepancy").pink.accent-2 report
      v-flex(xs4 px-3)
        v-card(outlined dark)
          v-card-title.title print ticket
          v-card-text.caption this takes you to the page.  gonna have to print it yourself for now.
          v-card-actions
            v-btn(dark @click="printTicket").pink.accent-2 print ticket

    v-divider.my-3
    v-layout(row).mt-3
      v-flex(xs4 px-3).title admin use only
      v-flex(xs4 px-3) 
        SpekkioReceiving

  
  v-dialog(v-model='discrepancydialog' persistant width=600)
      v-card()
        v-card-title report discrepancy 
        v-card-text
          v-textarea(
            v-model='discrepancynotes'
            label='notes'
            outlined
          )
        v-card-actions
          v-btn(@click='senddiscrepancy') send

  v-snackbar(v-model='snackbar' :timeout=3000 top color='green').mt-3.font-weight-bold discrepancy report sent
</template>

<script>

import { mapFields } from 'vuex-map-fields'
import JPGList from './list'
import LineItems from './line-items'
import RipCard from '../../../dashboards/views/art/detail/rip-card.vue'
import SpekkioReceiving from './spekkioreceiving'

export default {
  components: { JPGList, LineItems, RipCard, SpekkioReceiving },
  computed: {
    ...mapFields('purchaseOrders',{
      lines: 'item.lines',
    }),
    ...mapFields('jobs', {
      job: 'item',
      productGroups: 'item.productGroups',
      numberOfBoxes: 'item.numberOfBoxes',
      isProductsReceived: 'item.progress.isProductsReceived'
    })
  },
  data: () => ({ 
    discrepancydialog: false,
    discrepancynotes: '',
    snackbar: false,
  }),
  methods: {
    async markReceived () {
      this.isProductsReceived = true
      await this.$store.dispatch('jobs/updateOne', { data: this.job })
      await this.$store.dispatch('orders/updatespekkioreceived', this.job)
      
      // this.$store.dispatch('productionInstances/updateJobProgress', { progress: this.job.progress, job_id: this.job._id})
    },
    printTicket () {
      const id = this.job._id
      const routeData = this.$router.resolve('/jobs/job-ticket/print/' + id)
      window.open(routeData.href, '_blank')
    },

    opendiscrepancy () {
      this.discrepancydialog = true
    },

    async senddiscrepancy () {
      this.discrepancydialog = false
      // send email
      const discrepancies = await this.$store.dispatch('purchaseOrders/getdiscrepancylineitems')
      const dislist = await this.$store.dispatch('purchaseOrders/discrepancieshtmllist', discrepancies)
      await this.$store.dispatch('purchaseOrders/senddiscrepancy', {
        notes: this.discrepancynotes,
        discrepancies: dislist
      })

      // snackbar sent
      this.snackbar = true

      this.discrepancynotes = ''
    },

    

    save () { this.$store.dispatch('jobs/_SAVE') }
  }
}
</script>
