import commonActions from '@/store/common/actions'
import commonState from '@/store/common/state'
import commonGetters from '@/store/common/getters'
import commonMutations from '@/store/common/mutations'
import crud from './crud'
import * as lists from './modules/lists'
import { Company as Model, Address, Contact } from '@/models'

export default {
  namespaced: true,
  modules: {  
    lists
  },
  state: {
    path: 'companies',
    ...commonState(Model),
    address: new Address(),
    contact: new Contact()
  },
  getters: {
    ...commonGetters()
  },
  mutations: {
    ...commonMutations()
  },
  actions: {
    ...commonActions(Model),
    ...crud
    
    
  }
}
