<template lang="pug">
div.line-item
  v-layout.body-1(row px-4 align-center)
    v-flex(xs5)
      v-layout(row justify-start)
        v-flex.pa-1
          v-avatar(size=28 tile)
            v-img(:src="line.color.image.url")
        v-flex {{line.color.name}}

    v-flex(xs1).text-xs-center.text-uppercase {{line.size.name}}

    v-flex(xs3).text-xs-center
      template( v-if='order.isStatusFinal') {{ line.quantityFinal}}
      template(v-else) {{ line.quantity }}

    v-flex(xs1).text-xs-right {{line.cost | currency}}

    v-flex(xs2 v-if='order.isStatusFinal').text-xs-right {{line.costExtendFinal | currency}}
    v-flex(xs2 v-else).text-xs-right {{line.costExtend | currency}}

  v-layout.body-1(row px-4 align-center text-xs-right v-if='isFangamer')
    v-flex(xs8)
    v-flex(xs2) internal COG: {{ line.costProduct | currency}}
    v-flex(xs2) true COG: {{ line.costProductTrue | currency}}

</template>
<script>
import constants from '@/assets/constants'

export default {
  props: {
    line: { required: true, type: Object },
    order: { required: true, type: Object },
  },
  methods: {
    changeQuantity(payload) { this.$emit('changeQuantity', payload) },

  },
  computed: {
    isFangamer() {
      return this.order.company === constants.fangamerid
    }
  }

}
</script>

<style lang="stylus" scoped>
.line-item
  border-bottom solid #CFD8DC 1px
</style>
