import commonActions from '@/store/common/actions'
import commonState from '@/store/common/state'
import commonGetters from '@/store/common/getters'
import commonMutations from '@/store/common/mutations'
import Model from '../models/Dashboard'

export default {
  namespaced: true,
  state: {
    toggleProgress: false,
    ...commonState(Model),
    teams: [
      { _id: '001', name: 'team you', _sort: 100 },
      { _id: '006', name: 'team nu-you', _sort: 150 },
      { _id: '002', name: 'team eco', _sort: 200 },
      { _id: '003', name: 'team checkmark', _sort: 300 },
      { _id: '005', name: 'team emb', _sort: 350 },
      { _id: '004', name: 'remove team', _sort: 400 }
    ]
  },
  getters: {
    ...commonGetters(),
    teams (state) { return state.teams }
  },
  mutations: {
    ...commonMutations()
  },
  actions: {
    ...commonActions(Model),
    // ...crud(Model),
    // get schedule items
    async _LOAD_SCHEDULE ({ commit, dispatch, rootState, state }) {
      dispatch('productionInstances/setup', null, { root: true })
      // get the collection
      // TODO: get a filtered collection of status = wip
      const get = await dispatch('productionInstances/getCollection', null, { root: true })
      const items = get.filter(pi => {
        const retired = pi.meta ? pi.meta.retired : false

        return !retired
      })

      // console.log(items)
      // save the state
      commit('productionInstances/items', items, { root: true })
      dispatch('productionInstances/cleanup', null, { root: true })
    },

    async _LOAD_SCHEDULE_READ_ONLY ({ commit, dispatch, rootState, state }) {
      dispatch('productionInstances/_READ_FANGAMER', null, { root: true })
      // dispatch('productionInstances/setup', null, { root: true })
      // // get the collection
      // // TODO: get a filtered collection of status = wip
      // const get = await dispatch('productionInstances/getCollection', null, { root: true })
      // const result = get.filter(pi => {
      //   return pi.dashboard.orderDisplayName.includes('Fangamer - ')
      // })
      // // save the state
      // commit('productionInstances/items', result, { root: true })
      // dispatch('productionInstances/cleanup', null, { root: true })
    },

    // get preflight items
    async _LOAD_PREFLIGHT ({ commit, dispatch, rootState, state }) {
      dispatch('productionInstances/setup', null, { root: true })
      // get the collection
      // TODO: get a filtered collection of status = wip
      const get = await dispatch('productionInstances/getCollection', null, { root: true })
      const items = get.filter(pi => {
        const notpreflight = !pi.dashboard.jobProgress.isPreflighted
        const retired = pi.meta ? pi.meta.retired : false

        return notpreflight && !retired
      })

      // console.log(items)
      // save the state
      commit('productionInstances/items', items, { root: true })
      dispatch('productionInstances/cleanup', null, { root: true })
    },

    // get ink items
    async _LOAD_INKS ({ commit, dispatch, rootState, state }) {
      dispatch('productionInstances/setup', null, { root: true })
      // get the collection
      // TODO: get a filtered collection of status = wip
      const get = await dispatch('productionInstances/getCollection', null, { root: true })
      const items = get.filter(pi => {
        return pi.dashboard.jobProgress.isPreflighted &&
          pi.dashboard.jobProgress.isMockApproved &&
          !pi.progress.isInksMixed
      })
      // save the state
      commit('productionInstances/items', items, { root: true })
      dispatch('productionInstances/cleanup', null, { root: true })
    },

    // get screen items
    async _LOAD_SCREENS ({ commit, dispatch, rootState, state }) {
      dispatch('productionInstances/setup', null, { root: true })
      // get the collection
      // TODO: get a filtered collection of status = wip
      const get = await dispatch('productionInstances/getCollection', null, { root: true })
      const items = get.filter(pi => {
        return pi.dashboard.jobProgress.isPreflighted &&
        pi.dashboard.jobProgress.isMockApproved &&
        pi.progress.isArtRipped &&
        !pi.progress.isScreensPrepped
      })
      // save the state
      commit('productionInstances/items', items, { root: true })
      dispatch('productionInstances/cleanup', null, { root: true })
    },

    // get art items
    async _LOAD_ART ({ commit, dispatch, rootState, state }) {
      dispatch('productionInstances/setup', null, { root: true })
      // get the collection
      // TODO: get a filtered collection of status = wip
      const get = await dispatch('productionInstances/getCollection', null, { root: true })
      const items = get.filter(pi => {
        return pi.dashboard.jobProgress.isPreflighted &&
          !pi.progress.isArtRipped
      })
      // save the state
      commit('productionInstances/items', items, { root: true })
      dispatch('productionInstances/cleanup', null, { root: true })
    },

    // get pending art items
    async _LOAD_ART_PENDING ({ commit, dispatch, rootState, state }) {
      dispatch('setup')
      await dispatch('productionInstances/_READ_ART_PENDING', null, { root: true })
      dispatch('cleanup')
    },

    // get production items
    async _LOAD_PRODUCTION ({ commit, dispatch, rootState, state }) {
      dispatch('productionInstances/setup', null, { root: true })
      // get the collection
      // TODO: get a filtered collection of status = wip
      const get = await dispatch('productionInstances/getCollection', null, { root: true })
      const items = get.filter(pi => {
        if (pi.task.isTask) { return true }
        const progress = pi.dashboard.jobProgress
        return progress.isMockApproved &&
          progress.isProductsReceived &&
          pi.progress.isArtRipped &&
          pi.progress.isScreensPrepped &&
          pi.progress.isInksMixed
      })
      // save the state
      commit('productionInstances/items', items, { root: true })
      dispatch('productionInstances/cleanup', null, { root: true })
    },

    // get production (in progress) items
    async _LOAD_PRODUCTION_IN_PROGRESS ({ commit, dispatch, rootState, state }) {
      dispatch('productionInstances/setup', null, { root: true })
      // get the collection
      // TODO: get a filtered collection of status = wip
      const get = await dispatch('productionInstances/getCollection', null, { root: true })
      const items = get.filter(pi => {
        const progress = pi.dashboard.jobProgress
        return progress.isMockApproved &&
          progress.isProductsReceived &&
          pi.progress.isArtRipped &&
          pi.progress.isScreensPrepped &&
          pi.progress.isInksMixed &&
          pi.approval.approved
      })
      // save the state
      commit('productionInstances/items', items, { root: true })
      dispatch('productionInstances/cleanup', null, { root: true })
    },

    // get production (completed) items
    async _LOAD_PRODUCTION_COMPLETED ({ commit, dispatch, rootState, state }) {
      // get the collection
      // TODO: get a filtered collection of status = wip
      await dispatch('productionInstances/getCollectionCompleted', null, { root: true })
      // const items = state.items.filter(pi => {
      //   const progress = pi.dashboard.jobProgress
      //   return progress.isMockApproved &&
      //     progress.isProductsReceived &&
      //     pi.progress.isArtRipped &&
      //     pi.progress.isScreensPrepped &&
      //     pi.progress.isInksMixed

      // })
      // // save the state
      // commit('productionInstances/items', items, { root: true })
    }

  }
}
