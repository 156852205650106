<template lang="pug">
v-select(
  :label="label"
  :items="items"
  item-text="name"
  return-object
  flat
  clearable
  @input="input"
)
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: 'filter by something'
    },
    value: {
      type: Object
    },
    items: {
      type: Array,
      default: []
    }
  },
  methods: {
    input (item) {
      this.$emit('input', item)
    }
  }
}
</script>
