import { Subproduct } from '@/models'
import uuid from 'uuid/v4'

export default {
  addSubproduct ({ state, commit }) {
    state.subproduct.item = new Subproduct()
    // update the local copy of subproduct array
    commit('itemsUpdate', {
      list: 'item.subproducts',
      item: state.subproduct.item
    })
  },

  updateSubproducts ({ state, commit }) {
    // update the local copy of subproduct array
    commit('itemsUpdate', {
      list: 'item.subproducts',
      item: state.subproduct.item
    })
  },

  removeSubproduct ({ state, commit }) {
    commit('itemsRemove', {
      list: 'item.subproducts',
      item: state.subproduct.item
    })

    if(state.item.subproducts.length)
    state.subproduct.item = state.item.subproducts[0]
  },

  async duplicateSubproduct ({ state, commit, dispatch }) {
    const subproduct = new Subproduct(state.subproduct.item)
    subproduct._id = uuid()
    subproduct.cost = 0

    commit('subproductItem', subproduct)
    dispatch('updateSubproducts')
  }

}