<template lang="pug">
div.pa-0.transparent.text-xs-center(flat)

  template(v-if="input")
    span.text-xs-center.px-0
      input.css-input.pink.lighten-5.text-xs-center.py-3(
        v-model.number="cost"
        @change="changeCost"
        @focus="$event.target.select()"
        tabindex=1
      )

  template(v-else)
    v-layout(align-center text-center)
      v-flex.px-0.pt-3.pb-1 {{cost}}

</template>

<script>
export default {
  props: {
    line: { type: Object, required: true },
    pg: { type: Object, required: true },
    input: { type: Boolean, default: false },
  },

  data: () => ({ cost: null }),

  methods: {

    changeCost() {
      if (isNaN(this.cost) || this.cost === '' || this.cost === null) { this.cost = 0 }

      // prepare payload items
      const line = this.line
      line.costProductTrue = this.cost

      // replace line in pg
      const lines = this.pg.lineItems
      const lineIndex = lines.findIndex(x => x._id === line._id)
      lines.splice(lineIndex, 1, line)

      // set up payload
      const data = {
        _id: this.pg._id,
        lineItems: lines
      }

      // update pg record
      this.$store.dispatch('productGroups/updateOne', { data })
    }

  },

  watch: {

    line: {
      handler() {
        this.cost = this.line.costProductTrue
      },
      immediate: true,
      deep: true
    }

  }

}
</script>
<style lang="stylus" scoped>
.css-input
  border-color #cccccc
  padding 5px
  border-width 0px
  border-style hidden
  text-align center
  width 75%
.css-input focus
  outline none
</style>
