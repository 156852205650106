/** Model definition file for the Tier Class **/

export default class Tier {
  constructor ({
    _id = '',
    name = '',
    profitPerHour = '',
    markup = 20,
    isDefault = false
  } = {}) {
    this._id = _id
    this.name = name
    this.profitPerHour = profitPerHour
    this.markup = markup
    this.isDefault = isDefault
  }

  get displayName() { return this.name + ' / profit per  hour: ' +  this.profitPerHour  + ' / markup: ' + this.markup }
}
