<template lang="pug">
v-container(pa-3)
  h3.title.mb-3 locations
  AddLocation
  RemoveLocation
  v-divider.mt-3

  h3.title.my-3 mockups
  AddMockupPage
  ChangeMockupPageOrder
  v-divider.mt-3

  Company
  v-divider.mt-3

  h3.title.my-3 this design
  Duplicate
  PrintInkTicket
  DeleteDesign

</template>

<script>
import AddLocation from './AddLocation'
import RemoveLocation from './RemoveLocation'
import DeleteDesign from './DeleteDesign'
import Duplicate from './Duplicate'
import AddMockupPage from './AddMockupPage'
import ChangeMockupPageOrder from './ChangeMockupPageOrder'
import Company from './Company'
import PrintInkTicket from './InkTicket'
export default {
  components: {
    DeleteDesign,
    Duplicate,
    AddMockupPage,
    ChangeMockupPageOrder,
    AddLocation,
    RemoveLocation,
    Company,
    PrintInkTicket
  }
}
</script>

<style>

</style>
