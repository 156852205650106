import commonActions from '@/store/common/actions'
import commonState from '@/store/common/state'
import commonGetters from '@/store/common/getters'
import commonMutations from '@/store/common/mutations'
import crud from './crud'
import actions from './actions'
import { ProductionInstance as Model, Order, Job, Design } from '@/models'
import sendmail from '@/modules/orders/helpers/sendmail'

export default {
  namespaced: true,
  state: {
    path: 'production-instances',
    ...commonState(Model),
    input: {
      location: {},
      type: '',
      pi: {},
      dates: {
        schedule: ''
      }
    },
    scheduleLoaded: false
  },
  getters: {
    ...commonGetters()
  },
  mutations: {
    ...commonMutations(),
    scheduleLoaded(state, payload) {
      state.scheduleLoaded = payload
    }
  },
  actions: {
    ...commonActions(Model),
    ...crud,
    ...actions,
    
    updateScheduleDate ({ state, dispatch }, _id) {
      const pi = state.items.find(item => item._id === _id)
      pi.dates.schedule = state.input.dates.schedule
      pi.priority = 1
      dispatch('updateOne', {
        data: pi
      })
    },
    
    updatePriority ({ state, dispatch }, _id) {
      const pi = state.items.find(item => item._id === _id)
      pi.priority = state.input.priority
      dispatch('updateOne', {
        data: pi
      })
    },

    

    async saveWithFile ({ state, dispatch, commit, rootState }, data) {
      dispatch('setup')
      // handle the file first
      if (state.upload.formData) {
        const postFile = await dispatch('postOneFile', {
          data: state.upload.formData
        })
        data.ripFile = postFile
      }
      commit('item', data)
      dispatch('_SAVE')
      dispatch('cleanup')
    },

    async markCompleted ({ state, dispatch }) {
      dispatch('setup')
      // change current item status
      state.item.status = 'completed'
      dispatch('_UPDATE')
      // check related pi's for job completion
      const isJobNotComplete = state.items.find(item => item.status !== 'completed')
      // if job complete
      if(!isJobNotComplete){
        console.log('job completed')
        await dispatch('jobs/markCompleted', state.item.job, { root: true })
       }

      dispatch('cleanup')
    },

    async markIncomplete({ state, dispatch }) {
      dispatch('setup')
      // change current item status
      state.item.status = 'wip'
      dispatch('_UPDATE')
      
      dispatch('cleanup')
    },

    async markFinished({ state, dispatch }, { pi, name }) {
      dispatch('setup')
      // email kevin and dan
      sendmail({
        from: 'info@forwardprinting.com', // sender address
        to: 'kmc@forwardprinting.com, danc@forwardprinting.com', // list of receivers
        subject: 'finished: ' + name, // Subject line
        text: 'Hello!\nThis job has finished: https://fwdio.forwardprinting.com/jobs/' + pi.job, // plain text body
      })
      
      dispatch('cleanup')
    }
    
  }
}
