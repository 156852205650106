<template lang="pug">
  div
    v-select(
      label="status"
      v-model="status"
      :items="statuses"
      @change="save"
      outline
    )
    v-select(
      label="production priority"
      v-model="priorityProduction"
      :items="priorities"
      @change="save"
      outline
    )
    v-select(
      label="art priority"
      v-model="priorityArt"
      :items="priorities"
      @change="save"
      outline
    )
    pre {{ item._id }}
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  data: () => ({ priorities: [1, 2, 3, 4, 5] }),
  computed: {
    ...mapFields('productionInstances', {
      item: 'item',
      status: 'item.status',
      priorityArt: 'item.dashboard.priority.art',
      priorityProduction: 'item.dashboard.priority.production'
    }),
    statuses () {
      return this.item.statuses
    }
  },
  methods: {
    save () {
      this.$store.dispatch('productionInstances/_UPDATE')
    }
  }
}
</script>
