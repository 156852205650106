<template lang="pug">
div
  v-card.xs12.mb-3(
    flat
    width="100%"
    mb-3
    v-for="(location, locationKey) in  locations"
    v-if="location.template.name && location.decoration.name"
    :key="locationKey"
  )
    v-card-title.font-weight-black.display-3
      h5 {{location.template.name}}
    v-card-text.headline
      v-flex(xs12) decoration: {{location.decoration.name}}
      v-flex(xs12 mb-2)
        span.red--text(
          v-if="!sizeway(location).dimensions.width || !sizeway(location).dimensions.height") need dimensions!
        span(v-else="") dimensions: {{sizeway(location).dimensions.width}}" wide x {{sizeway(location).dimensions.height}}" tall

      template(v-if='isscreenprinting(location)')

        v-flex.headline(xs12 mb-2 mt-5) print colors:
          ColorList(:location='location')

      template(v-if='isembroidery(location)')

        v-flex.headline(xs12 mb-2) hoop placement: {{ location.embhoopplacement }}
        v-flex.headline(xs12) presser foot height: {{ location.embpresserfootheight }}
        v-flex.headline(xs12 mb-2 mt-5) thread colors:
          ColorList(:location='location')

</template>

<script>
import ColorList from '../color-list'
import constants from '@/assets/constants'

export default {
  computed: {
    design () { return this.$store.getters['designs/item'] },
    locations () { return this.design.locations },
    sizeways () { return this.design.sizeways },
    colorways () { return this.design.colorways }
    
  },
  methods: {
    sizeway (location) { return location.sizeways[0] },
    colorway (location) { return location.colorways[0] },
    isscreenprinting (location) {
      const _id = location.decorationCategory
      return _id === constants.decorationcategory.screenprint || _id === constants.decorationcategory.foil
    },
    isembroidery (location) { return location.decorationCategory === constants.decorationcategory.embroidery},
  },
  components: { ColorList }
}
</script>
