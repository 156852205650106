<template lang="pug">
v-container
  BaseList(
    :items="itemsSorted"
    :selected="item"
    @select="select"
    :addToggle='false'
  )
    template(slot-scope="{ item }" v-if="item.location.template.name")
      v-layout(row)
        v-flex(xs6) {{item.location.template.name}}
        v-flex.text-xs-right.pink--text(xs4 v-if="item.minutes.override")
          | minutes: {{item.minutes.override}}
        v-flex.text-xs-right(xs4 v-else)
          | minutes: {{item.minutes.default}}
        v-flex(xs2)
          v-btn(light @click='retirepi(item)' flat small ) delete
    template(slot="title")
      | prod. instances
  DetailLocation
</template>

<script>
import { ProductionInstance } from '@/models'
import { mapFields } from 'vuex-map-fields'
import BaseList from '@/components/base/list-selected'
import DetailLocation from './detail-location'

export default {
  components: { BaseList, DetailLocation },
  data: () => ({
    detailLocation: false
  }),
  computed: {
    ...mapFields('productionInstances', {
      items: 'items',
      item: 'item',
      detail: 'detail'
    }),
    itemsSorted () {
      return this.items
    }
  },
  methods: {
    add () {
      // open a dialog to specify location (required) of the new PI
      this.detail = true
    },
    select (item) {
      this.item = new ProductionInstance(item)
    },
    retirepi (item) {
      this.$store.dispatch('productionInstances/deleteOne', { _id: item._id })
      this.$store.commit('productionInstances/itemsRemove', { list: 'items', item })
    }
  }
}
</script>
