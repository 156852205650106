<template lang="pug">
DetailSmall(:detail="detail")
  template(slot="title") decoration category detail.
  template(slot="form")
    v-layout(row)
      v-flex
        v-text-field(
          label="name"
          v-model="name"
        )
  template(slot="controls")
    DetailControls(
      @save="save"
      @cancel="cancel"
      @remove="remove"
    )
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import DetailSmall from '@/components/base/detail-small-modal'
import DetailControls from '@/components/base/detail-controls-simple'

export default {
  components: { DetailControls, DetailSmall },
  computed: {
    ...mapFields('decorationCategories', {
      item: 'item',
      name: 'item.name',
      detail: 'detail',
    })
  },
  methods: {
     async save () {
      await this.$store.dispatch('decorationCategories/save', this.item)
      this.cancel()
    },

    async remove () {
      await this.$store.dispatch('decorationCategories/remove', this.item._id)
      this.cancel()
    },

    cancel () {
      this.detail = false
    }
  }
}
</script>
