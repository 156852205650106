<template lang="pug">
v-container(grid-list-lg fluid)
  v-layout(row)
    v-flex(xs6)
      FwdCard
        template(slot="title") access
        template(slot="content")
          h3.subheading.font-weight-bold product list
          p note that we do not have an api endpoint for searching on the master product list.  
          p this url returns a list of all products with all but the meta info stripped off.  it is a mangeable data set so we simply do our filtering and searching on the client side
          a(
            href="http://54.183.170.203/products"
            target="_blank"
          ) http://54.183.170.203/products

          h3.subheading.font-weight-bold.mt-4 product record [by id]
          p this url returns a unique product record found by 
            span.font-weight-bold _id
          p if you filter the available subproducts by desired garment color, you can determine all available sizes for that color and generate line items sorted by the size sortOrder
          a(
            href="http://54.183.170.203/products/5d1681ca643f261773e3f9a3"
            target="_blank"
          ) http://54.183.170.203/products/5d1681ca643f261773e3f9a3
    v-flex(xs6)
      FwdCard
        template(slot="title") notes
        template(slot="content")
          ol
            li you'll see some long urls for the images.  going to get those condensed in the future when we figure out our virtual storage solution.
            li the size records on the detailed product record have some useless properties that we'll be deleting in the future.  these are preceded by an underscore and wouldn't serve any use anyway.
            li the last tab has an example of how we have a client side product picker deliver sorted line items for a selected product/color
            li you can add multiple products AND colors per product.
          
      
</template>

<script>
export default {
}
</script>
