<template lang="pug">
div
  ListSelected(
    :items="itemsSorted"
    :selected="item"
    :addToggle="false"
    :toolbar="false"
    @select="select"
  )
    template(slot-scope="{ item }") {{item.name.name}} - {{item.cost | currency}}
    template(slot="subtitle" slot-scope="{ item }") {{item.displayInline}}

          
</template>
<script>
import { mapFields } from 'vuex-map-fields'
import { Subproduct } from '@/models'
import listSort from './list-sort'
import ListSelected from '@/components/base/list-selected'

export default {
  components: { ListSelected },
  computed: {
    ...mapFields('products', {
      item: 'subproduct.item',
      items: 'item.subproducts',
      itemColors: 'subproduct.item.colors'
    }),
    itemsSorted () {
      return listSort(this.items)
    }
  },
  methods: {
    select (item) {
      this.item = new Subproduct(item)
    },
  }
}
</script>
