<template lang="pug">
div
  ListSelected(
    :items="items"
    :toolbar="false"
    :selected="item"
    @select="select"
  )
    template(slot-scope="{ item }")
      v-layout.body-1.pa-0(row) 
        v-flex(xs8)
          | {{item.product.displayName}}
        v-flex(xs2)
          | {{item.quantity}}
        v-flex(xs2)
          | {{item.subtotal | currency}}
  ProductPicker(
    v-model="pickProduct"
    @product="addProductGroup"
  )
</template>

<script>
import { ProductGroup } from '@/models'
import { mapFields } from 'vuex-map-fields'
import ProductPicker from '@/components/product-picker'
import ListSelected from '@/components/base/list-selected-single-line'

export default {
  components: { ListSelected, ProductPicker },
  computed: {
    ...mapFields('jobs/productGroups', {
      items: 'items',
      item: 'item',
    }),
    ...mapFields('jobs', {
      pickProduct: 'input.pickProduct',
    }),
    itemsSorted () {
      return this.sortByString(this.items, 'displayName')
    }
  },
  methods: {
    select (item) {
      this.item = new ProductGroup(item)
    },
    async addProductGroup (payload) {
      // assign the payload results from product picker to 
      this.item = new ProductGroup({
        product: payload.product,
        lineItems: payload.lineItems,
      })
      // add the product group to the list
      this.$store.dispatch('jobs/productGroups/_SAVE')
      // select the added product group
      this.select(this.item)
    }
  }
}
</script>
