<template lang="pug">
v-container
  v-layout(row).mb-5
    v-flex(xs6)
      h3.display-1.mb-3 select fangamer product
      v-text-field(
        label='search for product'
        v-model='search'
        prepend-inner-icon='search'
      )
      v-btn(@click='searchApi') search
  v-layout(row)
    v-flex(xs12)
      v-list
        v-list-tile(v-for='(item, i) in results' :key='i' @click='setClientId(item)')
          v-list-tile-avatar(tile)
            v-img(:src='item.url')
          v-list-tile-title {{item.name}} - {{item.product}} -
            span.pink.lighten-5.px-3  {{item.id}}

  v-layout(row).mb-6
    v-flex(xs12)
      h3.display-1.mb-3 selected client product:
      div(v-if='clientProduct.id')
        p {{ clientProduct.name }} - {{ clientProduct.product }} -
          span.pink.lighten-5.px-3  {{clientProduct.id}}
        p
          a(:href="'https://spekkio.fangamer.com/admin/products/' + clientProduct.slug" target='_blank') Spekkio link
      div(v-else)
        p no product selected


  OrderProducts
  OrderCosts


    
</template>

<script>
import axios from 'axios'
import { mapFields } from 'vuex-map-fields'
import OrderProducts from './products'
import OrderCosts from './costs'

export default {
  components: { OrderProducts, OrderCosts },
  data: () => ({
    search: '',
    results: []
  }),
  computed: {
    ...mapFields('orders', {
      clientProduct: 'item.clientProduct',
    }),
  },
  methods: {
    setClientId(item) {
      this.clientProduct = item
      this.$store.dispatch('orders/_SAVE')
      this.results = []
    },
    updateOrder() {
      return this.$store.dispatch('orders/_SAVE')
    },
    async searchApi() {
      const results = await axios.post('orders/spekkio/getproducts', {
        data: this.search
      })
      console.log(data)
      const data = results.data.data
      this.results = data.map(x => {
        return {
          id: x.id,
          name: x.attributes.name,
          product: x.attributes.product_type_name,
          url: x.attributes.thumb_url,
          slug: x.attributes.slug
        }
      })
    }
  },
}
</script>
