import detail from '../views/detail'
import list from '../views/list'
import clientDocument from '../views/client-document'
import prodDocument from '../views/prod-document'
import prodInksDocument from '../views/ink-ticket'
import clientCommentSuccess from '../views/client-document/comment-success'
import clientApprovalSuccess from '../views/client-document/approval-success'

export default [
  {
    path: '/designs',
    name: 'design list',
    component: list,
    meta: { authorize: ['Admin', 'User', 'ProductionLead'] }
  },
  {
    path: '/designs/:id',
    name: 'design detail',
    component: detail,
    meta: { authorize: ['Admin', 'User', 'ProductionLead'] }
  },
  {
    path: '/designs/ink-ticket/print/:id',
    name: 'ink ticket via design',
    component: () => import('@/components/tickets/ink-ticket/index-design'), 
    meta: { authorize: ['Admin', 'User', 'Screens', 'Inks', 'Production', 'Shipping', 'ProductionLead', 'Fangamer', 'Purchasing'] }
  },
  {
    path: '/client/designs/comment-success',
    name: 'client design comment success',
    component: clientCommentSuccess
  },
  {
    path: '/client/designs/approval-success',
    name: 'client design approval success',
    component: clientApprovalSuccess
  },
  {
    path: '/client/designs/:id',
    name: 'client design detail',
    component: clientDocument
  },
  {
    path: '/prod/designs/:id',
    name: 'production design detail',
    component: prodDocument
  },
  {
    path: '/prod/inks/designs/:id',
    name: 'production ink detail',
    component: prodInksDocument
  }
]