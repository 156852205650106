<template lang="pug">
v-container
  LineItemHeader
  div(v-for="(line, i) in linesConsolidated" :key="line._id")
    LineItem(:line="line")
  LineItemFooter(
    :subtotal="cog"
    :quantityOrdered="quantityOrdered"
    :quantity="quantity"
  )
</template>
<script>
import { subsummaryProductNames } from '../helpers'
import { PurchaseOrderLine } from '@/models'
import { mapFields } from 'vuex-map-fields'
import LineItemHeader from './line-item-header'
import LineItemFooter from './line-item-footer'
import LineItem from './line-item'

export default {
  computed: {
    ...mapFields('purchaseOrders', {
      item: 'item',
      lines: 'item.lines',
      cog: 'item.cog',
      quantity: 'item.quantity',
      quantityOrdered: 'item.quantityOrdered',
    }),
    linesConsolidated () {
      const result = []
      const lines = this.lines.map(x => new PurchaseOrderLine(x))
      lines.forEach(line => {
        const index = result.findIndex(x => x.sortStringProduct === line.sortStringProduct)
        // no matching line so add it
        if (index < 0) {
          result.push(line)
          return
        }
        // otherwise update the existing line
        const lineItem = result[index].lineItem
        lineItem.quantity += line.lineItem.quantity
        lineItem.quantityOrdered += line.lineItem.quantityOrdered
        lineItem.quantityReceived += line.lineItem.quantityReceived
      })

      const sorted = this.sortByString(result, 'sortStringProduct')

      return subsummaryProductNames(sorted)
    }
  },
  components: { LineItem, LineItemHeader, LineItemFooter },
  
  
}
</script>
