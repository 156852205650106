<template lang="pug">
FwdSuccess(
  title="SUCCESS"
  subtitle="your approval has been noted.  next, our art department will get started on mockups and send a link for review soon."
  image="bddunkonak47.png"
)
</template>

<script>
import FwdSuccess from '@/components/base/FwdSuccess'
export default {
  components: { FwdSuccess }
}
</script>