<template lang="pug">
div.text-xs-right
  div.mb-3
    v-layout(row v-if='order.isStatusFinal')
      v-flex.font-weight-bold.text-xs-right(xs8) jobs subtotal
      v-flex(xs4) {{costs.jobsSubtotalFinal | currency}}
    v-layout(row v-else)
      v-flex.font-weight-bold.text-xs-right(xs8) job subtotal
      v-flex(xs4) {{costs.jobsSubtotal | currency}}

  v-layout(row).mb-3
    v-flex.font-weight-bold.text-xs-right(xs8) shipping subtotal
    v-flex(xs4) {{costs.shippingSubtotal | currency}}

  v-layout(row).mb-3
    v-flex.font-weight-bold.text-xs-right(xs8) other charges subtotal
    v-flex(xs4) {{costs.otherChargesSubtotal | currency}}
    
  v-divider.mb-3
</template>

<script>
export default {
  props: {
    order: { required: true, type: Object },
    costs: { required: true, type: Object },
  }
}
</script>
