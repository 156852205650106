<template lang="pug">
v-container()
  FwdHeadingLarge companies
  FwdList(
    :items="items"
    searchLabel="search by company name"
    @select="select"
    @add="add"
  )
    template(slot-scope="{ item }")
      v-layout(row)
        v-flex(xs6) {{item.name}}
    template(slot="title")
      | 
</template>

<script>
import FwdList from '@/components/base/FwdList'

export default {
  components: { FwdList },
  computed: {
    items() { return this.$store.getters['companies/items'] }
  },
  methods: {
    async add () {
      const add = await this.$store.dispatch('companies/_ADD')
      this.detail(add._id)
    },
    select (item) { this.detail(item._id) },
    detail (_id) { this.$router.push({ path: '/companies/' + _id }) },
  }
}
</script>
