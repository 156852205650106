<template lang="pug">
div
  DetailSmall(:detail="detail")
    template(slot="title") add on detail
    template(slot="form")
      v-layout(row)
        v-flex(xs8)
          v-select(
            label="add on"
            v-model="addOn"
            :items="addOns"
            item-text="name"
            return-object
          )
        
        v-flex(xs4).ml-3
          v-text-field(
            label="quantity"
            v-model.number="quantity"
          )
    template(slot="controls")
      DetailControls(
        @save="save"
        @cancel="cancel"
        @remove="remove"
      )
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import DetailSmall from '@/components/base/detail-small-modal'
import DetailControls from '@/components/base/detail-controls-simple'

export default {
  components: {
    DetailControls,
    DetailSmall,
  },
  props: ['detail'],
  computed: {
    ...mapFields('designs', {
      addOn: 'selected.addOn.addOn',
      quantity: 'selected.addOn.quantity',
    }),
    addOns () { return this.$store.getters['designLocationAddOns/items'] }
  },
  methods: {
    save () {
      this.$emit('save', this.form)
      this.cancel()
    },

    cancel () { this.$emit('cancel') },

    remove () {
      this.$emit('remove', this.form)
      this.cancel()
    }
  }
}
</script>
