// used for interface settings

export default {
  state: {
    settings: {
      quantity: { show: true, input: true },
      quantityOrdered: { show: true, input: true },
      quantityReceived: { show: true, input: true },
      quantityFinal: { show: true, input: true },
      cost: { show: true },
      costExtend: { show: true },
    }
  },
  getters: {
    settings (state) { return state.settings }
  },
  mutations: {
    settings (state, payload) { state.settings = payload }
  },
  actions: {
    settings ({ commit }, payload) { commit('settings', payload) },

    async changeQuantity ({ state, dispatch, commit }, payload) {
      // unpack
      const { line } = payload
  
      // replace line item
      commit('itemsUpdate', { list: 'item.lineItems', item: line })
     
      // update state
      await dispatch('updateAll')
    },
  }
}
