<template lang="pug">
v-layout(row)
  v-flex
    v-btn(
      @click="markOrdered"
      color="pink accent-1"

    ).white--text mark ordered
  v-flex.caption.pl-3
    p this will go back to the job and label items as ordered
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  computed: {
    ...mapFields('purchaseOrders', {
      item: 'item',
      ordered: 'item.ordered',
      lines: 'item.lines',
      actions: 'actions'
    })
  },
  methods: {
    async markOrdered() {
      await this.$store.dispatch('purchaseOrders/markOrdered')
      await this.$store.dispatch('purchaseOrders/_UPDATE')
      await this.$store.dispatch('purchaseOrders/loadRelatedJobs')
      this.actions = false
    }
  }
}
</script>

<style>

</style>
