<template lang="pug">
div
  p.headline.font-weight-bold SCREEN NOTES: 
  p.caption these notes are seen ONLY on screen dashboard.  they get saved with the design so put useful information for reprints here.
  v-textarea.subheading(
    v-model="notes"
    @change="save"
  )
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  computed: {
    ...mapFields('designs', {
      notes: 'item.notes.screen'
    })
  },
  methods: {
    save () { this.$store.dispatch('designs/_SAVE') }
  }
}
</script>
