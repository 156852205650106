import { PurchaseOrder, LineItem } from '@/models'
import helpers from './helpers'

export default {
  ...helpers,
  async _CREATE ({ commit, dispatch, rootState }, { vendor, lines }) {
    dispatch('setup')
    // get next PurchaseOrder number
    const number = await dispatch('getNextPurchaseOrderNumber')

    // prepare instance
    const data = new PurchaseOrder({ number, vendor })

    // post new record
    const item = await dispatch('postOne', { data })
    
    // process lines (do after initial post to get PurchaseOrder id)
    item.lines = await dispatch('processLinesForPurchaseOrder', lines)

    // calculate the PO quantity
    item.quantity = item.lines.map(l => l.lineItem.quantity).reduce((total, q) => total + q, 0)

    // update and bounce
    commit('item', item)
    commit('itemsUpdate', { list: 'items', item: item })
    dispatch('_UPDATE')
    
    dispatch('cleanup')
    return Promise.resolve()
  },

  async _READ ({ commit, dispatch }, _id) {
    dispatch('setup')
    const item = await dispatch('getOne', _id)

    // force model (need to do because was screwed up in the model)
    item.lines = item.lines.map(line => {
      line.lineItem = new LineItem(line.lineItem)
      return line
    })

    commit('item', item)
    commit('itemsUpdate', { list: 'items', item: item })
    dispatch('cleanup')
  },
  
  async _READ_ALL ({ commit, dispatch }) {
    dispatch('setup')
    const items = await dispatch('getCollection')
    // force model (need to do because was screwed up in the model)
    const newItems = items.map(item => {
      item.lines = item.lines.map(line => {
        line.lineItem = new LineItem(line.lineItem)
        return line
      })
      return item
    })
    commit('items', newItems)
    dispatch('cleanup')
  },

  async _UPDATE ({ state, commit, dispatch }) {
    dispatch('updateOne', { data: state.item })
    // update items array (local state)
    commit('itemsUpdate', { list: 'items', item: state.item })
  },

  async _DELETE ({ state, commit, dispatch }) {
    dispatch('deleteOne', { _id: state.item._id })
    // remove the item from local state
    commit('itemsRemove', { list: 'items', item: state.item })
  }

  
}
