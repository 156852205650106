<template lang="pug">
div
  v-container(fill-height)
    v-layout(align-center justify-center column fill-height )
      v-flex().display-3
        v-card(flat width="500px").grey.lighten-3
          v-img(:src="require(`@/assets/success/${image}`)")
          v-card-title.display-2.font-weight-black {{ title }}
          v-card-text.subheading
            p {{ subtitle }}
            p any questions or concerns please contact us at: 
              a(href="mailto: orders@forwardprinting.com").pink--text.text--accent-2 orders@forwardprinting.com

</template>

<script>
export default {
  props: {
    title: { required: true, type: String },
    subtitle: { required: true, type: String },
    image: { required: true, type: String },
  }
}
</script>
