<template lang="pug">
v-app
  CoreToolbar
  v-content
    router-view(:key="$route.fullPath")
  CoreNavigationDrawer
  //- CoreFooter
  Loader
  HelpDialog
</template>

<script>
import Loader from '@/components/loading'
import HelpDialog from '@/components/help-dialog'
import CoreToolbar from '@/components/core/toolbar'
import CoreFooter from '@/components/core/footer'
import CoreNavigationDrawer from '@/components/core/navigation-drawer'

export default {
  name: 'App',
  components: { Loader, CoreToolbar, CoreNavigationDrawer, CoreFooter, HelpDialog }
  // async created () {
  //   await this.$store.dispatch('users/checkauth')
  // }
}
</script>

<style lang="stylus">
html, body
  min-height: 100vh
.v-content
  background-color white
.list-item
  border-bottom 1px solid #F8BBD0
.hilite
  background-color #FF80AB
  color white
.top-level-menu-item
  background-color #EA80FC
.max-height
  min-height: 100vh
</style>
