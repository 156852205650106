import authheader from '../helpers/auth-header'
import { getField, updateField } from 'vuex-map-fields'
import axios from 'axios'
import router from '@/router'

export const state = () => ({
  items: [],
  // item: itemtemplate(),
  item: { role: 'Admin' },
  isloggedin: true,
  code: null,
  actions: false,
  currentUser: { role: 'Admin' }
})

export const getters = { getField }

export const mutations = {
  updateField,
  items (state, payload) {
    state.items = payload
  },
  item (state, payload) {
    state.item = payload
  },
  isloggedin (state, payload) {
    state.isloggedin = payload
  },
  currentUser (state, payload) {
    state.currentUser = payload
  },
  code (state, payload) {
    state.code = payload
  }
}

export const actions = {
  async readall ({ commit }) {
    const { data } = await axios.post(
      '/users/readall',
      {},
      {
        headers: authheader()
      }
    )
    commit('items', data)
  },

  async readone ({ commit }, _id) {
    const { data } = await axios.post(
      '/users/readbyid/' + _id,
      {},
      {
        headers: authheader()
      }
    )
    commit('item', data)
  },

  async update ({ state }) {
    const result = await axios.post(
      '/users/update/' + state.item._id,
      state.item,
      {
        headers: authheader()
      }
    )
    console.log(result.data)
  },

  async create ({ state }) {
    const { data } = await axios.post(
      '/users/create/',
      {},
      {
        headers: authheader()
      }
    )
    console.log(data)
    return data
  },

  async delet ({ state }, _id) {
    const result = await axios.post(
      '/users/delete/' + state.item._id,
      state.item,
      {
        headers: authheader()
      }
    )
    console.log(result.data)
  },

  async checkauth ({ commit, dispatch }) {
    const user = JSON.parse(localStorage.getItem('user'))

    if (user && user.token) {
      const { data } = await axios.post(
        '/users/checkauth',
        {},
        {
          headers: authheader()
        }
      )
      if (!data.fail) {
        commit('item', data)
        commit('isloggedin', true)
        commit('currentUser', user)
      } else {
        dispatch('logout')
      }
    }
    return user
  },

  async login ({ commit }, authcode) {
    const { data } = await axios.post('/users/login', { authcode })
    if (data.token) {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem('user', JSON.stringify(data))
      commit('isloggedin', true)
      commit('currentUser', data)
    }
    commit('item', data)
    commit('code', authcode)

    return data
  },

  logout ({ commit }) {
    // remove user from local storage to log user out
    localStorage.removeItem('user')
    commit('item', itemtemplate())
    commit('code', null)
    commit('isloggedin', false)
    router.push('/login')
  }
}

function itemtemplate () {
  return {
    role: 'admin'
  }
}

export default { state, getters, mutations, actions, namespaced: true }
