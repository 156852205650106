<template lang="pug">
div
  BaseList(
    :items="itemsSorted"
    @select="select"
    @add="add"
  )
    template(slot-scope="{ item }")
      v-layout(row)
        v-flex(xs6) {{item.name}}
        v-flex(xs6) {{item.category}}
    template(slot="title") product tags

  LocalDetail
</template>

<script>
import ProductTag from '@/modules/product-tags/models/ProductTag'
import { mapFields } from 'vuex-map-fields'
import BaseList from '@/components/base/list-simple'
import LocalDetail from './detail'

export default {
  components: { BaseList, LocalDetail },
  computed: {
    ...mapFields('productTags', [
      'items',
      'item',
      'detail'
    ]),
    itemsSorted () { return this.sortByString(this.items, 'category') }
  },
  methods: {
    add () {
      this.item = new ProductTag()
      this.detail = true
    },

    select (item) {
      this.item = new ProductTag(item)
      this.detail = true
    }

  }
}
</script>
