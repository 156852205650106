<template lang="pug">
v-container
  v-layout.headline(column)
    HeadInfo
    JobDetail

</template>

<script>
import HeadInfo from './head-info'
import JobDetail from './job-detail'

export default {
  components: {
    JobDetail,
    HeadInfo,
  }
}
</script>
