<template lang="pug">
div
    v-card(outlined dark)
        v-card-title.title report discrepancy to spekkio
        v-card-text.caption this will notify the Fangamer @receiving staff on the related Spekkio product page.  quantity variances will be reported base on the entered quantities on the PO receiving page.
        v-card-actions
            v-btn(dark @click="opendiscrepancy").pink.accent-2 report

    v-dialog(v-model='discrepancydialog' persistant width=600)
      v-card()
        v-card-title report discrepancy 
        v-card-text
          v-textarea(
            v-model='discrepancynotes'
            label='notes'
            outlined
          )
        v-card-actions
          v-btn(@click='spekkioreceivingdiscrepancy') send
          
    v-snackbar(v-model='snackbar' :timeout=3000 top color='green').mt-3.font-weight-bold discrepancy report sent to spekkio
</template>

<script>
// import { mapFields } from 'vuex-map-fields'

export default {
//   computed: {
//     ...mapFields('jobs', {
//       job: 'item',
//     }),
//   },
  data: () => ({ 
    discrepancydialog: false,
    discrepancynotes: '',
    snackbar: false,
  }),
  methods: {

    opendiscrepancy () {
      this.discrepancydialog = true
    },

    async spekkioreceivingdiscrepancy () {
      this.discrepancydialog = false
      const discrepancies = await this.$store.dispatch('purchaseOrders/getdiscrepancylineitems')
      await this.$store.dispatch('purchaseOrders/spekkioreceivingdiscrepancy', {
        notes: this.discrepancynotes,
        discrepancies
      })
      // snackbar sent
      this.snackbar = true
      this.discrepancynotes = ''
    },
  }
}
</script>
