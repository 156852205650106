/** Model definition file for the ScreenSize Class **/

export default class ScreenSize {
  constructor ({
    _id = '',
    name = '',
    size = ''
  } = {}) {
    this._id = _id
    this.name = name
    this.size = size
  }
}
