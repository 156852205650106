export default {
  
  async _CREATE ({ state, dispatch, commit }) {
    dispatch('setup')

    // handle potential upload
    await dispatch('_UPLOAD_IMAGE')

    // save the record
    dispatch('postOne', { data: state.item })

    // add to items state
    commit('itemsUpdate', { list: 'items', item: state.item })

    dispatch('cleanup')
  },

  async _READ ({ dispatch, commit }, _id ) {
    dispatch('setup')

    // load the record
    const item = await dispatch('getOne', _id)

    // add to items state
    commit('item', item)
    commit('itemsUpdate', { list: 'items', item })

    dispatch('cleanup')
  },

  async _READ_ALL ({ dispatch, commit }) {
    dispatch('setup')

    // load the records
    const items = await dispatch('getCollection')

    // add to items state
    commit('items', items)

    dispatch('cleanup')
  },

  async _UPDATE ({ state, dispatch, commit }) {
    dispatch('setup')

    // handle potential upload
    await dispatch('_UPLOAD_IMAGE')

    // update the record
    dispatch('updateOne', { data: state.item })

    // add to state
    commit('itemsUpdate', { list: 'items', item: state.item })

    dispatch('cleanup')
  },

  async _DELETE ({ state, dispatch, commit }) {
    dispatch('setup')

    // delete the record
    dispatch('deleteOne', { _id: state.item._id })

    // remove from state
    dispatch('_NEW')
    commit('itemsRemove', { list: 'items', item: state.item })

    dispatch('cleanup')
  },

  async _UPLOAD_IMAGE({ state, dispatch }) {
    // handle the file if it exists
    if (state.upload.url) {
      const image = await dispatch('postOneFile', { data: state.upload.formData })
      state.item.image = image
    }
    
  },


}