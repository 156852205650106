import Vue from 'vue'
import GAuth from 'vue-google-oauth2'

const gauthOption = {
  clientId:
    '833863178339-5u2jnjruq5qv9sguj5fjqq436sfvlqdm.apps.googleusercontent.com',
  scope: 'email profile',
  prompt: 'select_account'
}
Vue.use(GAuth, gauthOption)
