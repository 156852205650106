<template lang="pug">
v-container(grid-list-xl)
  v-layout(row)
    v-flex(xs6)
      v-card(flat color="pink lighten-5").pa-2
        FileListOriginal.white
    v-flex(xs6)
      v-card(flat color="pink lighten-5").pa-2
        FileListMockups.white
  v-layout(row)
    v-flex(xs6)
      v-card(flat color="pink lighten-5").pa-2
        FileListSeparations.white
    v-flex(xs6)
      v-card(flat color="pink lighten-5").pa-2
        FileListRips.white
</template>

<script>
import FileListOriginal from './FileListOriginal'
import FileListMockups from './FileListMockups'
import FileListSeparations from './FileListSeparations'
import FileListRips from './FileListRips'

export default {
  components: {
    FileListOriginal,
    FileListMockups,
    FileListSeparations,
    FileListRips
  }
}
</script>
