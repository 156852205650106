export const subsummaryProductNames = (sorted) => {
  // expects a sorted list of PurchaseOrderLines

  // run through the sorted items and remove redundant product names for cleaner display
  return sorted.map((line, index) => {
    const thisProduct_id  = line.product_id
    const previousProduct_id  = index === 0 ? '' : sorted[index - 1].product_id
    if (previousProduct_id === thisProduct_id) { line.productName = ''}
    return line
  })
}