<template lang="pug">
BaseListExtended(
  :items="itemsSorted"
  :filter="filter"
  filterLabel="brand"
  :filterItems="brands"
  @filter="setFilter"
  searchLabel="search by color name"
  @search="setSearch"
  @select="select"
  @add="add"
  :avatar="true"
)
  template(slot-scope="{ item }")
    v-layout(row)
      v-flex(xs6) {{item.name}}
      v-flex(xs6) {{item.brand.name}}
  template(slot="title")
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import BaseListExtended from '@/components/base/list-extended'
import { listSort } from '../helpers'

export default {
  components: { BaseListExtended },
  computed: {
    ...mapFields('productColors', {
      items: 'items',
      item: 'item',
      detail: 'detail',
      filter: 'filter',
      search: 'search',
    }),
    brands () { return this.$store.getters['brands/items']},
    itemsSorted () { return listSort({
        items: this.items,
        search: this.search,
        filter: this.filter,
      })
    }
  },
  methods: {
    async add () { await this.$store.dispatch('productColors/_ADD') },
    async select (item) { await this.$store.dispatch('productColors/_SELECT', item) },
    setFilter (filter) { this.filter = filter },
    setSearch (search) { this.search = search },
  },
  created () {
    this.filter = this.brands[0]
  }
}
</script>
