/** Model definition file for the DesignLocationAddOn Class **/

export default class DesignLocationAddOn {
  constructor ({
    _id = '',
    name = '',
    cost = null,
  } = {}) {
    this._id = _id
    this.name = name
    this.cost = cost
  }
}
