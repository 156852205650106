<template lang="pug">
div
  FwdDetailPage(v-if="loaded")
    template(slot="title-prefix") company: 
    template(slot="title") {{ item.name }}
    template(slot="controls")
      Controls
    template(slot="form")
      FwdTabs(
        v-model="tabs"
        :items="tabItems"
      )
  v-dialog(max-width=600 v-model="actions")
    FwdCard
      template(slot="title") actions
      template(slot="content")
        Actions

</template>

<script>
import { mapFields } from 'vuex-map-fields'
import DetailForm from '../../components/form'
import FwdDesigns from '../../components/designs'
import FwdOrders from '../../components/orders'
import Addresses from '../../components/addresses'
import Contacts from '../../components/contacts'
import Actions from '../../components/actions'
import Controls from './controls'

export default {
  components: {
    Actions,
    Controls
  },
  computed: {
    ...mapFields('companies', {
      item: 'item',
      tabs: 'tabs',
      loaded: 'loaded',
      actions: 'actions'
    })
  },
  data: () => ({
    tabItems: [
      { title: 'detail', component: DetailForm },
      { title: 'orders', component: FwdOrders },
      { title: 'designs', component: FwdDesigns },
      { title: 'contacts', component: Contacts },
      { title: 'addresses', component: Addresses },
    ]
  }),
  async created () {
    this.$store.dispatch('companies/_LOAD', this.$route.params.id)
  }
}
</script>
