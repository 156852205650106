<template lang="pug">
v-container()
  FwdHeadingLarge FG orders to pick up
  BaseList(
    :items="itemsSorted"
    @select="select"
    :addToggle="false"
  )
    template(slot-scope="{ item }")
      v-layout(row)
        v-flex(xs10) 
          span.font-weight-bold {{item.companyName}}
          |  - {{item.name}}
        v-flex(xs2) {{item.orderNumber}}
    template(slot="title")
      | 
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import BaseList from '@/components/base/list-simple'

export default {
  components: { BaseList },
  computed: {
    ...mapFields('orders', {
      items: 'items',
      item: 'item',
    }),
    itemsSorted () {
      // filter by selected status and fangamer
      const list = this.items.filter(item => {
        const status = item.status.name === 'ready to ship'
        const fangamer = item.company === '5ce8672394e11431a4ab9e24'
        return status && fangamer
      })
      // sort list by name
      return this.sortByString(list, 'displayName')
    }

  },
  methods: {
    select (item) { this.$router.push({ path: '/shipments/fangamerpickup/' + item._id }) },
  },
  
  created () { this.$store.dispatch('orders/_READ_ALL') }
}
</script>
