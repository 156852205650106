<template lang="pug">
v-layout(row)
  v-flex(xs8)
    v-btn.white--text(
      color="pink accent-1"
      @click="action"
      disabled
    ) duplicate job
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  computed: {
    ...mapFields('jobs', {
      item: 'item',
      actions: 'actions',
    })
  },
  data: () => ({ allowDelete: false }),
  methods: {
    async action() {
      await this.$store.dispatch('jobs/duplicateJob')
      this.actions = false
    }
  }
}
</script>
