<template lang="pug">
v-container()
  FwdHeadingLarge job-> order search
  v-layout(row py-3)
    v-flex(xs6)
      v-text-field(
        hide-details
        prepend-icon="search"
        single-line
        v-model='search'
        placeholder='enter job number here'
      )
  v-layout(row py-3)
    v-flex(xs6)
      v-btn(@click='findOrder') find order
  v-layout(row py-3)
    v-flex(xs6).red--text {{ message }}
</template>

<script>
export default {
  data: () => ({
    search: '',
    message: ''
  }),
  methods: {
    async findOrder () {
      this.message = await this.$store.dispatch('orders/findJob', this.search)
    }
  }
}
</script>
