import axios from 'axios'
import { storage } from './firebase'
// axios.interceptors.request.use(request => {
//   console.log('AXIOS REQUEST: ', request)
//   return request
// })

// this is the lowest-level API interface on the client side
///////////
// //// CREATE

// post record, return posted record
const postOne = async ({ path, data }) => {
  try {
    const PurchaseOrderST = await axios({
      method: 'post',
      url: path,
      data: data,
      headers: {
        'Content-Type': 'application/json'
      }
    })
    // expect a response with the record in .data
    return PurchaseOrderST.data
  } catch (error) {
    console.log(path + '/post failed', error)
    return Promise.reject(error)
  }
}

// post many record, return posted record
const postMany = async ({ path, data }) => {
  try {
    const PurchaseOrderST = await axios.post(path + '/many', data)
    // expect a response with the record in .data
    return PurchaseOrderST.data
  } catch (error) {
    console.log(path + '/post failed', error)
    return Promise.reject(error)
  }
}

// post file, return posted file details
const postOneFile = async ({ path, data }) => {
  try {
    const now = String(Date.now())
    const ref = storage.ref().child(path).child(now)
    const name =  data.name
    const upload = await ref.child(name).put(data)
    // expect a response with the file details
    let file = {}
    file.fileMeta = upload.metadata
    file.url = await upload.ref.getDownloadURL()
    console.log(file.url)
    return file
  } catch (error) {
    console.log(path + '/postOneFile failed', error)
    return Promise.reject(error)
  }
}

///////////
// //// READ

// get single record by _id
 const getOne = async ({ path, _id }) => {
  try {
    const GET = await axios.get(path + '/' + _id)
    // expect a response with the record in .data
    return GET.data
  } catch (error) {
    console.log(path + '/getOne failed', error)
    return Promise.reject(error)
  }
}

// get path of records
const getCollection = async ({ path }) => {
  // body should contain a filter
  try {
    const GET = await axios({
      method: 'get',
      url: path,
      headers: { 'Content-Type': 'application/json' }
    })
    // expect a response with the record array in .data
    return GET.data
  } catch (error) {
    console.log(path + '/getCollection failed', error)
    return Promise.reject(error)
  }
}

// get a collection of records by _id
const getByManyIds = async ({ path, _ids }) => {
  // body should contain a filter
  try {
    const PurchaseOrderST = await axios({
      method: 'post',
      url: path,
      headers: { 'Content-Type': 'application/json' },
      data: _ids
    })
    // expect a response with the record array in .data
    return PurchaseOrderST.data
  } catch (error) {
    console.log(path + '/getCollection failed', error)
    return Promise.reject(error)
  }
}

// get path of records, sending data
const getCollectionFiltered = async ({ path, endpoint, _id }) => {
  try {
    const GET = await axios({
      method: 'get',
      url: path + '/' + endpoint + '/' + _id,
      headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' }
    })
    // expect a response with the record array in .data
    return GET.data
  } catch (error) {
    console.log(path + '/getCollectionFiltered failed', error)
    return Promise.reject(error)
  }
}
// //// UPDATE

const updateOne = async ({ path, data }) => {
  try {
    const PATCH = await axios.patch(path + '/' + data._id, data)
    // expect a response
    return PATCH
  } catch (error) {
    console.log(data)
    console.log(path + '/updateOne failed', error)
    return Promise.reject(error)
  }
}

const updateMany = async ({ path, data }) => {
  try {
    const PATCH = await axios.patch(path, data)
    // expect a response
    return PATCH
  } catch (error) {
    console.log(path + '/update-many failed', error)
    return Promise.reject(error)
  }
}

// //// DELETE

// delete single record by _id
const deleteOne = async ({ path, _id }) => {
  try {
    const DELETE = await axios.delete(path + '/' + _id)
    // expect a response
    return DELETE
  } catch (error) {
    console.log(path + '/deleteOne failed', error)
    return Promise.reject(error)
  }
}

// delete multiple records by filter
const deleteMany = async ({ path, endpoint, _id }) => {
  try {
    const DELETE = await axios.delete(path + '/' + endpoint + '/' + _id)
    // expect a response
    return DELETE
  } catch (error) {
    console.log(path + '/deleteManyfailed', error)
    return Promise.reject(error)
  }
}

export default {
  postOne,
  postMany,
  postOneFile,
  getOne,
  getCollection,
  getCollectionFiltered,
  getByManyIds,
  updateOne,
  updateMany,
  deleteOne,
  deleteMany
}