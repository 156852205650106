<template lang="pug">
v-navigation-drawer(
  v-model='drawer'
  temporary=true
  fixed=true
  dark
  :key="drawerFocus"
)
  v-list.pt-0(dense='')
    v-divider
    v-list-tile(
      v-for='item in list'
      :key='item.title'
      @click='goTo(item.route)'
      :style="item.route.activate === drawerFocus ? 'background-color: magenta;' : ''"
      :class="{'top-level-menu-item': item.top}"
      )
      v-list-tile-action
        v-icon {{ item.icon }}
      v-list-tile-content
        v-list-tile-title {{ item.title }}
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  computed: {
    ...mapFields('core', {
      drawer: 'drawer',
      drawerFocus: 'drawerFocus'
    }),
    role () {
      const currentUser = this.$store.state.users.item
      return currentUser.role
    },
    menuItems () { return this.$store.getters['menu/menu'] },
    list () {
      // filter menu items to top level items and focused module
      const list = this.menuItems.filter(item => {
        return item.module === this.drawerFocus || item.top
      })
      // filter menu items based on user role
      const listByRole = list.filter(item => {
        return item.role.includes(this.role)
      })

      return listByRole
    }
  },
  methods: {
    goTo (route) {
      if (route.activate === this.drawerFocus) {
        this.drawerFocus = null
        return
      }
      route.activate
        ? this.drawerFocus = route.activate
        : this.$router.push(route)
    }
  }
}
</script>
