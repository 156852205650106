<template lang="pug">
div
  LineItemHeader
  template(v-for="line in lines")
    LineItem(
      :key="line._id"
      :line="line"
      :readOnly="readOnly"
      @changeQuantity="changeQuantity"
    )
  LineItemFooter
</template>
<script>
import LineItemHeader from './line-item-header'
import LineItemFooter from './line-item-footer'
import LineItem from './line-item'

export default {
  props: {
    readOnly: { type: Boolean, default: false },
    lines: { type: Array, required: true },
  },

  computed: {
    productGroup () { return this.$store.getters['jobs/productGroups/item'] },
    // lines () { return this.productGroup.lineItems },
  },

  methods: {
    changeQuantity (payload) {
      // report quantity change back to parent
      this.$emit('changeQuantity', payload)
    }
  },
  
  components: { LineItem, LineItemHeader, LineItemFooter },
}
</script>
