<template lang="pug">
div
  DeleteCompany
</template>

<script>
import DeleteCompany from './DeleteCompany'
export default {
  components: {
    DeleteCompany
  }
}
</script>

<style>

</style>
