import commonActions from '@/store/common/actions'
import commonState from '@/store/common/state'
import commonGetters from '@/store/common/getters'
import commonMutations from '@/store/common/mutations'
import Model from '../models/ScreenSize'

export default {
  namespaced: true,
  state: {
    path: 'screen-sizes',
    ...commonState(Model)
  },
  getters: { ...commonGetters() },
  mutations: { ...commonMutations() },
  actions: { ...commonActions(Model) }
}
