/** Model definition file for the Address Class **/
import uuid from 'uuid/v4'

export default class Address {
  constructor ({
    _id = uuid(),
    name = '',
    companyName = '',
    attn = '',
    address1 = '',
    address2 = '',
    city = '',
    state = '',
    zip = '',
    country = 'USA',
    email = '',
    isPrimary = false,
    isBilling = false,
  } = {}) {
    this._id = _id
    this.name = name
    this.companyName = companyName
    this.attn = attn
    this.address1 = address1
    this.address2 = address2
    this.city = city
    this.state = state
    this.zip = zip
    this.country = country
    this.email = email
    this.isPrimary = isPrimary
    this.isBilling = isBilling
  }
  get displayText () {
    let result = this.companyName ? this.companyName + '\n' : ''
    result += this.attn ? 'attn:' + this.attn + '\n' : ''
    result += this.address1 ? this.address1 + '\n' : ''
    result += this.address2 ? this.address2 + '\n' : ''
    result += this.city ? this.city + ', ' : ''
    result += this.state ? this.state + ' ' : ''
    result += this.zip ? this.zip + '\n' : ''
    result += this.country ? this.country : ''
    return result
  }

  get displayTextInline () {
    let result = this.displayText.replace(/\n/g, ' • ')
    return result
  }
}
