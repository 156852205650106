<template lang="pug">
v-container(v-if="loaded")
  v-layout(column)
    h2(:class="classH2") {{design.displayName}}
    v-flex()
      v-layout(row)
        v-flex
          h3(:class="classH3") mockup images
        v-flex.text-xs-right.pr-3
          v-btn(@click="toggleSize()") toggle full size mockup images
      FwdMockups(:toggleLargeImage="toggleLargeImage")
    v-flex
      h3(:class="classH3") print locations.
      PrintLocations.mx-4
    v-flex(v-if="design.notes.public")
      h3(:class="classH3") notes.
      Notes
    v-flex
      ApprovalBlock

</template>

<script>
import FwdMockups from './mockups'
import ApprovalBlock from './approval-block'
import PrintLocations from './print-locations'
import Notes from './notes'

export default {
  components: {
    FwdMockups,
    ApprovalBlock,
    PrintLocations,
    Notes
  },
  data: () => ({
    classH2: 'display-2 font-weight-black mb-3',
    classH3: 'display-1 pink--text text--accent-1 font-weight-black mb-3 pl-4',
    toggleLargeImage: false
  }),
  computed: {
    loaded () { return this.$store.getters['designs/loaded'] },
    design () { return this.$store.getters['designs/item'] }
  },

  methods: {
    toggleSize () { this.toggleLargeImage = !this.toggleLargeImage }
  }

}
</script>
