<template lang="pug">
DetailSmall(:detail="detail")
  template(slot="title") decoration code detail.
  template(slot="form")
    v-layout(row)
      v-flex
        v-text-field(
        label="name"
        v-model="name"
        )
        v-text-field(
          label="code"
          v-model="code"
        )
        v-select(
          label="decoration type"
          v-model="type"
          :items="decorationCategories"
          item-text="name"
          return-object
        )
        v-text-field(
          label="per use material cost"
          v-model.number="useCost"
        )
        v-text-field(
          label="setup time"
          v-model.number="setupTime"
        )
        v-text-field(
          label="setup cost"
          v-model.number="setupCost"
        )
  template(slot="controls")
    DetailControls(
      @save="save"
      @cancel="cancel"
      @remove="remove"
    )
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import DetailSmall from '@/components/base/detail-small-modal'
import DetailControls from '@/components/base/detail-controls-simple'

export default {
  components: { DetailControls, DetailSmall },
  computed: {
    decorationCategories () {
      return this.$store.getters['decorationCategories/items']
    },
    ...mapFields('decorationCodes', {
      item: 'item',
      name: 'item.name',
      code: 'item.code',
      type: 'item.component.type',
      useCost: 'item.component.useCost',
      setupTime: 'item.component.setup.time',
      setupCost: 'item.component.setup.cost',
      detail: 'detail',
    })
  },
  methods: {
     async save () {
      await this.$store.dispatch('decorationCodes/save', this.item)
      this.cancel()
    },

    async remove () {
      await this.$store.dispatch('decorationCodes/remove', this.item._id)
      this.cancel()
    },

    cancel () {
      this.detail = false
    }
  }
}
</script>
