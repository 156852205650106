/** Model definition file for the OrderStatusData Class **/
import { LogItem } from '@/models'
import PendingChecklist from './PendingChecklist'

export default class OrderStatusData {
  constructor ({
    log = [],
    quoteDate = '',
    isComplete = false,
    isShipped = false,
    isPricesLocked = false,
    pendingChecklist = []
  } = {}) {
    this.log = log.map(item => new LogItem(item))
    this.quoteDate = quoteDate
    this.isComplete = isComplete
    this.isShipped = isShipped
    this.isPricesLocked = isPricesLocked
    this.pendingChecklist = new PendingChecklist(pendingChecklist)
  }
}
