<template lang="pug">
div
  v-layout(row)
    v-flex
      v-checkbox(
        color="pink accent-1"
        v-model="approval.approved"
        label="approved?"
        @change='save'
      )
    v-flex approved by: {{approval.by}}
  v-layout(row).mb-4
    v-flex(v-if="approvalDate") on {{ approvalDateDisplay }}
  v-layout(row)
    v-flex url for client approval:
      a(:href="'/client/orders/' + id" target="_blank") /client/orders/{{id}}
  v-layout(row)
    v-flex
      select-owner
  v-layout(row v-if='!canestimatebesent').mt-5
    v-flex
      p estimates cannot be sent if there is no owner
      p if the order is for Fangamer then the client ID must be selected AND the drop dead date must be set
  v-layout(row).mt-5
    v-flex
      v-btn.pink.accent-1(
        @click="sendEstimate"
        dark
        flat
        v-if='canestimatebesent'
      ) send estimate to client
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import moment from 'moment'
import selectOwner from '../select-owner'

export default {
  components: { selectOwner },
  computed: {
    ...mapFields('orders', {
      id: 'item._id',
      company: 'item.company',
      clientProduct: 'item.clientProduct',
      owner: 'item.owner',
      approval: 'item.approval',
      approvalDate: 'item.approval.date',
      // controls
      dropdeaddate: 'item.dates.dropDead'
    }),
    approvalDateDisplay () {
      return moment(this.approvalDate).format('LLL')
    },
    isfangamer () {
      return this.company._id === '5ce8672394e11431a4ab9e24'
    },
    canestimatebesent () {
      const isowner = !!this.owner._id
      const isclientproduct = !!this.clientProduct.id
      const isdropdead = !!this.dropdeaddate
      if (this.isfangamer) return isowner && isclientproduct && isdropdead
      return isowner
    },
  },
  methods: {
    
    markApproved () {
      if (this.approval.approved) {
        this.approvalDate = new Date()
      } else {
        this.approvalDate = ''
      }
      this.$store.dispatch('orders/approveOrder')
      this.save()
    },
    save () { this.$store.dispatch('orders/_SAVE') },

    sendEstimate () {
      this.$store.dispatch('orders/sendEstimate')
    }
  }
}
</script>
