<template lang="pug">
div
  v-select.pa-3(
    label="location"
    v-model="location"
    :items="locations"
    return-object
  )
    template(slot="item" slot-scope="data")
      | {{data.item.template.name}}
    template(slot="selection" slot-scope="data")
      | {{data.item.template.name}}
  
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { ProductionInstance } from '@/models'

export default {
  computed: {
    ...mapFields('productionInstances', {
      location: 'input.location'
    }),
    locations () {
      let job = this.$store.getters['jobs/item']
      return job.design.locations
    },
    types () {
      const model =  new ProductionInstance()
      return model.types
    }
  }
}
</script>
