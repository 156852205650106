import list from '../views/list'
import detail from '../views/detail'

export default [
  {
    path: '/companies',
    name: 'companies list',
    component: list,
    meta: { authorize: ['Admin', 'User'] }
  },
  {
    path: '/companies/:id',
    name: 'companies detail',
    component: detail,
    meta: { authorize: ['Admin', 'User'] }
  }
]