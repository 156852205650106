<template lang="pug">
div
  v-layout(row)
    v-flex()
      v-checkbox(
        color="pink accent-1"
        v-model="approved"
        label="approved?"
        @change="markApproved"
        :disabled='!approvedBy'
      )
      div(v-if='!approvedBy') please enter a name to the right before approving
    v-flex()
      v-text-field(
        v-model="approvedBy"
        label="approved by"
        @change="save"
      )
  v-layout(row).mb-4
    v-flex(v-if="approvalDate") on {{ approvalDateDisplay }}
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import moment from 'moment'
import axios from 'axios'

export default {
  computed: {
    ...mapFields('productionInstances', {
      approved: 'item.approval.approved',
      approvalDate: 'item.approval.date',
      approvedBy: 'item.approval.by',
    }),
    approvalDateDisplay () {
      return moment(this.approvalDate).format('LLL')
    },
    design () {
      return this.$store.state.designs.item
    },
    ...mapFields('productionInstances', {
      item: 'item',
      locationname: "item.location.template.name"
    }),
  },
  methods: {
    markApproved () {
      if (this.approved && this.approvedBy) {
          this.approvalDate = Date.now()
          axios({
            method: 'post',
            url: 'https://slack.com/api/chat.postMessage',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'bearer xoxb-768636109602-2072999642183-INmwUnb36xSAJnDlz7ZsR0mV'
            },
            data: { "text": "rolling in on " + this.design.name + ' location: ' + this.locationname }
          })
      } else {
        this.approvalDate = ''
      }

      this.save()
    },
    save () { this.$store.dispatch('productionInstances/_UPDATE') },
  }
}
</script>
