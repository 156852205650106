import common from '@/store/common'
import crud from './crud'
import int from './interface'
import { ProductColor as Model } from '@/models'

export default {
  namespaced: true,
  state: {
    path: 'product-colors',
    ...common.state(Model)
  },
  getters: {
    ...common.getters()
  },
  mutations: {
    ...common.mutations()
  },
  actions: {
    ...common.actions(Model),
    ...crud,
    ...int,
    
  }
}
