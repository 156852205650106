<template lang="pug">
v-container(grid-list-xl)
  FwdHeadingLarge task 1 - edit desciptions
  v-layout(row)
    v-flex(xs12)
      p Our current garment descriptions have either nothing OR blocks of unformatted text pasted from the manufacturer's website.  We need to audit these and tighten up the formatting while adding a brief text excerpt to each product listing.

      p If you are concerned or confused by the details of any listing, feel free to flag this for review (see "finishing up" below).

      h2.title.font-weight-bold.mb-2 brief descriptions
      p We need a short desciption for each product, for display on the website.  This should be simple and short, limited to a single sentance.  You can usually edit a portion of the description from the manufacturer for this purpose.

      h2.title.font-weight-bold.mb-2 product details
      p We would also like a more detailed description of the product, formatted to look nice on a listing.  Currently, we do have some text details, but they have been cut and past from other websites and do not have formatting.
      p For this project we'll be using a very simple formatting language called Markdown.  This is what is typically used online for editing wiki articles.  There are only a few codes that you will need to know in order to use this.  If you want to explore the markdown language further you can always
        a(href='https://guides.github.com/features/mastering-markdown/' target='_blank')  learn more here.
      p We'll only likely need 4 codes for our purpose.
      ol
        li
          span.font-weight-bold make a word or phrase bold:
          span.cyan  **
          span [word or phrase]
          span.cyan **
        li
          span.font-weight-bold make a word or phrase italic:
          span.cyan  *
          span [word or phrase]
          span.cyan *
        li
          span.font-weight-bold make a word or phrase into a header:
          span.cyan  ###
          span [word or phrase]
        li
          span.font-weight-bold make consecutive lines into a bullet list:
          span.cyan  -
          span  [text]

      p.mt-3 Try entering some text using these codes in the 'data entry' area below and see the reults to the right
      v-layout(row).caption.mb-3
        v-flex(xs6).grey.lighten-4
          div.title.mb-3 data entry
          v-card(flat)
            v-card-text
              v-textarea(
                v-model='text'
                rows='12'
              )
        v-flex(xs6 ml-3)
          div.title.mb-3 result:
          v-card(flat)
            div(v-html='markDown')

      p you can see
        a(href='/products/audit/5d2667964cf31c728c9ac5b0' target='_blank')  an example of a well edited record here
        span (opens in a new tab)

      h2.title.font-weight-bold manufacturer websites
      p You'll likely need to visit manufacturer websites a lot in order to copy their text.  Here is a list of links to relevant sites:
      v-list(dense flat)
        div(v-for='manufacturer in manufacturers' :key='manufacturer.name')
            a( :href='manufacturer.to' target='_blank') {{ manufacturer.name }}

  FwdHeadingLarge task 2 - edit tags
  v-layout(row)
    v-flex(xs12)
      p We'll be assigning tags to each product to help us AND our customers search our product database for what is needed.

      h2.title.font-weight-bold.mb-2 tag categories
      p There are currently 4 tag categories, with at least a few tags each:
      ol
        li fabric
        li category
        li style
        li cut
      br
      h2.title.font-weight-bold.mb-2 tag assignments
      p When you get to the tag assignment screen you can click on any appropriate tags on the left side of the screen and these will be assigned to the product.  Likewise, if you click any of the tags assigned to a product, they will be removed.  Please simply use best judgement to assign the tags that seem appropriate.

      p If you aren't sure about any tag assignments, feel free to flag this for review (see "finishing up" below).

  FwdHeadingLarge finishing up
  v-layout(row)
    v-flex(xs6)
      p Once you finish editing a listing, feel free to check one of the following checkboxes:
      p
        span.font-weight-bold done auditing -
        span  you are happy with the record, remove it from the list and move on to the next.
      p
        span.font-weight-bold flag for review -
        span  this record might need some outside input, editing or advice.
      P once you are done editing feel free to hit the back button if you want to tackle another entry.
  FwdHeadingLarge questions or help?
  v-layout(row)
    v-flex(xs6)
      p Any questions, concerns, troubleshooting, etc, please contact:
      p
        a(href='https://app.slack.com/client/TNLJQ37HQ/CS7U959UJ' target='_blank') @Dan Corcoran on slack
      p
        a(href='mailto:danc@forwardprinting.com') danc@forwardprinting.com
      p 510-282-3045
</template>

<script>
import MarkDownIt from 'markdown-it'
const md = new MarkDownIt()

export default {
  data: () => ({
    text: '### This is a heading\n\n*here is some italic text*\n\n**two asterisks to do some bold text**\n\nTodo list:\n- feed cats\n- practice ninjitsu\n- make dinner',
    manufacturers: [
      { name: 'bella/canvas', to: 'https://www.bellacanvas.com/' },
      { name: 'gildan', to: 'https://gildan.com/' },
      { name: 'american apparel', to: 'https://americanapparel.com/' },
      { name: 'district', to: 'https://districtclothing.com/' },
      { name: 'port and company', to: 'https://sanmar.com/' },
      { name: 'tultex', to: 'https://tultex.com/' },
      { name: 'liberty bags', to: 'https://www.libertybags.net/' },
      { name: 'allmade', to: 'https://www.allmade.com/' },
      { name: 'alstyle', to: 'https://www.gildanbrands.com/alstyle' },
      { name: 'alternative', to: 'https://www.alternativeapparel.com/' },
      { name: 'anvil', to: 'https://www.anvilknitwear.com/store/globalLanding.jsp' },
      { name: 'comfort colors', to: 'https://www.comfortcolors.com/us/' },
      { name: 'corner stone', to: 'https://www.cornerstoneworkwear.com/' },
      { name: 'fruit of the loom', to: 'https://www.fruitactivewear.com/' },
      { name: 'hanes', to: 'https://www.hanes.com/' },
      { name: 'independent trading', to: 'https://www.independenttradingco.com/' },
      { name: 'los angeles apparel', to: 'https://losangelesapparel.net/' },
      { name: 'next level apparel', to: 'https://www.nextlevelapparel.com/' },
      { name: 'port authority', to: 'https://www.sanmar.com/' },
      { name: 'rabbit skins', to: 'https://www.latapparel.com/Brands/Rabbit-Skins/' }
    ]
  }),
  computed: {
    markDown () {
      return md.render(this.text)
    }
  }
}
</script>
