<template lang="pug">
v-container
  v-layout(row)
    v-flex(xs6)
      v-container
        NotesPublic
    v-flex(xs6)
      v-container
        NotesArt 
  v-layout(row)
    v-flex(xs6)
      v-container
        NotesInk
    v-flex(xs6)
      v-container
        NotesScreen
  v-layout(row)
    v-flex(xs6)
      v-container
        NotesProduction
</template>

<script>
import NotesPublic from './notes-public'
import NotesArt from './notes-art'
import NotesInk from './notes-ink'
import NotesScreen from './notes-screen'
import NotesProduction from './notes-production'

export default {
  components: {
    NotesPublic,
    NotesArt,
    NotesInk,
    NotesScreen,
    NotesProduction,
  }
}
</script>

<style>

</style>
