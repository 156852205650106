<template lang="pug">
DetailSmall(:detail="detail")
  template(slot="title") company addresses
  template(slot="form")
    Addresses
  template(slot="controls")
    v-btn(@click="cancel") ok
</template>

<script>
import DetailSmall from '@/components/base/detail-small-modal'
import { addresses as Addresses } from '@/modules/companies'

export default {
  components: { DetailSmall, Addresses },
  props: ['detail'],
  methods: { cancel () { this.$emit('cancel') } }
}
</script>
