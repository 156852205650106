import admin from '../views/admin'

export default [
  {
    path: '/productioninstances/admin',
    name: 'pi admin',
    component: admin,
    meta: { authorize: ['Admin', 'User'] }
  },
  
]
