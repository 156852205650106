import commonActions from '@/store/common/actions'
import commonState from '@/store/common/state'
import commonGetters from '@/store/common/getters'
import commonMutations from '@/store/common/mutations'
import { Decoration as Model, DecorationCode } from '@/models'

export default {
  namespaced: true,
  state: {
    path: 'decorations',
    ...commonState(Model),
    input: {
      code: {
        quantity: 1
      }
    }
  },
  getters: {
    ...commonGetters()
  },
  mutations: {
    ...commonMutations()
  },
  actions: {
    ...commonActions(Model),
    updateAllCodes ({ state, dispatch, commit, rootState }) {
      // console.log(state.items)
      const decorationCodes = rootState.decorationCodes.items
      const newItems = state.items.map(item => {
        item.codes = item.codes.map(code => {
          const newCode =  new DecorationCode(decorationCodes.find(x => code._id === x._id))
          newCode.quantity = code.quantity
          return newCode
        })
        return item
      })
      commit('items', newItems)
      dispatch('updateMany', { data: newItems })
    }
    
  }
}
