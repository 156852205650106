const commonTo =
    'danc@forwardprinting.com, kmc@forwardprinting.com, armando@forwardprinting.com, lemanuel@forwardprinting.com, max@forwardprinting.com, '

export default {
    pending(state) {
        return {
            from: 'info@forwardprinting.com', // sender address
            to: state.owner.email + ', ' + commonTo, // list of receivers
            subject: 'pending: ' + state.displayName, // Subject line
            text:
                'Hello!\nThis order has moved to pending status: https://fwdio.forwardprinting.com/orders/' +
                state._id // plain text body
        }
    },

    revertEstimate(state) {
        return {
            from: 'info@forwardprinting.com', // sender address
            to: state.owner.email + ', ' + commonTo, // list of receivers
            subject: 'estimate changes requested: ' + state.displayName, // Subject line
            text:
                'Hello!\nThe client requested changes to this project: https://fwdio.forwardprinting.com/orders/' +
                state._id // plain text body
        }
    },

    estimate(state) {
        return {
            from: 'info@forwardprinting.com', // sender address
            to: state.contact.email + ', ' + commonTo, // list of receivers
            subject: 'estimate: ' + state.displayName, // Subject line
            text:
                'Hello!\nPlease follow this link to view the estimate for your project: https://fwdio.forwardprinting.com/client/orders/' +
                state._id // plain text body
        }
    },

    quote(state) {
        return {
            from: 'info@forwardprinting.com', // sender address
            to: state.owner.email + ', ' + commonTo, // list of receivers
            subject: 'quote: ' + state.displayName, // Subject line
            text:
                'Hello!\nThis order has moved to quote status: https://fwdio.forwardprinting.com/orders/' +
                state._id // plain text body
        }
    },

    shipped(state) {
        return {
            from: 'info@forwardprinting.com', // sender address
            to: state.owner.email + ', ' + commonTo, // list of receivers
            subject: 'order shipped: ' + state.displayName, // Subject line
            text:
                'Hello!\nThis order has completed shipping. Does not mean it has been picked up yet: https://fwdio.forwardprinting.com/orders/' +
                state._id +
                '\n\n please do the following: \n1. let the clinet know that the shipment is coming\n2. finishing completing the payment or submitting the invoice as needed.' // plain text body
        }
    },

    confirmed(state) {
        return {
            from: 'info@forwardprinting.com', // sender address
            to: state.owner.email + ', ' + commonTo, // list of receivers
            subject: 'order confirmed: ' + state.displayName, // Subject line
            text:
                'Hello!\nThis order has been confirmed: https://fwdio.forwardprinting.com/orders/' +
                state._id // plain text body
        }
    },

    completed(state) {
        return {
            from: 'info@forwardprinting.com', // sender address
            to: state.owner.email + ', ' + commonTo, // list of receivers
            subject: 'order completed: ' + state.displayName, // Subject line
            text:
                'Hello!\nThis order has been completed: https://fwdio.forwardprinting.com/orders/' +
                state._id // plain text body
        }
    }
}
