<template lang="pug">
FwdCard
  template(slot="title") available colors
  template(slot="content")
    v-container(fluid)
      div.line-item(
          row
          v-for="color in brandColors"
          :key="color._id"
          @click="addColor(color)"
        ).py-3
        v-layout(row).align-center.py-0
          v-avatar(v-if="color.image.url" size=15 tile)
            img(:src="color.image.url")
          v-flex.py-1 {{color.name}}

</template>
<script>
import { mapFields } from 'vuex-map-fields'
import sortItems from './list-sort'

export default {
  computed: {
    ...mapFields('products', {
      brand: 'item.brand',
      itemColors: 'subproduct.item.colors'
    }),
    brandColors () {
      const colors = this.$store.getters['productColors/items']
      return sortItems(colors, this.brand, this.itemColors)
    }
  },
  methods: {
    async update () {
      await this.$store.dispatch('products/updateSubproducts')
      this.$store.dispatch('products/_UPDATE')
    },

    addColor (color) {
      this.itemColors.push(color)
      this.update()
    }
  }
}
</script>

<style lang="stylus" scoped>
div.line-item:hover
  background-color #F8BBD0
  cursor pointer
</style>
