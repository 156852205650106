<template lang="pug">
v-container(
  v-if="loaded"
  grid-list-lg
)
  v-layout(row)
    v-flex(xs6)
      FwdCard
        template(slot="title") approval
        template(slot="content")
           Approval
    v-flex(xs6)
      FwdCard
        template(slot="title") status log
        template(slot="content")
          p.underline(v-for="item in logSorted") {{item.text}} - {{formatDate(item.time)}}

  v-divider.my-3

  v-layout(column)
    h2(:class="classH2") {{design.displayName}}
    v-flex()
      v-layout(row)
        v-flex
          h3(:class="classH3") mockup images
        v-flex.text-xs-right.pr-3
          v-btn(@click="toggleSize()") toggle full size mockup images
      FwdMockups(:toggleLargeImage="toggleLargeImage")
    v-flex
      h3(:class="classH3") print locations.
      PrintLocations.mx-4
    v-flex(v-if="design.notes.public")
      h3(:class="classH3") notes.
      Notes

</template>

<script>
import FwdMockups from './mockups'
import Approval from './approval'
import PrintLocations from './print-locations-production'
import PointOfContact from '../form/contact'
import Notes from './notes'
import moment from 'moment'

export default {
  components: {
    FwdMockups,
    Approval,
    PrintLocations,
    Notes,
    PointOfContact
  },
  data: () => ({
    classH2: 'display-2 font-weight-black mb-3',
    classH3: 'display-1 pink--text text--accent-1 font-weight-black mb-3 pl-4',
    toggleLargeImage: false
  }),
  computed: {
    loaded () { return this.$store.getters['designs/loaded'] },
    design () { return this.$store.getters['designs/item'] },
    logSorted () {
      return this.sortByPropNumber(this.design.log, 'time')
    }
  },

  methods: {
    toggleSize () { this.toggleLargeImage = !this.toggleLargeImage },
    formatDate (date) {
      if (!date) { return }
      return moment(date).format('L')
    }
  }

}
</script>
<style lang="stylus" scoped>
.underline
  border-bottom 1px solid #E0E0E0
</style>
