<template lang="pug">
v-container.body-1
  v-layout(row).display-1.font-weight-bold
    v-flex
      h3.mb-3 sample ticket
    v-flex.text-xs-right
      v-layout(row wrap)
        v-flex(xs12) design # {{design.serial}}
        v-flex(xs12) job # {{job.jobNumber}}

  v-layout(row).mb-5
    v-flex().title
      span.font-weight-bold {{company.name}} -
      | {{job.design.name}}
      span(v-if="job.subtitle") - {{job.subtitle}}

  v-layout(row).mt-3
    v-flex(xs8).body-1
      v-layout(row)
        v-flex
          span.font-weight-bold job description:
          | {{job.design.subtitleText}}
      v-layout(row)
        v-flex
          span.font-weight-bold order name:
          | {{order.name}} / order # {{order.orderNumber}}

      v-layout(row mt-3 v-if="order.dates.inHands")
        v-flex()
          span.font-weight-bold in hands date:
          | {{formatDate((order.dates.inHands))}}
      v-layout(row v-if="order.dates.dropDead")
        v-flex()
          span.font-weight-bold drop dead date:
          | {{formatDate(order.dates.dropDead)}}
      v-layout(row v-if="order.owner.username")
        v-flex()
          span.font-weight-bold owner:
          | {{order.owner.username}}
      v-layout(row v-if="order.contact.fullName.trim()")
        v-flex()
          span.font-weight-bold point of contact:
          | {{order.contact.fullName}} - {{order.contact.email}}
      v-layout(row v-if="relatedJobs.length > 0")
        v-flex()
          span.font-weight-bold related jobs:
          | {{relatedJobs}}
    v-flex(xs4).pl-3
      v-img(:src='design.image.url')

</template>

<script>
import moment from 'moment'

export default {
  computed: {
    job () { return this.$store.getters['jobs/item'] },
    order () { return this.$store.getters['orders/item'] },
    company () { return this.$store.getters['companies/item'] },
    design () { return this.$store.getters['designs/item'] },
    relatedJobs () { return this.design.jobs.map(x => x.jobNumber) }
  },
  methods: {
    formatDate (date) { return moment(date).format('LL') }
  }
}
</script>
