<template lang="pug">
DetailSmall(:detail="detail")
  template(slot="title")
    | transaction detail.
  template(slot="form")
    v-layout(row)
      v-flex
        v-select(
          label="type"
          v-model="type"
          :items="item.types"
          item-text="type"
          return-object
          @change="updateDirection"
        )
        v-text-field(
          label="amount"
          v-model.number="amount"
        )
        v-textarea(
          label="note"
          v-model="note"
        )
        Dates()
    
  template(slot="controls")
    v-btn(@click="save")
      v-icon(left) save
      | ok
    v-btn.ml-3(@click="remove")
      v-icon(left) delete
      | remove
    v-btn.ml-3(@click="cancel")
      v-icon(left) cancel
      | cancel
      
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import DetailSmall from '@/components/base/detail-small-modal'
import Dates from './dates'

export default {
  components: {
    DetailSmall,
    Dates
  },
  computed: {
    ...mapFields('orders', {
      transactions: 'item.transactions',
      amountPaid: 'item.costs.amountPaid'
    }),
    ...mapFields('orders/transactions', {
      item: 'item',
      note: 'item.note',
      amount: 'item.amount',
      type: 'item.type',
      isCredit: 'item.isCredit',
      detail: 'detail',
    }),
  },
  methods: {
    save () {
      this.$store.commit('orders/itemsUpdate', {
        list: 'item.transactions',
        item: this.item
      })
      this.cancel()
    },
    cancel () {
      this.detail = false
      this.updateAmountPaid()
      this.$store.dispatch('orders/_SAVE')
    },
    remove () {
      this.$store.commit('orders/itemsRemove', {
        list: 'item.transactions',
        item: this.item
      })
      this.cancel()
    },
    updateDirection () {
      this.isCredit = this.type.isCredit
    },
    updateAmountPaid () {
      let amount = 0
      this.transactions.forEach(item => {
        const direction = item.isCredit ? 1 : -1
        amount += item.amount * direction
      })
      this.amountPaid = amount
    }
  }
}
</script>
