<template lang="pug">
  div
    v-img.mb-3(
      :src='image.url'
    )
    h3 {{ item.displayName }}
    p {{ item.subtitleText }}
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  computed: {
    ...mapFields('designs', {
      item: 'item',
      image: 'item.image'
    })
  }
}
</script>
