<template lang="pug">
v-btn.white--text(
  dark
  color="pink accent-1"
  @click="goToCompany"
) go to company
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  computed: {
    ...mapFields('orders', {
      item: 'item',
      actions: 'actions',
    })
  },
  methods: {
    async goToCompany() {
      this.$router.push('/companies/' + this.item.company)
      this.actions = false
    },
  }
}
</script>

<style>

</style>
