/** Model definition file for the PurchaseOrderLine Class **/
// import { LineItem, VendorCompany } from '@/models'
// TODO: get the model constructors working again (had to remove because webpack build wouldn't work)

export default class PurchaseOrderLine {
  constructor ({
    _id = '',
    PONumber = '',
    PO_id = '',
    lineItem = {},
    productName = '',
    product_id = '',
    order_id = '',
    orderName = '',
    orderNumber = '',
    company_id = '',
    companyName = '',
    job_id = '',
    jobName = '',
    jobNumber = '',
    vendor = {}
  } = {}) {
    this._id = _id
    this.PONumber = PONumber
    this.PO_id = PO_id
    this.lineItem = lineItem
    this.productName = productName
    this.product_id = product_id
    this.order_id = order_id
    this.orderName = orderName
    this.orderNumber = orderNumber
    this.company_id = company_id
    this.companyName = companyName
    this.job_id = job_id
    this.jobName = jobName
    this.jobNumber = jobNumber
    this.vendor = vendor
  }

  get jobDisplayName () {
    return `${this.jobNumber} ${this.jobName} - ${this.companyName} - ${this.orderNumber} ${this.orderName}`
  }
  
  get jobDisplayNameShort () {
    return `${this.jobNumber} ${this.jobName} // ${this.companyName}`
  }

  get sortString () {
    let string = this.job_id.padStart(100, '0')
    string += this.product_id.padStart(100, '0')
    string += String(this.lineItem.size.sortOrder).padStart(20, '0')
    return string
  }

  get sortStringProduct () {
    let string = this.productName.padStart(50, '0')
    string += this.product_id.padStart(50, '0')
    string += this.lineItem.color.name.padStart(50, '0')
    string += String(this.lineItem.size.sortOrder).padStart(20, '0')
    string += String(this.lineItem.size._id).padStart(50, '0')
    return string
  }

  get vendorName () { return this.vendor.name}
  
}
