<template lang="pug">
v-container(fluid grid-list-lg)
  v-layout(row wrap )
    v-flex(
      :class="flexSize"
      v-for="(mockup, index) in mockups"
      :key="mockup._id"
    )
      DocumentMockupCard.mb-2(
        :mockup="mockup"
      )
</template>

<script>
import DocumentMockupCard from './document-mockup-card'

export default {
  components: {
    DocumentMockupCard
  },
  props: {
    toggleLargeImage: { type: Boolean, default: false}
  },
  computed: {
    design () { return this.$store.getters['designs/item'] },
    mockups () {
      return this.sortByPropNumber(this.design.mockups, 'sortOrder').reverse()
    },
    flexSize() {
      if (this.toggleLargeImage) { return 'xs12'}
      return 'xs6'
    }
  }
  
}
</script>
