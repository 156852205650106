import { ProductColor as Model, Upload } from '@/models'

export default {
  
  async _ADD ({ state, dispatch }) {
    // check filter to autofill
    if (state.filter) {
      await dispatch('_NEW', { brand: state.filter })
    } else {
      await dispatch('_NEW')
    }

    // open detail
    dispatch('_DETAIL_OPEN')
  },

  async _SAVE ({ state, dispatch }) {
    // check _id to determine method
    if (state.item._id) {
      await dispatch('_UPDATE')
    } else {
      await dispatch('_CREATE')
    }

    // close detail
    dispatch('_DETAIL_CLOSE')
  },

  async _SELECT ({ dispatch }, item) {
    // load the record
    await dispatch('_READ', item._id)

    // open detail
    dispatch('_DETAIL_OPEN')
  },

  async _REMOVE ({ dispatch }) {
    // delete the record
    await dispatch('_DELETE')

    // close detail
    dispatch('_DETAIL_CLOSE')
  },

  async _NEW ({ commit }, payload) {
    // add to item state
    commit('item', new Model(payload))
  },

  async _DETAIL_OPEN ({ commit }) {
    commit('detail', true)
    commit('upload', new Upload())
  },

  async _DETAIL_CLOSE ({ commit, dispatch }) {
    commit('detail', false)
    await dispatch('_NEW')
  },


}