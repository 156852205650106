<template lang="pug">
v-footer(app :padless="true" v-if="loggedIn && !clientRoute").black.white--text.px-3
  v-layout(row justify-space-between align-center).text-lowercase
    span.caption user: {{user.username}} / role: {{user.role}}
    v-btn(@click='needHelp' small flat dark) need help?

</template>

<script>
export default {
  computed: {
    clientRoute () {
      return this.$route.path.includes('/client/')
    },
    user () { return this.$store.state.users.item },
    loggedIn () {
      const status = this.$store.state.users.account.status
      return status.loggedIn
    }

  },
  methods: {
    needHelp () { this.$store.commit('helpDialog/needHelp') }
  }
}
</script>
