import commonActions from '@/store/common/actions'
import commonState from '@/store/common/state'
import commonGetters from '@/store/common/getters'
import commonMutations from '@/store/common/mutations'
import crud from './crud'
import { PurchaseOrderLine as Model } from '../../'

export default {
  namespaced: true,
  state: {
    path: 'po-pool-lines',
    ...commonState(Model),
    selectedItems: []
  },
  getters: { ...commonGetters() },
  mutations: { ...commonMutations() },
  actions: {
    ...commonActions(Model),
    ...crud,
    
  }
}
