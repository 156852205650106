<template lang="pug">
FwdCard
  template(slot="title") assigned colors
  template(slot="content")
    v-container(fluid)
      div.line-item(
          row
          v-for="color in itemColors"
          :key="color._id"
          @click="removeColor(color)"
        ).py-3
        v-layout(row).align-center.py-0
          v-avatar(v-if="color.image.url" size=15 tile)
            img(:src="color.image.url")
          v-flex.py-1 {{color.name}}

</template>
<script>
import { mapFields } from 'vuex-map-fields'
import sortItems from './list-sort'

export default {
  computed: {
    ...mapFields('products', {
      itemColors: 'subproduct.item.colors'
    })
  },

  methods: {
    async update () {
      await this.$store.dispatch('products/updateSubproducts')
      this.$store.dispatch('products/_UPDATE')
    },

    removeColor (color) {
      let index = this.itemColors.findIndex(el => {
        return color._id === el._id
      })
      this.itemColors.splice(index, 1)
      this.update()
    }
  }
}
</script>

<style lang="stylus" scoped>
div.line-item:hover
  background-color #F8BBD0
  cursor pointer
</style>
