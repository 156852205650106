<template lang="pug">
v-container
  PiList
  v-layout(row)
    v-flex()
      PiDetail
</template>

<script>
import { list as PiList } from '@/modules/production-instances'
import { detail as PiDetail } from '@/modules/production-instances'

export default {
  components: { PiList, PiDetail },
}
</script>
