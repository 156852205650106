<template lang="pug">
v-container(grid-list-md fluid)
  v-layout(row)
    v-flex
      v-btn(@click='add') add shipment
  v-layout(row wrap)
    v-flex(v-for="(shipment, i) in items" :key="i" xs12)
      ShipmentCard(
        :shipment="shipment"
        @select="select(shipment)"
      )
  
  
            
  DetailSimple(
    :detail="detail"
    :item="item"
    @detail="detailToggle"
  )
</template>

<script>
import moment from 'moment'
import { Shipment } from '@/models'
import DetailSimple from './detail'
import ShipmentCard from './shipment'

export default {
  components: { DetailSimple, ShipmentCard },
  data: () => ({
    detail: false,
    item: {}
  }),
  computed: {
    items () {
      let order = this.$store.getters['orders/item']
      let shipments = order.shipments.map(shipment => {
        return new Shipment(shipment)
      })
      return shipments
    }
  },
  methods: {
    add () {
      this.item = new Shipment()
      this.detailToggle()
    },
    select (item) {
      this.item = new Shipment(item)
      this.detailToggle()
    },
    detailToggle () {
      this.detail = !this.detail
    },
    formatDate (date) {
      return moment(date).format('L')
    }
  }
}
</script>
