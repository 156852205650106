/** Model definition file for the Dashboard Class **/

export default class Dashboard {
  constructor ({
    
  } = {}) {

  }
  
  

}
