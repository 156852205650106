<template lang="pug">
v-container(fluid)
  ListCollectionSimple(
    :items="itemsSorted"
    @select="select"
    @add="add"
  )
    template(slot-scope="{ item }")
      v-layout(row)
        v-flex(xs3) {{item.fullName}}
        v-flex(xs3) {{item.title}}
        v-flex(xs3) {{item.email}}
        v-flex.font-weight-bold(xs3 pl-2) {{isPrimary(item)}}
  FwdDetailSmall(
    :detail="detail"
    @cancel="cancel"
  )
</template>

<script>
import { Contact } from '@/models'
import { mapFields } from 'vuex-map-fields'
import ListCollectionSimple from '@/components/base/list-simple'
import FwdDetailSmall from './detail'

export default {
  components: { ListCollectionSimple, FwdDetailSmall },
  data: () => ({ detail: false }),
  computed: {
    
    ...mapFields('companies', {
      item: 'contact',
      items: 'item.contacts'
    }),

    itemsSorted () {
      const items = this.items.map(item => new Contact(item))
      return this.sortByBool(items, 'isPrimary')
    }
  },
  methods: {
    isPrimary (contact) {
      if (contact.isPrimary) { return 'primary contact'}
      return ''
    },

    add () {
      this.item = new Contact()
      this.detail = true
    },

    select (item) {
      this.item = new Contact(item)
      this.detail = true
    },

    cancel () { this.detail = false }

  }
}
</script>
