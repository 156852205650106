export default {
  async duplicateProductGroups ({state, commit, dispatch }, { job, productGroups }) {
    // make copies of existing product groups
    const promises =[]
    for (const pg of productGroups) {
      delete pg._id
      pg.job_id = job._id
      promises.push(dispatch('postOne', { data: pg }))
    }
    const newProductGroups = await Promise.all(promises)
    commit('items', newProductGroups)

    return newProductGroups
  }
}