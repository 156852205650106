<template lang="pug">
v-container(grid-list-lg fluid)
  v-layout(row)
    v-flex()
      FwdCard
        template(slot="title") single product record
        template(slot="content")
          pre {{product}}
</template>

<script>
export default {
  computed: {
    product () {
      const item = this.$store.getters['products/item']
      return JSON.stringify(item, undefined, 2)
    }
  }
}
</script>

style <style lang="stylus">
pre
  white-space pre-wrap
</style>