<template lang="pug">
  v-layout(row)
    v-flex
      v-hover
        v-btn(
          slot-scope="{ hover }"
          @click="back"
          color="pink accent-1"
          small
          fab
          depressed
          :class="hover ? 'pink darken-4' : ''"
        ) 
          v-icon arrow_back

    
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  computed: {
    ...mapFields('orders', {
      item: 'item',
    })
  },
  methods: {
    back () {
      this.$router.go(-1)
    },


  }
}
</script>
