<template lang="pug">
div
  h3.subheading.font-weight-bold art
  v-checkbox(
    color='pink'
    label="rip file submitted?"
    v-model="ripped"
    @change="save"
  )
  h3.subheading.font-weight-bold pre production
  v-checkbox(
    color='pink'
    label="screen(s) prepped?"
    v-model="screens"
    @change="save"
  )
  v-checkbox(
    color='pink'
    label="ink(s) mixed?"
    v-model="progress.isInksMixed"
    @change="save"
  )
  h3.subheading.font-weight-bold production sign-off
  v-checkbox(
    color='pink'
    label="approved on the floor?"
    v-model="approved"
    @change="save"
  )
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  computed: {
    ...mapFields('productionInstances', {
      progress: 'item.progress',
      ripped: 'item.progress.isArtRipped',
      screens: 'item.progress.isScreensPrepped',
      inks: 'item.progress.isInksMixed',
      approved: 'item.progress.isPrintApproved'
    })
  },
  methods: {
    save () { this.$store.dispatch('productionInstances/_UPDATE') }
  }
}
</script>
