<template lang="pug">
DetailSmall(:detail="detail")
  template(slot="title") product tag detail.
  template(slot="form")
    v-text-field(
      label="name"
      v-model="name"
    )
    v-select(
      label="category"
      v-model="category"
      :items='categories'
    )
  template(slot="controls")
    DetailControls(
      @save="save"
      @cancel="cancel"
      @remove="remove"
    )
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import DetailSmall from '@/components/base/detail-small-modal'
import DetailControls from '@/components/base/detail-controls-simple'

export default {
  components: { DetailControls, DetailSmall },
  computed: {
    ...mapFields('productTags', {
      item: 'item',
      name: 'item.name',
      category: 'item.category',
      detail: 'detail'
    }),
    categories () { return this.item.categories }
  },
  methods: {
    async save () {
      await this.$store.dispatch('productTags/save', this.item)
      this.cancel()
    },

    async remove () {
      await this.$store.dispatch('productTags/remove', this.item._id)
      this.cancel()
    },

    cancel () {
      this.detail = false
    }
  }
}
</script>
