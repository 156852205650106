<template lang="pug">
div
  v-toolbar.transparent(flat dense v-if="toolbar")
    v-toolbar-title.font-weight-thin.display-1.pink--text.text-lowercase
      slot(name="title")
    v-spacer
    v-btn.pink.accent-1.ml-5(icon @click="add" dark small v-if="addToggle")
      v-icon add
  v-list.transparent(subheader)
    v-list-tile.list-item.pl-2(
        v-for='(item, $index) in items'
        :key='$index'
      )
      v-list-tile-content(@click="select(item)")
        v-list-tile-title
          slot(:item="item")
</template>
<script>
export default {
  props: {
    items: { type: Array },
    addToggle: { type: Boolean, default: true },
    toolbar: { type: Boolean, default: true }
  },
  methods: {
    select (item) { this.$emit('select', item) },
    add () { this.$emit('add') }
  }
}
</script>
