<template lang="pug">
v-dialog(max-width=600 v-model="detail")
  v-card.pa-5
    v-card-title.display-2.font-weight-black
      slot(name="title")
    v-card-text
      slot(name="content")
</template>
<script>
export default {
  props: {
    detail: { type: Boolean, required: true }
  }
}
</script>
