<template lang="pug">
v-layout.caption(row)
  v-flex(v-for="(item, i) in progressItems" :key="i")
    v-card.text-xs-center(
      tile
      flat
      :color="item ? 'black' : barStyles[i].color"
      :class="item ? 'font-weight-bold white--text' : ''"
    )
      v-card-text(class="pa-0") {{barStyles[i].abbr}}
</template>
<script>
export default {
  props: {
    progress: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    barStyles: [
    { abbr: 'prod', color: 'red lighten-4' },
    { abbr: 'art', color: 'red lighten-3' },
    { abbr: 'note', color: 'red lighten-2' },
    { abbr: 'pre', color: 'red lighten-1' },
    { abbr: 'sent', color: 'lime lighten-4' },
    { abbr: 'appr', color: 'lime lighten-3' },
    { abbr: 'ord', color: 'amber lighten-4' },
    { abbr: 'rec', color: 'amber lighten-4' },
    { abbr: 'done', color: 'purple lighten-4' },
    ]
  }),
  computed: {
    progressItems() {
      return Object.keys(this.progress)
      .filter(x => x !== 'isfangamerprintcheck')
      .map(key => {
        return this.progress[key]
      })
    }
  }
}
</script>