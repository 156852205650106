<template lang="pug">
v-container(fluid grid-list-xl)
  v-layout(row)
    v-flex(xs3)
      SubproductList
    template(v-if="item._id")
      v-flex(xs3)
        SubproductDetail
      v-flex(xs3)
        SubproductAssignedColors
      v-flex(xs3)
        BrandColors
</template>
<script>
import { mapFields } from 'vuex-map-fields'
import SubproductList from './list'
import SubproductDetail from './detail'
import SubproductAssignedColors from './color-assigned-list'
import BrandColors from './color-brand-list'

export default {
  components: {
    SubproductList,
    SubproductDetail,
    SubproductAssignedColors,
    BrandColors
  },
  computed: {
    ...mapFields('products', {
      item: 'subproduct.item'
    })
  }
}
</script>
