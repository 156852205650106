export default (model) => ({
  items: [],
  item: new model(),
  itemOriginal: {},
  input: {},
  tabs: 0,
  actions: false,
  search: '',
  filter: null,
  detail: false,
  loaded: false, 
  editState: false,
  saveFlag: false,
  upload: {
    formData: null,
    url: null
  }

})
