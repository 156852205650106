<template lang="pug">
div
  div(@click='launchFilePicker()')
    //- v-avatar.mb-3(tile :size="size + 'px'" v-ripple v-if="item.image.url && !upload.url")
    v-img(:src='item.image.url' contain v-if="item.image.url && !upload.url")
    v-img(:src='upload.url' v-else-if="upload.url")
    v-avatar.grey.lighten-3.mb-3(tile size="150px" v-ripple v-else)
      span Click to add image

  // image input: style is set to hidden and assigned a ref so that it can be triggered
  input(
    type='file'
    ref='file'
    @change='onFileChange($event.target.files)'
    style='display:none'
    )
  // error dialog displays any potential error messages
  v-dialog(v-model='errorDialog' max-width='300')
    v-card
      v-card-text.subheading {{errorText}}
      v-card-actions
        v-spacer
        v-btn(@click='errorDialog = false' flat='') Got it!
</template>

<script>
export default {
  props: {
    // to pull image info off of existing item
    item: {
      type: Object
    },
    // to pull info off of the state upload bucket
    upload: {
      type: Object,
      required: true
    },
    // pixel size of the image preview
    size: {
      type: Number,
      default: 350
    }
  },
  data: () => ({
    errorDialog: null,
    errorText: ''
  }),
  computed: {
    baseURL () {
      return this.axios.defaults.baseURL + '/img'
    }
  },
  methods: {
    launchFilePicker () {
      this.$refs.file.click()
    },
    onFileChange (file) {
      const maxSize = 1024 * 1024 * 5
      let imageFile = file[0]

      if (file.length > 0) {
        let size = imageFile.size / maxSize / maxSize
        if (!imageFile.type.match('image.*')) {
          // check whether the upload is an image
          this.errorDialog = true
          this.errorText = 'Please choose an image file'
        } else if (size > 1) {
          // check whether the size is greater than the size limit
          this.errorDialog = true
          this.errorText = 'Your file is too big! Please select an image under 1MB'
        } else {
          // Append file into FormData and turn file into image URL
          let url = URL.createObjectURL(imageFile)
          let formData = imageFile
          // Emit the FormData and image URL to the store
          this.$emit('upload', { formData, url })
        }
      }
    }
  }
}
</script>
