<template lang="pug">
v-container
  v-layout.headline(justify='space-around')
    v-flex(xs10)
      HeadInfo
      JobDetail

</template>

<script>
import HeadInfo from './head-info'
import JobDetail from './job-detail'

export default {
  components: {
    JobDetail,
    HeadInfo
  },

  async created () {
    await this.$store.dispatch('jobs/_LOAD', this.$route.params.id)
  }
}
</script>
