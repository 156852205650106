<template lang="pug">
v-layout(row)
  // show if ok to edit
  template(v-if="isEdit")
    v-hover
      v-btn(
        slot-scope="{ hover }"
        @click="save"
        color="pink accent-1"
        small
        fab
        depressed
        :class="hover ? 'pink darken-4' : ''"
      ) 
        v-icon save
  template(v-if="isEdit")
    v-hover
      v-btn(
        slot-scope="{ hover }"
        @click="actions"
        color="pink accent-1"
        depressed
        fab
        small
      ) 
        v-icon more_vert
    v-hover
      v-btn(
        slot-scope="{ hover }"
        @click="cancel"
        color="pink accent-1"
        small
        fab
        depressed
        :class="hover ? 'pink darken-4' : ''"
      ) 
        v-icon cancel
  v-flex(v-else)
    v-hover
      v-btn(
        slot-scope="{ hover }"
        @click="back"
        color="pink accent-1"
        small
        fab
        depressed
        :class="hover ? 'pink darken-4' : ''"
      ) 
        v-icon arrow_back
    v-hover
      v-btn(
        slot-scope="{ hover }"
        @click="edit"
        color="pink accent-1"
        small
        fab
        v-ripple
        depressed
        :class="hover ? 'pink' : ''"
      ) 
        v-icon edit

</template>

<script>

export default {
  props: {
    isEdit: { type: Boolean, required: true }
  },
  methods: {
    save () { this.$emit('save') },
    actions () { this.$emit('actions') },
    edit () { this.$emit('edit') },
    back () { this.$emit('back') },
    cancel () { this.$emit('cancel') }
  },
}
</script>
