import commonActions from '@/store/common/actions'
import commonState from '@/store/common/state'
import commonGetters from '@/store/common/getters'
import commonMutations from '@/store/common/mutations'
import Model from '../models/Dashboard'

export default {
  namespaced: true,
  state: {
    priorityToggle: false,
    teamToggle: false,
    progressToggle: false,
    taskdetail: false,
    ...commonState(Model),
    teams: [
      { _id: '001', name: 'team you', _sort: 100 },
      { _id: '006', name: 'team nu-you', _sort: 150 },
      { _id: '002', name: 'team eco', _sort: 200 },
      { _id: '003', name: 'team checkmark', _sort: 300 },
      { _id: '005', name: 'team emb', _sort: 350 },
      { _id: '004', name: 'remove team', _sort: 400 }
    ]
  },
  getters: {
    ...commonGetters(),
    teams (state) { return state.teams }
  },
  mutations: {
    ...commonMutations()
  },
  actions: {
    ...commonActions(Model),
    // get schedule items
    async _LOAD_SCHEDULE ({ commit, dispatch, rootState, state }) {
      dispatch('productionInstances/setup', null, { root: true })
      const items = await dispatch('productionInstances/getCollection', null, { root: true })
      commit('productionInstances/items', items, { root: true })
      dispatch('productionInstances/cleanup', null, { root: true })
    },
    async _LOAD_COMPLETEDLASTMONTH ({ commit, dispatch, rootState, state }) {
      dispatch('productionInstances/setup', null, { root: true })
      await dispatch('productionInstances/getcompletedlastmonth', null, { root: true })
      dispatch('productionInstances/cleanup', null, { root: true })
    },

  }
}
