<template lang="pug">
v-layout(row)
  v-flex(xs6)
    v-btn.white--text(
      dark
      color="pink accent-1"
      @click="goToOrder"
    ) go to order
  v-flex(xs6)
    v-btn.white--text(
      dark
      color="pink accent-1"
      @click="goToDesign"
    ) go to design
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  computed: {
    ...mapFields('jobs', {
      item: 'item',
      actions: 'actions',
    })
  },
  methods: {
    async goToOrder() {
      this.$router.push('/orders/' + this.item.order)
      this.actions = false
    },
    async goToCompany() {
      this.$router.push('/companies/' + this.item.company._id)
      this.actions = false
    },
    async goToDesign() {
      this.$router.push('/designs/' + this.item.design._id)
      this.actions = false
    },
  }
}
</script>

<style>

</style>
