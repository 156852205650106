/** Model definition file for the PendingChecklist Class **/
export default class PendingChecklist {
  constructor ({
    isDatesOk = false,
    isShippingOk = false,
    isDepositOk = false,
  } = {}) {
    this.isDatesOk = isDatesOk
    this.isShippingOk = isShippingOk
    this.isDepositOk = isDepositOk
  }
}
