<template lang="pug">
div
  v-list.transparent(two-line)
    template(v-for="(job, i) in jobs")
      JobLine(:job='job' :order='order' :key='i')
    template(v-for="(otherCharge, o) in otherCharges")
      OtherChargeLine(:order='order' :otherCharge='otherCharge' :key='(o + 1)*999')

</template>

<script>
import JobLine from './job-line'
import OtherChargeLine from './other-charge-line'

export default {
  props: {
    order: { required: true, type: Object },
    jobs: { required: true, type: Array },
    otherCharges: { required: true, type: Array }
  },
  components: { JobLine, OtherChargeLine }
}
</script>
