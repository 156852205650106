<template lang="pug">
div
  BaseList(
    :items="itemsSorted"
    :selected="location"
    :addToggle="editState"
    :toolbar="false"
    @select="select"
    @add="add"
  )
    template(slot-scope="{ item }") {{item.template.name}}
</template>
<script>
import { DesignLocation } from '@/models'
import { mapFields } from 'vuex-map-fields'
import BaseList from '@/components/base/list-selected'

export default {
  components: {
    BaseList
  },
  computed: {
    ...mapFields('designs', {
      items: 'item.locations',
      sizeways: 'item.sizeways',
      colorways: 'item.colorways',
      location: 'selected.location',
      selectedSizeway: 'selected.sizeway',
      selectedColorway: 'selected.colorway',
      selectedLocationSizeway: 'selected.locationSizeway',
      selectedLocationColorway: 'selected.locationColorway',
      // controls
      editState: 'editState'
    }),
    itemsSorted () {
      return this.sortByPropNumber(this.items, 'sortOrder')
    }
  },
  methods: {
    add () {
      this.location = new DesignLocation()
      this.sizeways.forEach(sizeway => {
        this.location.sizeways.push({
          sizeway,
          dimensions: {width:null, height: null},
          placements: []
        })
      })
      this.colorways.forEach(colorway => {
        this.location.colorways.push({
          colorway,
          screens: []
        })
      })
      this.$store.dispatch('designs/initializeSelectedLocation')
      this.items.push(this.location)
    },
    async select (item) {
      await this.$store.dispatch('designs/updateLocation')
      this.location = item
      this.$store.dispatch('designs/initializeSelectedLocation')
    }
    
  },
  created () {
    
  }
}
</script>
