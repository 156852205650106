export default {
  async markOrdered ({ state, dispatch, rootState }) {
    dispatch('setup')
    // mark ordered
    state.item.ordered = true
    state.item.dateOrdered = Date.now()

    // bail if no jobs to process
    const jobs = rootState.jobs.items
    if (jobs.length < 1) { return }

    // loop through jobs from this PurchaseOrder
    for (const job of jobs) {
      // find lines for particular job
      const lines = state.item.lines.filter(line => line.job_id === job._id)
      // find corresponding product groups
      const productGroups = job.productGroups

      // deal with and save product groups in subroutine
      const newProductGroups = await dispatch('markOrderedProductGroups', { lines, productGroups })

      // find out if all items are ordered and update job progress
      dispatch('markOrderedJobProgress', { newProductGroups, job })
    }

    // reload the related jobs
    await dispatch('loadRelatedJobs')
    dispatch('cleanup')
  },

  async markOrderedProductGroups ({ dispatch }, { lines, productGroups }) {
    const newProductGroups = []
    // find each line by looping through product groups
    for (const productGroup of productGroups) {
      // this should return a product group (updated)
      const newProductGroup = await dispatch('markOrderedLineItems', { lines, productGroup })
      newProductGroups.push(newProductGroup)
    }
    return newProductGroups
  },

  async markOrderedJobProgress ({ dispatch, commit }, { newProductGroups, job }) {
    // find each line by looping through product groups
    let isAllOrdered = true
    for (const productGroup of newProductGroups) {
      // check if all ordered
      const isLineItemNotOrdered = productGroup.lineItems.find(lineItem => {
        !lineItem.isOrdered && lineItem.quantity > 0
      })
      if (isLineItemNotOrdered) { isAllOrdered = false }
    }
    // find out if all items are ordered and update job progress
    if (isAllOrdered) {
      job.progress.isProductsOrdered = true
      // dispatch('productionInstances/updateJobProgress', { progress: job.progress, job_id: job._id }, { root: true })
      commit('jobs/item', job, { root: true })
      dispatch('jobs/_SAVE', null, { root: true })
    }
  },

  markOrderedLineItems ({ state, dispatch }, { lines, productGroup }) {
    // update the amount ordered and mark lineItem as ordered
    const newLineItems = []
    for (const lineItem of productGroup.lineItems) {
      // construct an updated line item (if possible)
      const find = lines.find(x => x.lineItem._id === lineItem._id)
      if (find !== undefined) {
        lineItem.quantityOrdered = find.lineItem.quantityOrdered
        lineItem.isOrdered = true
        lineItem.po_id = state.item._id
        lineItem.poNumber = state.item.number
      }
      newLineItems.push(lineItem)
    }

    // loop through new line items and update database
    newLineItems.forEach(l => {
      dispatch('productGroups/_UPDATE_ONE_LINE', l, { root: true })
    })

    // return updated product group
    productGroup.lineItems = newLineItems
    return productGroup
  }

}
