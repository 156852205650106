<template lang="pug">
v-container().pt-5
  FwdHeadingLarge po pool
  v-layout(row)
  BaseList(
    :items="itemsSorted"
    searchLabel="search po pool line items"
    @search="setSearch"
    @select="select"
    :addToggle="false"
    @vendorDetail="vendorDetail = true"
  )
    template(slot-scope="{ item }")
      v-layout(row v-if="item.lineType === 'line'")
        v-flex(xs6)   
          v-layout(row)
            v-flex(v-if="item.vendor._id").mr-2
              v-icon business
            v-flex(xs10).no-wrap {{item.productName}}
        v-flex(xs3) {{ item.lineItem.color.name}}
        v-flex(xs1).text-xs-center.text-uppercase {{item.lineItem.size.name}}
        v-flex(xs2).text-xs-right {{item.lineItem.quantity}}
      v-layout(row v-if="item.lineType === 'subsummary'")
        v-flex(xs12).black--text.font-weight-bold {{item.name}}
    template(slot="line2" slot-scope="{ item }")
      v-layout(row v-if="item.lineType === 'line'")
        v-flex.caption.pink--text {{item.vendor.name}}

  VendorCompanyDetail(
    :detail="vendorDetail"
    @save="saveVendorCompany"
    @cancel="cancel"
  )
</template>

<script>
import { PurchaseOrderLine } from '@/models'
import { mapFields } from 'vuex-map-fields'
import BaseList from './list-extended'
import VendorCompanyDetail from './detail-vendor'

export default {
  components: { BaseList, VendorCompanyDetail },
  data: () => ({ vendorDetail:  false }),
  computed: {
    ...mapFields('poPoolLines', {
      items: 'items',
      search: 'search',
      selectedItems: 'selectedItems'
    }),
    itemsSorted () {
      const items = this.items.map(x => new PurchaseOrderLine(x))
      // filter by search term
      let list = this.filterByProps(this.items, ['productName', 'vendorName'], this.search)
      // sort list by custom sort string
      list = this.sortByString(list, 'sortString')

      // add a prop to help build the list
      list = list.map(x =>  {
        x.lineType = 'line'
        return x
      })
      // prepare job subsummary lines
      // account for an array of a single item
      if(list.length === 0) { return list }
      if(list.length === 1) {
        const subsummary = { lineType: 'subsummary', name: list[0].jobDisplayName }
        list.splice(0, 0, subsummary)
        return list
      }
      // more than one item, start at second to last
      let previousItem = list[list.length - 1]
      for (let i = list.length - 2; i >= 0; --i) {
        const previousJobNumber = previousItem.jobNumber
        const currentJobNumber = list[i].jobNumber
        const subsummary = { lineType: 'subsummary', name: previousItem.jobDisplayName, job_id: previousItem.job_id }
        // if the date has changed from a previous item splice in a subsummary
        if (currentJobNumber !== previousJobNumber) {
          list.splice(i + 1, 0, subsummary)
        }
        // if the last item is reached create one more subsummary
        if (i === 0) { list.splice(0, 0, { lineType: 'subsummary', name: list[i].jobDisplayName, job_id: list[i].job_id }) }
        // set previous item to current item
        previousItem = list[i]
      }
      return list
    },

  },
  methods: {
    async select (item) {
      this.$router.push({ path: '/po-pool/' + item._id })
    },
    setSearch (search) { this.search = search },
    saveVendorCompany (vendor) {
      if(!vendor._id) { return }
      for (const item of this.selectedItems) {
        item.vendor = vendor
        this.$store.commit('poPoolLines/item', item)
        this.$store.dispatch('poPoolLines/_UPDATE')
      }
      this.selectedItems = []
      this.cancel()
    },
    cancel () {
      this.vendorDetail = false }
  },
  async created () { await this.$store.dispatch('poPoolLines/_READ_ALL') }
}
</script>

<style lang="stylus" scoped>
.no-wrap
  white-space nowrap
  overflow hidden
  text-overflow ellipsis
</style>