<template lang="pug">
div
  Actions
  HeadInfo
  CostSummary
  JobDetail
  Shipments
  ApprovalBlock
    

</template>

<script>
import ApprovalBlock from './approval-block'
import Actions from './actions'
import HeadInfo from './head-info'
import CostSummary from './cost-summary'
import JobDetail from './job-detail'
import Shipments from './shipments'

export default {
  components: {
    JobDetail,
    ApprovalBlock,
    Actions,
    HeadInfo,
    CostSummary,
    Shipments
  },
  // async beforeMount () {
  //   this.$store.dispatch('orders/_LOAD_JOBS_FOR_DOCUMENT')
  // }
}
</script>
