/** Model definition file for the ApprovalBlock Class **/

export default class ApprovalBlock {
  constructor ({
    approved = false,
    date = null,
    by = '',
    termsAndConditions = false,
    isNeedsRevision = false
  } = {}) {
    this.approved = approved
    this.date = date
    this.by = by
    this.termsAndConditions = termsAndConditions
    this.isNeedsRevision = isNeedsRevision
  }
  
}
