<template lang="pug">
v-card
  v-img(:src='image.url' height='200px' contain).mb-3
  div.px-3
    v-layout(row)
      v-flex.caption {{ brand }}
    v-layout(row).mb-3
      v-flex.title {{ code }} - {{ name }}
  v-card-text
    v-layout(row).mb-3
      v-flex()
        TagsDisplay
    v-layout(row).mb-3
      v-flex() {{ descrip }}
    v-layout(row).mb-3
      v-flex(v-html='markDown' style='{ white-space: pre-wrap}')
  v-card-actions.px-3
    v-checkbox(v-model='isEdited' label='done auditing?' @change="save")
    v-checkbox(v-model='flagForReview' label='flag for review' @change="save" color='red')
</template>

<script>
import TagsDisplay from './TagDisplay'
import { mapFields } from 'vuex-map-fields'
import MarkDownIt from 'markdown-it'
const md = new MarkDownIt()

export default {
  components: { TagsDisplay },
  computed: {
    ...mapFields('products', {
      description: 'item.description',
      descrip: 'item.descrip',
      text: 'item.text',
      image: 'item.image',
      brand: 'item.brand.name',
      code: 'item.code',
      name: 'item.name',
      tags: 'item.tags',
      edits: 'item.edits',
      isEdited: 'item.edits.phase1.isEdited',
      flagForReview: 'item.edits.phase1.flagForReview'
    }),
    markDown () {
      return md.render(this.text)
    }
  },
  methods: {
    save () { this.$store.dispatch('products/_UPDATE') }
  }
}
</script>
