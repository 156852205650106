import { Design as Model, Upload, MockupPage, DesignLocation, DesignLocationColorway, DesignLocationSizeway } from '@/models'

export default {
  async _ADD ({ state, dispatch, commit, rootState }) {
    dispatch('setup')

    const data = new Model({})
    data.company = rootState.companies.item._id
    const post = await dispatch('postOne', { data })
    commit('itemsUpdate', {
      list: 'items',
      item: post
    })
    commit('item', post)

    dispatch('cleanup')

    return post
  },

  async _ADD_LOCATION ({ state, dispatch, commit, rootState }) {
    const location = new DesignLocation()
    const sizeways = state.item.sizeways
    const colorways = state.item.colorways

    // initialize sizeways
    sizeways.forEach(sizeway => {
      location.sizeways.push(new DesignLocationSizeway({ sizeway }))
    })
    
    // initialize colorways
    colorways.forEach(colorway => {
      location.colorways.push(new DesignLocationColorway({ colorway }))
    })
    
    state.selected.location = location
    await dispatch('initializeSelectedLocation')

    commit('itemsUpdate', {
      list: 'item.locations',
      item: location
    })
    dispatch('_SAVE')
  },
  
  async _SAVE ({ state, commit, dispatch, rootState }) {
    // update (record with ID should always be created on server first)
    dispatch('updateOne', { data: state.item })
    
    // prepare the new items array
    commit('companies/itemsUpdate', {
      list: 'lists.designs',
      item: state.item
    }, { root: true })
  },

  async _REMOVE ({ state, dispatch, commit, rootState }) {
    dispatch('deleteOne', { _id: state.item._id })
    // prepare the new items array
    commit('itemsRemove', {
      list: 'items',
      item: state.item
    })
  },

  _REMOVE_LOCATION({ state, commit, dispatch }) {
    commit('itemsRemove', {
      list: 'item.locations',
      item: state.selected.location
    })
    // initialize the current location
    if (state.item.locations.length) {
      state.selected.location = state.item.locations[0]
      dispatch('initializeSelectedLocation')
    } else {
      state.selected.location = {}
    }
    dispatch('_SAVE')
  }, 

  async _LOAD ({state, commit, dispatch, rootState}, _id) {
    dispatch('setup')

      // get current design
      const design = await dispatch('getOne', _id )
      const company = await dispatch('companies/getOne', design.company, { root: true } )
      commit('companies/item', company, { root: true })
      design.company = company
      commit('item', design)

      // initialize the current location
      state.selected.sizeway = state.item.sizeways[0]
      state.selected.colorway = state.item.colorways[0]
      if (design.locations.length) {
        state.selected.location = design.locations[0]
        dispatch('initializeSelectedLocation')
      } 

      dispatch('cleanup')
  },
}