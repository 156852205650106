export default (items, search, filter) => {
  // make a mutable copy
  let list = items.map(a => { return a })

  // sort list by name
  list.sort((a, b) => {
    const A = a.name.toUpperCase()
    const B = b.name.toUpperCase()
    return A <= B ? -1 : 1
  })
  return list
}
