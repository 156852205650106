<template lang="pug">
div.text-xs-right
  v-layout(row)
    v-flex.text-xs-right(xs8) amount paid
    v-flex(xs4) {{ costs.amountPaid | currency}}
  v-layout(row)
    v-flex.text-xs-right(xs8) amount due
    v-flex(xs4) {{ amountDue | currency}}
</template>

<script>
export default {
  props: {
    order: { required: true, type: Object },
    costs: { required: true, type: Object },
  },
  computed: {
    amountDue () {
      if (this.order.isStatusFinal) { return this.costs.totalFinal - this.costs.amountPaid }
      else { return this.costs.total - this.costs.amountPaid }
    }
  }
}
</script>
