<template lang="pug">
v-layout(row)
  v-flex(xs6)
    v-btn.white--text(
        color="pink accent-1"
      :disabled="!allowDelete"
      @click="action"
    ) delete product
  v-flex.pl-3
    v-checkbox(
      label="allow delete?"
      v-model="allowDelete"
    )
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  computed: {
    ...mapFields('products', {
      item: 'item',
      actions: 'actions'
    })
  },
  data: () => ({ allowDelete: false }),
  methods: {
    async action() {
      await this.$store.dispatch('products/_REMOVE')
      this.actions = false
      this.$router.go(-1)
    }
  }
}
</script>
