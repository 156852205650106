<template lang="pug">
div
  LineItemHeader
  template(v-for="line in lines")
    LineItem(
      :key="line._id"
      :line="line"
    )
  LineItemFooter
</template>
<script>
import LineItemHeader from './line-item-header'
import LineItemFooter from './line-item-footer'
import LineItem from './line-item'

export default {
  props: {
    lines: { type: Array, required: true },
  },

  components: { LineItem, LineItemHeader, LineItemFooter },
}
</script>
