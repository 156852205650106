<template lang="pug">
div
  v-text-field(
    outline
    color='pink'
    label="name"
    v-model="name"
    @change="save"
  )
  div company:
    span.font-weight-bold {{company.name}}
  div status:
    span.font-weight-bold {{status.name}}
  div owner:
    span.font-weight-bold {{owner.username}}
  div order number:
    span.font-weight-bold {{orderNumber}}

</template>

<script>
import { mapFields } from 'vuex-map-fields'
import moment from 'moment'
import orderStates from '../../helpers/order-states'

export default {
  computed: {
    ...mapFields('orders', {
      order: 'item',
      name: 'item.name',
      owner: 'item.owner',
      status: 'item.status',
      orderNumber: 'item.orderNumber'
    }),
    statusDate () {
      return moment(this.order.statusDate).format('LL')
    },
    orderStates () { return orderStates },
    company () { return this.$store.state.companies.item }
  },
  methods: {
    save () {
      this.$store.dispatch('orders/_SAVE')
      this.$store.dispatch('productionInstances/updateOrderDisplayName', {
        order: this.order,
        orderDisplayName: this.company.name + ' - ' + this.name + ' - #' + this.orderNumber
      })
    }
  }
}
</script>
