/** Model definition file for the DesignLocation Class **/
import uuid from 'uuid/v4'

import {
  // Image,
  Decoration,
  DesignAddOn,
  DesignLocationSizeway,
  DesignLocationColorway
} from '@/models'

export default class DesignLocation {
  constructor ({
    _id = uuid(),
    template = {},
    subtitle = '',
    isDefaultRecord = false,
    sizeways = [],
    colorways = [],
    decorationCategory = null,
    decoration = {},
    addOns = [],
    embhoopplacement = '',
    embpresserfootheight = '1',
  } = {}) {
    this._id = _id
    this.template = template
    this.subtitle = subtitle
    this.isDefaultRecord = isDefaultRecord
    this.sizeways = sizeways.map(sizeway => {
      return new DesignLocationSizeway(sizeway)
    })
    this.colorways = colorways.map(colorway => {
      return new DesignLocationColorway(colorway)
    })
    this.decorationCategory = decorationCategory
    this.decoration = new Decoration(decoration)
    this.addOns = addOns.map(addOn => new DesignAddOn(addOn))
    this.embhoopplacement = embhoopplacement
    this.embpresserfootheight = embpresserfootheight
  }

  get name () {
    return this.template.name
  }
}
