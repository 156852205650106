<template lang="pug">
div.text-xs-right
  div.mb-3
    v-layout(row v-if='order.isStatusFinal')
      v-flex.font-weight-bold.text-xs-right(xs8) order subtotal
      v-flex(xs4) {{costs.subtotalFinal | currency}}

    v-layout(row v-else)
      v-flex.font-weight-bold.text-xs-right(xs8) order subtotal
      v-flex(xs4) {{costs.subtotal | currency}}
    
  v-divider.mb-3
</template>

<script>
export default {
  props: {
    order: { required: true, type: Object },
    costs: { required: true, type: Object },
  }
}
</script>
