import { getField, updateField } from 'vuex-map-fields'
import { menu as usersMenu } from '@/modules/users/'
import { menu as pricingMenu } from '@/modules/pricing/'
import { menu as productsMenu } from '@/modules/products/'
import { menu as companiesMenu } from '@/modules/companies/'
import { menu as designsMenu } from '@/modules/designs/'
import { menu as ordersMenu } from '@/modules/orders/'
import shippingmenu from '@/modules/shipping/menu'
import { menu as purchasingMenu } from './purchasing'
import { menu as schedulingmenu } from '@/modules/scheduling/'
import { menu as dashboardsMenu } from '@/modules/dashboards/'

export default {
  namespaced: true,
  state: {
    menu: [
      { title: 'Home', icon: 'dashboard', route: { path: '/' }, top: true, role: ['User', 'Admin', 'Screens', 'Inks', 'Production', 'Shipping', 'ProductionLead', 'Fangamer', 'Purchasing'] },
      ...productsMenu,
      ...companiesMenu,
      ...designsMenu,
      ...ordersMenu,
      ...shippingmenu,
      ...purchasingMenu,
      ...schedulingmenu,
      ...dashboardsMenu,
      { title: 'Admin', icon: 'weekend', route: { activate: 'admin' }, top: true, role: ['Admin'] },
      ...pricingMenu,
      {
        title: 'order settings',
        icon: 'settings',
        route: { path: '/order-settings' },
        module: 'admin',
        top: false,
        role: ['Admin']
      },
      {
        title: 'design settings',
        icon: 'settings',
        route: { path: '/design-settings' },
        module: 'admin',
        top: false,
        role: ['Admin']
      },
      {
        title: 'product settings',
        icon: 'settings',
        route: { path: '/product-settings' },
        module: 'admin',
        top: false,
        role: ['Admin']
      },
      {
        title: 'job settings',
        icon: 'settings',
        route: { path: '/admin' },
        module: 'admin',
        role: ['Admin']
      },
      ...usersMenu
    ]
  },
  getters: {
    menu (state) { return state.menu },
    getField
  },
  mutations: {
    updateField
  },
  actions: {
  }
}
