<template lang="pug">
div
  template(v-for="(productGroup, i) in productGroups")
    v-layout.pa-3.mt-5(row  :key="i")
      v-flex
        v-layout(row)
          v-flex(pl-3).subheading.pa-2 {{productGroup.product.displayName}}
        LineItems.mb-3.mt-2(
          :lines="removeZeroQuantityLineItems(productGroup.lineItems)"
          :subtotal="productGroup.subtotal"
          :quantity="productGroup.quantity"
          readOnly=true
        )

</template>

<script>
import LineItems from './line-items'

export default {
  components: { LineItems },
  computed: {
    productGroups () {
      return this.$store.getters['productGroups/items']
     },
  },
  methods: {
    removeZeroQuantityLineItems(lineItems) {
      return lineItems.filter(line => {
        return line.quantity !== 0
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.black-border
  border solid black 1px
.thick-left-border
  border-left solid black 4px
</style>
