<template lang="pug">
div
  v-layout(row v-if='approval.approved')
    v-flex()
      v-checkbox(
        color="pink accent-1"
        v-model="approval.approved"
        label="approved?"
        @change="save"
      )
    v-flex() approved by {{approval.by}} on {{ approvalDateDisplay }}
  v-layout(row)
    v-flex url for client approval:
      a(:href="'/client/designs/' + id" target="_blank") /client/designs/{{id}}
  v-layout(row).mt-5
    v-flex
      v-btn.white--text(
        @click="sendMockup"
        color="pink accent-1"
        :disabled="isEmailDisabled"
      ) email mockup to client
      v-btn.white--text(
        v-if='isFangamer'
        @click="sendMockupToSpekkio"
        color="pink accent-1"
        :disabled="isFangamerDisabled"
      ) send mockup to Spekkio

</template>

<script>
import { mapFields } from 'vuex-map-fields'
import moment from 'moment'

export default {
  computed: {
    ...mapFields('designs', {
      item: 'item',
      id: 'item._id',
      approval: 'item.approval',
      approvalDate: 'item.approval.date',
      approvedBy: 'item.approval.by'
    }),
    approvalDateDisplay () {
      return moment(this.approvalDate).format('LLL')
    },
    isFangamer () {
      return this.item.company._id === '5ce8672394e11431a4ab9e24'
    },
    isFangamerDisabled () {
      const id = this.item.clientProduct.id
      const slug = this.item.clientProduct.slug
      return !id || !slug
    },
    isEmailDisabled () {
      const isDesignRecord = this.$route.path.includes('design')
      return this.isFangamer || isDesignRecord
    }
  },
  methods: {
    markApproved () {
      if (this.approval.approved && this.approvedBy) {
        this.approvalDate = Date.now()
      } else {
        this.approvalDate = ''
      }
      this.$store.dispatch('designs/approveMockup')
      this.save()
    },
    save () { this.$store.dispatch('designs/_SAVE') },

    async sendMockup () {
      await this.$store.dispatch('designs/sendMockup')
      this.$router.go(-1)
    },
    sendMockupToSpekkio () {
      this.$store.dispatch('designs/sendMockupToSpekkio')
      this.$router.go(-1)
    }
  }
}
</script>
