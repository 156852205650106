import Vue from 'vue'
import './plugins/vuetify'
import './plugins/google-auth'
import './plugins/globalcomponents'
import './plugins/compositionapi'
import App from './App.vue'
import router from '@/router'
import store from './store/index'
import Vuelidate from 'vuelidate'
import Vue2Filters from 'vue2-filters'
import axios from 'axios'
import VueAxios from 'vue-axios'
import sortFilter from '@/mixins/sort-filter'

Vue.use(Vue2Filters)
Vue.use(Vuelidate)

Vue.config.productionTip = false

Vue.use(VueAxios, axios)
Vue.axios.defaults.baseURL = 'https://fwdwebnuxt.herokuapp.com/fwdio2'
// Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL

// mixins
Vue.mixin(sortFilter)

new Vue({
    router,
    store,
    mixins: [Vue2Filters.mixin],
    render: h => h(App)
}).$mount('#app')
