import commonActions from '@/store/common/actions'
import commonState from '@/store/common/state'
import commonGetters from '@/store/common/getters'
import commonMutations from '@/store/common/mutations'
import init from '@/store/init'
import { InkColor as Model } from '@/models'

export default {
  namespaced: true,
  state: {
    path: 'ink-colors',
    ...commonState(Model)
  },
  getters: {
    ...commonGetters()
  },
  mutations: {
    ...commonMutations()
  },
  actions: {
    ...commonActions(Model),
    ...init,

    async saveWithFile ({ state, dispatch, commit, rootState }, data) {
      dispatch('setup')
      // handle the file first
      if (state.upload.formData) {
        const postFile = await dispatch('postOneFile', {
          data: state.upload.formData
        })
        data.image = postFile
      }
      if (data._id) {
        await dispatch('updateOne', { data })
      } else {
        await dispatch('postOne', { data })
      }
      let newItems = await dispatch('getCollection')
      commit('items', newItems)
      dispatch('cleanup')
    },
    
  }
}
