<template lang="pug">
div
  DeleteUser
</template>

<script>
import DeleteUser from './DeleteUser'
export default {
  components: {
    DeleteUser
  }
}
</script>

<style>

</style>
