<template lang="pug">
div
  CList
  CDetail
  ProductPicker(v-model='detail' @product='_new')
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import ProductPicker from '@/components/product-picker'
import CList from '../components/list'
import CDetail from '../components/detail'

export default {
  components: { CList, CDetail, ProductPicker },
  computed: {
    ...mapFields('productGroups', ['detail']),
    itemsSorted () { return listSort({ items: this.items}) }
  },
  methods: {
    async _new (product) {
      await this.$store.dispatch('productGroups/_NEW', product)
    },
  }
}
</script>
