import sortFilter from '@/mixins/sort-filter'
const sf = sortFilter.methods

export default (payload) => {

  // unpack
  const { items } = payload

  // sort list by name
  return sf.sortByString(items, 'sortString')

}