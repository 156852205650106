<template lang="pug">
div
  FwdDetailPage(v-if="loaded")
    template(slot="title-prefix") product:
    template(slot="title") {{ item.displayName }}
    template(slot="controls")
      Controls
    template(slot="form")
      FwdTabs(
        v-model="tabs"
        :items="tabItems"
      )
  v-dialog(max-width=600 v-model="actions")
    FwdCard
      template(slot="title") actions
      template(slot="content")
        Actions

</template>

<script>
import { mapFields } from 'vuex-map-fields'
import DetailForm from '../../components/form'
import IDescription from '../../components/description'
import ITags from '../../components/tags'
import IHelp from '../../components/Instructions'
import Subproducts from '../../components/subproducts'
import Actions from '../../components/actions'
import Controls from './controls'

export default {
  components: {
    Actions,
    Controls
  },
  computed: {
    ...mapFields('products', {
      item: 'item',
      tabs: 'tabs',
      loaded: 'loaded',
      actions: 'actions'
    })
  },
  data: () => ({
    tabItems: [
      { title: 'detail', component: DetailForm },
      { title: 'description', component: IDescription },
      { title: 'tags', component: ITags },
      { title: 'subproducts', component: Subproducts },
      { title: 'help', component: IHelp }
    ]
  }),
  async created () {
    this.$store.dispatch('products/_LOAD', this.$route.params.id)
    await this.$store.dispatch('productColors/_READ_ALL')
  }
}
</script>
