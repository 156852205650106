/** Model definition file for the Design Class **/
import { Contact, Image, Sizeway, Colorway, DesignLocation, LogItem, Comment, ApprovalBlock } from '@/models'

export default class Design {
  constructor ({
    _id = '',
    name = '',
    company = '',
    serial = null,
    jobs = [],
    image = {},
    locations = [],
    sizeways = [new Sizeway({ name: 'default', isDefaultRecord: true })],
    mockups = [],
    colorways = [new Colorway({ name: 'default', isDefaultRecord: true })],
    notes = {
      art: '',
      screen: '',
      ink: '',
      public: '',
      production: ''
    },
    approval = {},
    admin = {
      enableSizeways: false,
      enableColorways: false
    },
    files = {
      original: [],
      mockups: [],
      separations: [],
      rips: []
    },
    log = [],
    comments = [],
    contact = {}, // prolly deprecated
    contacts = [],
    imageSamples = [],
    clientProduct = {}
  } = {}) {
    this._id = _id
    this.name = name
    this.company = company
    this.serial = serial
    this.jobs = jobs
    this.image = new Image(image)
    this.locations = locations.map(location => {
      return new DesignLocation(location)
    })
    this.sizeways = sizeways
    this.mockups = mockups
    this.colorways = colorways
    this.notes = notes
    this.approval = new ApprovalBlock(approval)
    this.admin = admin
    this.files = files
    this.log = log.map(x => new LogItem(x))
    this.comments = comments.map(x => new Comment(x))
    this.contact = new Contact(contact)
    this.contacts = contacts === null ? [] : contacts.map(c => new Contact(c))
    this.imageSamples = imageSamples.map(image => new Image(image))
    this.clientProduct = Object.assign({}, clientProduct)
  }

  get displayName () {
    return this.company.name + ' - ' + this.name + ' - ' + this.serial
  }

  get subtitleText () {
    let result = ''
    if (this.locations.length === 0) { return result }
    this.locations.forEach((location, index) => {
      if (!location.template.name) { return 0 }
      result += location.template.name + ': '
      result += location.decoration.name
      if ((this.locations.length - 1) > index) {
        result += ' / \n'
      }
    })
    return result
  }
}
