<template lang="pug">
div
  BaseList(
    :items="itemsSorted"
    @select="select"
    @add="add"
  )
    template(slot-scope="{ item }")
      v-layout(row)
        v-flex(xs6) {{item.name}}
    template(slot="title")
      | ink types
  LocalDetail(
    :detail="detail"
    :item="item"
    @save="save"
    @remove="remove"
    @cancel="cancel"
  )
</template>

<script>
import { InkType } from '@/models'
import { mapFields } from 'vuex-map-fields'
import BaseList from '@/components/base/list-simple'
import LocalDetail from './detail'

export default {
  components: { BaseList, LocalDetail },
  computed: {
    ...mapFields('inkTypes', {
      items: 'items',
      item: 'item',
      detail: 'detail'
    }),
    itemsSorted () {
      return this.sortByString(this.items, 'name')
    }
  },
  methods: {
    add () {
      this.item = new InkType()
      this.detail = true
    },
    select (item) {
      this.item = new InkType(item)
      this.detail = true
    },
    async save () {
      await this.$store.dispatch('inkTypes/save', this.item)
      this.cancel()
    },
    async remove () {
      await this.$store.dispatch('inkTypes/remove', this.item._id)
      this.cancel()
    },
    cancel () {
      this.detail = false
    }
  }
}
</script>
