<template lang="pug">
DetailSmall(:detail="detail" v-if="detail")
  template(slot="title") order comment detail.
  template(slot="form")
    v-layout(row)
      v-flex
        v-select(
          label="type"
          v-model="form.type"
          :items="form.types"
        )
    v-layout(row)
      v-flex
        v-text-field(
          label="subject"
          v-model="form.subject"
        )
    v-layout(row)
      v-flex
        v-textarea(
          label="text"
          v-model="form.text"
        )
    v-layout(row)
      v-flex.font-weight-bold.subheading owner: 
        span.font-weight-light {{user.username}}
    
  template(slot="controls")
    DetailControls(
      @save="save"
      @cancel="cancel"
      @remove="remove"
    )
</template>

<script>
import DetailSmall from '@/components/base/detail-small-modal'
import DetailControls from '@/components/base/detail-controls-simple'
import { mapFields } from 'vuex-map-fields'

export default {
  components: { DetailControls, DetailSmall },
  props: ['detail', 'item'],
  data: () => ({
    form: {
    }
  }),
  computed: {
    ...mapFields('orders', {
      items: 'item.comments'
    }),
    user () {
      return this.$store.state.users.item
    }
  },
  watch:{
    item: {
      handler () {
        this.form = this.item
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    save () {
      this.form.time = new Date()
      this.form.owner = this.user
      let items = this.items.map(a => { return a })
      let index = items.findIndex(el => {
        return this.form._id === el._id
      })
      if (index !== -1) {
        items.splice(index, 1, this.form)
      } else {
        items.push(this.form)
      }
      this.items = items
      this.$store.dispatch('orders/_SAVE')
      this.cancel()
    },
    cancel () {
      this.$emit('detail')
    },
    remove (item) {
      let items = this.items.map(a => { return a })
      let index = items.findIndex(el => {
        return this.form._id === el._id
      })
      items.splice(index, 1)
      this.items = items
      this.$store.dispatch('orders/_SAVE')
      this.cancel()
    }
  }
}
</script>
