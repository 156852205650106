import router from '@/router'
import axios from 'axios'

export default {
  async duplicateJob ({ state, commit, dispatch, rootState }) {
    dispatch('setup')
    // work on the current job
    const job = state.item

    // set off the duplication chain
    const newJob = await axios.post('/jobs/duplicate', {
      _id: job._id,
      order: rootState.orders.item
    })

    router.go(-1)
    dispatch('cleanup')
    return newJob
  }

  // async addPoLinesToPool ({ }, { order_id }) {
  //   const result = await axios.post('/jobs/add-po-lines-to-pool/', { order_id })
  //   return result
  // }
}
