<template lang="pug">
div
  p this is the additional point(s) of contact for mockups.  all mocks will also be sent to the contact(s) listed on the order
  v-layout(row)
    v-flex
      v-select(
        label="add a point of contact"
        v-model="contact"
        :items="contactList"
        return-object
        item-text="fullName"
        @change="add"
      )
  v-layout(row)
    v-flex
      h3 art contacts:
      v-list(dense)
        v-list-tile(v-for='(item, i) in contacts' :key='i')
          v-list-tile-content
            v-list-tile-title.subheading {{item.fullName}} - {{ item.email }}]
          v-list-tile-action
            v-btn(@click="removeContact(item)" color="pink accent-1" flat) remove

</template>

<script>
import { Contact } from '@/models'
import { mapFields } from 'vuex-map-fields'

export default {
  data: () => ({ contact: new Contact() }),
  computed: {
    ...mapFields('designs', {
      contacts: 'item.contacts'
    }),
    contactList () {
      const company = this.$store.getters['companies/item']
      return company.contacts
    }
  },
  methods: {
    add (item) {
      this.$store.commit('designs/itemsUpdate', { list: 'item.contacts', item })
      this.contact = new Contact()
      this.$store.dispatch('designs/_SAVE')
    },
    removeContact (item) {
      this.$store.commit('designs/itemsRemove', { list: 'item.contacts', item })
      this.$store.dispatch('designs/_SAVE')
    }
  }
}
</script>
