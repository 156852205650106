<template lang="pug">
v-container.pa-0

  v-layout(row).my-5
    v-flex(xs12)
      h3.display-1.mb-3 line items
      v-layout(
        row
        v-for='pg in pgs'
        :key='pg._id'
      )
        v-flex(xs3)
          v-layout(row pl-5 py-2).body-2.pink.lighten-5
            v-flex.title {{pg.product.code}}
          div.caption.pl-5.mt-4 {{pg.product.brand.name}}
          div.pl-5 {{pg.product.name}}
          div
            v-img(height="250px" contain :src="pg.product.image.url")
        v-flex(xs9)
          LineItems(
            :lines="pg.lineItems"
            :pg='pg'
          )

  v-layout(row align-center).my-5
    v-flex(xs4)
      v-btn(@click='collectCOGTotals') collect COG totals
    v-flex(xs4) COG: {{ cog | currency }}
    v-flex(xs4) true COG: {{ cogTrue | currency }}

    
</template>

<script>
import _ from 'lodash'
import { mapFields } from 'vuex-map-fields'
import LineItems from './line-items/'

export default {
  components: { LineItems },

  data: () => ({
    pgs: []
  }),

  computed: {
    ...mapFields('productGroups', {
      productGroups: 'items',
    }),

    ...mapFields('jobs', {
      jobs: 'items',
    }),

    ...mapFields('orders', {
      order: 'item',
      cog: 'item.costs.cog',
      cogTrue: 'item.costs.cogTrue',
    }),

    lines() {
      const lines = []
      for (const pg of this.productGroups) {
        pg.lineItems.forEach(x => lines.push(x))
      }
      return lines
    }
  },

  methods: {
    collectCOGTotals() {
      let cog = 0
      let internalCog = 0

      for (const pg of this.pgs) {
        pg.lineItems.forEach(line => {
          const markup = (pg.markup * .01) + 1
          const costProductPlusMarkup = line.costProduct * markup
          if (!pg.removeProductCost)
            cog += costProductPlusMarkup * line.quantityFinal
          internalCog += line.costProductTrue * line.quantityFinal
        })
      }

      this.cog = cog
      this.cogTrue = internalCog

      this.$store.dispatch('orders/_SAVE')
    }

  },

  async mounted() {
    const jobIds = this.jobs.map(x => x._id)
    const calls = []
    jobIds.forEach(_id => {
      const call = this.$store.dispatch('productGroups/getCollectionFiltered', { _id, endpoint: 'job' })
      calls.push(call)
    })
    const results = await Promise.all(calls)
    const union = []
    for (const result of results) {
      result.forEach(pg => {
        const job = this.jobs.find(job => job._id === pg.job_id)
        const markup = job.pricing.tier.markup
        const removeProductCost = job.removeProductCost
        if (removeProductCost) pg.removeProductCost = removeProductCost
        pg.markup = markup
        union.push(pg)
      })
    }
    this.pgs = _.sortBy(union, [x => { return x.product._id }])
    return console.log({
      jobs: this.jobs,
      pgs: this.pgs
    })
  }

}
</script>
