<template lang="pug">
v-text-field(
  :label="label"
  v-model="search"
  append-icon="search"
  clearable
  @input="emitSearch"
)
</template>
<script>

export default {
  props: {
    label: {
      type: String,
      required: true
    }
  },
  data: () => ({ search: '' }),
  methods: {
    emitSearch () {
      this.$emit('search', this.search)
    }
  }
}
</script>
