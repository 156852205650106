<template lang="pug">
div
  v-toolbar.white(flat dense)
    v-toolbar-title.font-weight-thin.display-1.pink--text.text-lowercase
      slot(name="title")
    v-spacer
    v-btn.pink.accent-1.ml-5(
      icon
      @click="add"
      dark
      small
    )
      v-icon add
  v-list(subheader dense)
    v-list-tile.list-item(
        v-for='item in items'
        :key='item._id'
        @click="select(item)"
        avatar
      )
      v-list-tile-content
        v-list-tile-title.body-1
          slot(:item="item")
</template>
<script>
export default {
  props: {
    items: { type: Array }
  },
  methods: {
    select (item) { this.$emit('select', item) },
    add () { this.$emit('add') }
  }
}
</script>

<style lang="stylus" scoped>
.black-border
  border 1px solid black
</style>
