<template lang="pug">
v-btn.white--text(
  dark
  color="pink accent-1"
  @click="addLocation"
) add location
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  computed: {
    ...mapFields('designs', {
      actions: 'actions',
    })
  },
  methods: {
    async addLocation() {
      await this.$store.dispatch('designs/_ADD_LOCATION')
      this.actions = false
    }
  }
}
</script>
