<template lang="pug">
v-container
  Document
</template>

<script>
import Document from '../../components/document/index-prod-inkticket'

export default {
  components: {
    Document
  },
  
  async created () {
    this.$store.dispatch('loader/noInit')
    await this.$store.dispatch('designs/_LOAD', this.$route.params.id)
    this.$store.state.designs.item.company = this.$store.state.companies.item
  }
}
</script>
