<template lang="pug">
DetailSmall(:detail="detail")
  template(slot="title") address detail.
  template(slot="form")
    v-layout(row)
      v-flex(xs6)
        v-text-field(
          label="nickname for this address (we need this for display on the list)"
          v-model="name"
        )
      v-flex(xs3 pl-3)
        v-checkbox(v-model="isPrimary" label="primary address?")
      v-flex(xs3 pl-3)
        v-checkbox(v-model="isBilling" label="billing address?")
    v-layout(row)
      v-flex(xs6)
        v-text-field(
          label="company"
          v-model="companyName"
        )
      v-flex(xs6 pl-3)
        v-text-field(
          label="attn (recipient)"
          v-model="attn"
        )
    
    v-flex
      v-text-field(
        label="address"
        v-model="address1"
      )
    v-flex
      v-text-field(
        label="more address (apt, floor, suite, etc)"
        v-model="address2"
      )

    v-layout(row)
      v-flex(xs6)
        v-text-field(
          label="city"
          v-model="city"
        )
      v-flex(xs2 pl-3)
        v-text-field(
          label="state"
          v-model="state"
        )
      v-flex(xs4 pl-3)
        v-text-field(
          label="zip"
          v-model="zip"
        )
    v-flex(xs4)
      v-text-field(
        label="country"
        v-model="country"
      )
    v-flex(xs8)
      v-text-field(
        label="email"
        v-model="email"
      )

  template(slot="controls")
    DetailControls(
      @save="save"
      @cancel="cancel"
      @remove="remove"
    )
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import DetailControls from '@/components/base/detail-controls-simple'
import DetailSmall from '@/components/base/detail-small-modal'

export default {
  props: {
    detail: { type: Boolean, required: true }
  },
  computed: {
    ...mapFields('companies', {
      item: 'address',
      name: 'address.name',
      isPrimary: 'address.isPrimary',
      isBilling: 'address.isBilling',
      companyName: 'address.companyName',
      attn: 'address.attn',
      address1: 'address.address1',
      address2: 'address.address2',
      city: 'address.city',
      state: 'address.state',
      zip: 'address.zip',
      country: 'address.country',
      email: 'address.email',
    })
  },
  methods: {
    save () {
      this.$store.commit('companies/itemsUpdate', { list: 'item.addresses', item: this.item })
      this.$store.dispatch('companies/_SAVE')
      this.cancel()
    },

    remove () {
      this.$store.commit('companies/itemsRemove', { list: 'item.addresses', item: this.item })
      this.$store.dispatch('companies/_SAVE')
      this.cancel()
    },

    cancel () { this.$emit('cancel') }

  },
  components: {
    DetailSmall,
    DetailControls
  }
}
</script>
