import commonActions from '@/store/common/actions'
import commonState from '@/store/common/state'
import commonGetters from '@/store/common/getters'
import commonMutations from '@/store/common/mutations'
import init from '@/store/init'
import crud from './crud'
import subproductHelpers from './subproductHelpers'
import { Product as Model } from '@/models'

export default {
  namespaced: true,
  state: {
    path: 'products',
    ...commonState(Model),
    subproduct: {
      item: {}
    }
  },
  getters: {
    ...commonGetters(),
    subproduct (state) { return state.subproduct },
  },
  mutations: {
    ...commonMutations(),
    subproductItem (state, payload) { state.subproduct.item = payload },
  },
  actions: {
    ...commonActions(Model),
    ...crud,
    ...subproductHelpers,
    ...init,

    async saveThumb ({ state, dispatch, commit, rootState }) {
      dispatch('setup')
      // handle the file first
      if (state.upload.formData) {
        const postFile = await dispatch('postOneFile', {
          data: state.upload.formData
        })
        state.item.image = postFile
      }
      dispatch('_UPDATE')
      dispatch('cleanup')
    }

  }
}
