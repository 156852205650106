export default (items) => {
  // make a mutable copy
  let list = items.map(a => { return a })
  // sort list by name and cost
  list.sort((a, b) => {
    const A = a.name.name
    const B = b.name.name
    if (A > B) return 1
    if (A < B) return -1
    const C = a.cost
    const D = b.cost
    if (C > D) return 1
    if (C < D) return -1
    return 0
  })
  return list
}
