<template lang="pug">
div
  ListCollectionSimple(
    :items="items"
    @select="select"
    @add="add"
  )
    template(slot-scope="{ item }")
      v-layout(row)
        v-flex(xs4) {{item.type.type}}
        v-flex.text-xs-right(xs4) {{ dateDisplay(item.date) }}
        v-flex.text-xs-right(xs4) {{ item.amount | currency }}
  v-layout.pr-3(row)
    v-flex(xs2) 
    v-flex.text-xs-right(xs8)
    v-flex.text-xs-right.font-weight-bold.subheading(xs2) {{ amountPaid | currency }}
  DetailSimple
</template>

<script>
import Transaction from '../../models/Transaction'
import { mapFields } from 'vuex-map-fields'
import ListCollectionSimple from '@/components/base/list-simple'
import DetailSimple from './detail'
import moment from 'moment'

export default {
  components: { ListCollectionSimple, DetailSimple },
  computed: {
    ...mapFields('orders', {
      items: 'item.transactions',
      amountPaid: 'item.costs.amountPaid',
    }),
    ...mapFields('orders/transactions', {
      item: 'item',
      detail: 'detail',
    }),
  },
  methods: {
    add () {
      this.item = new Transaction()
      this.detail = true
    },
    select (item) {
      this.item = new Transaction(item)
      this.detail = true
    },
    updateAmountPaid () {
      let amount = 0
      this.items.forEach(item => {
        const direction = item.isCredit ? 1 : -1
        amount += item.amount * direction
      })
      this.amountPaid = amount
    },
    dateDisplay (date) {
      return date ? moment(date).format('L') : ''
    }
  }
}
</script>
