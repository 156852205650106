/** Model definition file for the Decoration Class **/

export default class Decoration {
  constructor ({
    _id = '',
    name = '',
    description = '',
    quantityPerHour = null,
    decorationCategory = null,
    codes = []
  } = {}) {
    this._id = _id
    this.name = name
    this.description = description
    this.quantityPerHour = quantityPerHour
    this.decorationCategory = decorationCategory
    this.codes = codes
  }
}
