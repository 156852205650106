<template lang="pug">
v-container(fluid).pa-0.mb-3
  SummaryList(:jobs='jobs' :otherCharges='otherCharges' :order='order')

  div.text-xs-right.subheading.pr-3
    Subtotals(:order='order' :costs='costs')
    Adjustments(:order='order' :costs='costs')
    OrderSubtotals(:order='order' :costs='costs')
    SalesTax(:order='order' :costs='costs')
    OrderTotal(:order='order' :costs='costs')
    AmountDue(:order='order' :costs='costs')

</template>

<script>
import SummaryList from './summary-list'
import Subtotals from './subtotals'
import Adjustments from './adjustments'
import OrderSubtotals from './order-subtotals'
import SalesTax from './sales-tax'
import OrderTotal from './order-total'
import AmountDue from './amount-due'

export default {
  computed: {
    order () { return this.$store.getters['orders/item'] },
    otherCharges () { return this.order.otherCharges },
    costs () { return this.order.costs },
    jobs () { return this.$store.getters['jobs/items'] }
  },
  components: { SummaryList, Subtotals, Adjustments, OrderSubtotals, SalesTax, OrderTotal, AmountDue }
}
</script>
