<template lang="pug">
v-container()
  FwdHeadingLarge orders confirmed report
  div(v-for='(item, i) in itemsSorted' :key='i')
    v-layout(row)
      v-flex(xs8)
        span.font-weight-bold {{item.companyName}}
        |  - {{item.name}}
      v-flex(xs4).text-xs-right {{item.orderNumber}}
    div.grey.lighten-3
      v-layout(row v-for='(job, i) in item.jobList' :key='i')
        v-flex(xs12).pl-3 {{job.design.name}}
        v-container.pink.white--text
          v-layout(row v-for='(pi, i) in job.productionInstances' :key='i+500000')
            v-flex(xs6 v-if="pi.location.template").pl-3 {{pi.location.template.name}}
            v-flex(xs6 v-else).pl-3.red--text need name
            v-flex(xs6).pl-1 {{pi.status}}

</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  computed: {
    ...mapFields('orders', {
      items: 'items',
      item: 'item'
    }),
    itemsSorted () {
      // sort list by name
      return this.sortByString(this.items, 'name')
    }

  },
  methods: {
    detail (_id) {
      this.$router.push({ path: '/orders/' + _id })
    }
  },

  async created () {
    await this.$store.dispatch('orders/_READ_CONFIRMED')
  }
}
</script>
