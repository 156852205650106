import { Product as Model, Subproduct } from '@/models'

export default {
  async _ADD ({ state, dispatch, commit }) {
    dispatch('setup')

    // set up a single subproduct
    const subproducts = []
    subproducts.push(new Subproduct())

    // post document
    const data = new Model({ subproducts })
    const newItem = await dispatch('postOne', { data })

    dispatch('cleanup')
    return newItem
  },

  async _LOAD({ state, commit, dispatch, rootState }, _id) {
    dispatch('setup')
    // get current product
    const product = await dispatch('getOne', _id)
    commit('item', product)
    
    // get current subproducts 
    const subproducts = product.subproducts
    if (subproducts.length) {
      commit('subproductItem', subproducts[0])
    } else {
      commit('subproductItem', new Subproduct())
    }

    // cleanup
    dispatch('cleanup')
  },

  async _REMOVE ({ dispatch, commit, rootState, state }) {
    dispatch('setup')

    // set the retired flag
    state.item.meta.retired = true

    // update the record
    const data = state.item
    await dispatch('updateOne', { data })

    // remove the item from local state
    commit('itemsRemove', {
      list: 'items',
      item: state.item
    })

    // cleanup
    state.editState = false
    state.actions = false
    dispatch('cleanup')
  },

  async _UPDATE ({ commit, dispatch, state }) {

    dispatch('updateOne', { data: state.item })
    commit('itemsUpdate', { list: 'items', item: state.item })

  }

}