/** Model definition file for the InkColor Class **/
import { Image } from '@/models'

export default class InkColor {
  constructor ({
    _id = '',
    name = '',
    pms = '',
    image = {}
  } = {}) {
    this._id = _id
    this.name = name
    this.pms = pms
    this.image = new Image(image)
  }

  get displayName () {
    return this.name + ' [pms ' + this.pms + ']'
  }
}
