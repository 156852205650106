<template lang="pug">
v-container
  v-layout(row)
    v-flex(xs6)
      v-layout(row wrap)
        v-flex(v-for='category in categories' :key='category' xs12).mb-2
          v-card(flat).pa-0
            v-card-title.font-weight-bold {{ category }}
            v-card-text
              v-chip(v-for='tag in filterTags(category)' :key='tag._id' @click='registerTag(tag)') {{tag.name}}
    v-flex(xs6 ).ml-3
      DetailCard

</template>

<script>
import DetailCard from '../Card'
import _ from 'lodash'
import { mapFields } from 'vuex-map-fields'

export default {
  components: { DetailCard },
  computed: {
    ...mapFields('products', {
      description: 'item.description',
      descrip: 'item.descrip',
      text: 'item.text',
      image: 'item.image',
      brand: 'item.brand.name',
      code: 'item.code',
      name: 'item.name',
      tags: 'item.tags',
      isEdited: 'item.edits.phase1.isEdited'
    }),
    categories () {
      const tags = this.$store.getters['productTags/items']
      const categories = tags.map(x => x.category)
      return _.uniq(categories)
    },
    productTags () {
      const tagsOriginal = this.$store.getters['productTags/items']
      const tags = _.difference(tagsOriginal, this.tags)
      const result = {}
      for (const cat of this.categories) {
        result[cat] = tags.filter(tag => tag.category === cat)
      }
      return result
    }

  },
  methods: {
    save () { this.$store.dispatch('products/_UPDATE') },
    filterTags (category) {
      for (const cat in this.productTags) {
        if (cat === category) {
          return this.productTags[cat]
        }
      }
    },
    registerTag (tag) {
      this.tags.push(tag)
      this.save()
    }
  }
}
</script>
