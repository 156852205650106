<template lang="pug">
div.body-1
  v-layout(row).display-1.font-weight-bold
    v-flex
      h3.mb-3 job ticket
    v-flex.text-xs-right
      p job # {{job.jobNumber}}
      p # boxes: {{job.numberOfBoxes}}

  div.mb-5
    v-layout(row)
      v-flex().title
        span.font-weight-bold {{company.name}} -
        | {{job.design.name}}
        span(v-if="job.subtitle") - {{job.subtitle}}
        span(v-if='job.removeProductCost').title.pink--text.text--accent-2.font-weight-bold.ml-3 CONTRACT PRINT

  v-layout(row)
    v-flex
      span.font-weight-bold job description:
      | {{job.design.subtitleText}}
  v-layout(row)
    v-flex
      span.font-weight-bold order name:
      | {{order.name}} / order # {{order.orderNumber}}

  v-layout(row mt-3 v-if="order.dates.inHands")
    v-flex()
      span.font-weight-bold in hands date:
      | {{formatDate((order.dates.inHands))}}
  v-layout(row v-if="order.dates.dropDead")
    v-flex()
      span.font-weight-bold drop dead date:
      | {{formatDate(order.dates.dropDead)}}
  v-layout(row v-if="order.owner.username")
    v-flex()
      span.font-weight-bold owner:
      | {{order.owner.username}}
  v-layout(row v-if="order.contact.fullName.trim()")
    v-flex()
      span.font-weight-bold point of contact:
      | {{order.contact.fullName}} - {{order.contact.email}}

</template>

<script>
import moment from 'moment'

export default {
  computed: {
    job () { return this.$store.getters['jobs/item'] },
    order () { return this.$store.getters['orders/item'] },
    company () { return this.$store.getters['companies/item'] }
  },
  methods: {
    formatDate (date) { return moment(date).format('LL') }
  }
}
</script>
