<template lang="pug">
div
  FwdDetailPage(v-if="loaded")
    template(slot="title-prefix") design:
    template(slot="title") {{ item.displayName }}
    template(slot="controls")
      Controls
    template(slot="form")
      FwdTabs(
        v-model="tabs"
        :items="tabItems"
      )
  v-dialog(max-width=600 v-model="actions")
    FwdCard
      template(slot="title") actions
      template(slot="content")
        Actions
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import DetailForm from '../../components/form'
import Mockups from '../../components/mockups'
import Locations from '../../components/locations'
import Notes from '../../components/notes'
import Document from '../../components/document/index-internal'
// import Admin from '../../components/admin'
import Comments from '../../components/comments'
import DesignFiles from '../../components/files'
import ImageSamples from '../../components/images'
import ClientId from '../../components/ClientId'
import Actions from '../../components/actions'
import Controls from './controls'

export default {
  components: {
    Controls,
    Actions
  },
  data: () => ({
    tabItems: [
      { title: 'detail', component: DetailForm },
      { title: 'locations', component: Locations },
      { title: 'mockup pages', component: Mockups },
      { title: 'notes', component: Notes },
      { title: 'mockup', component: Document },
      { title: 'files', component: DesignFiles },
      { title: 'comments', component: Comments },
      { title: 'image samples', component: ImageSamples }

    ]
  }),
  computed: {
    ...mapFields('designs', {
      item: 'item',
      tabs: 'tabs',
      loaded: 'loaded',
      actions: 'actions'
    })
  },
  async created () {
    await this.$store.dispatch('designs/_LOAD', this.$route.params.id)
    if (this.item.company._id === '5ce8672394e11431a4ab9e24') {
      this.tabItems.push({ title: 'client id', component: ClientId })
    }
  }
}
</script>
