import { JobProgress, PiProgress } from '@/models'
import axios from 'axios'

export default {
  async duplicateProductionInstances ({state, commit, dispatch }, { job }) {
    // make copies of existing production instances
    const promises =[]
    for (const pi of state.items) {
      // wipe the PI and assign new job_id and job number
      delete pi._id
      pi.job = job._id
      pi.progress = new PiProgress()
      pi.dashboard.jobNumber = job.jobNumber
      pi.dashboard.jobProgress = new JobProgress()
      promises.push(dispatch('postOne', { data: pi }))
    }
    const newProductionInstances = await Promise.all(promises)

    commit('items', newProductionInstances)

    return newProductionInstances
  },
  
  async updatedecorationcost ({state, commit, dispatch }, { order_id }) {
    axios.post('/production-instances/updatedecorationcost', {
      data: { order_id }
    })
  },
  
  async tempupdatedecorationcosts ({state, commit, dispatch }) {
    axios.post('/production-instances/tempupdatedecorationcosts')
  },

}