<template lang="pug">
v-container()
  FwdHeadingLarge ready to ship
  BaseList(
    :items="itemsSorted"
    @select="select"
    :addToggle="false"
  )
    template(slot-scope="{ item }")
      v-layout(row)
        v-flex(xs10) 
          span.font-weight-bold {{item.companyName}}
          |  - {{item.name}}
        v-flex(xs2) {{item.orderNumber}}
    template(slot="title")
      | 
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import BaseList from '@/components/base/list-simple'

export default {
  components: { BaseList },
  computed: {
    ...mapFields('orders', {
      items: 'items',
      item: 'item',
    }),
    itemsSorted () {
      // filter by search term
      let list = this.filterByProps(this.items, ['displayName'], this.search)
      // filter by selected status
      list = list.filter(item => item.status.name === 'ready to ship')
      // sort list by name
      return this.sortByString(list, 'displayName')
    }

  },
  methods: {
    select (item) { this.$router.push({ path: '/shipments/' + item._id }) },
  },
  
  created () { this.$store.dispatch('orders/_READ_ALL') }
}
</script>
