/** Model definition file for the DesignLocationTemplate Class **/

export default class DesignLocationTemplate {
  constructor ({
    _id = '',
    name = '',
    maxWidth = '',
    maxHeight = '',
    description = ''
  } = {}) {
    this._id = _id
    this.name = name
    this.maxWidth = maxWidth
    this.maxHeight = maxHeight
    this.description = description
  }
}
