<template lang="pug">
v-container
  v-layout(row)
    v-flex(xs6)
        FwdCard
          template(slot="title") cost adjustments
          template(slot="content")
            CostAdjustments
    v-flex(xs6 pl-5)
      FwdCard
        template(slot="title") don't touch please
        template(slot="content")
          p be very cautious about changing these values
          v-select(
            label="status"
            v-model="status"
            :items="orderStates"
            item-text="name"
            return-object
            @change="save"
          )
          v-select(
            label="owner"
            v-model="owner"
            :items="owners"
            item-text="username"
            return-object
            @change="save"
          )
          v-text-field(
            label="orderNumber"
            v-model="orderNumber"
            @change="save"
          )
          v-checkbox(
            v-model="isPricesLocked"
            label="is prices locked?"
            color="pink"
            @change="save"
          )
    
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import moment from 'moment'
import orderStates from '../../helpers/order-states'
import CostAdjustments from './cost-adjustments'

export default {
  components: { CostAdjustments },
  computed: {
    ...mapFields('orders', {
      order: 'item',
      name: 'item.name',
      owner: 'item.owner',
      status: 'item.status',
      orderNumber: 'item.orderNumber',
      isPricesLocked: 'item.statusData.isPricesLocked'
    }),
    orderStates () { return orderStates },
    owners () { return this.$store.state.users.items }
  },
  methods: {
    save () { this.$store.dispatch('orders/_SAVE') },
    update (input) { this.$store.dispatch('orders/_UPDATE', input) }
  }
}
</script>
