<template lang="pug">
div(v-if='selected')
  v-toolbar.transparent(flat dense v-if="toolbar")
    v-toolbar-title.font-weight-black.title.pink--text
      slot(name="title")
    v-spacer
    v-btn.pink.accent-1.ml-5(
      v-if="addToggle"
      icon
      @click="add"
      dark
      small
    )
      v-icon add
  v-list.transparent()
    v-list-tile.list-item(
        v-for='(item, $index) in items'
        :key='$index'
        @click="select(item)"
        :class="{ selected: item._id === selected._id}"
      )
      v-list-tile-content
        v-list-tile-title
          slot(:item="item")
</template>
<script>
export default {
  props: {
    items: { type: Array },
    addToggle: { type: Boolean, default: true },
    toolbar: { type: Boolean, default: true },
    selected: {
      type: Object,
    }
  },
  methods: {
    select (item) { this.$emit('select', item) },
    add () { this.$emit('add') }
  }
}
</script>
<style lang="stylus">
.selected
  background-color #FF4081
  color white

</style>
