<template lang="pug">
div
  DetailSmall(:detail="detail")
    template(slot="title")
      | laser detail
    template(slot="form")
      v-select(
        label="laser number"
        v-model="number"
        :items="lasernumbers"
      )
      v-textarea(
        label="description"
        v-model="description"
      )
    template(slot="controls")
      DetailControls(
        @save="save"
        @cancel="cancel"
        @remove="remove"
      )
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import DetailSmall from '@/components/base/detail-small-modal'
import DetailControls from '@/components/base/detail-controls-simple'

export default {
  components: {
    DetailControls,
    DetailSmall
  },
  props: ['detail'],
  computed: {
    ...mapFields('designs', {
      number: 'selected.laser.number',
      description: 'selected.laser.description'

    }),
    lasernumbers () {
      return [1, 2, 3, 4]
    }
  },
  methods: {
    save () {
      this.$emit('save', this.form)
      this.cancel()
    },
    cancel () { this.$emit('cancel') },
    remove () {
      this.$emit('remove', this.form)
      this.cancel()
    }
  }
}
</script>
