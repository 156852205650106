<template lang="pug">
div
  v-btn(@click="pickProduct = true" dark) pick product
  PGList
  div(v-if="job.productGroups.length")
    LineItems.mt-3(
      :lines="lineItems"
      :subtotal="subtotal"
      :quantity="quantityProductGroup"
      @changeQuantity="changeQuantity"
    )
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import PGList from '@/modules/jobs/components/product-groups/list'
import LineItems from '@/modules/jobs/components/line-items'

export default {
  components: { PGList,  LineItems },
  computed: {
    ...mapFields('jobs',{
      job: 'item',
      productGroup: 'productGroup',
      product: 'productGroup.product',
      lineItems: 'productGroup.lineItems',
      subtotal: 'productGroup.subtotal',
      quantityProductGroup: 'productGroup.quantity',
      quantityJob: 'item.quantity',
      loaded: 'loaded',
      pickProduct: 'input.pickProduct'
    }),
    ...mapFields('orders',{
      order: 'item'
    })
  },
  methods: {
    changeQuantity (payload) {
      let lines = this.lineItems.map(a => { return a })
      let index = lines.findIndex(el => {
        return payload.line._id === el._id
      })
      if (index !== -1) {
        lines.splice(index, 1, payload.line)
      } 
      this.lineItems = lines
      this.$store.dispatch('jobs/changeQuantity', payload.changeAmount)
    }
  }
}
</script>
