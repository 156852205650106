/** Model definition file for the File Class **/
import { Meta } from '@/models'
import uuid from 'uuid/v4'

export default class File {
  constructor ({
    _id = uuid(),
    name = '',
    fileMeta = {},
    url = '',
    meta = {},
    notes = ''
  } = {}) {
    this._id = _id
    this.name = name
    this.fileMeta = fileMeta
    this.url = url
    this.meta = new Meta(meta)
    this.notes = notes
  }

  get dateCreated() { return this.meta.dateCreated }
}
