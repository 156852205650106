/** Model definition file for the Size Class **/

export default class Size {
  constructor ({
    _id = '',
    name = '',
    sortOrder = 99,
    category = {}
  } = {}) {
    this._id = _id
    this.name = name
    this.sortOrder = sortOrder
    this.category = category
  }

  get catname () {
    return this.category.name
  }
}
