<template lang="pug">
div
  v-card.xs12.mb-3(
    flat
    width="100%"
    mb-3
    v-for="(location, locationKey) in  locations"
    v-if="location.template.name && location.decoration.name"
    :key="locationKey"
  )
    v-card-title.font-weight-black.display-3
      h5 {{location.template.name}}
    v-card-text.headline
      v-flex(xs12) decoration: {{location.decoration.name}}
      v-flex(xs12 mb-2)
        span.red--text(
          v-if="!sizeway(location).dimensions.width || !sizeway(location).dimensions.height") need dimensions!
        span(v-else="") dimensions: {{sizeway(location).dimensions.width}}" wide x {{sizeway(location).dimensions.height}}" tall

      v-flex(xs12 mb-2) placement:
        span.red--text(v-if="!sizeway(location).placements.length") need at least one placement!
      v-flex.subheading(xs12 v-for="(placement, placementIndex) in sizeway(location).placements" :key="placementIndex") {{placement.name}} - {{placement.details}}

      v-flex.headline(xs12 mb-2) print colors:
        ColorList(:location='location')

</template>

<script>
import ColorList from '../color-list'

export default {
  computed: {
    design () { return this.$store.getters['designs/item'] },
    locations () { return this.design.locations },
    sizeways () { return this.design.sizeways },
    colorways () { return this.design.colorways }
  },
  methods: {
    sizeway (location) { return location.sizeways[0] },
    colorway (location) { return location.colorways[0] }
  },
  components: { ColorList }
}
</script>
