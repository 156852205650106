/** Model definition file for the DesignScreen Class **/
import uuid from 'uuid/v4'
import { InkColor } from '@/models'

export default class DesignScreen {
  constructor ({
    _id = uuid(),
    inkColor = {},
    inkType = {},
    printOrder = '',
    mesh = '',
    
  } = {}) {
    this._id = _id
    this.inkColor = new InkColor(inkColor)
    this.inkType = inkType
    this.printOrder = printOrder
    this.mesh = mesh
  }
  get displayName () {
    return this.printOrder + ' - ' +
      this.inkColor.displayName + ' - ' +
      this.inkType.name + ' / mesh: ' + this.mesh
  }
}
