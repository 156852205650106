<template lang="pug">
div
  v-toolbar.transparent(flat dense)
    v-toolbar-title.font-weight-thin.display-1.pink--text.text-lowercase
      slot(name="title")
    v-spacer
    v-btn.black.ml-5(icon @click="add" dark small)
      v-icon add
    FilterList.mt-3(slot="extension" @filter="filter")
  v-list.mt-3.transparent(subheader)
    draggable(
      @end="checkMove"
      v-model="list"
    )
      v-list-tile.list-item.pl-2(
        v-for='item in items'
        :key='item._id'
      )
        v-list-tile-content
          v-list-tile-title.text-uppercase
            slot(:item="item")
        v-list-tile-action
          v-icon(@click="select(item)") edit
</template>

<script>
import draggable from 'vuedraggable'
import FilterList from './filter'

export default {
  components: { draggable, FilterList },
  props: ['items'],
  data: () => ({ list: [] }),
  watch: {
    items: {
      handler () {
        this.list = this.items
      },
      immediate: true
    },

  },
  methods: {
    filter (item) { this.$emit('filter', item) },
    select (item) { this.$emit('select', item) },
    add () { this.$emit('add') },
    async checkMove (evt) {
      // set indices
      this.list.forEach((item, index) => {
        item.sortOrder = index
      })
      // initiate bulk update
      await this.$store.dispatch('sizes/updateMany', { data: this.list })
    }
  }
}
</script>
