<template lang="pug">
v-card.pa-0.transparent.text-xs-center(flat)
  template(v-if="!readOnly")
    v-card-text.text-xs-center.px-0
      input.css-input.pink.lighten-5.text-xs-center(
        v-model.number="quantity"
        @change="changeQuantity"
        @focus="$event.target.select()"
        tabindex=1
      )
  template(v-else)
    v-card-text.text-xs-center.px-0 {{line.quantity}}
</template>
<script>
export default {
  props: ['line', 'readOnly'],
  data: () => ({ quantity: null, quantityOld: null }),
  methods: {
    changeQuantity () {
      if (isNaN(this.quantity) || this.quantity === null) { this.quantity = 0 }
      
      const changeAmount = this.quantity - this.quantityOld
      const line = { ...this.line }
      const _id = this.line._id

      line.quantity = this.quantity
      this.$emit('changeQuantity', { changeAmount, _id, line })
      this.quantityOld = this.quantity
    }
  },
  
  watch: {
    line: {
      handler() {
        this.quantity = this.line.quantity
        this.quantityOld = this.line.quantity
      },
      immediate: true,
      deep: true
    }
  }
  
}
</script>
<style lang="stylus" scoped>
.css-input
  border-color #cccccc
  padding 5px
  border-width 0px
  border-style hidden
  text-align center
  width 75%
.css-input focus
  outline none
</style>
