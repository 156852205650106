<template lang="pug">
div
  ListSelected(
    :items="items"
    :toolbar="false"
    :selected="item"
    @select="select"
  )
    template(slot-scope="{ item }")
      v-layout.body-1.pa-0(row) 
        v-flex(xs8) {{ item.jobNumber }} - {{ item.design.name }} 
        v-flex(xs4).font-weight-bold {{ item.progress.isProductsReceived ? 'received!' : '' }} 
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import ListSelected from '@/components/base/list-selected-single-line'

export default {
  components: { ListSelected },
  computed: {
    ...mapFields('jobs', {
      items: 'items',
      item: 'item',
    }),
    itemsSorted () {
      return this.sortByPropNumber(this.items, 'jobNumber')
    }
  },
  methods: {
    select (item) {
      this.item = item
    }
  }
}
</script>
