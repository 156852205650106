/** Model definition file for the Colorway Class **/
import uuid from 'uuid/v4'

export default class Colorway {
  constructor ({
    _id = uuid(),
    name = '',
    isDefaultRecord = false
  } = {}) {
    this._id = _id
    this.name = name
    this.isDefaultRecord = isDefaultRecord
  }
}
