import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/src/stylus/app.styl'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.use(Vuetify, {
  iconfont: 'md',
  theme: {
    primary: '#4DD0E1',
    secondary: '#E0F7FA',
    accent: '#18FFFF',
    error: '#FF4081',
    warning: '#E040FB',
    info: '#00BCD4'
  }
})
