import { Promise } from 'q'
import { getField, updateField } from 'vuex-map-fields'

export default {
  namespaced: true,
  state: {
    needHelp: false,
  },
  getters: { getField },
  mutations: {
    updateField,
    needHelp (state, payload) { state.needHelp = !state.needHelp }
  },
  actions: {
    
  }
}