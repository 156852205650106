<template lang="pug">
div
  v-layout(row)
    v-flex
      v-checkbox(
        v-model="termsAndConditions"
        label="I agree to the Forward Printing order terms and conditions (required)"
      )
  v-layout(row)
    v-flex
      v-checkbox(
        v-model="approved"
        label="approved?"
        @change="markApproved"
        :disabled="!termsAndConditions || !approvalBy"
      )
    v-flex
      v-text-field(
        v-model="approvalBy"
        label="approved by (required)"
      )
  v-layout(row)
    v-flex.body-1(v-if="approvalDate") on {{approvalDateDisplay}}
  v-layout.caption(row)
    v-flex
      v-expansion-panel
        v-expansion-panel-content
          template(v-slot:header)
            div view terms and conditions
          div.pa-2
            TermsText
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import moment from 'moment'
import TermsText from './terms'

export default {
  components: { TermsText },
  computed: {
    ...mapFields('orders', {
      approval: 'item.approval',
      approved: 'item.approval.approved',
      approvalDate: 'item.approval.date',
      approvalBy: 'item.approval.by',
      termsAndConditions: 'item.approval.termsAndConditions'
    }),
    approvalDateDisplay () {
      return moment(this.approvalDate).format('LLL')
    }
  },
  methods: {
    markApproved () {
      if (this.approved) {
        this.approvalDate = new Date()
      } else {
        this.approvalDate = ''
      }
      this.$store.dispatch('orders/approveOrder')
      this.$router.push({ path: '/client/orders/approval-success' })
    }
  }
}
</script>
