<template lang="pug">
div
  FwdDetailPage()
    template(slot="title-prefix") pool item: 
    template(slot="title") {{ item.productName }} - size: {{ item.lineItem.size.name }}
    template(slot="controls")
      Controls
    template(slot="form")
      FwdTabs(
        v-model="tabs"
        :items="tabItems"
      )
  v-dialog(max-width=600 v-model="actions")
    FwdCard
      template(slot="title") actions
      template(slot="content")
        Actions
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import DetailForm from './form'
import Actions from '../../components/actions'
import Controls from './controls'

export default {
  components: {
    Controls,
    Actions,
  },
  data: () => ({
    tabItems: [
      { title: 'details', component: DetailForm },
    ]
  }),
  computed: {
    ...mapFields('poPoolLines', {
      item: 'item',
      tabs: 'tabs',
      actions: 'actions'
    })
  },
  async created () {
    await this.$store.dispatch('poPoolLines/_READ', this.$route.params.id)
    await this.$store.dispatch('products/_LOAD', this.item.product_id)
  }
}
</script>
