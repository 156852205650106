/** Model definition file for the ProductionInstance Class **/
import { PiProgress, Comment, Press, ApprovalBlock } from '@/models'
import PIDashboard from './PIDashboard'
import moment from 'moment'

const $preripchecklist = {
  issepuploaded: false,
  issepnamed: false,
  issepcolorsnamed: false,
  isripcolors: false,
  isripcolorsmatchmockup: false,
  isripregistrationmarks: false,
  isripcorrectsize: false,
  isrelabeldetails: false,
  isnecessarysizes: false,
}

export default class ProductionInstance {
  constructor ({
    _id = '',
    order = '',
    job = '',
    design = '',
    location = {},
    progress = {},
    dates = {
      dropDead: '',
      dropDeadOverride: '',
      schedule: ''
    },
    minutes = {
      default: 0,
      override: 0
    },
    ripFile = {},
    preripchecklist = $preripchecklist,
    type = '',
    press = {},
    notes = '',
    status = '',
    priority = 1,
    dashboard = {},
    approval = {},
    comments = [],
    team = { _id: '', name: '', _sort: 0 },
    task = {
      isTask: false,
      assignment: '',
      assignee: '',
      isComplete: false
    },
    schedType = '',
    _tempDate = '',
    production = {
      progress: 1
    },
    meta = {},
    decorationcost = 0
  } = {}) {
    this._id = _id
    this.order = order
    this.job = job
    this.design = design
    this.location = location
    this.progress = new PiProgress(progress)
    this.dates = dates
    this.minutes = minutes
    this.ripFile = ripFile
    this.preripchecklist = Object.assign($preripchecklist, preripchecklist)
    this.type = type
    this.press = new Press(press)
    this.notes = notes
    this.status = status
    this.priority = priority
    this.dashboard = new PIDashboard(dashboard)
    this.approval = new ApprovalBlock(approval)
    this.comments = comments.map(x => new Comment(x))
    this.team = team
    this.task = task
    if (!this.task.isComplete) { this.task.isComplete = false }
    // temporary props
    this.schedType = schedType
    this._tempDate = _tempDate
    this.production = production
    this.meta = meta
    this.decorationcost = decorationcost
  }

  get types () {
    return ['screen-print', 'screen-prep', 'screen-teardown']
  }

  get statuses () {
    return ['completed', 'wip', 'hold', 'nip']
  }

  get image () {
    return this.design.image
  }
  get dateProduction () {
    return this.dates.schedule
  }

  get _sortSchedule () {
    return schedule(this)
  }
}

const schedule = (ctx) => {
  let sort = ''

  // ########### HANDLE DATE
  const _date = ctx.dateProduction

  // unscheduled or scheduled
  sort += !_date ? '2000' : '1000'

  // by production date
  if (_date) {
    const instance = new Date(_date)
    sort += moment(instance).unix().toString().padStart(15, '0')
  } else {
    sort += '000000000000000'
  }

  // by team value
  const teamsort = ctx.team._sort
  if (teamsort) {
    sort += teamsort.toString().padStart(4, '0')
  } else {
    sort += '0000'
  }

  // by isTask
  const isTask = ctx.task.isTask
  if (isTask) {
    sort += '0001'
  } else {
    sort += '0000'
  }

  // by production priority
  const productionPriority = ctx.dashboard.priority.production
  if (productionPriority) {
    sort += productionPriority.toString().padStart(4, '0')
  } else {
    sort += '0000'
  }
  return sort
}
