<template lang="pug">
div
  LineItemHeader
  LineItem(
    v-if='line.quantity > 0'
    v-for="line in lines"
    :key="line._id"
    :line="line"
    :pg='pg'
  )
  //- LineItemFooter
</template>
<script>
import LineItemHeader from './line-item-header'
import LineItemFooter from './line-item-footer'
import LineItem from './line-item'

export default {
  props: {
    lines: { type: Array, required: true },
    pg: { type: Object, required: true },
  },

  components: { LineItem, LineItemHeader, LineItemFooter },
}
</script>
