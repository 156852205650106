<template lang="pug">
div
  v-select(
    label="press"
    v-model="press"
    :items="presses"
    return-object
    item-text="name"
    @change="save"
  )
  v-select(
    label="type"
    v-model="type"
    :items="types"
    item-text="name"
    return-object
    @change="save"
  )
  v-text-field(
    label="minutes: default"
    v-model="minutesDefault"
    readonly
  )
  v-text-field(
    label="minutes override"
    v-model="minutesOverride"
    @change="save"
    outlined
  )
  v-text-field(
    label="location id"
    v-model="locationid"
    @change="save"
    outlined
  )
  v-text-field(
    label="name"
    v-model="designname"
    @change="save"
    outlined
  )
  v-text-field(
    label="image"
    v-model="image"
    @change="save"
    outlined
  )
  v-text-field(
    label="decoration cost"
    v-model="decorationcost"
    @change="save"
    outlined
  )
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  computed: {
    ...mapFields('productionInstances', {
      press: 'item.press',
      type: 'item.type',
      minutesDefault: 'item.minutes.default',
      minutesOverride: 'item.minutes.override',
      locationid: 'item.location._id',
      designname: 'item.dashboard.designName',
      image: 'item.dashboard.image.url',
      decorationcost: 'item.decorationcost'
    }),

    presses () { return this.$store.getters['presses/items'] },
    types () { return this.$store.getters['productionInstanceTypes/items'] }

  },
  methods: {
    save () { this.$store.dispatch('productionInstances/_UPDATE') }
  }

}
</script>
