<template lang="pug">
div
  v-btn.white--text(
    dark
    color="pink accent-1"
    @click="changeMockupPageOrder"
  ) change mockup page order

  DetailSimple(
    :detail="detail"
    @cancel="cancel"
  )
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { MockupPage } from '@/models'
import DetailSimple from './detail'

export default {
  data: () => ({
    detail: false
  }),
  computed: {
    ...mapFields('designs', {
      actions: 'actions',
    })
  },
  methods: {
    changeMockupPageOrder () {
      this.detail = true
    },

    cancel () {
      this.detail = false
      this.actions = false
    }

  },
  components: { DetailSimple }
}
</script>
