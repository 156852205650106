import axios from 'axios'

export default {
  async notifymissingprintcheck ({ commit, dispatch, state, rootState }) {
    dispatch('setup')

    // get next job number
    const design = rootState.designs.item
    console.log(design.clientProduct)

    const spekkio = await axios.post('/jobs/spekkio/notifymissingprintcheck', {
      product: design.clientProduct
    })
    
    dispatch('cleanup')
    return spekkio
  },


}
