<template lang='pug'>
v-container().grey--text
  FwdHeadingLarge orders
  v-text-field(
    label='search'
    v-model='search'
  )
  div(v-for='(item, i) in itemssorted' :key='i' ).py-2.line-item
    v-layout(row)
      v-flex(xs6 @click='select(item)')
        span.font-weight-bold {{item.companyName}}
        |  - {{item.name}}
      v-flex(xs3) {{formatdate(item.statusData.quoteDate)}}
      v-flex(xs2) {{item.status.name}}
      v-flex(xs1)
        v-btn(@click='retire(item)' icon).red
          v-icon.white--text mdi-delete

</template>

<script>
import { mapFields } from 'vuex-map-fields'
import axios from 'axios'
import moment from 'moment'

export default {
  computed: {
    ...mapFields('orders', {
      items: 'items',
      order: 'item',
      search: 'search'
    }),
    itemssorted () {
      return this.items.filter(item => {
        const searchterm = item.name + item.companyName + item.orderNumber
        // console.log(searchterm)
        return searchterm.toUpperCase().includes(this.search.toUpperCase())
      })
    }
  },
  methods: {
    async add () {
      const add = await this.$store.dispatch('orders/_ADD')
      this.detail(add._id)
    },
    select (item) {
      this.detail(item._id)
    },
    retire (item) {
      this.order = item
      this.order.meta.retired = true
      this.$store.dispatch('orders/_SAVE')
    },
    detail (_id) {
      this.$router.push({ path: '/orders/' + _id })
    },
    setSearch (search) {
      this.search = search
    },
    formatdate(date) { return moment(date).format('LL')}
  },

  async created () {
    const read = await axios.post('/orders/read', {
      filter: {
        'meta.retired': { $ne: true },
        $or: [
          { 'status.name': 'estimate' },
          { 'status.name': 'quote' }
        ]
      }
    })
    this.items = read.data
  }
}
</script>
<style lang="stylus" scoped>
.line-item
  border-bottom 1px solid #4DD0E1

div.line-item:hover
  background-color #E0F7FA
  color black
  cursor pointer

div.line-item:active
  background-color #006064
  color white
  cursor progress
</style>
