<template lang="pug">
  div
    file-pond(
      name="test"
      ref="pond"
      class-name="my-pond"
      label-idle="drop files here or click to browse..."
      allow-multiple="true"
      accepted-file-types="image/jpeg, image/png"
      @init="handleFilePondInit"
      @addfile="addFile"
    )
    v-container(grid-list-lg)
      v-layout(row)
        v-flex(xs6 pa-3 v-for='file in imageSamples' :key='file.url')
          v-card()
            v-img(:src="file.url")
            v-card-actions
              v-btn(@click='remove(file)') remove
</template>

<script>
// Import FilePond
import vueFilePond from 'vue-filepond';

// Import plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js';

// Import styles
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';

// Create FilePond component
const FilePond = vueFilePond( FilePondPluginFileValidateType, FilePondPluginImagePreview );



export default {
  data: () => ({ myFiles: [] }),
  computed: {
    design () { return this.$store.getters['designs/item']},
    imageSamples () { return this.design.imageSamples }
  },
  methods: {
    handleFilePondInit () {
      // console.log('FilePond has initialized')
    },
    async addFile(error, file) { this.$store.dispatch('designs/addImageSample', file) },
    remove (item) {
      this.$store.commit('designs/itemsRemove', { list: 'item.imageSamples', item })
      this.$store.dispatch('designs/_SAVE')
      this.myFiles = this.imageSamples
    }
  },
  watch: {
    imageSamples () { this.myFiles = this.imageSamples }
  },
  components: { FilePond }
}
</script>