<template lang="pug">
v-container(fluid)
  BaseListSearchOnly(
    :items="itemsSorted"
    searchLabel="search orders"
    @search="setSearch"
    @select="select"
    @add="add"
  )
    template(slot-scope="{ item }")
      v-layout(row)
        v-flex(xs6) {{item.name}}
        v-flex(xs3) {{item.orderNumber}}
        v-flex(xs3) {{item.status.name}}
    template(slot="title")
      | 
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import BaseListSearchOnly from '@/components/base/list-search-only'

export default {
  components: { BaseListSearchOnly },
  computed: {
    ...mapFields('companies', {
      items: 'lists.orders',
      search: 'lists.searchOrders'
    }),
    itemsSorted () {
      // filter by search term
      let list = this.filterByProps(this.items, ['displayName'], this.search)
      // sort list by name
      return this.sortByString(list, 'displayName')
    }
  },
  methods: {
    async add () {
      const add = await this.$store.dispatch('orders/_ADD')
      this.detail(add._id)
    },
    select (item) {
      this.detail(item._id)
    },
    detail (_id) {
      this.$router.push({ path: '/orders/' + _id })
    },
    setSearch (search) {
      this.search = search
    },
  },
}
</script>
