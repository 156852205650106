<template lang="pug">
div
  span.pink--text(v-if="!colorway.screens.length") need at least one print color!
  v-list(subheader dense v-if='items.length')
    v-list-tile.list-item(
      v-for='(item, i) in items'
      :key='i'
      avatar
    )

      v-list-tile-avatar(
        v-if="item.inkColor.image.url"
        size=30
      )
        v-img.black-border(:src="item.inkColor.image.url")

      v-list-tile-content
        v-list-tile-title.body-1 {{ lineFormat(item) }}
</template>

<script>
export default {
  props: {
    location: {
      type: Object,
      required: true
    }
  },
  computed: {
    colorway () { return this.location.colorways[0] },
    items () { return this.colorway.screens }
  },
  methods: {
    lineFormat (item) {
      let result = ''
      result += item.printOrder
      result += item.printOrder ? ' - ' : ''
      result += item.inkColor.name
      result += item.inkColor.pms ? ' [pms ' : ''
      result += item.inkColor.pms
      result += item.inkColor.pms ? ']' : ''
      if (item.inkType) {
        result += item.inkType.name ? ' - ' : ''
        result += item.inkType.name
      }
      result += item.mesh ? ' / mesh: ' + item.mesh : ''
      return result
    }
  }
}
</script>

<style lang="stylus" scoped>
.black-border
  border 1px solid black 
</style>