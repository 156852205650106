<template lang="pug">
v-btn(@click='downloadpdf') download pdf

</template>

<script>
import pdf from '../../../helpers/pdf'

export default {
  computed: {
    order () { return this.$store.getters['orders/item'] },
    company () { return this.$store.getters['companies/item'] },
    jobs () { return this.$store.getters['jobs/items'] },
    otherCharges () { return this.order.otherCharges },
    payload () {
      return {
        order: this.order,
        company: this.company,
        jobs: this.jobs,
        otherCharges: this.otherCharges,

      }
    }
  },
  methods: {
    downloadpdf () {
     pdf.download(this.payload)
    },




  }
}
</script>
