import { Order as Model, Job, Design, ProductionInstance } from '@/models'
// import orderStates from '../helpers/order-states'
import axios from 'axios'

export default {

  async reportfangamergarments ({ dispatch, commit, state, rootState }) {
    dispatch('setup')
    const items = await axios.get('/orders/report/fangamergarments')
    console.log(items)

    commit('items', items.data)

    dispatch('cleanup')
  },

}
