<template lang="pug">
v-layout(row)
  v-flex
    v-btn(
      :disabled="!allowDelete"
      @click="deleteUser"
    ) delete user
  v-flex
    v-checkbox(
      label="allow delete?"
      v-model="allowDelete"
    )
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  computed: {
    ...mapFields('users', {
      item: 'item',
      actions: 'actions',
      // controls
      editState: 'editState'
    })
  },
  data: () => ({ allowDelete: false }),
  methods: {
    async deleteUser() {
      await this.$store.dispatch('users/_REMOVE')
      this.editState = false
      this.actions = false
      this.$router.go(-1)
    }
  }
}
</script>

<style>

</style>
