<template lang="pug">
v-container
  FwdHeadingLarge audit products
  BaseListExtended(
    :items="itemsSorted"
    searchLabel="search products"
    :addToggle="false"
    @search="setSearch"
    @select="select"
    :avatar="true"
  )
    template(slot-scope="{ item }")
      v-layout(row)
        v-flex(xs9) {{item.displayName}}
        v-flex(xs3) {{item.brand.name}}
    template(slot="title")
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import BaseListExtended from '@/components/base/list-search-only'
import { listSort } from '../../../helpers/'

export default {
  components: { BaseListExtended },
  computed: {
    ...mapFields('products', {
      items: 'items',
      search: 'search'
    }),
    itemsSorted () {
      const sort = listSort({
        items: this.items,
        search: this.search
      })
      const filter = sort.filter(x => {
        if (x.edits.phase1.isEdited) {
          console.log('edited')
        }
        return !x.edits.phase1.isEdited && !x.edits.phase1.flagForReview
      })
      return filter
    }
  },
  methods: {
    select (item) { this.detail(item._id) },
    detail (_id) { this.$router.push({ path: '/products/audit/' + _id }) },
    setSearch (search) { this.search = search }
  }
}
</script>
