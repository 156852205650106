<template lang="pug">
div
  v-container(fluid pa-0)
    v-layout(row px-3)
      v-flex.display-3.font-weight-bold.mb-3 {{order.status.name}}
      v-flex.text-xs-right.title.font-weight-bold order # {{order.orderNumber}}
  DocHeading {{company.name}} - {{order.name}}
  DocDetails

</template>

<script>
import DocHeading from '../_heading'
import DocDetails from './details'

export default {
  computed: {
    order () { return this.$store.getters['orders/item'] },
    company () { return this.$store.getters['companies/item'] }
  },
  components: { DocHeading, DocDetails }
}
</script>
