<template lang="pug">
div(:class="{ readonly: line.readonly }") 
  v-layout(row px-4 pt-2 v-if='line.productName').black-top
    v-flex.subheading.pink--text {{ line.productName }}


  v-layout.line-item.body-1(row px-4 align-center)
    v-flex(xs2)
      div.px-0
        v-avatar(size=28 tile)
          img(:src="line.lineItem.color.image.url")
    
    v-flex(xs3)
      div.px-0 {{line.lineItem.color.name}}
    
    v-flex(xs1).text-xs-center
      div.px-0.text-uppercase {{line.lineItem.size.name}}

    v-flex(xs6)
      v-layout(row align-center)
        v-flex.pl-3(xs4)
        v-flex.pl-3(xs4)
          div.text-xs-center.pa-0.transparent(flat)
            span(:class="{ 'green--text font-weight-bold text--accent-3': line.lineItem.quantityReceived >= line.lineItem.quantityOrdered }").px-0 {{line.lineItem.quantityOrdered}}
       

        v-flex.pl-3(xs4 v-if="line.readonly")
          div.text-xs-center.pa-0.transparent(flat)
            span.px-0 {{line.lineItem.quantityReceived}}
        v-flex.pl-3(xs4 v-else)
          div.text-xs-center.pa-0.transparent(flat)
            input.css-input.pink.lighten-5(
              v-model.number="quantityReceived"
              @change="change"
              @focus="$event.target.select()"
              tabindex=1
            )
    
</template>
<script> 
export default {
  props: ['line'],
  data: () => ({ quantityReceived: 0 }),
  methods: {
    change () {
      const item = this.line
      item.lineItem.quantityReceived = this.quantityReceived
      this.$store.dispatch('purchaseOrders/lineItemReceived', item)
    }

  },
  watch: {
    line: {
      handler () { 
        this.quantityReceived = this.line.lineItem.quantityReceived 
      },
      immediate: true
    }
  }
}
</script>
<style lang="stylus" scoped>
.line-item
  border-bottom solid #FCE4EC 1px
.css-input
  border-color #cccccc
  padding 10px
  border-width 0px
  border-style hidden
  text-align center
  width 50%
.css-input focus
  outline none
.readonly 
  background-color #E0E0E0

.black-top
  border-top 2px solid black
</style>
