<template lang="pug">
v-container
  v-layout(row align-center justify-center)
    v-flex.text-center.display-4
      h2 SUCCESS
      p.title 
      v-img(:src="require('@/assets/success/the-game.png')")
</template>

<script>

export default {
}
</script>
<template lang="pug">
FwdSuccess(
  title="SUCCESS"
  subtitle="your approval has been noted.  next, we'll get this job released to production."
  image="the-game.png"
)
</template>

<script>
import FwdSuccess from '@/components/base/FwdSuccess'
export default {
  components: { FwdSuccess }
}
</script>