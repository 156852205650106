<template lang="pug">
div
  BaseList(
    :items="itemsSorted"
    @select="select"
    @add="add"
  )
    template(slot-scope="{ item }") {{item.name}}
    template(slot="title") design location templates

  LocalDetail
</template>

<script>
import { DesignLocationTemplate } from '@/models'
import { mapFields } from 'vuex-map-fields'
import BaseList from '@/components/base/list-simple'
import LocalDetail from './detail'

export default {
  components: { BaseList, LocalDetail },
  computed: {
    ...mapFields('designLocationTemplates', {
      items: 'items',
      item: 'item',
      detail: 'detail'
    }),
    itemsSorted () {
      return this.sortByString(this.items, 'name')
    }
  },
  methods: {
    add () {
      this.item = new DesignLocationTemplate()
      this.detail = true
    },

    select (item) {
      this.item = new DesignLocationTemplate(item)
      this.detail = true
    }
   
  }
}
</script>
