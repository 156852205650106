import { menu as purchaseOrdersMenu } from '@/modules/purchase-orders/'

export const menu = [
  { title: 'Purchasing', icon: 'add_shopping_cart', route: { activate: 'purchasing' }, top: true, role: ['Admin', 'User', 'Purchasing', 'Production', 'ProductionLead', 'Shipping'] },
  ...purchaseOrdersMenu,
  {
    title: 'purchasing settings',
    icon: 'settings',
    route: { path: '/purchasing/settings' },
    module: 'admin',
    role: ['Admin']
  }
]