<template lang="pug">
div
  FwdDetailPage(v-if="loaded")
    template(slot="title-prefix") job: 
    template(slot="title") {{job.displayName}}
    template(slot="subtitle")
      span.grey--text.caption order: {{order.displayName}}
    template(slot="controls")
      Controls
    template(slot="form")
      FwdTabs(
        v-model="tabs"
        :items="tabItems"
      )
  v-dialog(max-width=600 v-model="actions")
    FwdCard
      template(slot="title") actions
      template(slot="content")
        Actions
</template>

<script>
import { Job } from '@/models'
import {mapFields} from 'vuex-map-fields'
import DetailForm from '../../components/form'
import JobTicket from '../../components/document-jpt'
import ProductGroups from '@/modules/product-groups/views/master-detail'
import Progress from '../../components/progress'
import ProductionInstances from '../../components/production-instances'
import Admin from '../../components/admin'
import Mockup from '@/modules/designs/components/document/index-internal'
import Actions from '../../components/actions'
import Controls from './controls'

export default {
  components: {
    Actions,
    Controls
  },
  data: () => ({
    tabItems: [
      {title: 'detail', component: DetailForm },
      {title: 'products', component: ProductGroups },
      {title: 'mockup', component: Mockup },
      {title: 'job ticket', component: JobTicket },
      {title: 'progress', component: Progress },
      {title: 'pi\'s', component: ProductionInstances },
      {title: 'admin', component: Admin },
    ]
  }),
  computed: {
    design() { return this.$store.state.designs.item},
    ...mapFields('jobs', {
      job: 'item',
      tabs: 'tabs',
      loaded: 'loaded',
      actions: 'actions'
    }),
    ...mapFields('orders', {
      order: 'item',
    })
  },
  
  async created () {
    this.$store.dispatch('jobs/_LOAD', this.$route.params.id)
  },

}
</script>
