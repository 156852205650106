/** Model definition file for the Product Class **/
import {
  LineItem,
  Brand,
  SizeCategory,
  Image,
  Meta,
  Subproduct,
  Size,
  ProductColor,
  VendorCompany
} from '@/models'

export default class Product {
  constructor ({
    _id = '',
    meta = {},
    name = '',
    code = '',
    description = '',
    text = '',
    brand = {},
    sizeCategory = {},
    image = {},
    sizesAvailable = [],
    subproducts = [],
    vendor = {},
    edits = {
      phase1: { isEdited: false, flagForReview: false }
    },
    descrip = '',
    isPreferred = false,
    tags = []
  } = {}) {
    this._id = _id
    this.meta = new Meta(meta)
    this.name = name
    this.code = code
    this.description = description
    this.text = text
    this.brand = new Brand(brand)
    this.sizeCategory = new SizeCategory(sizeCategory)
    this.image = new Image(image)
    this.sizesAvailable = sizesAvailable
    this.subproducts = subproducts.map(subproduct => {
      const result = new Subproduct(subproduct)
      // process sizes
      result.sizes = result.sizes.map(size => {
        return new Size(size)
      })
      // process colors
      result.colors = result.colors.map(color => {
        return new ProductColor(color)
      })
      return result
    })
    this.vendor = new VendorCompany(vendor)
    this.edits = edits
    this.descrip = descrip
    this.isPreferred = isPreferred
    this.tags = tags
  }
  get displayName () {
    return this.brand.name + ' - ' + this.code + ' ' + this.name
  }

  get sizesavail () {
    const items = this.sizesAvailable
    let list = items.map(a => { return a })
    list.sort((a, b) => {
      const A = a.sortOrder
      const B = b.sortOrder
      return A <= B ? -1 : 1
    })
    return list
  }

  lines (colors) {
    let lines = []
    const subproducts = this.subproducts
    // for each color, loop through subproducts
    colors.forEach(color => {
      subproducts.forEach(subproduct => {
        // get the cost for later
        let costProduct = subproduct.cost
        // if that subproduct contains that color then add those lines to the array
        const colorFound = subproduct.colors.find(c => { return c._id === color._id })
        if (colorFound) {
          // make line items
          subproduct.sizes.forEach(size => {
            let lineItem = new LineItem({ color, size, costProduct })
            lines.push(lineItem)
          })
        }
      })
    })
    // sort the line items
    lines.sort((a, b) => {
      // sort by color name first
      const A = a.color.name
      const B = b.color.name
      if (A > B) return 1
      if (A < B) return -1
      // sory by size sortOrder
      const C = a.size.sortOrder
      const D = b.size.sortOrder
      if (C > D) return 1
      if (C < D) return -1
      return 0
    })
    return lines
  }
}
