export default {
  methods: {
    sortByString (items, prop) {
      let list = items.map(a => { return a })
      // sort list by prop
      list.sort((a, b) => {
        const A = a[prop].toUpperCase()
        const B = b[prop].toUpperCase()
        return A <= B ? -1 : 1
      })
      return list
    },
    sortByPropNumber (items, prop) {
      let list = items.map(a => { return a })
      // sort list by prop
      list.sort((a, b) => {
        const A = a[prop]
        const B = b[prop]
        return A <= B ? -1 : 1
      })
      return list
    },
    sortByBool (items, prop) {
      let list = items.map(a => { return a })
      // sort list by prop
      list.sort((a, b) => {
        const A = a[prop]
        const B = b[prop]
        return (A === B)? 0 : A ? -1 : 1
      })
      return list
    },
    filterByProps (items, props, searchTerm) {
      if (!searchTerm) return items
      let list = []
      // filter by checking each item
      items.forEach(item => {
        let found = false
        // check if any of the designated props contain the search term
        props.forEach(prop => {
          if (String(item[prop]).toUpperCase().includes(searchTerm.toUpperCase())) {
            found = true
          }
        })
        if (found) { list.push(item) }
      })
      return list
    },
    filterByPropsId (items, props, searchItem) {
      if (!searchItem) return items
      let list = []
      // filter by checking each item
      items.forEach(item => {
        let found = false
        // check if any of the designated props contain the search term
        props.forEach(prop => {
          if (item[prop]._id === searchItem._id) {
            found = true
          }
        })
        if (found) { list.push(item) }
      })
      return list
    }
  }
}