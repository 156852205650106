<template lang="pug">
  v-layout(row)
    v-flex
      FwdCard
        template(slot="title") details
        template(slot="content")
          v-select(
            label="name"
            v-model="name"
            :items="subproductNames"
            item-text="name"
            return-object
            @change="update"
          )
          v-text-field(
            label="cost"
            type="number"
            v-model.number="cost"
            @change="update"
          )
      FwdCard
        template(slot="title") assigned sizes
        template(slot="content")
          ListSizes
  
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import ListSizes from './list-sizes'

export default {
  components: { ListSizes },
  computed: {
    ...mapFields('products', {
      id: 'subproduct.item._id',
      name: 'subproduct.item.name',
      cost: 'subproduct.item.cost'
    }),
    subproductNames () {
      const items = this.$store.getters['subproductNames/items']
      return this.sortByString(items, 'name')
    }
  },
  methods: {
    async update () {
      await this.$store.dispatch('products/updateSubproducts')
      this.$store.dispatch('products/_UPDATE')
    }
  }
}
</script>
