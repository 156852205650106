<template lang="pug">
div
  ListScreen(
    :items="itemsSorted"
    @select="select"
    @add="add"
  )
    template(slot-scope="{ item }")
      | {{item.displayName}}
    template(slot="title") screens 
  DetailSimple(
    :detail="detail"
    @save="save"
    @remove="remove"
    @cancel="cancel"
  )
</template>

<script>
import { mapFields  } from 'vuex-map-fields'
import { DesignScreen } from '@/models'
import ListScreen from './list-design-screen'
import DetailSimple from './detail'

export default {
  components: { ListScreen, DetailSimple },
  data: () => ({ detail: false }),
  computed: {
    ...mapFields('designs', {
      screens: 'selected.locationColorway.screens',
      screen: 'selected.screen'
    }),
    itemsSorted () {
      return this.sortByPropNumber(this.screens, 'printOrder')
    }
  },
  methods: {
    add () {
      this.screen = new DesignScreen()
      this.detail = true
    },
    select (item) {
      this.screen = new DesignScreen(item)
      this.detail = true
    },
    async save () {
      await this.$store.dispatch('designs/updateScreens')
      await this.$store.dispatch('designs/updateColorways')
      await this.$store.dispatch('designs/updateLocation')
      this.$store.dispatch('designs/_SAVE')
    },
    cancel () { this.detail = false },
    async remove (item) {
      await this.$store.dispatch('designs/removeScreen')
      this.$store.dispatch('designs/_SAVE')
      this.cancel()
    }
  }
}
</script>
