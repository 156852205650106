import commonActions from '@/store/common/actions'
import commonState from '@/store/common/state'
import commonGetters from '@/store/common/getters'
import commonMutations from '@/store/common/mutations'
import crud from './crud'
import actions from './actions'
import sumJobCosts from './sumJobCosts'
import spekkio from './spekkio'
import fangamerreports from './fangamerreports'
import status from './status'
import document from './document'
import * as transactions from './modules/transactions'
import { Order as Model } from '@/models'

export default {
  namespaced: true,
  modules: {
    transactions
  },
  state: {
    path: 'orders',
    ...commonState(Model)
  },
  getters: {
    ...commonGetters()
  },
  mutations: {
    ...commonMutations()
  },
  actions: {
    ...commonActions(Model),
    ...crud,
    ...sumJobCosts,
    ...spekkio,
    ...fangamerreports,
    ...status,
    ...document,
    ...actions

  }
}
