import commonActions from '@/store/common/actions'
import commonState from '@/store/common/state'
import commonGetters from '@/store/common/getters'
import commonMutations from '@/store/common/mutations'
import crud from './crud'
import spekkio from './spekkio'
import { Design as Model, Upload, Image, MockupPage, File } from '@/models'
import mockups from './mockups'
import router from '@/router'

import {
  updateLocation,
  updateMockups,
  removeMockup,
  updatePlacements,
  removePlacement,
  updateScreens,
  removeScreen,
  updateLasers,
  removeLaser,
  updateSizeways,
  updateColorways,
  pricingInfo
} from './actions'

export default {
  namespaced: true,
  state: {
    path: 'designs',
    ...commonState(Model),
    tabs: 0,
    loaded: false,
    fileOriginal: new File(),
    fileMockups: new File(),
    fileSeparations: new File(),
    fileRips: new File(),
    selected: {
      addOn: {},
      location: {},
      sizeway: {},
      colorway: {},
      locationSizeway: {},
      locationColorway: {},
      placement: {},
      screen: {},
      production: {
        upload: new Upload(),
        image: new Image()

      },
      laser: {},
      mockup: new MockupPage(),
      mockupUpload: new Upload(),
      laserimageupload: new Upload()
    }
  },
  getters: {
    ...commonGetters(),
    loaded (state) { return state.loaded }
  },
  mutations: {
    ...commonMutations(),
    tabs (state, payload) {
      state.tabs = payload
    },
    loaded (state, payload) { state.loaded = payload },
    locations (state, payload) { state.item.locations = payload },
    mockupUpload (state, payload) { state.selected.mockupUpload = payload },
    laserimageupload (state, payload) { state.selected.laserimageupload = payload },
    laserimage (state, payload) { state.selected.locationColorway.production.image = payload },
    mockupImage (state, payload) { state.selected.mockup.image = payload },
    uploadOriginalFile (state, payload) { state.fileOriginal.file = payload }
  },
  actions: {
    ...commonActions(Model),
    ...crud,
    ...spekkio,
    ...mockups,

    // update arrays within the location
    updateLocation,
    updateMockups,
    removeMockup,
    updateScreens,
    removeScreen,
    updateLasers,
    removeLaser,
    updateColorways,
    updatePlacements,
    removePlacement,
    updateSizeways,
    pricingInfo,

    initializeSelectedLocation ({ state }) {
      // initialize the selected location-sizeway
      state.selected.locationSizeway = state.selected.location.sizeways.find(el => {
        return el.sizeway._id === state.selected.sizeway._id
      })
      // initialize the selected location-colorway
      state.selected.locationColorway = state.selected.location.colorways.find(el => {
        return el.colorway._id === state.selected.colorway._id
      })
    },

    async saveMockupFile ({ state, dispatch, commit, rootState }) {
      dispatch('setup')
      // handle the file first
      if (state.selected.mockupUpload.formData) {
        const postFile = await dispatch('postOneFile', {
          data: state.selected.mockupUpload.formData
        })
        commit('mockupImage', postFile)
      }
      state.selected.mockupUpload = new Upload()
      // state.item.mockups = items
      dispatch('cleanup')
    },

    async laserimageupload ({ state, dispatch, commit, rootState }) {
      dispatch('setup')
      // handle the file first
      if (state.selected.laserimageupload.formData) {
        const postFile = await dispatch('postOneFile', {
          data: state.selected.laserimageupload.formData
        })
        commit('laserimage', postFile)
      }
      state.selected.laserimageupload = new Upload()
      // state.item.mockups = items
      dispatch('cleanup')
    },

    async saveThumb ({ state, dispatch, commit, rootState }) {
      dispatch('setup')
      // handle the file first
      if (state.upload.formData) {
        const postFile = await dispatch('postOneFile', {
          data: state.upload.formData
        })
        state.item.image = postFile
      }
      commit('itemsUpdate', { list: 'items', item: state.item })
      // state.item.mockups = items
      state.upload = new Upload()
      dispatch('cleanup')
    },

    async removeThumb ({ state, dispatch, commit, rootState }) {
      dispatch('setup')
      // handle the file first
      state.item.image = new Image()
      commit('itemsUpdate', { list: 'items', item: state.item })
      state.upload = new Upload()
      dispatch('cleanup')
    },

    async saveProductionThumb ({ state, dispatch, commit, rootState }) {
      dispatch('setup')
      // handle the file first
      if (state.upload.formData) {
        const postFile = await dispatch('postOneFile', {
          data: state.upload.formData
        })
        state.item.image = postFile
      }
      commit('itemsUpdate', { list: 'items', item: state.item })
      // state.item.mockups = items
      state.upload = new Upload()
      dispatch('cleanup')
    },

    async addImageSample ({ state, dispatch }, file) {
      dispatch('setup')
      // upload file to server
      if (file) {
        const postFile = await dispatch('postOneFile', { data: file.file })
        // add to state
        state.item.imageSamples.push(new Image(postFile))
        // save design
        dispatch('_SAVE')
      }
      dispatch('cleanup')
    },

    async duplicateDesign ({ state, dispatch, commit }) {
      dispatch('setup')
      // create a duplicate
      const data = state.item
      data.name = `${data.name} COPY`
      data.comments = []
      data.log = []
      data.imageSamples = []
      data.approval = {}

      const duplicate = await dispatch('postOne', { data })
      router.push(`/designs/${duplicate._id}`)
      dispatch('cleanup')
      return duplicate
    }

  }
}
