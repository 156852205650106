import axios from 'axios'

export default {
  
  async _CREATE ({ state, dispatch, commit, rootState }) {
    // get the job id
    state.item.job_id = rootState.jobs.item._id

    // save the record
    const post = await dispatch('postOne', { data: state.item })

    // add to items state
    commit('item', post)
    commit('itemsUpdate', { list: 'items', item: post })
  },

  async _READ ({ dispatch, commit }, _id ) {
    dispatch('setup')

    // load the record
    const item = await dispatch('getOne', _id)

    // add to items state
    commit('item', item)
    commit('itemsUpdate', { list: 'items', item })

    dispatch('cleanup')
  },

  async _READ_JOB ({ dispatch, commit }, _id ) {
    // load the records
    const items = await dispatch('getCollectionFiltered', { _id, endpoint: 'job'})

    // add to items state
    commit('item', items[0])
    commit('items', items)
    return items
  },

  async _UPDATE ({ state, dispatch, commit }) { 
    // update the record
    dispatch('updateOne', { data: state.item })

    // add to state
    commit('itemsUpdate', { list: 'items', item: state.item })
  },

  async _UPDATE_MANY ({ state, dispatch }) { 
    console.log(state.items)
    // update the records
    dispatch('updateMany', { data: state.items })
  },

  async _UPDATE_ONE_LINE ({}, line) { 
    // this method DOES NOT update local state
    // handle all logic on the server
    await axios({
      method: 'patch',
      url: 'product-groups/update-line/' + line._id,
      data: line,
      headers: { 'Content-Type': 'application/json' }
    })
  },

  async _DELETE ({ state, dispatch, commit }) {
    // delete the record
    dispatch('deleteOne', { _id: state.item._id })

    // remove from state
    commit('itemsRemove', { list: 'items', item: state.item })

    // change focus to any other available pg
    if (state.items.length) {
      state.item = state.items[0]
    } else {
      state.item = new ProductGroup()
    }

    // capture quantity difference
    dispatch('updateAll')

    // update order costs
    dispatch('orders/sumJobCosts', null, { root: true })
  },


}