export default [
  {
    title: 'po list',
    icon: 'list',
    route: { path: '/purchase-orders' },
    module: 'purchasing',
    role: ['Admin', 'User',  'Purchasing', 'Production', 'ProductionLead', 'Shipping']
  },
  {
    title: 'po pool',
    icon: 'list',
    route: { path: '/po-pool' },
    module: 'purchasing',
    role: ['Admin', 'User',  'Purchasing']
  },
]