import common from '@/store/common'
import { DesignLocationAddOn as Model } from '@/models'

export default {
  namespaced: true,
  state: {
    path: 'design-location-add-ons',
    ...common.state(Model)
  },
  getters: {
    ...common.getters()
  },
  mutations: {
    ...common.mutations()
  },
  actions: {
    ...common.actions(Model)
  }
}
