<template lang="pug">
v-dialog(max-width=600 persistent v-model="detail")
  v-card(dark color='grey darken-4').pa-5
    v-card-title.display-2.font-weight-black pickup detail
    v-card-text
      v-layout(row)
        v-flex(xs4)
          v-text-field(
            label="# of boxes/bags/items"
            v-model.number="form.numberOfBoxes"
          )
      
      v-layout(row)
        v-flex(xs8)
          v-text-field(
            v-model="form.receiver"
            label="who is picking up?"
            color="pink accent-1"
          )
          
    v-card-actions
      v-btn(@click="save" :disabled="!oktosave")
        v-icon(left) save
        | mark as picked up?
      v-btn.ml-3(@click="cancel")
        v-icon(left) cancel
        | cancel

</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  props: ['detail', 'item'],
  data: () => ({
    form: {}
  }),
  computed: {
    ...mapFields('orders', {
      items: 'item.shipments',
      shippingSubtotal: 'item.costs.shippingSubtotal',
      status: 'item.status'
    }),
    oktosave () {
      const boxes = this.form.numberOfBoxes > 0
      const signed = this.form.receiver
      return boxes && signed
    },
    addresses () {
      let company = this.$store.getters['companies/item']
      return  company.addresses
    }
  },
  watch:{
    item: {
      handler () {
        this.form = this.item
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    save () {
      this.form.isShipped = true
      this.$store.commit('orders/itemsUpdate', { list: 'item.shipments', item: this.form })

      this.updateSubtotal()
      if (this.form.isShipped) {
        const shippedOn = this.form.shippedOn
        this.form.shippedOn = shippedOn ? shippedOn : Date.now()
        this.$store.dispatch('orders/markShipped', this.item)
      } else {
        this.$store.dispatch('orders/_SAVE')
      }
      this.cancel()
    },
    cancel () {
      this.$emit('detail')
    },
    remove (item) {
      this.$store.commit('orders/itemsRemove', { list: 'item.shipments', item: this.form })
      this.updateSubtotal()
      this.$store.dispatch('orders/_SAVE')
      this.cancel()
    },
    updateSubtotal () {
      let subtotal = 0
      this.items.forEach(item => { subtotal += +item.cost })
      this.shippingSubtotal = subtotal
      this.$store.dispatch('orders/sumJobCosts')
    }
    
  }
}
</script>
