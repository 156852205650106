<template lang="pug">
div(v-if="$route.path.includes('/client/')").pl-4
  v-layout(row)
      h3.display-1.pink--text.text--accent-1.font-weight-black.mb-3 approval/changes
  v-layout(row)
    v-flex(xs6)
      p do you want to approve or request changes?
      v-btn(
        v-if="!approved"
        dark
        @click="openapproval"
      ) approve
      v-btn(
        dark
        @click="wantToRequestChanges = true; wantToApprove = false; goto()"
      ) request changes
  div.mt-4(v-if="wantToApprove && !approved")
    v-layout(row)
      v-flex
        p If the information on this mockup is correct please mark as approved below.
    v-layout(row)
      v-flex(xs8)
        v-card(id='approval')
          v-card-title.font-weight-black.title approval
          v-card-text
            Approval
  div.mt-4(v-if="wantToRequestChanges")
    v-layout(row)
      p If changes are needed please leave a change request and we will submit an updated estimate for review.
    v-layout(row)
      v-flex(xs8)
        v-card
          v-card-title.font-weight-black.title request change(s)
          v-card-text
            Comment

</template>

<script>
import Approval from './approval'
import Comment from './comment'
import Vue from 'vue'

export default {
  components: {
    Approval,
    Comment
  },
  computed: {
    approved () {
      const item = this.$store.getters['designs/item']
      return item.approval.approved
    }
  },
  data: () => ({
    wantToApprove: false,
    wantToRequestChanges: false
  }),
  methods: {
    openapproval () {
      this.wantToApprove = true
      this.wantToRequestChanges = false
      this.goto()
    },
    goto () {
      this.$vuetify.goTo(50000, {
        duration: 300,
        offset: 0,
        easing: 'easeInOutCubic'
      })
    }
  }
}
</script>
