<template lang="pug">
div
  v-layout(row)
    v-flex
      v-select(
        label="point of contact"
        v-model="contact"
        :items="contactList"
        return-object
        item-text="fullName"
        @change="save"
      )
  v-layout(row)
    v-flex
      v-btn(@click="contact = {}" color="pink accent-1").white--text remove
</template>

<script>
import { Contact } from '@/models'
import { mapFields } from 'vuex-map-fields'

export default {
  computed: {
    ...mapFields('orders', {
      contact: 'item.contact'
    }),
    contactList () {
      const company = this.$store.getters['companies/item']
      return company.contacts
    }
  },
  methods: {
    save () { this.$store.dispatch('orders/_SAVE') }
  }
}
</script>
