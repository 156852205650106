import { LogItem } from '@/models'
import axios from 'axios'
import moment from 'moment'

const adminList = 'danc@forwardprinting.com, kmc@forwardprinting.com, billy@forwardprinting.com,'

export default {

  // async sendMockupToSpekkio ({ state, dispatch, rootState }) {
  //   dispatch('setup')
  //   // TODO: validate email address and send alert if not available
  //   let to = adminList
  //   const spekkioPage = 'https://spekkio.fangamer.com/admin/products/' + state.item.clientProduct.slug

  //   const mail = {
  //     from: 'info@forwardprinting.com', // sender address
  //     to, // list of receivers
  //     subject: 'mockup: ' + state.item.displayName, // Subject line
  //     text: 'The following mockup link was sent to SPEKKIO: http://fwdio.forwardprinting.com/client/designs/' + state.item._id + '\n\nYou can view the Spekkio page here:' + spekkioPage // plain text body
  //   }
  //   axios({
  //     method: 'post',
  //     url: 'designs/sendmail',
  //     data: mail,
  //     headers: { 'Content-Type': 'application/json' }
  //   })

  //   // ################# SEND TO SPEKKIO
  //   const spekkio = await axios({
  //     method: 'post',
  //     url: 'designs/spekkio/mockup',
  //     data: {
  //       data: { body: `
  //         <span>:  new mockup ready for review! <a href='http://fwdio.forwardprinting.com/client/designs/${state.item._id}' target='_blank'>${state.item.displayName}</a></span>
  //         ` },
  //       product: state.item.clientProduct
  //     },
  //     headers: { 'Content-Type': 'application/json' }
  //   })
  //   console.log(spekkio)
  //   rootState.jobs.item.spekkio.mockupcomment = spekkio.data.id

  //   try {
  //     const path = '/jobs/progress/mockup/' + state.item._id
  //     const data = { value: true }
  //     await axios.patch(path, data)
  //     axios.patch('/production-instances/progress/mockup/' + state.item._id, data)

      
  //   } catch (error) {
  //     console.log('send to spekkio failed', error)
  //     return Promise.reject(error)
  //   }

  //   // save to local state
  //   rootState.jobs.item.progress.isMockSent = true

  //   // log the action
  //   let recipients = ''
  //   if (state.item.contacts.length > 0) {
  //     state.item.contacts.forEach(contact => {
  //       to += `${contact.fullName}, `
  //     })
  //   }
  //   state.item.log.push(new LogItem({
  //     name: 'mockup sent',
  //     text: `mockup has been sent to spekkio: ${spekkioPage} \n
  //       recipients: ${recipients}
  //     `,
  //     user: rootState.users.item
  //   }))

  //   dispatch('jobs/_SAVE', null, { root: true })
  //   dispatch('_SAVE')
  //   dispatch('cleanup')
  // },

  async sendMockupToSpekkio ({ state, dispatch, rootState }) {
    dispatch('setup')
    const spekkioPage = 'https://spekkio.fangamer.com/admin/products/' + state.item.clientProduct.slug
    // post to server to update:
    // pi's sharing design (wip and not retired)
    // jobs related to these pi's
    // send email to admin list
    const request = axios.post('/designs/sendmockup', {
      _id: state.item._id,
      emailrecipients: adminList,
      displayname: state.item.displayName    
    })

    const spekkio = axios.post('/designs/spekkio/mockup', {
      _id: state.item._id,
      displayname: state.item.displayName,
      product: state.item.clientProduct
    })

    await Promise.all([request, spekkio])

    // log the change
    state.item.log.push(new LogItem({
      name: 'status change',
      text: 'mockup has been sent to the spekkio page: ' + spekkioPage,
      user: rootState.users.item
    }))

    dispatch('_SAVE')

    return dispatch('cleanup')
  },

  

  async sendapprovedboosttospekkio ({ state, dispatch, rootState }) {

    // ################# SEND TO SPEKKIO
    const result = await axios({
      method: 'post',
      url: 'designs/spekkio/approvedboost',
      data: {
        design: state.item._id,
        productid: state.item.clientProduct.id,

      },
      headers: { 'Content-Type': 'application/json' }
    })
  }
}
