<template lang="pug">
div(class="text-xs-center")
  v-dialog(
    v-model="needHelp"
    width="500"
  )
    v-card(
      color="black"
      dark
    )
      v-card-text
        h3.title.mb-4 Need help now?
        v-layout(row).bottom-border
          v-flex(xs6) for questions about INKS, SCREENS, ART and any issues with this application, contact:
          v-flex(xs6).pl-3
            p Dan C 
              a(href='mailto:danc@forwardprinting.com') danc@forwardprinting.com
            p 510-282-3045
           
        v-layout(row).bottom-border
          v-flex(xs6) for questions about the production dashboard and any issues on the production floor:
          v-flex(xs6).pl-3
            p Lee 
              a(href='mailto:lee@forwardprinting.com') lee@forwardprinting.com
            p 203-307-5044
           
        v-layout(row).bottom-border
          v-flex(xs6) for questions about the schedule, receiving or job status:
          v-flex(xs6).pl-3
            p Kevin 
              a(href='mailto:kmc@forwardprinting.com') kmc@forwardprinting.com
            p 510-967-3119
           
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  computed: {
    ...mapFields('helpDialog', {
      needHelp: 'needHelp',
    })
  }
}
</script>

<style lang="stylus" scoped>
.bottom-border
  border-bottom 1px solid white
  margin-bottom 10px
</style>