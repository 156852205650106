<template lang="pug">
DetailSmall(:detail="detail")
  template(slot="title") transactions
  template(slot="form")
    Transactions
  template(slot="controls")
    v-btn(@click="cancel") ok
</template>

<script>
import DetailSmall from '@/components/base/detail-small-modal'
import Transactions from '../../transactions'

export default {
  components: { DetailSmall, Transactions },
  props: ['detail'],
  methods: { cancel () { this.$emit('cancel') } }
}
</script>
