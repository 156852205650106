<template lang="pug">
v-container(fluid)
  v-layout(row)
    v-flex(xs6)
      FwdCard
        template(slot="title") details
        template(slot="content")
          v-layout(row)
            v-flex
              v-text-field(
                label="username"
                v-model="username"
                @change="save"
              )
              v-text-field(
                label="password"
                v-model="password"
                @change="save"
              )
          v-layout(row)
            v-flex(xs6)
              v-text-field(
                label="first name"
                v-model="firstName"
                @change="save"
              )
            v-flex.ml-3(xs6)
              v-text-field(
                label="last name"
                v-model="lastName"
                @change="save"
              )
          v-layout(row)
            v-flex()
              v-text-field(
                label="email"
                type="email"
                v-model="email"
                @change="save"
              )
          v-layout(row)
            v-flex()
              v-select(
                label="role"
                v-model="role"
                :items="roles"
                @change="save"
              )
          v-layout(row)
            v-flex()
              v-btn(@click='retire' color='warning') retire this user

</template>

<script>
import { User } from '@/models'
import { roles } from '../../data/roles'
import { mapFields } from 'vuex-map-fields'
import sortFilter from '@/mixins/sort-filter'

export default {
  computed: {
    ...mapFields('users', {
      item: 'item',
      username: 'item.username',
      password: 'item.password',
      firstName: 'item.firstName',
      lastName: 'item.lastName',
      email: 'item.email',
      role: 'item.role'
    }),
    roles () { return roles }
  },
  methods: {
    save () { this.$store.dispatch('users/update') },
    retire () {
      this.$store.dispatch('users/delet')
      this.$router.go(-1)
    }
  }
}
</script>
