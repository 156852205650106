/** Model definition file for the DecorationCode Class **/

export default class DecorationCode {
  constructor ({
    _id = '',
    name = '',
    code = '',
    quantity = 1,
    component = {
      type: {},
      useCost: null,
      setup: { cost: null, time: null }
    }
  } = {}) {
    this._id = _id
    this.name = name
    this.code = code
    this.quantity = quantity
    this.component = component
  }
}
