<template lang="pug">
DetailSmall(:detail="detail")
  template(slot="title") product color detail.
  template(slot="form")
    v-layout(row)
      v-flex
        v-text-field(
          label="name"
          v-model="name"
        )
        v-select(
          label="brand"
          v-model="brand"
          :items="brands"
          item-text="name"
          return-object
        )
    v-layout(row)
      v-flex(xs6 offset-xs3)
        ImageInput(
          :item="item"
          :upload="upload"
          :size="Number(150)"
          @upload="uploadImage"
        )
  template(slot="controls")
    DetailControls(
      @save="save"
      @cancel="cancel"
      @remove="remove"
    )
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import DetailSmall from '@/components/base/detail-small-modal'
import DetailControls from '@/components/base/detail-controls-simple'
import ImageInput from '@/components/base/image-input-fb'

export default {
  components: {
    DetailControls,
    DetailSmall,
    ImageInput
  },
  computed: {
    ...mapFields('productColors', {
      item: 'item',
      upload: 'upload',
      name: 'item.name',
      brand: 'item.brand',
      detail: 'detail'
    }),
    brands () { return this.$store.getters['brands/items'] }
  },
  methods: {
    async save () { await this.$store.dispatch('productColors/_SAVE') },
    async remove () { await this.$store.dispatch('productColors/_REMOVE') },
    async cancel () { await this.$store.dispatch('productColors/_DETAIL_CLOSE') },
    uploadImage(item) { this.$store.commit('productColors/upload', item) }
  }
}
</script>
