import { Company as Model } from '@/models'

export default {
  async _ADD ({ state, dispatch, commit, rootState }) {
    // get the default pricing tier and assign to company now
    const pricingTier = rootState.tiers.items.find(item => item.isDefault)
    const newItem = await dispatch('postOne', {
      data: new Model({
        pricingTier
      })
    })
    // update local state
    commit('itemsUpdate', {
      list: 'items',
      item: newItem
    })
    commit('item', newItem)
    return newItem
  },

  async _LOAD ({ dispatch, commit }, _id) {
    dispatch('setup')
    // get current company
    const company = await dispatch('getOne', _id )

    // setup any necessary API calls
    const apiCalls = []
    // load related designs
    apiCalls.push(dispatch('designs/getCollectionFiltered', { endpoint: 'company', _id }, { root: true }))
    // load related orders
    apiCalls.push(dispatch('orders/getCollectionFiltered', { endpoint: 'company', _id }, { root: true }))

    // go fetch what is needed
    const calls = await Promise.all(apiCalls)

    // update local state
    commit('item', company)
    dispatch('companies/lists/designs', calls[0], { root: true })
    dispatch('companies/lists/orders', calls[1], { root: true })
    
    // cleanup
    dispatch('cleanup')
  },

  async _REMOVE ({ dispatch, commit, rootState, state }) {
    dispatch('deleteOne', {_id: state.item._id })
    // remove the item from local state
    commit('itemsRemove', {
      list: 'items',
      item: state.item
    })
  },

  async _SAVE ({ dispatch, commit, rootState, state }) {
    dispatch('updateOne', { data: state.item })
    // update items array (local state)
    commit('itemsUpdate', {
      list: 'items',
      item: state.item
    })
  },
}