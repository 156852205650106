<template lang="pug">
v-container(fluid)
  BaseListSearchOnly(
    :items="itemsSorted"
    searchLabel="search designs"
    :addToggle="editState"
    @search="setSearch"
    @select="select"
    @add="add"
  )
    template(slot-scope="{ item }")
      v-layout(row)
        v-flex(xs12) {{ item.serial }} - {{item.name}}
          pre {{ item }}
</template>

<script>
import { Design } from '@/models'
import { mapFields } from 'vuex-map-fields'
import BaseListSearchOnly from '@/components/base/list-search-only'
import _ from 'lodash'

export default {
  components: { BaseListSearchOnly },
  computed: {
    editState () { return this.$store.state.companies.editState },
    ...mapFields('designs', {
      items: 'items',
      search: 'search'
    }),
    itemsSorted () {
      const uniques = (arr) => _.xor(...arr.map(a => [a]))
      const duplicates = (arr) => _.xor(arr, uniques(arr))

      const items = _.filter(this.items, x => x.company._id === '5ce8672394e11431a4ab9e24')
      const serials = duplicates(_.map(items, x => x.serial))
      const list = _.filter(items, x => serials.includes(x.serial))
      // sort list by name
      return this.sortByPropNumber(list, 'serial')
    }
  },
  methods: {
    async add () {
      const add = await this.$store.dispatch('designs/_ADD')
      this.detail(add._id)
    },

    select (item) {
      this.detail(item._id)
    },

    detail (_id) {
      this.$router.push({ path: '/designs/' + _id })
    },

    setSearch (search) {
      this.search = search
    }

  },

  async created() {
    let collection =  await this.$store.dispatch('designs/getCollection')
    this.$store.commit('designs/items', collection)
  }
}
</script>
