<template lang="pug">
div
  FwdDetailPage()
    template(slot="title-prefix") user:
    template(slot="title") {{ item.username }}
    template(slot="form")
      DetailForm
  v-dialog(max-width=600 v-model="actions")
    FwdCard
      template(slot="title") actions
      template(slot="content")
        Actions

</template>

<script>
import { mapFields } from 'vuex-map-fields'
import DetailForm from '../../components/form'
import Actions from '../../components/actions'

export default {
  components: {
    Actions,
    DetailForm
  },
  computed: {
    ...mapFields('users', {
      item: 'item',
      actions: 'actions'
    })
  },
  async created () {
    this.$store.dispatch('users/readone', this.$route.params.id)
  }
}
</script>
