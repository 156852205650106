export default {
  async sumJobCosts ({ state, rootState }) {
    // get job subtotals
    let subtotal = 0
    let quantity = 0
    let subtotalFinal = 0
    let quantityFinal = 0
    rootState.jobs.items.forEach(job => {
      subtotal += job.subtotal
      quantity += job.quantity
      subtotalFinal += job.subtotalFinal
      quantityFinal += job.quantityFinal
    })
    state.item.costs.jobsSubtotal = subtotal
    state.item.costs.jobsSubtotalFinal = subtotalFinal
    
    let total = 0
    let totalFinal = 0
    // add up jobs, shipping and other charges
    total += state.item.costs.jobsSubtotal
    totalFinal += state.item.costs.jobsSubtotalFinal
    total += state.item.costs.shippingSubtotal
    total += state.item.costs.otherChargesSubtotal
    totalFinal += state.item.costs.shippingSubtotal
    totalFinal += state.item.costs.otherChargesSubtotal

    // apply adjustments
    // // calculate a percentage adjustment to subtotal and add to running total
    if (state.item.costs.adjustmentPercentage) {
      state.item.costs.adjustment = total * state.item.costs.adjustmentPercentage / 100
      state.item.costs.adjustmentFinal = totalFinal * state.item.costs.adjustmentPercentage / 100
    } else {
      state.item.costs.adjustment = 0
      state.item.costs.adjustmentFinal = 0
    }
    // // calculate a numerical adjustment to subtotal and add to running total
    if (state.item.costs.adjustmentAmount) {
      state.item.costs.adjustment += state.item.costs.adjustmentAmount
      state.item.costs.adjustmentFinal += state.item.costs.adjustmentAmount
    }
    total += state.item.costs.adjustment
    totalFinal += state.item.costs.adjustmentFinal
  
    state.item.costs.subtotal = total
    state.item.costs.subtotalFinal = totalFinal
    
    if (state.item.costs.taxOverride || rootState.companies.item.taxable.isTaxable) {
      total += state.item.costs.taxOverride
      totalFinal += state.item.costs.taxOverride
      state.item.costs.tax = 0
    } else {
      // calculate sales tax
      const taxRate = rootState.admin.item.taxRate
      state.item.costs.tax = state.item.costs.subtotal * .01 * taxRate
      total += state.item.costs.tax
      state.item.costs.taxFinal = state.item.costs.subtotalFinal * .01 * taxRate
      totalFinal += state.item.costs.taxFinal
    }
    state.item.costs.total = total
    state.item.costs.quantity = quantity
    state.item.costs.totalFinal = totalFinal
    state.item.costs.quantityFinal = quantityFinal
  }
}