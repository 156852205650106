<template lang="pug">
v-select.font-weight-bold(
  v-model="filter"
  :items="items"
  item-text="name"
  return-object
  flat
)
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  computed: {
    ...mapFields('sizes', {
      filter: 'filter'
    }),
    items () {
      const items = this.$store.getters['sizeCategories/items']
      return this.sortByString(items, 'name')
    }
  },
  watch: {
    items () { this.filter = this.items[0] }
  }
}
</script>
