<template lang="pug">
v-container().pt-5
  v-layout(row)
    v-flex(xs6)
      FwdCard
        template(slot="title") meta
        template(slot="content") 
          p
            span.font-weight-bold date created: 
            | {{ formatDate(dateCreated) }}
          p
            span.font-weight-bold quantity: 
            | {{ quantity }}
          p
            span.font-weight-bold quantity ordered: 
            | {{ quantityOrdered }}
          p
            span.font-weight-bold cost of goods: 
            | {{ cog | currency }}
      FwdCard
        template(slot="title") vendor
        template(slot="content") 
          v-select(
            v-model="vendor"
            label="vendor"
            :items="vendors"
            item-text="name"
            return-object
            @change="save"
          )
          v-text-field(
            label='vendor override'
            v-model='vendorOverride.name'
            @change='vendorOverrideAndSave'
          )
          v-text-field(
            label='po number'
            v-model='ponumber'
            @change='save'
          )
      
    v-flex(xs6 pl-3)
      FwdCard()
        template(slot="title") dates
        template(slot="content")
          p
            span.font-weight-bold date ordered: 
            | {{ formatDate(dateOrdered) }}
          p
            span.font-weight-bold date to arrive: 
            | {{ formatDate(dateToArrive) }}
          p
            span.font-weight-bold date received: 
            | {{ formatDate(dateReceived) }}
      FwdCard()
        template(slot="title") tracking numbers
        template(slot="content")
          v-textarea(
            v-model="trackingNumbers"
            label="tracking numbers"
          )
          

      FwdCard()
        template(slot="title") jobs on this po
        template(slot="content")
          v-list(
            flat
            dense
          )
            v-list-tile(
              v-for="(job, i) in jobsOnThisPo"
              :key="i"
              @click="$router.push('/jobs/' + job.job_id)"
            )
              v-list-tile-content
                v-list-tile-title 
                  span.pink--text {{ job.jobNumber }}  
                  span {{ job.companyName }} - {{ job.jobName }}
              v-list-tile-action
                v-icon subdirectory_arrow_right

      
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import sortFilter from '@/mixins/sort-filter'
import moment from 'moment'

export default {
  data: () => ({ vendorOverride: { name: '' } }),
  computed: {
    product () { return this.$store.getters['products/item']},
    vendors () { return this.$store.getters['vendors/items']},
    ...mapFields('purchaseOrders', {
      item: 'item',
      lines: 'item.lines',
      vendor: 'item.vendor',
      dateCreated: 'item.dateCreated',
      dateOrdered: 'item.dateOrdered',
      dateReceived: 'item.dateReceived',
      dateToArrive: 'item.dateToArrive',
      quantity: 'item.quantity',
      quantityOrdered: 'item.quantityOrdered',
      cog: 'item.cog',
      trackingNumbers: 'item.trackingNumbers',
      ponumber: 'item.number',
    }),
    jobsOnThisPo () { return this.item.jobsOnThisPo }
  },
  methods: {
    vendorOverrideAndSave () {
      this.vendor = this.vendorOverride
      this.save()
    },
    save () { this.$store.dispatch('purchaseOrders/_UPDATE') },
    formatDate (date) { return date ? moment(date).format('L') : '' }
  }
}
</script>
