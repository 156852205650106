<template lang="pug">
v-container()
  v-layout(row)
    v-flex(xs6)
      FwdCard
        template(slot="title") details
        template(slot="content") 
          v-text-field(
            label="name"
            v-model="name"
            @change="save"
          )
          v-text-field(
            label="website"
            v-model="website"
            @change="save"
          )
      FwdCard
        template(slot="title") pricing/billing
        template(slot="content") 
          v-checkbox(
            label="deposit required?"
            v-model="isDepositRequired"
            @change="save"
          )
          v-select(
            label="pricing tier"
            v-model="pricingTier"
            :items="tiers"
            item-text="name"
            return-object
            @change="save"
          )
    v-flex(xs6 pl-3)
      v-alert(:value="true" type="info") Please make sure the company is taxable if we are shipping to either Washington or Arizona

      FwdCard
        template(slot="title") tax status
        template(slot="content")
          v-checkbox(
            label="tax exempt?"
            v-model="taxable.isTaxable"
            @change="save"
          )
          v-select(
            label="reason for exemption"
            v-model="taxable.reason"
            :items="taxExemptReasons"
            @change="save"
          )
          v-textarea(
            label="tax exemption notes"
            v-model="taxable.notes"
            @change="save"
          )
      
</template>

<script>
import { Company } from '@/models'
import { mapFields } from 'vuex-map-fields'
import sortFilter from '@/mixins/sort-filter'

export default {
  computed: {
    tiers () { return this.$store.getters['tiers/items']},
    ...mapFields('companies', {
      name: 'item.name',
      website: 'item.website',
      addresses: 'item.addresses',
      contacts: 'item.contacts',
      taxable: 'item.taxable',
      isDepositRequired: 'item.isDepositRequired',
      pricingTier: 'item.pricingTier'
      
    }),
    taxExemptReasons () {
      let model = new Company()
      return model.taxExemptReasons
    }
  },
  methods: {
    save () { this.$store.dispatch('companies/_SAVE') }
  }
}
</script>
