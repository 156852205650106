<template lang="pug">
div
  v-layout(row)
    v-flex(xs6)
      h3.title financial
  v-layout(row).mt-3
    v-flex(xs6)
      v-btn(
        @click="transactionsDetail = true"
        dark
        color="pink accent-1"
      ) manage transactions
    v-flex(xs6)

  TransactionsDetail(
    :detail="transactionsDetail"
    @cancel="cancel"
  )
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import TransactionsDetail from './transactions-detail'

export default {
  components: { TransactionsDetail },
  computed: {
    ...mapFields('orders', {
      item: 'item',
      actions: 'actions'
    })
  },
  data: () => ({
    transactionsDetail: false,
  }),
  methods: {
    cancel () {
      this.transactionsDetail = false
      this.actions = false
    }
  }
}
</script>

<style>

</style>
