<template lang="pug">
v-layout(row)
  v-flex
    v-btn(
      :disabled="!allow"
      @click="addPoLines"
    ) add po lines
  v-flex
    v-checkbox(
      label="allow?"
      v-model="allow"
    )
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  computed: {
    ...mapFields('orders', {
      item: 'item',
      actions: 'actions'
    })
  },
  data: () => ({ allow: false }),
  methods: {
    async addPoLines() {
      await this.$store.dispatch('jobs/addPoLinesToPool', { order_id: this.item._id })
      this.actions = false
    }
  }
}
</script>

<style>

</style>
