<template lang="pug">
div
  v-select(
    v-model='platen'
    :items='platensizes'
    label='platen size'
    @change='$store.dispatch("designs/_SAVE")'
    clearable
  )
  p.font-weight-bold laser placement diagram:
  ImageInput(
    :item="selected"
    :upload="upload"
    @upload="uploadImage"
  )
  ListLaser(
    :items="itemsSorted"
    @select="select"
    @add="add"
  )
    template(slot-scope="{ item }")
      | {{item.number}} - {{ item.description}}
    template(slot="title") lasers
  DetailSimple(
    :detail="detail"
    @save="save"
    @remove="remove"
    @cancel="cancel"
  )
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { DesignLaser } from '@/models'
import ListLaser from './list'
import DetailSimple from './detail'
import ImageInput from '@/components/base/image-input-fb'

export default {
  components: { ListLaser, DetailSimple, ImageInput },
  data: () => ({
    detail: false,
    platensizes: [
      'standard 16 wide',
      'ladies 12 wide',
      'jumbo 20 wide',
      'sleeve 5 wide',
      'infant 7 wide',
      'tote 10 wide',
      'double sleeve',
      'bandana 24 wide'
    ]
  }),
  computed: {
    ...mapFields('designs', {
      selected: 'selected.locationColorway.production',
      lasers: 'selected.locationColorway.production.lasers',
      platen: 'selected.locationColorway.production.platen',
      laser: 'selected.laser',
      item: 'selected.production',
      upload: 'selected.production.upload'
    }),
    itemsSorted() {
      return this.sortByPropNumber(this.lasers, 'number')
    }
  },
  methods: {
    add() {
      this.laser = new DesignLaser()
      this.detail = true
    },
    select(item) {
      this.laser = new DesignLaser(item)
      this.detail = true
    },
    async save() {
      await this.$store.dispatch('designs/updateLasers')
      await this.$store.dispatch('designs/updateColorways')
      await this.$store.dispatch('designs/updateLocation')
      this.$store.dispatch('designs/_SAVE')
    },
    cancel() { this.detail = false },
    async remove(item) {
      await this.$store.dispatch('designs/removeLaser')
      this.$store.dispatch('designs/_SAVE')
      this.cancel()
    },
    async uploadImage(upload) {
      this.$store.commit('designs/laserimageupload', upload)
      await this.$store.dispatch('designs/laserimageupload')
      this.$store.dispatch('designs/_SAVE')
    }
  }
}
</script>
