<template lang="pug">
v-layout(row)
  v-flex
    v-btn(
      @click="initializeQuantities"
      color="pink accent-1"
      :disabled='ordered'
    ).white--text initialize quantities
  v-flex.caption.pl-3
    p this will make all "ordered" quantities match the initial quantities
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  computed: {
    ...mapFields('purchaseOrders', {
      item: 'item',
      lines: 'item.lines',
      actions: 'actions',
      ordered: 'item.ordered'
    })
  },
  methods: {
    async initializeQuantities() {
      const newLines = this.lines.map(line => {
        line.lineItem.quantityOrdered = line.lineItem.quantity
        return line
      })
      this.lines = newLines
      this.$store.dispatch('purchaseOrders/_UPDATE')
      
      this.actions = false
    }
  },
  watch: {

  }
}
</script>

<style>

</style>
