/** Model definition file for the PiProgress Class **/

export default class PiProgress {
  constructor ({
    isArtRipped = false,
    isScreensPrepped = false,
    isInksMixed = false,
    isPrintApproved = false,
  } = {}) {
    this.isArtRipped = isArtRipped
    this.isScreensPrepped = isScreensPrepped
    this.isInksMixed = isInksMixed
    this.isPrintApproved = isPrintApproved
  }
}
