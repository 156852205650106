<template lang="pug">
v-card(@click="$emit('select')" hover dark).pa-2
  v-card-title.title {{ titleName }}
  v-card-text
    v-layout(row)
      v-flex(xs12 sm6 ml-3).body-1
        v-layout(row)
          v-flex(xs12).font-weight-bold
            p status: 
              span.font-weight-regular {{ titleStatus }}
            p # of boxes: 
              span.font-weight-regular {{shipment.numberOfBoxes}}

    v-layout(row)
      v-flex(xs6 v-if="shipment.shipmentNotes")
        span.font-weight-bold shipment notes:
        | {{shipment.shipmentNotes}}
      v-flex(xs6 v-if="shipment.deliveryNotes")
        span.font-weight-bold delivery notes:
        | {{shipment.deliveryNotes}}
</template>

<script>
import moment from 'moment'
import { Shipment } from '@/models'

export default {
  props: ['shipment'],
  computed: {
    jobs () {
      return this.$store.getters['jobs/items']
    },
    iscontract () {
      return !!this.jobs.find(job => job.removeProductCost)
    },
    titleName () {
      const ad = this.shipment.address
      if (ad.name) { return ad.name }
      if (ad.companyName) { return ad.companyName }
      if (ad.attn) { return ad.attn }
      return 'incomplete address'
    },
    titleStatus () {
      const shippedOn = this.shipment.shippedOn
      return shippedOn ? 'shipped on ' + this.formatDate(shippedOn) : 'not shipped'
    }
  },
  methods: {
    formatDate (date) {
      return moment(date).format('L')
    }
  }
}
</script>

<style lang="stylus" scoped>
.courier
  font-family 'Courier New', Courier, monospace
</style>
