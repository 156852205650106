<template lang="pug">
DetailSmall(:detail="detail")
  template(slot="title")
    | size category detail.
  template(slot="form")
    v-layout(row)
      v-flex
        v-text-field(
          label="name"
          v-model="name"
        )
  template(slot="controls")
    DetailControls(
      @save="save"
      @cancel="cancel"
      @remove="remove"
    )
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import DetailSmall from '@/components/base/detail-small-modal'
import DetailControls from '@/components/base/detail-controls-simple'

export default {
  components: { DetailControls, DetailSmall },
  props: ['detail'],
  computed: {
    ...mapFields('sizeCategories', {
      name: 'item.name'
    })
  },
  methods: {
    save () { this.$emit('save') },
    cancel () { this.$emit('cancel') },
    remove () { this.$emit('remove') }
  }
}
</script>
