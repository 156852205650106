<template lang="pug">
v-dialog(max-width="65%" persistent v-model="value")
  div
    v-card(flat)
      v-card-title.title.text-uppercase.font-weight-bold {{ title }}
      v-card-text
        PickProduct(
          v-if="!product._id"
          :items="products"
          @cancel="cancel"
          @selectProduct="selectProduct"
        )
        PickColors(
          v-else
          :items="subproductColors"
          @cancel="cancel"
          @submit="submit"
          @reset="reset"
        )
</template>
<script>
import { mapFields } from 'vuex-map-fields'
import PickProduct from './pick-product'
import PickColors from './pick-colors'
import uniqueColors from './unique-colors'

export default {
  components: {
    PickProduct,
    PickColors
  },
  props: ['value'],
  data: () => ({
    title: 'Pick Product',
    product: {},
    colors: []
  }),
  computed: {
    ...mapFields('products', {
      filter: 'filter',
      search: 'search',
    }),
    products () { return this.$store.getters['products/items'] },
    subproducts () { return this.product.subproducts },
    subproductColors () { return uniqueColors(this.product.subproducts) }
  },
  methods: {
    async selectProduct (selected) {
      let product = await this.$store.dispatch('products/getOne', selected._id)
      this.product = product
      this.filter = null
      this.search = ''
    },
    reset () {
      this.product = {}
    },
    cancel () {
      this.reset()
      this.$emit('input', false)
    },
    submit (colors) {
      let result = {
        product: this.product,
        colors,
        lineItems: this.product.lines(colors)
      }
      this.$emit('product', result)
      this.cancel()
    }
  },
  mounted () {
    this.product = {}
    this.colors = []
  }
}
</script>
