<template lang="pug">
v-container
  FwdHeadingLarge product colors
  CList
  VDetail
</template>

<script>
import CList from '../components/list'
import VDetail from './detail'

export default {
  components: { CList, VDetail },
  created () { this.$store.dispatch('productColors/_READ_ALL')}
}
</script>
