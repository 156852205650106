<template lang="pug">
div
  v-layout(row)
    v-flex
      v-btn(
        @click='launchFilePicker()'
        v-if="upload.url"
        color="green"
        dark
      ) add file
        v-icon(right dark) done
      v-btn(@click='launchFilePicker()' v-else) add file

  input(
    type='file'
    ref='file'
    @change='onFileChange($event.target.files)'
    style='display:none'
    )
</template>

<script>
export default {
  props: {
    // to pull file info off of existing item
    item: {
      type: Object
    },
    // to pull info off of the state upload bucket
    upload: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    errorDialog: null,
    errorText: ''
  }),
  methods: {
    launchFilePicker () {
      this.$refs.file.click()
    },
    onFileChange (file) {
      let uploadFile = file[0]

      // Append file into FormData and turn file into image URL
      let url = URL.createObjectURL(uploadFile)
      let formData = uploadFile
      // Emit the FormData and image URL to the store
      this.$emit('upload', { formData, url })
    }
  }
}
</script>
