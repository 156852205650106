<template lang="pug">
div
  v-layout(row)
    v-flex(xs6)
      v-btn.white--text(
        color="pink accent-1"
        @click="action"
      ) add product color
  ProductColorDetail(
    :detail="detail"
    @save="save"
    @remove="remove"
    @cancel="cancel"
  )
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { ProductColor } from '@/models'
import {detail as ProductColorDetail } from '@/modules/product-colors/'

export default {
  data: () => ({ detail: false }),
  computed: {
    ...mapFields('products', {
      product: 'item',
      actions: 'actions'
    }),
    ...mapFields('productColors', {
      item: 'item'
    }),
  },
  methods: {
    async action() {
      this.item = new ProductColor({ brand: this.product.brand })
      console.log(this.item)
      this.detail = true
      this.actions = false
    },

    async save () {
      await this.$store.dispatch('productColors/saveWithFile', this.item)
      this.cancel()
    },
    async remove () {
      await this.$store.dispatch('productColors/remove', this.item._id)
      this.cancel()
    },
    cancel () {
      this.detail = false
    },
  },
  components: { ProductColorDetail }
}
</script>
