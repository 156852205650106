<template lang="pug">
v-layout(row px-4 py-2).body-2.pink.lighten-5
  v-flex(xs3) color
  v-flex(xs1).text-xs-center size
  v-flex(xs5)
    v-layout(row)
      v-flex(xs3).text-xs-center 
        template(v-if='settings.quantity.show') original
      v-flex(xs3).text-xs-center 
        template(v-if='settings.quantityOrdered.show') ordered
      v-flex(xs3).text-xs-center 
        template(v-if='settings.quantityReceived.show') received
      v-flex(xs3).text-xs-center 
        template(v-if='settings.quantityFinal.show') final
  v-flex.text-xs-right(xs1) 
    template(v-if='settings.cost.show') cost
  v-flex.text-xs-right(xs2)
    template(v-if='settings.costExtend.show') extend
</template>

<script>
export default {
  computed: {
    settings () { return this.$store.getters['productGroups/settings'] }
  },
}
</script>