<template lang="pug">
v-card.pa-0.transparent.text-xs-center(flat)
  template(v-if="!readOnly")
    input.css-input.pink.lighten-5.text-xs-center(
      v-model.number="quantityFinal"
      @change="changeQuantity"
      @focus="$event.target.select()"
      tabindex=1
    )
  template(v-else)
    v-card-text.text-xs-center.px-0 {{line.quantityFinal}}
</template>
<script>
export default {
  props: ['line', 'readOnly'],
  data: () => ({ quantityFinal: null, quantityFinalOld: null }),
  methods: {
    changeQuantity () {
      if (isNaN(this.quantityFinal) || this.quantityFinal === null) { this.quantityFinal = 0 }
      
      const changeAmount = this.quantityFinal - this.quantityFinalOld
      const line = { ...this.line }
      const _id = this.line._id

      line.quantityFinal = this.quantityFinal
      this.$emit('changeQuantity', { changeAmount, _id, line })
      this.quantityFinalOld = this.quantityFinal
    }
  },
  
  watch: {
    line: {
      handler() {
        this.quantityFinal = this.line.quantityFinal
        this.quantityFinalOld = this.line.quantityFinal
      },
      immediate: true,
      deep: true
    }
  }
  
}
</script>
<style lang="stylus" scoped>
.css-input
  border-color #cccccc
  padding 5px
  border-width 0px
  border-style hidden
  text-align center
  width 75%
.css-input focus
  outline none
</style>
