/** Model definition file for the Shipment Class **/
import uuid from 'uuid/v4'
import { Address } from '@/models'

export default class Shipment {
  constructor ({
    _id = uuid(),
    address = {},
    cost = 0,
    carrier = 'UPS',
    tracking = '',
    numberOfBoxes = 0,
    shipBy = new Date(Date.now() + 14 * 86400000),
    receiveBy = new Date(Date.now() + 21 * 86400000),
    shippedOn = '',
    deliveryNotes = '',
    shipmentNotes = '',
    isShipped = '',
    receiver = ''
  } = {}) {
    this._id = _id
    this.address = new Address(address)
    this.cost = cost
    this.carrier = carrier
    this.tracking = tracking
    this.numberOfBoxes = numberOfBoxes
    this.shipBy = new Date(shipBy)
    this.receiveBy = new Date(receiveBy)
    this.shippedOn = shippedOn ? new Date(shippedOn) : ''
    this.deliveryNotes = deliveryNotes
    this.shipmentNotes = shipmentNotes
    this.isShipped = isShipped
    this.receiver = receiver
  }
  get carriers () {
    return ['UPS', 'FedEx', 'USPS', 'OnTrac']
  }
}
