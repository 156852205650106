import { MockupPage } from '@/models'

export const updateLocation = ({ state }) => {
  let locations = state.item.locations
  let index = locations.findIndex(el => {
    return state.selected.location._id === el._id
  })
  if (index !== -1) {
    locations.splice(index, 1, state.selected.location)
  } else {
    locations.push(state.selected.location)
  }
}

export const removeLocation = ({ state, commit, dispatch }) => {
  const locations = state.item.locations
  let index = locations.findIndex(el => {
    return state.selected.location._id === el._id
  })
  if (locations.length > 1) {
    locations.splice(index, 1)
  } else {
    commit('locations', [])
  }
  // initialize the current location
  if (state.item.locations.length) {
    state.selected.location = state.item.locations[0]
    dispatch('initializeSelectedLocation')
  } else {
    state.selected.location = {}
  }
}

export const updatePlacements = ({ state }) => {
  let placements = state.selected.locationSizeway.placements
  let indexPlacements = placements.findIndex(el => {
    return state.selected.placement._id === el._id
  })
  if (indexPlacements !== -1) {
    placements.splice(indexPlacements, 1, state.selected.placement)
  } else if (state.selected.placement !== {}) {
    placements.push(state.selected.placement)
  }
  state.selected.placement = {}
}

export const removePlacement = ({ state, dispatch }) => {
  let placements = state.selected.locationSizeway.placements
  let index = placements.findIndex(el => {
    return state.selected.placement._id === el._id
  })
  placements.splice(index, 1)
  // update the current location
  dispatch('updateSizeways')
  dispatch('updateLocation')
}

export const updateScreens = ({ state }) => {
  let screens = state.selected.locationColorway.screens
  let indexScreens = screens.findIndex(el => {
    return state.selected.screen._id === el._id
  })
  if (indexScreens !== -1) {
    screens.splice(indexScreens, 1, state.selected.screen)
  } else if (state.selected.screen !== {}) {
    screens.push(state.selected.screen)
  }
  state.selected.screen = {}
}

export const removeScreen = ({ state, dispatch }) => {
  let screens = state.selected.locationColorway.screens
  let index = screens.findIndex(el => {
    return state.selected.screen._id === el._id
  })
  screens.splice(index, 1)
  // update the current location
  dispatch('updateColorways')
  dispatch('updateLocation')
}

export const updateLasers = ({ state }) => {
  let lasers = state.selected.locationColorway.production.lasers
  let indexLasers = lasers.findIndex(el => {
    return state.selected.laser._id === el._id
  })
  if (indexLasers !== -1) {
    lasers.splice(indexLasers, 1, state.selected.laser)
  } else if (state.selected.laser !== {}) {
    lasers.push(state.selected.laser)
  }
  state.selected.laser = {}
}

export const removeLaser = ({ state, dispatch }) => {
  let lasers = state.selected.locationColorway.production.lasers
  let index = lasers.findIndex(el => {
    return state.selected.laser._id === el._id
  })
  lasers.splice(index, 1)
  // update the current location
  dispatch('updateColorways')
  dispatch('updateLocation')
}

export const updateSizeways = ({ state }) => {
  let sizeways = state.selected.location.sizeways
  let indexSizeways = sizeways.findIndex(el => {
    return state.selected.sizeway._id === el.sizeway._id
  })
  if (indexSizeways !== -1) {
    sizeways.splice(indexSizeways, 1, state.selected.locationSizeway)
  } else {
    sizeways.push(state.selected.locationSizeway)
  }
}

export const updateColorways = ({ state }) => {
  let colorways = state.selected.location.colorways
  let indexColorways = colorways.findIndex(el => {
    return state.selected.colorway._id === el.colorway._id
  })
  if (indexColorways !== -1) {
    colorways.splice(indexColorways, 1, state.selected.locationColorway)
  } else {
    colorways.push(state.selected.locationColorway)
  }
}

// MOCKUPS

export const updateMockups = ({ state }) => {
  let mockups = state.item.mockups
  let index = mockups.findIndex(el => {
    return state.selected.mockup._id === el._id
  })
  if (index !== -1) {
    mockups.splice(index, 1, state.selected.mockup)
  } else if (state.selected.mockup !== {}) {
    mockups.push(state.selected.mockup)
  }
  state.selected.mockup = new MockupPage()
}

export const removeMockup = ({ state }) => {
  let mockups = state.item.mockups
  let index = mockups.findIndex(el => {
    return state.selected.mockup._id === el._id
  })
  mockups.splice(index, 1)
}

export const pricingInfo = ({ state, dispatch, rootState }) => {
  let decorationCodes = []
  let quantitiesPerHour = []
  let addOnAmount = 0
  const numberOfLocations = state.item.locations.length

  // update the design locations
  const decorations = rootState.decorations.items
  const newLocations = state.item.locations.map(item => {
    const update = decorations.find(dec => dec._id === item.decoration._id)
    if (update) { item.decoration = update }
    return item
  })
  state.item.locations = newLocations
  dispatch('_SAVE')

  newLocations.forEach(location => {
    // get the codes for all locations
    decorationCodes = decorationCodes.concat(location.decoration.codes)
    // get the average quantity per hour
    quantitiesPerHour = quantitiesPerHour.concat(location.decoration.quantityPerHour)
    // get the addOn amount
    if (location.addOns.length) {
      location.addOns.forEach(ao => { addOnAmount += ao.addOn.cost * ao.quantity })
    }
  })

  // determine average quantity per hour
  let sum = 0
  for (let i = 0; i < quantitiesPerHour.length; i++) {
    sum += parseInt(quantitiesPerHour[i], 10)
  }
  const quantityPerHour = sum / quantitiesPerHour.length
  console.log('# per hour', quantityPerHour)

  // return results to job
  const payload = { decorationCodes, quantityPerHour, addOnAmount, numberOfLocations }
  return payload
}
