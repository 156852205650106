import { Order as Model, Job, Design, ProductionInstance } from '@/models'
// import orderStates from '../helpers/order-states'
import axios from 'axios'

export default {
    async _ADD({ commit, dispatch, state, rootState }) {
        dispatch('setup')
        // get the updated admin record
        await dispatch('admin/_LOAD', null, { root: true })
        // get the first order state
        const status = { name: 'estimate' }
        // find the current user
        const owner = rootState.users.currentUser
        // make a new order
        let newOrder = new Model({
            orderNumber: rootState.admin.item.orderNumber,
            status,
            owner,
            company: { _id: rootState.companies.item._id },
            companyName: rootState.companies.item.name
        })
        const post = await dispatch('postOne', { data: newOrder })
        // update the admin record for next order number and save
        commit('admin/orderNumber', rootState.admin.item.orderNumber + 1, {
            root: true
        })
        dispatch(
            'admin/updateOne',
            { data: rootState.admin.item },
            { root: true }
        )
        //
        dispatch('cleanup')
        return post
    },

    async _SAVE({ commit, dispatch, state, rootState }) {
        //  save jobs
        const data = rootState.jobs.items
        if (data.length) {
            dispatch('jobs/updateMany', { data }, { root: true })
        }
        // save the order
        dispatch('updateOne', { data: state.item })

        commit(
            'companies/itemsUpdate',
            {
                list: 'lists.orders',
                item: state.item
            },
            { root: true }
        )

        commit('itemsUpdate', {
            list: 'items',
            item: state.item
        })
    },

    async _UPDATE({ commit, dispatch, state }, update) {
        const data = { ...update, _id: state.item._id }
        const patch = await dispatch('updateOne', { data })
        console.log('order update', patch)

        commit(
            'companies/itemsUpdate',
            {
                list: 'lists.orders',
                item: state.item
            },
            { root: true }
        )

        commit('itemsUpdate', {
            list: 'items',
            item: state.item
        })
    },

    async _READ({ dispatch, commit, state, rootState }, _id) {
        dispatch('setup')
        // get current order
        const order = await dispatch('getOne', _id)

        // setup any necessary API calls
        const apiCalls = []
        // load related jobs
        apiCalls.push(dispatch('_LOAD_RELATED_JOBS', _id))
        // load company?
        // using the raw api call to avoid loading the company's design and orders lists
        apiCalls.push(
            dispatch('companies/getOne', order.company, { root: true })
        )

        // go fetch what is needed
        const calls = await Promise.all(apiCalls)

        // commit the jobs list
        commit('jobs/items', calls[0], { root: true })
        // commit the company
        commit('companies/item', calls[1], { root: true })
        // order.company = calls[1]

        commit('item', order)
        // sum job costs
        await dispatch('sumJobCosts')

        commit('tabs', 0)
        dispatch('cleanup')
    },

    async _READ_ALL({ dispatch, commit, state, rootState }, _id) {
        dispatch('setup')
        const orders = await dispatch('getCollection')
        commit('items', orders)
        dispatch('cleanup')
    },

    async _READ_CONFIRMED({ dispatch, commit, state, rootState }, _id) {
        dispatch('setup')
        const results = await axios.get('/orders/report')
        console.log(results.data)
        commit('items', results.data)
        dispatch('cleanup')
    },

    async _LOAD_RELATED_JOBS({ dispatch, commit, state, rootState }, _id) {
        let jobs = await dispatch(
            'jobs/getCollectionFiltered',
            { endpoint: 'order', _id },
            { root: true }
        )
        // assign models
        jobs = jobs.map(job => {
            job.design = new Design(job.design)
            return job
        })
        console.log({ jobs })
        return jobs
    },

    async _REMOVE({ dispatch, commit, rootState, state }) {
        const _id = state.item._id
        dispatch('deleteOne', { _id })
        // remove the item from local state
        commit('itemsRemove', { list: 'items', item: state.item })
        commit('item', new Model())

        // remove pi's
        const jobs = rootState.jobs.items
        jobs.forEach(job => {
            dispatch(
                'productionInstances/deleteMany',
                { endpoint: 'job', _id: job._id },
                { root: true }
            )
        })
        commit('productionInstances/items', [], { root: true })
        commit('productionInstances/item', new ProductionInstance(), {
            root: true
        })

        // remove jobs
        dispatch('jobs/deleteMany', { endpoint: 'order', _id }, { root: true })
        commit('jobs/items', [], { root: true })
        commit('jobs/item', new Job(), { root: true })
    }
}
