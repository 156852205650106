import commonActions from '@/store/common/actions'
import commonState from '@/store/common/state'
import commonGetters from '@/store/common/getters'
import commonMutations from '@/store/common/mutations'
import init from '@/store/init'
import { SubproductName as Model } from '@/models'

export default {
  namespaced: true,
  state: {
    path: 'subproduct-names',
    ...commonState(Model)
  },
  getters: {
    ...commonGetters()
  },
  mutations: {
    ...commonMutations()
  },
  actions: {
    ...commonActions(Model),
    ...init
  }
}
