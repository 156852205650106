<template lang="pug">
v-layout.body-1.font-weight-bold.pink--text(row px-4 pt-3)
  v-flex(xs1)
  v-flex(xs3)
  v-flex.text-xs-right(xs1)
  v-flex.text-xs-center.pl-3(xs3) {{quantity}}
  v-flex.text-xs-right(xs2)
  v-flex.text-xs-right(xs2) {{ subtotal | currency }}
</template>

<script>
export default {
  computed: {
    productGroup () { return this.$store.getters['productGroups/item'] },
    subtotal () { return this.productGroup.subtotal },
    quantity () { return this.productGroup.quantity },
  }
}
</script>
