<template lang="pug">
div
  h3.title.my-3 company
  GoToCompany
  v-btn(
    @click="contactsDetail = true"
    dark
    color="pink accent-1"
  ) manage contacts

  ContactsDetail(
    :detail="contactsDetail"
    @cancel="cancel"
  )
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import ContactsDetail from './contacts-detail'
import GoToCompany from './go-to-company'

export default {
  components: { ContactsDetail, GoToCompany },
  computed: {
    ...mapFields('designs', {
      item: 'item',
      actions: 'actions'
    })
  },
  data: () => ({
    addressDetail: false,
    contactsDetail: false,
  }),
  methods: {
    cancel () {
      this.addressDetail = false
      this.contactsDetail = false
      this.actions = false
    }
  }
}
</script>

<style>

</style>
