<template lang="pug">
v-container(grid-list-lg)
  v-layout(row)
    v-flex
      FwdCard.mt-3
        template(slot="title") job progress
        template(slot="content")
          JobProgressBar(:progress="job.progress")
  v-layout(row)
    v-flex(xs6)
      FwdCard
        template(slot="title") preflight
        template(slot="content")
          v-checkbox(
            label="are the products ok to print with this design?"
            v-model="progress.isProductOk"
            @change="save"
            color="pink"
          )
          v-checkbox(
            label="is the art filed?"
            v-model="progress.isArtFiled"
            @change="save"
            color="pink"
          )
          v-checkbox(
            label="have design details been left for the art department?"
            @change="save"
            v-model="progress.isDesignDetails"
            color="pink"
          )
          template(v-if='isfangamer')
            v-checkbox(
              label="has this passed Fangamer's printcheck?"
              @change="save"
              v-model="progress.isfangamerprintcheck"
              color="pink"
            )
            v-btn(
              v-if='!progress.isfangamerprintcheck'
              @click='notifymissingprintcheck'
            ) notify spekkio about missing Print Check
            v-checkbox(
              v-if='ispreflightok'
              label="has this passed the preflight check?"
              @change="save"
              v-model="progress.isPreflighted"
              color="pink"
            )
          v-checkbox(
            v-if='!isfangamer'
            label="has this passed the preflight check?"
            @change="save"
            v-model="progress.isPreflighted"
            color="pink"
          )
      FwdCard
        template(slot="title") mockups and art
        template(slot="content")
          v-checkbox(
            label="has the mockup been sent?"
            @change="save"
            v-model="progress.isMockSent"
            color="pink"
          )
          v-checkbox(
            label="is the mockup approved?"
            @change="save"
            v-model="progress.isMockApproved"
            color="pink"
          )
    v-flex(xs6)
      FwdCard
        template(slot="title") products
        template(slot="content")
          v-checkbox(
            label="have products been ordered?"
            @change="save"
            v-model="progress.isProductsOrdered"
            color="pink"
          )
          v-checkbox(
            label="have products been received?"
            @change="save"
            v-model="progress.isProductsReceived"
            color="pink"
          )
      FwdCard
        template(slot="title") completion
        template(slot="content")
          v-checkbox(
            label="has job been completed?"
            @change="save"
            v-model="progress.isCompleted"
            color="pink"
          )

</template>

<script>
import { mapFields } from 'vuex-map-fields'
import JobProgressBar from '@/components/JobProgressBar'
import assets from '@/assets/constants'

export default {
  components: { JobProgressBar },
  computed: {
    ...mapFields('jobs', {
      job: 'item',
      progress: 'item.progress'
    }),
    ...mapFields('companies', {
      company: 'item'
    }),
    isfangamer () { return this.company._id === assets.fangamerid },
    
    ispreflightok () {
      return this.progress.isfangamerprintcheck
    }
  },
  methods: {
    async save () {
      await this.$store.dispatch('jobs/_SAVE')
      // this.$store.dispatch('productionInstances/updateJobProgress', { progress: this.progress, job_id: this.job._id})
    },
    async notifymissingprintcheck() {
      await this.$store.dispatch('jobs/notifymissingprintcheck')

    }
  }
}
</script>
