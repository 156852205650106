<template lang="pug">
v-container
  v-layout(wrap row)
    v-flex(
      xs12
      lg6
      v-for="color in itemColors"
      :key="color._id"
    )
      v-layout(row).align-center
        v-avatar(v-if="color.image.url" size=20 tile)
          img(:src="color.image.url")
        v-flex {{color.name}}
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import sortItems from './list-sort'

export default {
  computed: {
    itemColors () {
      const item = this.$store.getters['products/item']
      return sortItems(item.subproducts)
    }
  }
}
</script>
