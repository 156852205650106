<template lang="pug">
v-container(grid-list-xl fluid px-3 py-0)
  v-layout(row wrap)
    v-flex(xs4)
      v-container
        Details
        Dates.pt-3
        CostSummary.pt-3
    v-flex(xs8)
      Jobs

</template>

<script>
import CompanySelect from './company'
import Details from './details'
import Dates from './dates'
import CostSummary from '../order-cost-summary'
import Jobs from '../jobs'

export default {
  components: {
    CompanySelect,
    Details,
    Dates,
    CostSummary,
    Jobs
  }
}
</script>
