<template lang="pug">
div
  v-toolbar.transparent(flat dense)
    v-toolbar-title.font-weight-black.title.pink--text
      slot(name="title")
    v-spacer
    v-btn.ml-5.pink.accent-1(icon @click="add" dark large v-if="addToggle")
      v-icon add
    v-layout(row slot="extension")
      v-flex(xs6 v-if='filterToggle').mr-5
        ListFilter(
          :label="filterLabel"
          :items="filterItems"
          :filterStartValue="filter"
          @filter="emitFilter"
        )
      v-flex(xs6)
        ListSearch(
          :label="searchLabel"
          @search="emitSearch"
        )
  v-list.transparent(subheader)
    v-list-tile.list-item.pa-2(
      v-for='item in items'
      :key='item._id'
      @click="select(item)"
    )
      v-list-tile-avatar(v-if="avatar" tile)
        img(:src="item.image.url")
      v-list-tile-content
        v-list-tile-title
          slot(:item="item")
</template>
<script>
import ListFilter from './list-filter'
import ListSearch from './list-search'
export default {
  components: {
    ListFilter,
    ListSearch
  },
  props: {
    items: { type: Array },
    filter: { type: Object },
    filterToggle: { type: Boolean, default: true },
    filterLabel: { type: String },
    filterItems: { type: Array },
    searchLabel: { type: String },
    addToggle: { type: Boolean, default: true },
    avatar: { type: Boolean, default: false }
  },
  computed: {
    baseURL () { return this.axios.defaults.baseURL + '/img' }
  },
  methods: {
    select (item) { this.$emit('select', item) },
    add () { this.$emit('add') },
    emitFilter (filter) {
      this.$emit('filter', filter)
    },
    emitSearch (search) {
      this.$emit('search', search)
    }
  }
}
</script>
