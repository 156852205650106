import { Promise } from 'q'
import { getField, updateField } from 'vuex-map-fields'
import router from '@/router'

export default {
  namespaced: true,
  state: {
    loading: false,
    isInit: false,
    noInit: false,
    initialized: [],
    initCollections: [
      // admin
      // { module: 'users', name: 'users' },
      // pricing
      { module: 'tiers', name: 'tiers' },
      { module: 'decorations', name: 'decorations' },
      { module: 'decorationCategories', name: 'decoration categories' },
      { module: 'decorationCodes', name: 'decoration codes' },
      // products
      { module: 'brands', name: 'brands' },
      { module: 'sizeCategories', name: 'size categories' },
      { module: 'subproductNames', name: 'subproduct names' },
      { module: 'sizes', name: 'sizes' },
      { module: 'productTags', name: 'product tags' },
      { module: 'products', name: 'products' },
      // {module: 'productColors', name: 'product colors'},
      // companies
      { module: 'companies', name: 'companies' },
      // designs
      { module: 'designLocationTemplates', name: 'design location templates' },
      { module: 'screenSizes', name: 'screen sizes' },
      { module: 'inkTypes', name: 'ink types' },
      { module: 'inkColors', name: 'ink colors' },
      { module: 'designLocationAddOns', name: 'design loc add ons' },
      // orders
      { module: 'presses', name: 'presses' },
      { module: 'productionInstanceTypes', name: 'production instance types' },
      // purchasing
      { module: 'vendors', name: 'vendors' }
    ]
  },
  getters: { getField },
  mutations: {
    updateField,
    loading (state, payload) {
      state.loading = payload
    }
  },
  actions: {
    async init ({ dispatch, commit, state }) {
      state.isInit = true
      state.loading = true
      // admin handled separately
      await dispatch('admin/init', null, { root: true })
      // handle all standard collections
      const all = []
      state.initCollections.forEach((collection) => {
        all.push(dispatch('initCollection', collection))
      })
      // update decorations
      const complete = await Promise.all(all)
      await dispatch('decorations/updateAllCodes', null, { root: true })

      // load users
      await dispatch('users/readall', null, { root: true })
      // // load current user if logged in
      // const currentUser = JSON.parse(localStorage.getItem('user'))
      // if (currentUser) {
      //   await dispatch('users/readone', currentUser._id, {
      //     root: true
      //   })
      // }

      state.loading = false
      // state.isInit = false
      state.initialized = []
      return complete
    },
    async initCollection ({ state, dispatch, commit }, collection) {
      const loaded = await dispatch(
        collection.module + '/getCollection',
        null,
        { root: true }
      )
      commit(collection.module + '/items', loaded, { root: true })
      state.initialized.push(collection.name + ' LOADED!')
    },
    async noInit ({ state, dispatch, commit }) {
      state.noInit = true
    }
  }
}
