<template lang="pug">
div
  v-layout(row)
    v-flex
      v-btn.white--text(
        color="pink accent-1"
        @click="detail = true"
      ) open UPS transit time map
  v-dialog(max-width=600 v-model="detail")
    v-card(flat)
      v-img(width="100%" :src="require('@/assets/orders/map_0620_OCT19.png')")

</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  computed: {
    ...mapFields('orders', {
      item: 'item',
      actions: 'actions'
    })
  },
  data: () => ({ detail: false }),
  
}
</script>
