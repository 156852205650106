<template lang="pug">
v-container
  ListCollectionMockupPages(
    :items="itemsSorted"
    @select="select"
  )
  DetailSimple(
    :detail="detail"
    @cancel="cancel"
  )
</template>

<script>
import { MockupPage } from '@/models'
import { mapFields } from 'vuex-map-fields'
import ListCollectionMockupPages from './mockup-pages'
import DetailSimple from './detail'

export default {
  components: { ListCollectionMockupPages, DetailSimple },
  data: () => ({
    detail: false
  }),
  computed: {
    ...mapFields('designs', {
      items: 'item.mockups',
      selectedMockup: 'selected.mockup',
      // controls
    }),
    itemsSorted () {
      return this.sortByPropNumber(this.items, 'sortOrder')
    }
  },
  methods: {
    select (item) {
      this.selectedMockup = new MockupPage(item)
      this.detail = true
    },
    cancel () { this.detail = false }

  }
}
</script>
