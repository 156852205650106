/** Model definition file for the Job Class **/
import { Pricing, Design, ProductGroup, JobProgress } from '@/models'

export default class Job {
  constructor ({
    _id = '',
    order = '',
    design = '',
    subtitle = '',
    progress = {},
    jobNumber = null,
    productGroups = [],
    subtotal = 0,
    subtotalFinal = 0,
    quantity = 0,
    quantityOrdered = 0,
    quantityReceived = 0,
    quantityFinal = 0,
    quantityOverride = '',
    pricing = {},
    removeProductCost = false,
    quantityBreakGroup = null,
    numberOfBoxes = 0,
    isNeedsRevision = false,
    isPressCheck = false,
    spekkio = {
      mockupcomment: null
    },
  } = {}) {
    this._id = _id
    this.order = order
    this.design = design
    this.subtitle = subtitle
    if (productGroups.length) {
      this.productGroups = productGroups.map(productGroup => {
        return new ProductGroup(productGroup)
      })
    } else {
      this.productGroups = []
    }
    this.progress = new JobProgress(progress)
    this.jobNumber = jobNumber
    this.subtotal = subtotal
    this.subtotalFinal = subtotalFinal
    this.quantity = quantity
    this.quantityOrdered = quantityOrdered
    this.quantityReceived = quantityReceived
    this.quantityFinal = quantityFinal
    this.quantityOverride = quantityOverride
    this.pricing = new Pricing(pricing)
    this.removeProductCost = removeProductCost
    this.quantityBreakGroup = quantityBreakGroup
    this.numberOfBoxes = numberOfBoxes
    this.isNeedsRevision = isNeedsRevision
    this.isPressCheck = isPressCheck
    this.spekkio = spekkio
  }

  get image () {
    return this.design.image
  }

  get displayName () {
    let result = ''
    result += this.design.name
    if (this.subtitle) {
      result += ' - ' + this.subtitle
    }
    result += ' - #' + this.jobNumber
    if (this.quantityBreakGroup) {
      result += ' - quantity group' + this.quantityBreakGroup
    }
    return result
  }

  get posOnThisJob () {
    const pos = []
    this.productGroups.forEach(productGroup => {
      productGroup.lineItems.forEach(line => {
        const instance = {
          po_id: line.po_id,
          poNumber: line.poNumber
        }
        const find = pos.find(x => x.po_id === instance.po_id)
        if (find === undefined && instance.po_id) { pos.push(instance) }
      })
    })
    return pos
  }


}
