/** Model definition file for the User Class **/

export default class User {
  constructor ({
    _id = '',
    username = '',
    firstName = '',
    lastName = '',
    email = '',
    role = '',
    token = ''
  } = {}) {
    this._id = _id
    this.username = username
    this.firstName = firstName
    this.lastName = lastName
    this.email = email
    this.role = role
    this.token = token
  }

  get displayName () {
    return this.lastName + ', ' + this.firstName
  }
}
