/** Model definition file for the Sizeway Class **/
import uuid from 'uuid/v4'
import Models from '@/models'

export default class Sizeway {
  constructor ({
    _id = uuid(),
    name = '',
    isDefaultRecord = false,
    screenSize = null,
    filmTemplate = null,
    productSizes = ""
  } = {}) {
    this._id = _id
    this.name = name
    this.isDefaultRecord = isDefaultRecord
    this.screenSize = screenSize
    this.productSizes = productSizes
    this.filmTemplate = filmTemplate
  }
}
