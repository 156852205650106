<template lang="pug">
v-card(color='grey lighten-3' v-if="ripFile.url")
  v-card-title.title.font-weight-bold {{ ripFile.name }}
  v-card-text 
    div notes: 
      span.font-weight-bold {{ ripFile.notes }}
    div date uploaded: 
      span.font-weight-bold {{ formatDate(ripFile.dateCreated) }}
  v-card-actions
    v-btn.green.lighten-3(:href='ripFile.url' download) download file
              
  
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import moment from 'moment'

export default {
  computed: {
    ...mapFields('productionInstances', {
      item: 'item',
      ripFile: 'item.ripFile',
    }),

  },
  methods: {
    formatDate(date) { return moment(date).format('L') }
  }
}
</script>
