<template lang="pug">
v-container
  FwdHeadingLarge products
  CList
</template>

<script>
import CList from '../components/list'

export default {
  components: { CList },
}
</script>
