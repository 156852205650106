/** Model definition file for the LogItem Class **/
import uuid from 'uuid/v4'
import { User } from '@/models'

export default class LogItem {
  constructor ({
    _id = uuid(),
    name = '',
    text = '',
    time = Date.now(),
    user = {}
  } = {}) {
    this._id = _id
    this.name = name
    this.text = text
    this.time = time
    this.user = new User(user)
  }
}
