<template lang="pug">
v-list.text-uppercase(dense subheader)
  v-list-tile(
    v-for="size in sizesAvailable"
    :key="size._id"
    @click="addToSizes(size)"
    :class="{ hilitebright: highlightClass(size)}"
  )
    v-list-tile-content
      v-list-tile-title
        | {{size.name}}
  
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  computed: {
    ...mapFields('products', {
      subproductSizes: 'subproduct.item.sizes',
      sizesAvailable: 'item.sizesavail'
    })
  },
  methods: {
    addToSizes (size) {
      let index = this.subproductSizes.findIndex(s => {
        return s._id === size._id
      })
      if (index >= 0) {
        // remove it
        this.subproductSizes.splice(index, 1)
      } else {
        // add it
        this.subproductSizes.push(size)
      }
      this.update()
    },
    
    highlightClass (size) {
      return this.subproductSizes.find(s => {
        return s._id === size._id
      })
    },

    async update () {
      await this.$store.dispatch('products/updateSubproducts')
      this.$store.dispatch('products/_UPDATE')
    }

  }
}
</script>
<style lang="stylus">
.hilitebright
  background-color #F8BBD0
  color black
  border-bottom solid 1px white
</style>
