/** Model definition file for the ProductColor Class **/
import { Brand, Image } from '@/models'

export default class ProductColor {
  constructor ({
    _id = '',
    name = '',
    brand = {},
    image = {}
  } = {}) {
    this._id = _id
    this.name = name
    this.brand = new Brand(brand)
    this.image = new Image(image)
  }
}
