<template lang="pug">
div
  div
    v-layout(row)
      v-flex.font-weight-bold(xs8) sales tax
      v-flex(xs4).text-xs-right {{costs.tax | currency}}

    v-layout(row v-if="costs.taxOverride")
      v-flex.font-weight-bold(xs8) sales tax override
      v-flex.pink--text(xs4).text-xs-right {{costs.taxOverride | currency}}

    v-layout(row v-if='order.isStatusFinal')
      v-flex.font-weight-bold(xs8) sales tax (final)
      v-flex(xs4 v-if="!costs.taxOverride").text-xs-right {{costs.taxFinal | currency}}
      v-flex.pink--text(xs4 v-else).text-xs-right {{costs.taxOverride | currency}}

  v-divider
</template>

<script>
export default {
  props: {
    order: { required: true, type: Object },
    costs: { required: true, type: Object }
  }
}
</script>
