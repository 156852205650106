/** Model definition file for the OtherCharge Class **/
import uuid from 'uuid/v4'

export default class OtherCharge {
  constructor ({
    _id = uuid(),
    name = '',
    quantity = 0,
    cost = 0
  } = {}) {
    this._id = _id
    this.name = name
    this.quantity = quantity
    this.cost = cost
  }

  get costExtend () {
    return this.cost * this.quantity
  }
}
