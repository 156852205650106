<template lang="pug">
v-layout(row)
  v-flex
    v-btn.white--text(
      dark
      color="pink accent-1"
      @click="addProductGroup"
    ) add product
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  computed: {
    ...mapFields('jobs', {
      item: 'item',
      actions: 'actions',
      pickProduct: 'input.pickProduct'
    }),
    ...mapFields('orders', {
      // controls
      editState: 'editState'
    })
  },
  methods: {
    async addProductGroup() {
      this.$store.dispatch('productGroups/_ADD')
      this.actions = false
    }
  }
}
</script>

<style>

</style>
