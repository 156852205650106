<template lang="pug">
  div
    v-text-field(
      v-model="dropDeadDisplay"
      label="drop dead date (from order)"
      readonly
    )
    v-dialog(
      ref="dialogDropDeadOverride"
      v-model="modalDropDeadOverride"
      :return-value.sync="dates.dropDeadOverride"
      persistent
      lazy
      full-width
      width="290px"
    )
      template(v-slot:activator="{ on }")
        v-text-field(
          v-model="dropDeadOverrideDisplay"
          label="drop dead date override"
          prepend-inner-icon="event"
          readonly
          v-on="on"
          outlined
        )
      v-date-picker(v-model="dates.dropDeadOverride" scrollable v-if="")
        v-spacer
        v-btn(flat color="primary" @click="modalDropDeadOverride = false") Cancel
        v-btn(flat color="primary" @click="datesDropDeadOverride") OK
    v-dialog(
      ref="dialogSchedule"
      v-model="modalSchedule"
      :return-value.sync="dates.schedule"
      persistent
      lazy
      full-width
      width="290px"
    )
      template(v-slot:activator="{ on }")
        v-text-field(
          v-model="ScheduleDisplay"
          label="schedule date"
          prepend-inner-icon="event"
          readonly
          v-on="on"
          outlined
        )
      v-date-picker(v-model="dates.schedule" scrollable)
        v-spacer
        v-btn(flat color="primary" @click="modalSchedule = false") Cancel
        v-btn(flat color="primary" @click="datesScheduleCommit") OK
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import moment from 'moment'

export default {
  data: () => ({
    modalDropDead: false,
    modalDropDeadOverride: false,
    modalSchedule: false
  }),
  computed: {
    ...mapFields('productionInstances', {
      dates: 'item.dates'
    }),
    dropDeadDisplay () {
      const date = this.dates.dropDead
      return date ? moment(date).format('LL') : ''
    },
    dropDeadOverrideDisplay () {
      const date = this.dates.dropDeadOverride
      return date ? moment(date).format('LL') : ''
    },
    ScheduleDisplay () {
      const date = this.dates.schedule
      return date ? moment(date).format('LL') : ''
    }
  },
  methods: {
    datesScheduleCommit () {
      this.$refs.dialogSchedule.save(this.dates.schedule)
      this.save()
    },
    datesDropDeadOverride () {
      this.$refs.dialogDropDeadOverride.save(this.dates.dropDeadOverride)
      this.save()
    },
    save () { this.$store.dispatch('productionInstances/_UPDATE') }
  }
}
</script>
