export default {

  async updateAll ({ commit, dispatch, state, rootState }) {
    // const quantity = rootState.jobs.item.quantityOverride ? rootState.jobs.item.quantityOverride : rootState.jobs.item.quantity
    // set job quantities
    dispatch('loopAndUpdateJobQuantities')

    // update job costs
    const pricing = await dispatch('jobs/updateJobPricing', null, { root: true })

    // loop through product groups and update them as well as job
    await dispatch('loopAndUpdateProductGroups', pricing)

    // update order and jobs
    commit('jobs/itemsUpdate', { list: 'items', item: rootState.jobs.item }, { root: true })
    dispatch('jobs/_SAVE', null, { root: true })
    dispatch('orders/sumJobCosts', null, { root: true })
  },

  // ///////////////////
  // ///////////////////
  // ///////////////////

  async loopAndUpdateProductGroups ({ state, commit, dispatch, rootState }, pricing) {
    const { jobCosts, tier } = pricing
    let subtotalJob = 0
    let subtotalJobFinal = 0

    // loop through product groups
    const productGroups = state.items.map(productGroup => {
      // loop thru line items
      let subtotal = 0
      let subtotalFinal = 0
      let quantityPG = 0
      let quantityPGOrdered = 0
      let quantityPGReceived = 0
      let quantityPGFinal = 0
      let lineItems = productGroup.lineItems.map(lineItem => {
        // update quantities
        quantityPG += lineItem.quantity
        quantityPGOrdered += lineItem.quantityOrdered
        quantityPGReceived += lineItem.quantityReceived
        quantityPGFinal += lineItem.quantityFinal

        // determine product cost
        let costProduct = lineItem.costProduct * (tier.markup / 100 + 1)
        if (rootState.jobs.item.removeProductCost) {
          costProduct = 0
        }
        // determine cost for line
        const lineCost = jobCosts.decorationCost + costProduct
        lineItem.cost = Number(Math.round(parseFloat(lineCost + 'e' + 2)) + 'e-' + 2)

        // update subtotals
        subtotal += lineItem.costExtend
        subtotalFinal += lineItem.costExtendFinal
        return lineItem
      })
      productGroup.lineItems = lineItems
      productGroup.subtotal = subtotal
      productGroup.subtotalFinal = subtotalFinal
      subtotalJob += subtotal
      subtotalJobFinal += subtotalFinal
      productGroup.quantity = quantityPG
      productGroup.quantityOrdered = quantityPGOrdered
      productGroup.quantityReceived = quantityPGReceived
      productGroup.quantityFinal = quantityPGFinal

      // TODO: handle job subtotals and job quantities also.  make it more elegant
      return productGroup
    })
    // save updated items to state
    const updatedItem = productGroups.find(pg => pg._id === state.item._id)
    commit('item', updatedItem)
    commit('items', productGroups)

    // update job subtotal

    dispatch('jobs/subtotal', {
      subtotal: subtotalJob,
      subtotalFinal: subtotalJobFinal
    }, { root: true })

    // update all product groups
    dispatch('_UPDATE_MANY')
  },

  async loopAndUpdateJobQuantities ({ state, dispatch }) {
    let quantityJob = 0
    let quantityJobOrdered = 0
    let quantityJobReceived = 0
    let quantityJobFinal = 0

    // loop through product groups
    state.items.forEach(productGroup => {
      // loop thru line items
      productGroup.lineItems.forEach(lineItem => {
        // update quantities
        quantityJob += lineItem.quantity
        quantityJobOrdered += lineItem.quantityOrdered
        quantityJobReceived += lineItem.quantityReceived
        quantityJobFinal += lineItem.quantityFinal
      })
    })

    // update job
    dispatch('jobs/quantity', {
      quantity: quantityJob,
      quantityOrdered: quantityJobOrdered,
      quantityReceived: quantityJobReceived,
      quantityFinal: quantityJobFinal
    }, { root: true })
  }

}
