<template lang="pug">
div
  v-container
    v-layout(align-center justify-center row fill-height)
      div
        v-btn(@click='login' color='info' large) login with gmail
        div(v-if="item.fail" class="red accent-3 pa-2").mt-3 {{item.message}}
  v-container
    v-layout(align-center justify-center row fill-height)
      div
        p any issues, contact dan:
        p
          a(href='danc@forwardprinting.com') danc@forwardprinting.com
        p logged in? {{ isloggedin }}

</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  computed: {
    ...mapFields('users', ['item', 'isloggedin'])
  },
  methods: {
    async login () {
      const authcode = await this.$gAuth.getAuthCode()
      await this.$store.dispatch('users/login', authcode)
      if (this.isloggedin) {
        this.$router.push('/')
      }
    }
  }
}
</script>
