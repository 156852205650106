<template lang="pug">
v-layout.body-1.font-weight-bold.pink--text(row px-4 py-1 pt-3)
  v-flex(xs2)
  v-flex(xs2)
  v-flex.text-xs-right(xs1)
  v-flex.text-xs-center(xs6)
    v-layout(row).pl-4
      v-flex(4) {{quantity}}
      v-flex(4) {{quantityOrdered}}
      v-flex(4) 

  v-flex.text-xs-right(xs2)
</template>
<script>
export default {
  props: ['subtotal', 'quantity', 'quantityOrdered']
}
</script>
