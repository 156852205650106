export default [
  { title: 'Designs', icon: 'image', route: { activate: 'designs' }, top: true , role: ['Admin', 'User']},
  {
    title: 'ink color list',
    icon: 'list',
    route: { path: '/ink-colors' },
    module: 'designs',
    role: ['Admin', 'User']
  },
  
]