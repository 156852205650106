<template lang="pug">
v-layout(row)
  v-flex
    v-btn(
      :disabled="!allowDelete"
      @click="deletePurchaseOrder"
      color="pink accent-1"
    ).white--text delete PurchaseOrder
  v-flex
    v-checkbox(
      label="allow delete?"
      v-model="allowDelete"
      color="pink accent-1"
    )
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  computed: {
    ...mapFields('purchaseOrders', {
      item: 'item',
      actions: 'actions'
    })
  },
  data: () => ({ allowDelete: false }),
  methods: {
    async deletePurchaseOrder() {
      await this.$store.dispatch('purchaseOrders/_DELETE')
      this.actions = false
      this.$router.go(-1)
    }
  }
}
</script>

<style>

</style>
