<template lang="pug">
div
  FwdDetailPage(v-if="loaded")
    template(slot="title-prefix") order: 
    template(slot="title") {{ item.displayName }}
    template(slot="controls")
      Controls
    template(slot="form")
      Shipments
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import Shipments from '../../components/shipments-fangamer'
import Controls from './controls'

export default {
  components: {
    Controls,
    Shipments
  },
  computed: {
    ...mapFields('orders', {
      item: 'item',
      tabs: 'tabs',
      loaded: 'loaded',
      editState: 'editState'
    })
  },
  async created () {
    this.$store.dispatch('orders/_READ', this.$route.params.id)
  }
}
</script>
