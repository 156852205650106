/** Model definition file for the DesignLocationSizeway Class **/
import uuid from 'uuid/v4'
import { Sizeway, Placement } from '@/models'

export default class DesignLocationSizeway {
  constructor ({
    sizeway = {},
    dimensions = {
      width: null,
      height: null
    },
    placements = [],
  } = {}) {
    this.sizeway = new Sizeway(sizeway)
    this.dimensions = dimensions
    this.placements = placements.map(placement => {
      return new Placement(placement)
    })
  }

}
