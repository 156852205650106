export default {
  async getNextPurchaseOrderNumber ({ dispatch, rootState }) {
    // get next PurchaseOrder number
    await dispatch('admin/_LOAD', null, { root: true })
    const number = rootState.admin.item.poNumber

    // update the admin record for next po number
    rootState.admin.item.poNumber += 1
    dispatch('admin/_UPDATE', null, { root: true })

    return number
  },


  async processLinesForPurchaseOrder ({ state }, lines) {
    const result = lines.map(line => {
      line.PONumber = state.item.number,
      line.PO_id = state.item._id
      return line
    })
    return result
  },

  async loadRelatedJobs ({ state, dispatch }) {
    const _ids = state.item.jobsOnThisPo.map(x => x.job_id)
    await dispatch('loadJobsByIds', _ids)
  },

  async loadJobsByIds ({ commit, dispatch }, _ids) {
    const jobs =  await dispatch('jobs/getByManyIds', _ids, { root: true })
    // loop through jobs to add product groups
    const fetches = []
    jobs.forEach(job => { fetches.push(dispatch('productGroups/_READ_JOB', job._id, { root: true })) })
    const fetchResults = await Promise.all(fetches)
    const jobsHydrated = jobs.map((job, index) => {
      job.productGroups = fetchResults[index]
      return job
    })
    console.log('hydrated jobs:', jobsHydrated)
    // update state
    commit('jobs/items', jobsHydrated, { root: true })
    commit('jobs/item', jobsHydrated[0], { root: true })
  },


  

  
}
