<template lang="pug">
v-container(
  grid-list-lg
)

  v-layout(column)
    h2(:class="classH2") ink ticket: {{design.displayName}}
    v-flex
      h3(:class="classH3") print locations.
      PrintLocations.mx-4
    v-flex(v-if="design.notes.ink")
      h3(:class="classH3") notes.
      Notes

</template>

<script>
import FwdMockups from './mockups'
import Approval from './approval-production'
import PrintLocations from './print-locations-inkonly'
import Notes from './notes-ink'
import moment from 'moment'

export default {
  components: {
    FwdMockups,
    Approval,
    PrintLocations,
    Notes
  },
  data: () => ({
    classH2: 'display-2 font-weight-black mb-3',
    classH3: 'display-1 pink--text text--accent-1 font-weight-black mb-3 pl-4',
    toggleLargeImage: false
  }),
  computed: {
    loaded () { return this.$store.getters['designs/loaded'] },
    design () { return this.$store.getters['designs/item'] },
    logSorted () {
      return this.sortByPropNumber(this.design.log, 'time')
    }
  },

  methods: {
    toggleSize () { this.toggleLargeImage = !this.toggleLargeImage },
    formatDate (date) {
      if (!date) { return }
      return moment(date).format('L')
    }
  }

}
</script>
<style lang="stylus" scoped>
.underline
  border-bottom 1px solid #E0E0E0
</style>
