<template lang="pug">
div
  v-toolbar.transparent(flat dense)
    v-layout(row slot="extension")
      v-flex(xs5 ml-3)
        ListSearch(
          :label="searchLabel"
          @search="emitSearch"
        )
      v-spacer
      v-flex(xs2 ml-1)
        v-btn(
          dark
          flat
          color="pink accent-2"
          @click="vendorDetail"
        ) assign vendor
      v-flex(xs2 ml-1)
        v-btn(
          dark
          flat
          color="pink accent-2"
          @click="assignToPo"
        ) assign to po
  v-list.transparent(subheader two-line dense)
    div(
      v-for='item in items'
      :key='item._id'
    )
      v-list-tile.list-item.pa-2(
        v-if="item.lineType === 'line'"
        :class="{ hilite: selectedItems.includes(item) }"
        @click="selectItem(item)"
      )
        v-list-tile-content()
          v-list-tile-title
            slot(:item="item")
          v-list-tile-sub-title
            slot(name="line2" :item="item")
        v-list-tile-action()
          v-icon(
            @click="select(item)"
          ) input

      v-list-tile.list-item.pa-2(
        v-else
      ).pink.lighten-5
        v-list-tile-content
          v-list-tile-title
            slot(:item="item")
        v-list-tile-action()
          v-btn(
            dark
            color="pink accent-1"
            @click="selectAllJob(item)"
          ) select all
</template>
<script>
import ListSearch from './list-search'
import { mapFields } from 'vuex-map-fields'

export default {
  components: {
    ListSearch
  },
  props: {
    items: { type: Array },
    searchLabel: { type: String },
    addToggle: { type: Boolean, default: true },
  },
  computed: {
    ...mapFields('poPoolLines', ['selectedItems'])
  },
  methods: {
    select (item) { this.$emit('select', item) },
    add () { this.$emit('add') },
    emitSearch (search) { this.$emit('search', search) },
    vendorDetail () { this.$emit('vendorDetail')},
    selectItem (item) {
      const index = this.selectedItems.findIndex(x => x._id === item._id)
      if (index === -1) {
        this.selectedItems.push(item)
      } else {
        this.selectedItems.splice(index, 1)
      }
    },
    async assignToPo () {
      const allVendorCompanys = this.selectedItems.map(item => item.vendor)
      const vendors = []
      allVendorCompanys.forEach(x => {
        if (vendors.find(vendor => x._id === vendor._id)) { return }
        vendors.push(x)
      })

      for (const vendor of vendors) {
        const lines = []
        this.selectedItems.forEach(item => {
          if (item.vendor._id === vendor._id) { lines.push(item) }
        })
        await this.$store.dispatch('purchaseOrders/_CREATE', { vendor, lines })
      }
      // delete the selected items
      this.selectedItems.forEach(item => {
        this.$store.dispatch('poPoolLines/deleteOne', { _id: item._id })
        this.$store.commit('poPoolLines/itemsRemove', { list: 'items', item })
      })
      this.selectedItems = []
      this.$router.push({ path: '/po-pool/' })
    },

    selectAllJob (item) {
      const jobItems = this.items.filter(x => item.job_id === x.job_id && x.lineType !== 'subsummary')
      jobItems.forEach(x => {
        if (this.selectedItems.find(s => x._id === s._id) === undefined) {
          this.selectedItems.push(x)
        }
      })
    }
  }
}
</script>
