<template lang="pug">
v-container(grid-list-md)
  v-btn(@click="add" dark) add comment
  v-layout(row v-for="(comment, i) in items" :key="i")
    v-flex(xs4)
      v-card(@click="select(comment)")
        v-card-title.font-weight-bold.subheading()
          | {{comment.type}} - {{comment.subject}}
        v-card-text
          v-layout(row style="{white-space: pre-line; white-space: pre-wrap;}")
            v-flex {{comment.text}}
          v-layout(row)
            v-flex
              span.font-weight-bold submitted on: 
              | {{formatDate(comment.time)}}
      
  DetailSimple(
    :detail="detail"
    :item="item"
    @detail="detailToggle"
  )
</template>

<script>
import moment from 'moment'
import { Comment } from '@/models'
import DetailSimple from './detail'

export default {
  components: { DetailSimple },
  data: () => ({
    detail: false,
    item: {}
  }),
  computed: {
    items () {
      let design = this.$store.getters['designs/item']
      let comments = design.comments.map(comment => {
        return new Comment(comment)
      })
      const sorted = this.sortByPropNumber(comments, 'time')
      return sorted.reverse()
    }
  },
  methods: {
    add () {
      this.item = new Comment()
      this.detailToggle()
    },
    select (item) {
      this.item = new Comment(item)
      this.detailToggle()
    },
    detailToggle () {
      this.detail = !this.detail
    },
    formatDate (date) {
      return moment(date).format('LL')
    }
  }
}
</script>
