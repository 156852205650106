export default [
  { 
    title: 'Products',
    icon: 'style',
    route: { activate: 'product' },
    top: true,
    role: ['Admin', 'User']
  },
  {
    title: 'product list',
    icon: 'list',
    route: { path: '/products' },
    module: 'product',
    role: ['Admin', 'User']
  },
  {
    title: 'product colors',
    icon: 'list',
    route: { path: '/product-colors' },
    module: 'product',
    role: ['Admin', 'User']
  },
  
]