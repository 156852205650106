import { ProductGroup as Model } from '@/models'

export default {
  
  async _ADD ({ state, dispatch }) {
    // open detail
    dispatch('_DETAIL_OPEN')
  },     

  async _SAVE ({ state, dispatch }) {
    // check _id to determine method
    if (state.item._id) {
      await dispatch('_UPDATE')
    } else {
      await dispatch('_CREATE')
    }

    // close detail
    dispatch('_DETAIL_CLOSE')
  },

  async _SELECT ({ commit }, item) {
    // load the record
    commit('item', item)
  },

  async _REMOVE ({ dispatch }) {
    // delete the record
    await dispatch('_DELETE')
  },

  async _NEW ({ commit, dispatch }, payload) {
    // unpack
    const { product, lineItems } = payload
    // add to item state
    const item = new Model({ product, lineItems })
    commit('item', item)
    dispatch('_CREATE')
  },

  async _DETAIL_OPEN ({ commit }) {
    commit('detail', true)
  },

  async _DETAIL_CLOSE ({ commit, dispatch }) {
    commit('detail', false)
    await dispatch('_NEW')
  },


}