import list from '../views/list'
import listPaid from '../views/list-paid'
import listinvoice from '../views/list-invoice'
import listConfirmed from '../views/report-confirmed'
import fangamergarments from '../views/report-fangamergarments'
import listToRetire from '../views/list-toretire'
import detail from '../views/detail'
import clientDocument from '../views/client-document'
import clientCommentSuccess from '../views/client-document/comment-success'
import clientApprovalSuccess from '../views/client-document/approval-success'
import jobSearch from '../views/job-search'

export default [
  {
    path: '/orders',
    name: 'orders list',
    component: list,
    meta: { authorize: ['Admin', 'User', 'ProductionLead', 'Purchasing'] }
  },
  {
    path: '/orders/paid',
    name: 'orders list paid',
    component: listPaid,
    meta: { authorize: ['Admin'] }
  },
  {
    path: '/orders/invoice',
    name: 'orders list invoice',
    component: listinvoice,
    meta: { authorize: ['Admin'] }
  },
  {
    path: '/orders/confirmed',
    name: 'orders list confirmed',
    component: listConfirmed,
    meta: { authorize: ['Admin'] }
  },
  {
    path: '/orders/fangamergarments',
    name: 'fangamer garments',
    component: fangamergarments,
    meta: { authorize: ['Admin'] }
  },
  {
    path: '/orders/toretire',
    name: 'orders list to retire',
    component: listToRetire,
    meta: { authorize: ['Admin'] }
  },
  {
    path: '/orders/job-search',
    name: 'orders job search',
    component: jobSearch,
    meta: { authorize: ['Admin', 'User', 'ProductionLead', 'Purchasing'] }
  },
  {
    path: '/orders/:id',
    name: 'order detail',
    component: detail,
    meta: { authorize: ['Admin', 'User', 'ProductionLead', 'Purchasing'] }
  },
  {
    path: '/client/orders/comment-success',
    name: 'client order comment success',
    component: clientCommentSuccess
  },
  {
    path: '/client/orders/approval-success',
    name: 'client order approval success',
    component: clientApprovalSuccess
  },
  {
    path: '/client/orders/:id',
    name: 'client order detail',
    component: clientDocument
  }
]
