<template lang="pug">
DetailSmall(:detail="detail")
  template(slot="title")
    | design for this job.
  template(slot="form")
    v-autocomplete(
      label="design"
      v-model="design"
      :items="designs"
      item-text="name"
      return-object
    )    
  template(slot="controls")
    v-btn(@click="save" :disabled="!design._id") OK
</template>

<script>
import DetailSmall from '@/components/base/detail-small-modal'
import { mapFields } from 'vuex-map-fields'

export default {
  components: {
    DetailSmall
  },
  computed: {
    ...mapFields('designs', {
      design: 'item',
      detail: 'detail',
      input: 'input'
    }),
    designs () {
      return this.$store.getters['designs/items']
    }
  },
  methods: {
    async save () {
      // add or update?
      if (this.input.method === 'add') {
        // add the job
        await this.$store.dispatch('jobs/_ADD')
      } else if (this.input.method === 'update') {
        // update the job
        await this.$store.dispatch('jobs/updateDesign')
      }
      // close the detail dialog
      this.detailToggle()
    },

    detailToggle () { this.detail = !this.detail }
  }
}
</script>
