<template lang="pug">
v-container(fluid)
  v-layout(row)
    v-flex(xs6)
      FwdCard
        template(slot="title") meta
        template(slot="content") 
          p
            span.font-weight-bold companyName: 
            | {{ companyName }}
          p
            span.font-weight-bold orderName: 
            | {{ orderName }}
          p
            span.font-weight-bold orderNumber: 
            | {{ orderNumber }}
          p
            span.font-weight-bold jobName: 
            | {{ jobName }}
          p
            span.font-weight-bold jobNumber: 
            | {{ jobNumber }}
      FwdCard
        template(slot="title") vendor
        template(slot="content") 
          v-select(
            v-model="vendor"
            label="vendor"
            :items="vendors"
            item-text="name"
            return-object
            @change="save"
          )
      
    v-flex(xs6 pl-3)
      FwdCard()
        template(slot="title") product
        template(slot="content" v-if="product._id")
          v-layout(row pb-1)
            v-flex.title.font-weight-thin {{ product.brand.name }}
          v-layout(row pb-1)
            v-flex.text-uppercase.title {{ product.code }}
          v-layout(row)
            v-flex.title {{ product.name }}
          v-layout.title(row mt-5)
            v-flex(xs6) size: {{ lineItem.size.name }}
            v-flex(xs6) quantity: {{ lineItem.quantity }}
          v-layout(row).mt-5
            v-flex
              v-img(
                :src="product.image.url"
                contain
                max-height="300"
              )
            v-flex

          
      
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import sortFilter from '@/mixins/sort-filter'

export default {
  computed: {
    product () { return this.$store.getters['products/item']},
    vendors () { return this.$store.getters['vendors/items']},
    ...mapFields('poPoolLines', {
      PONumber: 'item.PONumber',
      PO_id: 'item.PO_id',
      lineItem: 'item.lineItem',
      productName: 'item.productName',
      product_id: 'item.product_id',
      order_id: 'item.order_id',
      orderName: 'item.orderName',
      orderNumber: 'item.orderNumber',
      company_id: 'item.company_id',
      companyName: 'item.companyName',
      job_id: 'item.job_id',
      jobName: 'item.jobName',
      jobNumber: 'item.jobNumber',
      vendor: 'item.vendor'
    })
  },
  methods: {
    save () { this.$store.dispatch('poPoolLines/_UPDATE') }
  }
}
</script>
