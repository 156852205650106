/** Model definition file for the ProductGroup Class **/
import { LineItem, Product } from '@/models'
export default class ProductGroup {
  constructor ({
    _id = '',
    job_id = '',
    product = {},
    lineItems = [],
    quantity = 0,
    quantityFinal = 0,
    quantityReceived = 0,
    quantityOrdered = 0,
    subtotal = 0,
    subtotalFinal = 0,
  } = {}) {
    this._id = _id
    this.job_id = job_id
    this.product = new Product(product)
    this.lineItems = lineItems.map(line => {
      return new LineItem(line)
    })
    this.quantity = quantity
    this.quantityFinal = quantityFinal
    this.quantityOrdered = quantityOrdered
    this.quantityReceived = quantityReceived
    this.subtotal = subtotal
    this.subtotalFinal = subtotalFinal
  }

}
