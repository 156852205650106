<template lang="pug">
v-layout(row)
  v-flex(xs6)
    v-btn.white--text(
      color="pink accent-1"
      @click="action"
    ) duplicate selected subproduct
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  computed: {
    ...mapFields('products', {
      actions: 'actions'
    })
  },
  methods: {
    async action() {
      await this.$store.dispatch('products/duplicateSubproduct')
      this.actions = false
    }
  }
}
</script>
