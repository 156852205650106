<template lang="pug">
div
  v-layout(row)
    v-flex.font-weight-bold(xs8) order subtotal
    v-flex(xs4).text-xs-right {{costs.subtotal | currency}}
  v-layout(row v-if='order.isStatusFinal').pink.lighten-5
    v-flex.font-weight-bold(xs8) order subtotal (final)
    v-flex(xs4).text-xs-right {{costs.subtotalFinal | currency}}

  v-divider
</template>

<script>
export default {
  props: {
    order: { required: true, type: Object },
    costs: { required: true, type: Object }
  }
}
</script>
