<template lang="pug">
div
  DetailSmall(:detail="detail")
    template(slot="title")
      | screen detail
    template(slot="form")
      v-layout(row)
        v-flex(xs10)
          v-autocomplete(
            label="ink color"
            v-model="inkColor"
            :items="inkColors"
            item-text="displayName"
            return-object
            persistent-hint
          )
        v-flex(xs2)
          v-btn.black(dark fab flat small icon @click="detailInkColor = true")
            v-icon add
      v-select(
        label="ink type"
        v-model="inkType"
        :items="inkTypes"
        item-text="name"
        return-object
      )
      v-layout(row)
        v-flex(xs6)
          v-text-field(
            label="mesh"
            v-model="mesh"
          )
        v-flex(xs6 pl-3)
          v-select(
            label="print order"
            v-model="printOrder"
            :items="printPositions"
          )
    template(slot="controls")
      DetailControls(
        @save="save"
        @cancel="cancel"
        @remove="remove"
      )
  InkColorDetail(
    :detail="detailInkColor"
    @cancel="detailInkColor = false"
  )
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import DetailSmall from '@/components/base/detail-small-modal'
import DetailControls from '@/components/base/detail-controls-simple'
import InkColorDetail from '@/modules/ink-colors/detail'

export default {
  components: {
    DetailControls,
    DetailSmall,
    InkColorDetail
  },
  props: ['detail'],
  data: () => ({ detailInkColor: false }),
  computed: {
    ...mapFields('designs', {
      inkColor: 'selected.screen.inkColor',
      inkType: 'selected.screen.inkType',
      mesh: 'selected.screen.mesh',
      printOrder: 'selected.screen.printOrder'
    }),
    printPositions () {
      return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
    },
    inkColors () {
      return this.$store.getters['inkColors/items']
    },
    inkTypes () {
      return this.$store.getters['inkTypes/items']
    }
  },
  methods: {
    save () {
      this.$emit('save', this.form)
      this.cancel()
    },
    cancel () { this.$emit('cancel') },
    remove () {
      this.$emit('remove', this.form)
      this.cancel()
    }
  }
}
</script>
