import { Job, ProductGroup, Design } from '@/models'
import router from '@/router'
import { Promise } from 'q'

export default {
  async _ADD ({ commit, dispatch, state, rootState }) {
    dispatch('setup')

    // get next job number
    const nextJobNumber = await dispatch('admin/getNextJobNumber', null, { root: true })
    console.log('next job number', nextJobNumber)

    // get order id
    const order_id = rootState.orders.item._id
    // create blank job
    const jobTemplate = new Job({
      jobNumber: nextJobNumber,
      order: order_id
    })
    jobTemplate.design = rootState.designs.item._id

    // set the default tier
    const companyTier = rootState.companies.item.pricingTier
    const pricingTier = companyTier._id ? companyTier : rootState.tiers.items.find(item => item.isDefault)
    jobTemplate.pricing.tier = pricingTier

    // post the new job
    const newJob = await dispatch('postOne', { data: jobTemplate })
    const getDesign = await dispatch('designs/getOne', rootState.designs.item._id, { root: true })
    newJob.design = new Design(getDesign)
    commit('designs/item', getDesign, { root: true })
    // commit('designs/loaded', true, { root: true })
    // clear out the product groups
    commit('productGroups/item', {}, { root: true })
    commit('productGroups/items', [], { root: true })

    commit('item', newJob)
    await dispatch('updateJobPricing')
    commit('itemsUpdate', {
      list: 'items',
      item: newJob
    })

    // // add new PI's
    await dispatch('productionInstances/_CREATE_BATCH', { design: getDesign, order: rootState.orders.item }, { root: true })
    dispatch('cleanup')
    // go to detail page
    router.push({ path: '/jobs/' + newJob._id })
  },

  async _SAVE ({ state, commit, dispatch }) {
    dispatch('setup')

    // update job
    const update = dispatch('updateOne', { data: state.item })
    dispatch('productionInstances/updateJobProgress', { progress: state.item.progress, job_id: state.item._id }, { root: true })

    // update state
    commit('itemsUpdate', { list: 'items', item: state.item })

    // cleanup
    dispatch('cleanup')
    return update
  },

  async _REMOVE ({ state, dispatch, commit, rootState }) {
    dispatch('setup')
    await dispatch('deleteOne', { _id: state.item._id })
    // prepare the new items array
    commit('itemsRemove', {
      list: 'items',
      item: state.item
    })

    // remove related production instances
    dispatch('productionInstances/deleteMany', {
      endpoint: 'job',
      _id: state.item._id
    }, { root: true })

    // remove related product groups
    dispatch('productGroups/deleteMany', {
      endpoint: 'job',
      _id: state.item._id
    }, { root: true })

    // update order costs
    dispatch('orders/sumJobCosts', null, { root: true })

    // cleanup
    dispatch('cleanup')
  },

  async _LOAD ({ state, commit, dispatch, rootState }, _id) {
    dispatch('setup')

    const job = await dispatch('getOne', _id)
    // commit the job
    commit('item', job)

    // setup any necessary API calls
    const apiCalls = []

    // load order
    apiCalls.push(dispatch('orders/_READ', job.order, { root: true }))

    // load design
    apiCalls.push(dispatch('designs/_LOAD', job.design, { root: true }))

    // load product groups
    apiCalls.push(dispatch('productGroups/_READ_JOB', _id, { root: true }))

    // load the PI's
    apiCalls.push(dispatch('productionInstances/_READ_JOB_ITEMS', state.item, { root: true }))

    // go fetch what is needed
    const calls = await Promise.all(apiCalls)

    // update products
    dispatch('productGroups/updateAll', null, { root: true })

    // load company
    await dispatch('companies/_LOAD', rootState.designs.item.company._id, { root: true })

    // console.log(calls)
    // order last minute details
    // const order = calls[0]
    // order.company = rootState.companies.item
    // commit('orders/item', order, { root: true })

    // TODO: avoid this if possible:
    job.design = rootState.designs.item

    // update the job pricing
    dispatch('updateJobPricing')

    // clean up
    dispatch('cleanup')
  },

  async _LOAD_COMPANY_DESIGNS ({ state, commit, dispatch, rootState }, _id) {
    dispatch('setup')
    // get current company's designs
    let designs = await dispatch('designs/getCollectionFiltered', {
      endpoint: 'company',
      _id
    }, { root: true })

    commit('designs/items', designs, { root: true })
    dispatch('cleanup')
  }

}
