<template lang="pug">
div
  PGList
  v-layout(v-if="productGroups.length" row)
    v-flex(xs3)
      v-layout(row pl-5 py-2).body-2.pink.lighten-5
        v-flex.title {{product.code}}
      div.caption.pl-5.mt-4 {{product.brand.name}}
      div.pl-5 {{product.name}}
      div
        v-img(width="100%" :src="product.image.url")
    v-flex(xs9)
      LineItems(
        :lines="lineItems"
        :subtotal="subtotal"
        :quantity="quantityProductGroup"
        @changeQuantity="changeQuantity"
      )
</template>

<script>
import { LineItem } from '@/models'
import { mapFields } from 'vuex-map-fields'
import PGList from './list'
import LineItems from '../line-items'

export default {
  components: { PGList,  LineItems },
  computed: {
    ...mapFields('jobs',{
      job: 'item',
      quantityJob: 'item.quantity',
      loaded: 'loaded'
    }),
    ...mapFields('jobs/productGroups',{
      productGroup: 'item',
      productGroups: 'items',
      product: 'item.product',
      lineItems: 'item.lineItems',
      subtotal: 'item.subtotal',
      quantityProductGroup: 'item.quantity',
    })
  },
  methods: {
    async changeQuantity (payload) {
      const { _id, changeAmount, line } = payload
      let lines = this.lineItems.map(a => a)
      let index = lines.findIndex(el => {
        return _id === el._id
      })
      if (index !== -1) {
        lines.splice(index, 1, new LineItem(line))
      } 
      this.lineItems = lines
      await this.$store.dispatch('jobs/productGroups/changeQuantity', changeAmount)
      // this.$store.dispatch('jobs/productGroups/_UPDATE_LINE_ITEM', {
      //   lineItem_id: line._id,
      //   job_id: this.job._id,
      //   productGroup_id: this.productGroup._id,
      //   update: { ...line }
      // })
    }
  }
}
</script>