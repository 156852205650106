<template lang="pug">
v-select(
  v-model="filter"
  :label="label"
  :items="items"
  item-text="name"
  return-object
  flat
  @change="emitFilter"
)
</template>
<script>

export default {
  props: {
    label: {
      type: String
    },
    filterStartValue: {
      type: Object
    },
    items: {
      type: Array,
      required: true
    }
  },
  data: () => ({ filter: {} }),
  methods: {
    emitFilter () {
      this.$emit('filter', this.filter)
    }
  },
  watch: {
    filterStartValue: {
      handler () { this.filter = this.filterStartValue },
      immediate: true,
      deep: true
    }
  }
}
</script>
