<template lang="pug">
div
  v-layout(row)
    v-flex(xs8) amount due
    v-flex(xs4).text-xs-right {{ amountDue | currency}}
</template>

<script>
export default {
  props: {
    order: { required: true, type: Object },
    costs: { required: true, type: Object }
  },
  computed: {
    amountDue () {
      if (this.order.isStatusFinal) { return this.costs.totalFinal - this.costs.amountPaid } else { return this.costs.total - this.costs.amountPaid }
    }
  }
}
</script>
