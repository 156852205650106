import { getField, updateField } from 'vuex-map-fields'

export default {
  namespaced: true,
  state: {
    drawer: false,
    drawerFocus: null
  },
  getters: {
    drawer (state) { return state.drawer },
    getField
  },
  mutations: {
    toggleDrawer (state, payload) {
      state.drawer = !state.drawer
    },
    drawer (state, payload) {
      state.drawer = payload
    },
    drawerFocus (state, payload) {
      state.drawerFocus = payload
    },
    updateField
  },
  actions: {
    toggleDrawer ({ commit }) {
      commit('toggleDrawer')
    },
    login ({ commit }) {
      commit('drawer', false)
      commit('drawerFocus', null)
    }
  }
}
