<template lang="pug">
div
  v-layout(row v-if="form._id")
    v-flex
      v-textarea(
        v-model="form.text"
        label="comment"
      )
  v-layout(row)
    v-flex
      v-btn(dark @click="save") submit
  
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { Comment } from '@/models'

export default {
  data: () => ({
    form: new Comment()
  }),
  computed: {
    ...mapFields('designs', {
      items: 'item.comments'
    })
  },
  methods: {
    async save () {
      this.form.time = new Date()
      this.form.owner = 'client'
      this.form.subject = 'revise mockup'
      this.form.type = 'design request for change'
      let items = this.items.map(a => { return a })
      let index = items.findIndex(el => {
        return this.form._id === el._id
      })
      if (index !== -1) {
        items.splice(index, 1, this.form)
      } else {
        items.push(this.form)
      }
      this.items = items
      await this.$store.dispatch('designs/requestChangesMockup')
      this.cancel()
      this.$router.push({ path: '/client/designs/comment-success' })
    },
    cancel () {
      this.form = new Comment()
    }
  }
}
</script>
