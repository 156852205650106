<template lang="pug">
v-layout(row)
  v-flex()
    v-dialog(
      v-model="modal"
      persistent
      lazy
      full-width
      width="290px"
    )
      template(v-slot:activator="{ on }")
        v-text-field(
          v-model="dateDisplay"
          label="transaction date"
          prepend-icon="event"
          readonly
          v-on="on"
        )
      v-date-picker(
        v-model="date"
        scrollable
      )
          v-spacer
          v-btn(flat color="primary" @click="date = ''") Remove Date
          v-btn(flat color="primary" @click="modal = false") OK
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import moment from 'moment'

export default {
  data: () => ({
    modal: false
  }),
  computed: {
    ...mapFields('orders/transactions', {
      date: 'item.date'
    }),
    dateDisplay () {
      return this.date ? moment(this.date).format('LL') : ''
    }
  }
}
</script>
