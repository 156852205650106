<template lang="pug">
v-layout(row)
  v-flex
    v-btn(
      @click="markReceived"
      color="pink accent-1"
      :disabled='received'
    ).white--text mark PO received
  v-flex.caption.pl-3
    p this will simply mark this PurchaseOrder as received and has no communication with the job.
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  computed: {
    ...mapFields('purchaseOrders', {
      item: 'item',
      received: 'item.received',
      dateReceived: 'item.dateReceived',
      lines: 'item.lines',
      actions: 'actions'
    })
  },
  methods: {
    async markReceived() {
      this.received = true
      this.dateReceived = Date.now()
      await this.$store.dispatch('purchaseOrders/_UPDATE')
      this.actions = false
    }
  }
}
</script>

<style>

</style>
