<template lang="pug">
div
  v-checkbox(
    v-model="approved"
    label="approved?"
    @change="save"
  )
  v-text-field(
    v-model="by"
    label="approved by"
    @change="save"
  )
  v-flex on {{approval.date}}
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  computed: {
    ...mapFields('designs', {
      approval: 'item.approval',
      by: 'item.approval.by',
      approved: 'item.approval.approved',
      // controls
      editState: 'editState'
    })
  },
  methods: {
    save () { this.$store.dispatch('designs/_SAVE') }
  }
}
</script>
