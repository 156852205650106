import uuid from 'uuid/v4'

/** Model definition file for the Image Class **/

export default class Image {
  constructor ({
    _id = uuid(),
    fileMeta = null,
    url = null
  } = {}) {
    this._id = _id
    this.fileMeta = fileMeta
    this.url = url
  }
}
