<template lang="pug">
div()
  v-layout(row wrap)
    v-flex(xs12)
      JobsList
  v-divider
  v-layout(row wrap)
    v-flex(xs12)
      OtherCharges
</template>

<script>
import JobsList from '@/modules/jobs/views/list'
import OtherCharges from '../other-charges'

export default {
  components: {
    JobsList,
    OtherCharges
  }
}
</script>