<template lang="pug">
div
  v-layout(row mt-2)
    v-flex(xs6)
      ListFilter(
        label="filter by brand"
        v-model="filter"
        :items="brands"
      )
    v-flex(xs6 ml-5)
      ListSearch(
        label="search by name or code"
        v-model="search"
      )
</template>
<script>
import ListSearch from '@/components/list-search'
import ListFilter from '@/components/list-filter'
import { mapFields } from 'vuex-map-fields'

export default {
  components: { ListSearch, ListFilter },
  computed: {
    brands () { return this.$store.getters['brands/items'] },
    ...mapFields('products', {
      search: 'search',
      filter: 'filter'
    })
  }
}
</script>
