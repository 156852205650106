<template lang="pug">
v-container(fluid)
  ListCollectionSimple(
    :items="itemsSorted"
    @select="select"
    @add="add"
  )
    template(slot="line1" slot-scope="{ item }")
      v-layout(row)
        v-flex(xs6) {{item.name}}
        v-flex.font-weight-bold.caption(xs6 pl-2) {{notes(item)}}
    template(slot="line2" slot-scope="{ item }")
      v-layout(row)
        v-flex.body-1(xs12) {{item.displayTextInline  }}
  FwdDetailSmall(
    :detail="detail"
    @cancel="cancel"
  )
</template>

<script>
import { Address } from '@/models'
import { mapFields } from 'vuex-map-fields'
import ListCollectionSimple from '@/components/base/list-simple-2line'
import FwdDetailSmall from './detail'

export default {
  components: { ListCollectionSimple, FwdDetailSmall },
  data: () => ({ detail: false }),
  computed: {
    
    ...mapFields('companies', {
      item: 'address',
      items: 'item.addresses'
    }),

    itemsSorted () {
      const items = this.items.map(item => new Address(item))
      return this.sortByBool(items, 'isPrimary')
    }
  },
  methods: {
    notes (item) {
      const p = this.isPrimary(item)
      const b = this.isBilling(item)
      if (p && b) {
        return p + ', ' + b
      } else if (p) {
        return p
      } else if (b) {
        return b
      } else {
        return ''
      }
    },

    isPrimary (contact) {
      if (contact.isPrimary) { return 'primary address'}
      return ''
    },
    isBilling (contact) {
      if (contact.isBilling) { return 'billing address'}
      return ''
    },

    add () {
      this.item = new Address()
      this.detail = true
    },

    select (item) {
      this.item = new Address(item)
      this.detail = true
    },

    cancel () { this.detail = false }

  }
}
</script>
