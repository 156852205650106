<template lang="pug">
v-dialog(max-width=600 persistent v-model="detail")
  v-card(dark color='grey darken-4').pa-5
    v-card-title.display-2.font-weight-black
      slot(name="title")
    v-card-text
      slot(name="form")
    v-card-actions
      slot(name="controls")
</template>
<script>
export default {
  name: 'detail_small',
  props: ['detail']
}
</script>
