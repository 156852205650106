<template lang="pug">
div
  v-layout(row)
    v-flex(xs-6)
      v-text-field(
        label="width"
        v-model="width"
        @change="save"
      )
    v-flex(xs-6 pl-3)
      v-text-field(
        label="height"
        v-model="height"
        @change="save"
      )
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  computed: {
    ...mapFields('designs', {
      width: 'selected.locationSizeway.dimensions.width',
      height: 'selected.locationSizeway.dimensions.height'
    })
  },
  methods: {
   async save () {
      await this.$store.dispatch('designs/updateSizeways')
      await this.$store.dispatch('designs/updateLocation')
      this.$store.dispatch('designs/_SAVE')
    }
  }
}
</script>
