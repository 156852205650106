<template lang='pug'>
v-container().grey--text
  FwdHeadingLarge orders
  v-text-field(
    label='search'
    v-model='search'
  )
  div(v-for='(item, i) in itemssorted' :key='i' @click='select(item)').py-2.line-item
    v-layout(row)
      v-flex(xs8)
        span.font-weight-bold {{item.companyName}}
        |  - {{item.name}}
      v-flex(xs2) {{item.orderNumber}}
      v-flex(xs2) {{item.status.name}}

</template>

<script>
import { mapFields } from 'vuex-map-fields'
import axios from 'axios'

export default {
  computed: {
    ...mapFields('orders', {
      items: 'items',
      item: 'item',
      search: 'search'
    }),
    itemssorted () {
      return this.items.filter(item => {
        const searchterm = item.name + item.companyName + item.orderNumber
        // console.log(searchterm)
        return searchterm.toUpperCase().includes(this.search.toUpperCase())
      })
    }
  },
  methods: {
    async add () {
      const add = await this.$store.dispatch('orders/_ADD')
      this.detail(add._id)
    },
    select (item) {
      this.detail(item._id)
    },
    detail (_id) {
      this.$router.push({ path: '/orders/' + _id })
    },
    setSearch (search) {
      this.search = search
    }
  },

  async created () {
    const read = await axios.post('/orders/read', {
      filter: {
        'meta.retired': { $ne: true },
        $or: [
          { 'status.name': 'invoice' },
          { 'status.name': 'invoiced' },
          { 'status.name': 'paid' }
        ]
      }
    })
    this.items = read.data
  }
}
</script>
<style lang="stylus" scoped>
.line-item
  border-bottom 1px solid #4DD0E1

div.line-item:hover
  background-color #E0F7FA
  color black
  cursor pointer

div.line-item:active
  background-color #006064
  color white
  cursor progress
</style>
