<template lang="pug">
div
  v-toolbar.transparent.mb-2(flat dense)
    v-toolbar-title.font-weight-thin.display-1.pink--text.text-lowercase
      |  placements
    v-spacer
    v-btn.pink.accent-1.ml-5(icon @click="add" dark small)
      v-icon add
  div(
    v-for='item in itemsSorted'
    :key='item._id'
  ).py-2
    v-hover(v-slot:default="{ hover }")
      v-layout(
        @click='select(item)'
        row 
        :class='hover ? "hilite" : ""'
      ).list-item.pl-3
        v-flex(style="{white-space: pre-line; white-space: pre-wrap;}") {{item.name}}: {{item.details}}
 
  DetailSimple(
    :detail="detail"
    @save="save"
    @remove="remove"
    @cancel="cancel"
  )
</template>

<script>
import { mapFields  } from 'vuex-map-fields'
import { Placement } from '@/models'
import ListCollectionSimple from '@/components/base/list-simple'
import DetailSimple from './detail'

export default {
  components: { ListCollectionSimple, DetailSimple },
  data: () => ({ detail: false }),
  computed: {
    ...mapFields('designs', {
      placements: 'selected.locationSizeway.placements',
      placement: 'selected.placement'
    }),
    itemsSorted () {
      return this.placements
      // return this.sortByString(this.placements, 'name')
    }
  },
  methods: {
    add () {
      this.placement = new Placement()
      this.detail = true
    },
    select (item) {
      this.placement = new Placement(item)
      this.detail = true
    },
    async save () {
      await this.$store.dispatch('designs/updatePlacements')
      await this.$store.dispatch('designs/updateSizeways')
      await this.$store.dispatch('designs/updateLocation')
      this.$store.dispatch('designs/_SAVE')
    },
    cancel () { this.detail = false },
    async remove (item) {
      await this.$store.dispatch('designs/removePlacement')
      this.$store.dispatch('designs/_SAVE')
      this.cancel()
    }
  }
}
</script>
