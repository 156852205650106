<template lang="pug">
div
  v-layout(row px-4 pt-2 pb-3 v-if='line.productName')
    v-flex.subheading.pink--text {{ line.productName }}


  v-layout.line-item.body-1(row px-4 py-1 align-center)
    v-flex(xs1)
      div.px-0
          v-avatar(size=28 tile)
            img(:src="line.lineItem.color.image.url")
    
    v-flex(xs3)
      div.px-0 {{line.lineItem.color.name}}
    
    v-flex.text-xs-center(xs1)
      div.px-0.text-uppercase {{line.lineItem.size.name}}

    v-flex(xs5)
      v-layout(row)
        v-flex.pl-3(xs4)
          div.text-xs-center.pa-0.transparent(flat)
            span.px-0 {{line.lineItem.quantity}}

        v-flex.pl-3(xs4)
          div.text-xs-center.pa-0.transparent(flat)
            span(:class="{ 'red--text font-weight-bold text--accent-3': line.lineItem.quantity > line.lineItem.quantityOrdered }").px-0 {{line.lineItem.quantityOrdered}}

        v-flex.pl-3(xs4)
          div.text-xs-center.pa-0.transparent(flat)
            span(:class="{ 'green--text font-weight-bold text--accent-3': line.lineItem.quantityReceived >= line.lineItem.quantityOrdered }").px-0 {{line.lineItem.quantityReceived}}
    
</template>
<script>
export default {
  props: ['line'],
  data: () => ({ quantityOrdered: null }),
  computed: {
    ordered () {
      const item = this.$store.getters['purchaseOrders/item']
      return item.ordered
    }
  },
  watch: {
    line: {
      handler() { this.quantityOrdered = this.line.lineItem.quantityOrdered },
      immediate: true,
      deep: true
    }
  },
  methods: {
    change () {
      const item = { ...this.line }
      item.lineItem.quantityOrdered = this.quantityOrdered
      this.$store.commit('purchaseOrders/itemsUpdate', {
        list: 'item.lines',
        item
      })
      this.$store.dispatch('purchaseOrders/_UPDATE')
    },

    sendToPool () {
      this.$store.dispatch('purchaseOrders/sendBackToPool', this.line)
    }
  }
}
</script>
<style lang="stylus" scoped>
.line-item
  border-bottom solid #FCE4EC 1px
.css-input
  border-color #cccccc
  padding 10px
  border-width 0px
  border-style hidden
  text-align center
  width 50%
.css-input focus
  outline none
</style>
