<template lang="pug">
v-container()
  FwdHeadingLarge design settings
  v-layout.mt-5(row wrap)
    v-flex(xs6).pr-3
      DesignLocationTemplates
    v-flex(xs6).pl-3
      ScreenSizes
  v-layout.mt-5(row wrap)
    v-flex(xs6).pr-3
      InkTypes
    v-flex(xs6).pl-3
      DesignLocationAddOns
      
</template>

<script>
import { list as DesignLocationTemplates } from '@/modules/design-location-templates/'
import { list as ScreenSizes} from '@/modules/screen-sizes'
import { list as DesignLocationAddOns} from '@/modules/design-location-add-ons'
import InkTypes from '@/modules/ink-types/list'

export default {
  components: {
    DesignLocationTemplates,
    ScreenSizes,
    InkTypes,
    DesignLocationAddOns
  },
  data: () => ({

  }),
  methods: {

  },
  created () {

  }
}
</script>
