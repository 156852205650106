import * as firebase from "firebase/app"

// Add the Firebase services
import "firebase/auth"
import "firebase/storage"

const firebaseConfig = {
  apiKey: "AIzaSyDxwhh5qbTMUIDG3O7_WCunDAgP44esFUc",
  authDomain: "fwdio-13f60.firebaseapp.com",
  databaseURL: "https://fwdio-13f60.firebaseio.com",
  projectId: "fwdio-13f60",
  storageBucket: "fwdio-13f60.appspot.com",
  messagingSenderId: "27756525093",
  appId: "1:27756525093:web:5c116366c133859a"
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig)

// anon auth
firebase.auth().signInAnonymously().catch(function(error) {
  // Handle Errors here.
  // var errorCode = error.code;
  // var errorMessage = error.message;
  // ...
});

// export firebase storage
const storage =  firebase.storage()

export { storage } 