<template lang="pug">
v-layout(row px-4 py-2).body-2.pink.lighten-5
  v-flex(xs3) color
  v-flex(xs1).text-xs-center size
  v-flex(xs2).text-xs-center original
  v-flex(xs2).text-xs-center ordered
  v-flex(xs2).text-xs-right internal cost
  v-flex(xs2).text-xs-right true cost
</template>

<script>
export default {
}
</script>