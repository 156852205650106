/** Model definition file for the Order Class **/
import { Contact, OtherCharge, Shipment, Comment } from '@/models'
import OrderCosts from './OrderCosts'
import OrderStatusData from './OrderStatusData'

export default class Order {
    constructor({
        _id = '',
        company = '',
        companyName = '',
        name = '',
        owner = '',
        status = '',
        statusData = {},
        orderNumber = null,
        taxable = {
            isTaxable: false,
            reason: ''
        },
        approval = {
            approved: false,
            termsAndConditions: false,
            date: null,
            by: ''
        },
        dates = {
            dropDead: '',
            inHands: '',
            currentStatus: '',
            dropDeadIsHard: false
        },
        costs = {
            quantity: 0,
            subtotal: 0,
            jobsSubtotal: 0,
            otherChargesSubtotal: 0,
            shippingSubtotal: 0,
            tax: 0,
            taxOverride: 0,
            adjustment: 0,
            adjustmentPercentage: 0,
            adjustmentAmount: 0,
            total: 0,
            amountPaid: 0,
            cog: 0,
            cogTrue: 0,
            productCost: 0
        },
        shipments = [],
        jobs = [],
        otherCharges = [],
        comments = [],
        contact = {},
        transactions = [],
        meta = {},
        clientProduct = {}
    } = {}) {
        this._id = _id
        this.company = company
        this.companyName = companyName
        this.name = name
        this.owner = owner
        this.status = status
        this.statusData = new OrderStatusData(statusData)
        this.orderNumber = orderNumber
        this.taxable = taxable
        this.approval = approval
        this.dates = dates
        this.costs = new OrderCosts(costs)
        this.shipments = shipments.map(shipment => new Shipment(shipment))
        this.jobs = jobs
        this.otherCharges = otherCharges.map(otherCharge => {
            return new OtherCharge(otherCharge)
        })
        this.comments = comments.map(comment => new Comment(comment))
        this.transactions = transactions
        this.contact = new Contact(contact)
        this.dates.currentStatus = new Date(dates.currentStatus)
        this.meta = meta
        this.clientProduct = clientProduct
    }
    get taxExemptReasons() {
        return ['out of state', 'on file', 'non-profit exemption']
    }

    get displayName() {
        return this.companyName + ' - ' + this.name + ' - #' + this.orderNumber
    }
    get amountOwed() {
        return this.costs.total - this.costs.amountPaid
    }

    get isStatusFinal() {
        const status = this.status.name
        return (
            status === 'invoice' || status === 'invoiced' || status === 'paid'
        )
    }
}
