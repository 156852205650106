<template lang="pug">
DetailSmall(:detail="detail")
  template(slot="title")
    | ink color detail.
  template(slot="form")
    v-layout(row)
      v-flex
        v-text-field(
          label="name"
          v-model="name"
        )
        v-text-field(
          label="pms"
          v-model="pms"
        )
    v-layout(row)
      v-flex
        ImageInput(
          :item="item"
          :upload="upload"
          :size="Number(200)"
          @upload="uploadImage"
        )
  template(slot="controls")
    DetailControls(
      @save="save"
      @cancel="cancel"
      @remove="remove"
    )
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import DetailSmall from '@/components/base/detail-small-modal'
import DetailControls from '@/components/base/detail-controls-simple'
import ImageInput from '@/components/base/image-input-fb'

export default {
  components: {
    DetailControls,
    DetailSmall,
    ImageInput
  },
  props: ['detail'],
  computed: {
    ...mapFields('inkColors', {
      item: 'item',
      upload: 'upload',
      name: 'item.name',
      pms: 'item.pms'
    })
  },
  methods: {
    async save () {
      await this.$store.dispatch('inkColors/saveWithFile', this.item)
      this.cancel()
    },
    async remove () {
      await this.$store.dispatch('inkColors/remove', this.item._id)
      this.cancel()
    },
    cancel () { this.$emit('cancel') },

    uploadImage(item) {
      this.$store.commit('inkColors/upload', item)
    }
  }
}
</script>
