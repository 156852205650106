/** Model definition file for the DesignAddOn Class **/
import uuid from 'uuid/v4'
import { DesignLocationAddOn } from '@/models'

export default class DesignAddOn {
  constructor ({
    _id = uuid(),
    addOn = {},
    quantity = 1,
    
  } = {}) {
    this._id = _id
    this.addOn = new DesignLocationAddOn(addOn)
    this.quantity = quantity
  }

  get name () { return this.addOn.name }
}
