/** Model definition file for the MockupPage Class **/
import { Image } from '@/models'
import uuid from 'uuid/v4'

export default class MockupPage {
  constructor ({
    _id = uuid(),
    name = '',
    description = '',
    sortOrder = 99,
    image = new Image()
  } = {}) {
    this._id = _id
    this.name = name
    this.description = description
    this.sortOrder = sortOrder
    this.image = image
  }
}
