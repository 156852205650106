import commonActions from '@/store/common/actions'
import commonState from '@/store/common/state'
import commonGetters from '@/store/common/getters'
import commonMutations from '@/store/common/mutations'
import axios from 'axios'
import { Job as Model, JobCosts } from '@/models'

import crud from './crud'
import Actions from './actions'
import addPoLines from './addPoLines'
import updateDesign from './updateDesign'
import spekkio from './spekkio'

export default {
  namespaced: true,
  state: {
    path: 'jobs',
    ...commonState(Model),
    input: {
      job: null,
      design: null,
      subtitle: null,
      pricing: null,
      pickProduct: false
    }
  },
  getters: {
    ...commonGetters(),
    pricing (state) { return state.item.pricing }
  },
  mutations: {
    ...commonMutations(),
    decorationCodes (state, payload) { state.item.pricing.decorationCodes = payload },
    quantityPerHour (state, payload) { state.item.pricing.quantityPerHour = payload },
    jobCosts (state, payload) { state.item.pricing.jobCosts = payload },
    quantity (state, payload) {
      const { quantity, quantityOrdered, quantityReceived, quantityFinal } = payload
      state.item.quantity = quantity
      state.item.quantityOrdered = quantityOrdered
      state.item.quantityReceived = quantityReceived
      state.item.quantityFinal = quantityFinal
    },
    subtotal (state, payload) {
      const { subtotal, subtotalFinal } = payload
      state.item.subtotal = subtotal
      state.item.subtotalFinal = subtotalFinal
    }
  },
  actions: {
    ...commonActions(Model),
    updateDesign,
    ...crud,
    ...addPoLines,
    ...Actions,
    ...spekkio,

    async quantity ({ commit }, payload) { commit('quantity', payload) },
    async subtotal ({ commit }, payload) { commit('subtotal', payload) },

    async updateJobPricing ({ state, dispatch, commit, rootState }) {
      // exit if prices are locked
      const isPricesLocked = rootState.orders.item.statusData.isPricesLocked
      if (isPricesLocked) { return state.item.pricing }

      // ask design for pricing info
      const { decorationCodes, quantityPerHour, addOnAmount, numberOfLocations } = await dispatch('designs/pricingInfo', null, { root: true })

      // pass the codes
      commit('decorationCodes', decorationCodes)
      // pass the quantity per hour
      commit('quantityPerHour', quantityPerHour)

      const tier = state.item.pricing.tier
      const jobQuantity = rootState.jobs.item.quantityOverride > 0 ? rootState.jobs.item.quantityOverride : rootState.jobs.item.quantity
      const jobCosts = new JobCosts({ decorationCostOverride: state.item.pricing.jobCosts.decorationCostOverride })

      // loop thru decorationCodes to generate some pricing values
      decorationCodes.forEach(item => {
        // # of screens
        if (item.component.type.name === 'screenprint') {
          jobCosts.screens += item.quantity
        }
        // per print use cost
        if (item.component.useCost) {
          jobCosts.useCost += item.component.useCost * item.quantity
        }
        // total job setup time
        if (item.component.setup.time) {
          jobCosts.setupTime += item.component.setup.time * item.quantity
        }
        // total job setup cost
        if (item.component.setup.cost) {
          jobCosts.setupCost += item.component.setup.cost * item.quantity
        }
      })
      // calculate some more pricing values based on the decorationCodes loop
      jobCosts.setupCostPerPiece = jobCosts.setupCost / jobQuantity
      jobCosts.profitPerHour = tier.profitPerHour
      jobCosts.profitCostPerPiece = jobCosts.profitPerHour / (quantityPerHour / numberOfLocations)
      jobCosts.quantityPerHour = quantityPerHour

      // calculate the master decorationCost
      let cost = 0
      cost += jobCosts.useCost
      cost += jobCosts.setupCostPerPiece
      cost += jobCosts.profitCostPerPiece
      cost += addOnAmount

      if (jobCosts.decorationCostOverride > 0) { jobCosts.decorationCost = jobCosts.decorationCostOverride } else { jobCosts.decorationCost = (+cost * 100) / 100 }

      // update the job costs
      commit('jobCosts', jobCosts)

      // update state
      commit('itemsUpdate', { list: 'items', item: state.item })

      // save job
      dispatch('_SAVE')

      // return pricing object for job
      return state.item.pricing
    },

    async _LOAD_PRODUCT_GROUPS_FOR_DOCUMENT ({ state, commit, dispatch }) {
      const productGroups = await dispatch('productGroups/getCollectionFiltered', { _id: state.item._id, endpoint: 'job' }, { root: true })
      state.item.productGroups = productGroups
    },

    async markCompleted ({ state, dispatch }, _id) {
      const result = await axios.patch('jobs/progress/completed/' + _id, { data: true })
      if (result.data.status === 'completed') {
        dispatch('orders/completeOrder', null, { root: true })
      }
    },

    async removeDesignApproval ({ state, commit, dispatch, rootState }) {
      for (const job of state.items) {
        await dispatch('designs/_LOAD', job.design, { root: true })
        rootState.designs.item.approval.approved = false
        dispatch('designs/_SAVE', null, { root: true })
      }
    }

  }
}
