/** Model definition file for the Placement Class **/
import uuid from 'uuid/v4'

export default class Placement {
  constructor ({
    _id = uuid(),
    name = '',
    details = ''
  } = {}) {
    this._id = _id
    this.name = name
    this.details = details
  }
}
