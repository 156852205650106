import { getField } from 'vuex-map-fields'
export default () => ({
  items (state) { return state.items },
  item (state) { return state.item },
  itemOriginal (state) { return state.itemOriginal },
  input (state) { return state.input },
  tabs (state) { return state.tabs },
  actions (state) { return state.actions },
  search (state) { return state.search },
  filter (state) { return state.filter },
  detail (state) { return state.detail },
  loaded (state) { return state.loaded },
  editState (state) { return state.editState },
  upload (state) { return state.upload },
  getField
})
