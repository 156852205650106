<template lang="pug">
div
  v-chip(v-for='tag in tags' :key='tag._id' @click='tagRemove(tag)') {{tag.name}}

</template>

<script>
import _ from 'lodash'
import { mapFields } from 'vuex-map-fields'

export default {
  computed: {
    ...mapFields('products', {
      description: 'item.description',
      descrip: 'item.descrip',
      text: 'item.text',
      image: 'item.image',
      brand: 'item.brand.name',
      code: 'item.code',
      name: 'item.name',
      tags: 'item.tags',
      isEdited: 'item.edits.phase1.isEdited'
    }),
    categories () {
      const tags = this.$store.getters['productTags/items']
      const categories = tags.map(x => x.category)
      return _.uniq(categories)
    },
    productTags () {
      const result = {}
      for (const cat of this.categories) {
        result[cat] = this.tags.filter(tag => tag.category === cat)
      }
      return result
    }

  },
  methods: {
    save () { this.$store.dispatch('products/_UPDATE') },
    filterTags (category) {
      for (const cat in this.productTags) {
        if (cat === category) {
          return this.productTags[cat]
        }
      }
    },
    tagRemove (tag) {
      this.tags = _.without(this.tags, tag)
      this.save()
    }
  }
}
</script>
