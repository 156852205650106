<template lang="pug">
div
  v-layout(row)
    v-flex
      v-btn(@click="cancel") cancel
  v-layout(row)
    v-flex
      ListFilterSearch
  v-list
    v-list-tile.list-item(
        v-for='item in itemsSorted'
        :key='item._id'
        @click="select(item)"
        avatar
      )
      v-list-tile-avatar(v-if="item.image.url" tile)
        img(:src="item.image.url")
      v-list-tile-content
        v-list-tile-title
          | {{item.brand.name}} - {{item.code}} - {{item.name}}
</template>
<script>
import ListFilterSearch from './list-filter-search'
import sortItems from './list-sort'

export default {
  components: { ListFilterSearch },
  props: ['items'],
  computed: {
    filter () {
      return this.$store.getters['products/filter']
    },
    search () {
      return this.$store.getters['products/search']
    },
    itemsSorted () {
      return sortItems(this.items, this.search, this.filter)
    },
    baseURL () {
      return this.axios.defaults.baseURL + '/img'
    }
  },
  methods: {
    cancel () {
      this.$emit('cancel')
    },
    select (item) {
      this.$emit('selectProduct', item)
    }
  }
}
</script>
