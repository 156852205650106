import Home from '@/views/Home'
import productSettings from '@/views/ProductSettings'
import designSettings from '@/views/DesignSettings'

export default [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: { authorize: ['Admin', 'User', 'Screens', 'Inks', 'Production', 'Shipping', 'ProductionLead', 'Fangamer', 'Purchasing'] }
  },
  {
    path: '/decorations',
    name: 'decorations',
    component: () => import('@/views/Decorations'),
    meta: { authorize: ['Admin'] }
  },
  {
    path: '/decorations/:id',
    name: 'decoration detail',
    component: () => import('@/modules/decorations/detail'),
    meta: { authorize: ['Admin'] }
  },
  {
    path: '/pricing-settings',
    name: 'pricing-settings',
    component: () => import('@/views/PricingSettings'),
    meta: { authorize: ['Admin'] }
  },
  {
    path: '/product-settings',
    name: 'product-settings',
    component: productSettings,
    meta: { authorize: ['Admin'] }
  },
  {
    path: '/ink-colors',
    name: 'ink-colors',
    component: () => import('@/modules/ink-colors/list')
  },
  {
    path: '/design-settings',
    name: 'design-settings',
    component: designSettings,
    meta: { authorize: ['Admin'] }
  },
  {
    path: '/order-settings',
    name: 'order-settings',
    component: () => import('@/views/OrderSettings'),
    meta: { authorize: ['Admin'] }
  },
  {
    path: '/purchasing/settings',
    name: 'purchasing settings',
    component: () => import('@/views/PurchasingSettings'),
    meta: { authorize: ['Admin'] }
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('@/modules/admin/detail'),
    meta: { authorize: ['Admin'] }
  }
]
