<template lang="pug">
v-container().pt-5
  FwdHeadingLarge purchase orders
  BaseList(
    :items="itemsSorted"
    searchLabel="search po's"
    @search="setSearch"
    @select="select"
  )
    template(slot-scope="{ item }")
      v-layout(row).body-1
        v-flex(xs1) {{ item.number }}
        v-flex(xs7) {{ item.vendor.name }}
        v-flex(xs3)
          template(v-if="item.ordered && !item.received") ordered on: 
            span.font-weight-bold.pink--text {{ formatDate(item.dateOrdered) }}
          template(v-if="item.received") received on: 
            span.font-weight-bold.pink--text  {{ formatDate(item.dateReceived) }}
          template(v-else)
        v-flex(xs1).text-xs-right {{ item.quantity }}
</template>

<script>
import moment from 'moment'
import listSort from '../../helpers/listSort'
import { mapFields } from 'vuex-map-fields'
import BaseList from './list-extended'

export default {
  components: { BaseList },
  computed: {
    ...mapFields('purchaseOrders', {
      items: 'items',
      search: 'search',
    }),
    itemsSorted () {
      // filter by search term
      let list = this.filterByProps(this.items, ['number', 'vendorName', 'quantity'], this.search)
      // sort list by name
      return listSort({ items: list })
    }

  },
  methods: {
    select (item) { this.$router.push({ path: '/purchase-orders/' + item._id }) },
    setSearch (search) { this.search = search },
    formatDate (date) { return moment(date).format('L') }
  },
  async created () { await this.$store.dispatch('purchaseOrders/_READ_ALL') }
}
</script>
