<template lang="pug">
div.text-xs-right
  div.mb-3
    v-layout(row v-if='order.isStatusFinal') 
      v-flex.font-weight-bold.text-xs-right(xs8) sales tax
      v-flex(xs4 v-if="!costs.taxOverride") {{costs.taxFinal | currency}}
      v-flex.pink--text(xs4 v-else) {{costs.taxOverride | currency}}

    v-layout(row v-else)
      v-flex.font-weight-bold.text-xs-right(xs8) sales tax
      v-flex(xs4 v-if="!costs.taxOverride") {{costs.tax | currency}}
      v-flex.pink--text(xs4 v-else) {{costs.taxOverride | currency}}
      
  v-divider.mb-3
</template>

<script>
export default {
  props: {
    order: { required: true, type: Object },
    costs: { required: true, type: Object },
  }
}
</script>
