<template lang="pug">
v-container()
  FwdHeadingLarge product settings
  v-layout.mt-5(row wrap)
    v-flex(xs6).pr-3
      SubproductNames
    v-flex(xs6).pl-3
      SizeCategories
  v-layout.mt-5(row wrap)
    v-flex(xs6).pr-3
      Sizes
    v-flex(xs6).pl-3
      Brands
  v-layout.mt-5(row)
    v-flex(xs6).pr-3
      ProductTags
</template>

<script>
import { list as Brands } from '@/modules/brands/'
import SizeCategories from '@/modules/size-categories/list'
import SubproductNames from '@/modules/subproduct-names/list'
import Sizes from '@/modules/sizes/list'
import ProductTags from '@/modules/product-tags/views/list'

export default {
  components: {
    Brands,
    SizeCategories,
    SubproductNames,
    Sizes,
    ProductTags
  }
}
</script>
