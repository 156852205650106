<template lang="pug">
  v-toolbar(
    app
    flat
    dense
    v-if="loggedIn && !clientRoute && !printRoute && !detailRoute"
  ).mb-5.transparent
    v-btn(
      @click='toggleDrawer'
      small
      flat
      )
      v-icon() menu
    //- v-toolbar-title.body-2
    //-   span fwd
    v-spacer
    v-btn(
      v-if="loggedIn"
      @click="logout"
      small
      flat
    ) logout

</template>

<script>
export default {
  computed: {
    clientRoute () {
      return this.$route.path.includes('/client/')
    },
    printRoute () {
      return this.$route.path.includes('/print/')
    },
    detailRoute () {
      return this.$route.params.id
    },
    drawer () {
      return this.$store.state.core.drawer
    },
    loggedIn () {
      return this.$store.state.users.isloggedin
    }
  },
  methods: {
    toggleDrawer () {
      this.$store.dispatch('core/toggleDrawer')
    },
    logout () {
      this.$store.dispatch('users/logout')
      this.$router.push('/login')
    }
  }
}
</script>
