<template lang="pug">
v-container(pa-3)
  h3.title.mb-3 product groups
  AddProductGroup
  RemoveProductGroup
  v-divider.mt-3

  h3.title.my-3 tickets
  Tickets
  v-divider.mt-3
  
  h3.title.my-3 navigation
  Navigation
  v-divider.mt-3

  h3.title.my-3 this job
  //- DuplicateJob
  DeleteJob
</template>

<script>
import DeleteJob from './DeleteJob'
import Tickets from './Tickets'
import AddProductGroup from './AddProductGroup'
import RemoveProductGroup from './RemoveProductGroup'
import Navigation from './Navigation'
import DuplicateJob from './DuplicateJob'
export default {
  components: {
    DeleteJob,
    Tickets,
    AddProductGroup,
    RemoveProductGroup,
    Navigation,
    DuplicateJob
  }
}
</script>

<style>

</style>
