<template lang="pug">
div
  v-container(fluid grid-list-md)
    v-layout(row wrap)
      v-flex(
        xs3
        v-for="item in items"
        :key='item._id'
      )
        v-card(flat color="pink lighten-4").pa-1
          v-card(flat color="white" min-height="270"  @click="select(item)")
            v-img(
              v-if="item.image.url"
              :src="item.image.url"
              aspect-ratio=1.5
            )
            v-card-text
              h3.body-2 {{item.name}}
              p.caption {{item.description}}
        
</template>
<script>
import { mapFields } from 'vuex-map-fields'

export default {
  computed: {
    ...mapFields('designs', {
      items: 'item.mockups'
    })
  },
  methods: {
    select (item) { this.$emit('select', item) },

  },
}
</script>
