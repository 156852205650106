<template lang="pug">
v-layout(row)
  v-flex(xs6)
    v-btn.white--text(
      dark
      color="pink accent-1"
      @click="jobTicket"
    ) job ticket
  v-flex(xs6)
    v-btn.white--text(
      dark
      color="pink accent-1"
      @click="sampleTicket"
    ) sample ticket
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  computed: {
    ...mapFields('jobs', {
      item: 'item',
      actions: 'actions',
    })
  },
  methods: {
    async jobTicket() {
      const id = this.$route.params.id
      const routeData = this.$router.resolve('/jobs/job-ticket/print/' + id)
      window.open(routeData.href, '_blank')
      this.actions = false
    },
    async sampleTicket() {
      const id = this.$route.params.id
      const routeData = this.$router.resolve('/jobs/sample-ticket/print/' + id)
      window.open(routeData.href, '_blank')
      this.actions = false
    },
    async screenTicket() {
      const id = this.$route.params.id
      const routeData = this.$router.resolve('/jobs/screen-ticket/print/' + id)
      window.open(routeData.href, '_blank')
      this.actions = false
    },
    async inkTicket() {
      const id = this.$route.params.id
      const routeData = this.$router.resolve('/jobs/ink-ticket/print/' + id)
      window.open(routeData.href, '_blank')
      this.actions = false
    },
  }
}
</script>

<style>

</style>
