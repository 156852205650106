import Vue from 'vue'
import Store from '@/store'
import Router from 'vue-router'
// import modules
import someRoutes from './routes'
import companies from '@/modules/companies/routes'
import orders from '@/modules/orders/routes'
import shipments from '@/modules/shipping/routes'
import jobs from '@/modules/jobs/routes'
import designs from '@/modules/designs/routes'
import products from '@/modules/products/routes'
import productColors from '@/modules/product-colors/routes'
import purchaseOrders from '@/modules/purchase-orders/routes'
import users from '@/modules/users/routes'
import dashboards from '@/modules/dashboards/routes'
import scheduling from '@/modules/scheduling/routes'
import productioninstances from '@/modules/production-instances/routes'
// import production from "arctic/productioninstances/routes";

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        // top level routes
        ...someRoutes,

        // module routes
        // ...production,
        ...companies,
        ...orders,
        ...shipments,
        ...jobs,
        ...designs,
        ...products,
        ...productColors,
        ...purchaseOrders,
        ...users,
        ...scheduling,
        ...dashboards,
        ...productioninstances,

        // otherwise redirect to home
        { path: '*', redirect: '/' }
    ]
})

router.beforeEach((to, from, next) => {
    init(to)
    return next()
    authorize(to, next)
})

export default router

function init(to) {
    const client = to.path.includes('/client/')
    const login = to.path.includes('/login')
    const print = to.path.includes('/print/')
    const production = to.name === 'production'
    const exclude = client || login || print || production
    const isinit = Store.state.loader.isInit
    if (isinit) return null
    if (!exclude) {
        Store.dispatch('loader/init')
    }
}

function authorize(to, next) {
    // redirect to login page if not logged in and trying to access a restricted page
    const client = to.path.includes('/client/')
    const login = to.path.includes('/login')
    const print = to.path.includes('/print/')
    const exclude = client || login || print
    const loggedIn = JSON.parse(localStorage.getItem('user'))

    // redirect to login page if not logged in and trying to access a restricted page
    if (!exclude && !loggedIn) {
        return next({ path: '/login' })
    }

    const { authorize } = to.meta
    if (authorize) {
        // check if route is restricted by role
        init(to)
        if (authorize.length && !authorize.includes(loggedIn.role)) {
            // role not authorised so redirect to home page
            return next({ path: '/' })
        } else {
            return next()
        }
    } else {
        init(to)
        return next()
    }
}
