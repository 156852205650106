<template lang="pug">
v-card(flat color="white" min-height="200")
  FwdList(
    :items="itemsSorted"
    @select="select"
    @add="add"
  )
    template(slot-scope="{ item }")
      v-layout.body-1(row)
        v-flex(xs4).no-wrap {{item.name}}
        v-flex(xs4).no-wrap {{item.notes}}
        v-flex(xs4).no-wrap {{formatDate(item.meta.dateCreated)}}
    template(slot="title") mockups
  LocalDetail(
    :detail="detail"
    @cancel="cancel"
  )
</template>

<script>
import moment from 'moment'
import { File, Upload } from '@/models'
import { mapFields } from 'vuex-map-fields'
import FwdList from '@/components/base/list-simple'
import LocalDetail from './detail'

export default {
  components: { FwdList, LocalDetail },
  data: () => ({ detail: false }),
  computed: {
    ...mapFields('designs', {
      item: 'fileMockups',
      items: 'item.files.mockups',
      upload: 'upload',

    }),
    itemsSorted () {
      let list = this.sortByBool(this.items, 'dateCreated')
      // sort list by name
      return list
    }
  },
  methods: {
    add () {
      this.item = new File()
      this.upload = new Upload()
      this.detail = true
    },
    select (item) {
      this.item = new File(item)
      this.detail = true
    },
    cancel () {
      this.detail = false
    },

    formatDate(date) {
      return moment(date).format('L')
    }
  }
}
</script>

<style lang="stylus" scoped>
.no-wrap
  white-space nowrap
  overflow hidden
  text-overflow ellipsis
</style>
