/** Model definition file for the Admin Class **/

export default class Admin {
  constructor ({
    _id = 'admin',
    orderNumber = null,
    jobNumber = null,
    poNumber = null,
    designNumber = null,
    taxRate = 10.25
  } = {}) {
    this._id = _id
    this.orderNumber = orderNumber
    this.jobNumber = jobNumber
    this.poNumber = poNumber
    this.designNumber = designNumber
    this.taxRate = taxRate
  }
}
