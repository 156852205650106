<template lang="pug">
v-container()
  FwdHeadingLarge orders
  BaseList(
    :items="itemsSorted"
    searchLabel="search orders"
    @search="setSearch"
    @select="select"
    @add="add"
    :addToggle="false"
    :filter="filter"
    filterLabel="status"
    :filterItems="filterItems"
    @filter="setFilter"
  )
    template(slot-scope="{ item }")
      v-layout(row)
        v-flex(xs7)
          span.font-weight-bold {{item.companyName}}
          |  - {{item.name}}
        v-flex(xs1) {{item.orderNumber}}
        v-flex(xs1) {{item.status.name}}
        v-flex(xs2).text-xs-right {{item.costs.total | currency}}
        v-flex(xs2).text-xs-right {{amountdue(item) | currency}}
        
    template(slot="title")

      |
</template>

<script>
import { Order } from '@/models'
import { mapFields } from 'vuex-map-fields'
import BaseList from '@/components/base/list-extended'
import orderStates from '../../helpers/order-states'

export default {
  components: { BaseList },
  computed: {
    ...mapFields('orders', {
      items: 'items',
      item: 'item',
      search: 'search',
      filter: 'filter'
    }),
    itemsSorted () {
      // filter by search term
      let list = this.filterByProps(this.items, ['displayName'], this.search)
      // filter by selected brand
      if (this.filter !== null) {
        list = list.filter(item => {
          return item.status.name === this.filter.name
        })
      }
      // sort list by name
      return this.sortByString(list, 'displayName')
    },
    
    filterItems () { return orderStates }

  },
  methods: {
    async add () {
      const add = await this.$store.dispatch('orders/_ADD')
      this.detail(add._id)
    },
    select (item) {
      this.detail(item._id)
    },
    detail (_id) {
      this.$router.push({ path: '/orders/' + _id })
    },
    setSearch (search) {
      this.search = search
    },
    setFilter (filter) {
      this.filter = filter
    },
    amountdue(item) {
      const { total, totalFinal, amountPaid } = item.costs
      if (totalFinal > 0) return totalFinal - amountPaid
      return total - amountPaid
    },
  },

  created () {
    this.$store.dispatch('orders/_READ_ALL')
    this.setFilter(null)
  }
}
</script>
