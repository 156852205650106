<template lang="pug">
v-text-field(
  :label="label"
  append-icon="search"
  clearable
  @input="input"
)
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      default: 'search'
    },
    value: {
      type: String
    }
  },
  methods: {
    input (input) {
      this.$emit('input', input)
    }
  }
}
</script>
