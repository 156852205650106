<template lang="pug">
v-container(grid-list-lg fluid)
  v-layout(row)
    v-flex(xs8)
      v-layout(row wrap)
        v-flex(xs6)
          FwdCard
            template(slot="title") details
            template(slot="content")
              Details
          FwdCard
            template(slot="title") thumb
            template(slot="content")
              Thumb

        v-flex(xs6)
          FwdCard
            template(slot="title") assigned colors
            template(slot="content")
              AssignedColors

    v-flex(xs4)
      h3.headline.font-weight-bold.mb-5 preview
      DetailCard

</template>

<script>
import Thumb from './thumb'
import AssignedColors from './assigned-colors'
import Details from './details'
import DetailCard from '../Card'

export default {
  components: {
    Thumb,
    AssignedColors,
    Details,
    DetailCard
  }
}
</script>
