import axios from 'axios'

export default async (data) => {
  // TODO: validate email address and send alert if not available
  await axios({
    method: 'post',
    url: 'orders/sendmail',
    data,
    headers: { 'Content-Type': 'application/json' }
  })
    
}