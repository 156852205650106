<template lang="pug">
v-layout(row)
  v-flex
    v-btn.white--text(
      dark
      color="pink accent-1"
      @click="removeProductGroup"
    ) remove selected product
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  computed: {
    ...mapFields('jobs', {
      item: 'item',
      actions: 'actions',
    })
  },
  methods: {
    async removeProductGroup() {
      this.$store.dispatch('productGroups/_REMOVE')
      this.actions = false
    }
  }
}
</script>

<style>

</style>
