import list from '../views/list'
import detail from '../views/detail'
import productPicker from '../views/product-picker'
import editList from '../views/edits/phase1/list'
import editListFlagged from '../views/edits/phase1/list-flagged'
import editDetail from '../views/edits/phase1/detail'

export default [
  {
    path: '/products',
    name: 'products list',
    component: list,
    meta: { authorize: ['Admin', 'User'] }
  },
  {
    path: '/products/audit',
    name: 'products audit list',
    component: editList,
    meta: { authorize: ['Admin', 'User'] }
  },
  {
    path: '/products/audit/flagged',
    name: 'products audit list flagged',
    component: editListFlagged,
    meta: { authorize: ['Admin', 'User'] }
  },
  {
    path: '/products/audit/:id',
    name: 'products audit detail',
    component: editDetail,
    meta: { authorize: ['Admin', 'User'] }
  },
  {
    path: '/products/:id',
    name: 'products detail',
    component: detail,
    meta: { authorize: ['Admin', 'User'] }
  },
  {
    path: '/client/product-picker',
    name: 'product picker example',
    component: productPicker
  }
]
