<template lang="pug">
div
  FwdDetailPage(v-if="loaded")
    template(slot="title-prefix") products: 
    template(slot="title") api example
    template(slot="form")
      FwdTabs(
        v-model="tabs"
        :items="tabItems"
      )
  v-container(fluid v-else)
    v-layout(justify="center" align="center" row)
      div(class="text-center ma-12")
        v-progress-circular(
          indeterminate
          color="purple"

        )
      

</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { Job } from '@/models'
import Notes from '../../components/product-picker-example/notes'
import ProductList from '../../components/product-picker-example/product-list'
import SelectedProduct from '../../components/product-picker-example/selected-product'
import PPExample from '../../components/product-picker-example/pp-example'

export default {
  computed: {
    ...mapFields('products', {
      item: 'item',
      tabs: 'tabs',
      loaded: 'loaded'
    })
  },
  data: () => ({
    tabItems: [
      { title: 'notes', component: Notes },
      { title: 'product list', component: ProductList },
      { title: 'selected product', component: SelectedProduct },
      { title: 'product picker example', component: PPExample },
    ]
  }),
  async created () {
     // load tiers
    const tiers = await this.$store.dispatch('tiers/getCollection')
    this.$store.commit('tiers/items', tiers)
    // load products list
    const products = await this.$store.dispatch('products/getCollection')
    this.$store.commit('products/items', products)
    // load example product
    const product = await this.$store.dispatch('products/getOne', '5d1681ca643f261773e3f9a3')
    this.$store.commit('products/item', product)
    // load example design
    const design = await this.$store.dispatch('designs/getOne', '5d250fdc4cf31c728c9ac553')
    this.$store.commit('designs/item', design)
    // blank job
    const job = new Job({
      design,
      pricing: { tier: tiers.find(tier => tier.isDefault) }
    })
    this.$store.commit('jobs/item', job)
    this.$store.commit('jobs/inputJob', job)
    // load brands
    const brands = await this.$store.dispatch('brands/getCollection')
    this.$store.commit('brands/items', brands)
    await this.$store.dispatch('jobs/updateJobPricing')

    this.$store.commit('jobs/editState', true)
    this.$store.commit('products/loaded', true)
  }
}
</script>
