<template lang="pug">
div
  DeleteOrder
  //- DuplicateOrder
  AddPoLines
  v-divider.my-3
  Company
  v-divider.my-3
  Financial
  v-divider.my-3
  UPSMap
  v-divider.my-3
  UpdateSubtotals
</template>

<script>
import DeleteOrder from './DeleteOrder'
import DuplicateOrder from './DuplicateOrder'
import AddPoLines from './AddPoLines'
import Company from './company'
import Financial from './financial'
import UPSMap from './UPSMap'
import UpdateSubtotals from './updateSubtotals'

export default {
  components: {
    DeleteOrder,
    DuplicateOrder,
    AddPoLines,
    Company,
    Financial,
    UPSMap,
    UpdateSubtotals
  }
}
</script>

<style>

</style>
