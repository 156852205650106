<template lang="pug">
DetailSmall(:detail="detail" v-if="detail")
  template(slot="title")
    | shipment detail.
  template(slot="form")
    v-layout(row)
      v-flex
        v-select(
          label="address"
          v-model="form.address"
          return-object
          :items="addresses"
          item-text="name"
        )
    v-layout(row)
      v-flex(xs4)
        v-text-field(
          label="cost"
          v-model.number="form.cost"
        )
      v-flex(xs4 pl-3)
        v-text-field(
          label="# of boxes"
          v-model.number="form.numberOfBoxes"
        )
      v-flex(xs4 pl-3)
        v-select(
          label="carrier"
          v-model="form.carrier"
          :items="form.carriers"
        )
    
    v-layout(row)
      v-flex
        v-text-field(
          label="tracking number"
          v-model="form.tracking"
        )
    //- Dates(:item="form" @updateDates="")
    v-layout(row)
      v-flex(xs6)
        v-textarea(
          label="delivery notes"
          v-model.number="form.deliveryNotes"
        )
      v-flex(xs6 pl-3)
        v-textarea(
          label="shipping notes"
          v-model.number="form.shipmentNotes"
        )
    v-layout(row)
      v-flex()
        v-checkbox(
          v-model="form.isShipped"
          label="mark as shipped?"
          color="pink accent-1"
        )
    v-layout(row)
      v-flex()
        v-text-field(
          v-model="form.receiver"
          label="receiver"
        )
  template(slot="controls")
    DetailControls(
      @save="save"
      @cancel="cancel"
      @remove="remove"
    )
</template>

<script>
import DetailSmall from '@/components/base/detail-small-modal'
import DetailControls from '@/components/base/detail-controls-simple'
import Models from '@/models'
import { mapFields } from 'vuex-map-fields'
import Dates from './dates'

export default {
  components: { DetailControls, DetailSmall, Dates },
  props: ['detail', 'item'],
  data: () => ({
    form: {}
  }),
  computed: {
    ...mapFields('orders', {
      items: 'item.shipments',
      shippingSubtotal: 'item.costs.shippingSubtotal',
      status: 'item.status'
    }),
    addresses () {
      let company = this.$store.getters['companies/item']
      return  company.addresses
    }
  },
  watch:{
    item: {
      handler () {
        this.form = this.item
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    save () {
      this.$store.commit('orders/itemsUpdate', { list: 'item.shipments', item: this.form })

      this.updateSubtotal()
      if (this.form.isShipped) {
        const shippedOn = this.form.shippedOn
        this.form.shippedOn = shippedOn ? shippedOn : Date.now()
        this.$store.dispatch('orders/markShipped', this.item)
      } else {
        this.$store.dispatch('orders/_SAVE')
      }
      this.cancel()
    },
    cancel () {
      this.$emit('detail')
    },
    remove (item) {
      this.$store.commit('orders/itemsRemove', { list: 'item.shipments', item: this.form })
      this.updateSubtotal()
      this.$store.dispatch('orders/_SAVE')
      this.cancel()
    },
    updateSubtotal () {
      let subtotal = 0
      this.items.forEach(item => { subtotal += +item.cost })
      this.shippingSubtotal = subtotal
      this.$store.dispatch('orders/sumJobCosts')
    }
    
  }
}
</script>
