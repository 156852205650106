<template lang="pug">
v-tabs(
  slider-color="black"
  color="grey lighten-2"
  :value="value"
)
  template(v-for="(tab, $index) in items")
    v-tab.grey--text(
      :key="$index"
      ripple
      @click="$emit('input', $index)"
    ) {{ tab.title }}
    v-tab-item(:key="$index").max-height
      component(:is="tab.component")
</template>

<script>

export default {
  props: {
    value: { type: Number, required: true },
    items: { type: Array, required: true }
  }
}
</script>

<style lang="stylus" scoped>
.fixed
  position fixed
</style>
