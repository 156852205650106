<template lang="pug">
v-container(grid-list-md)
  v-layout(row)
    v-flex(xs6)
      ListLocations
      LocationDetail
    v-flex(xs6)
      DetailFocus(
        :item="item"
        :colorway="selectedColorway"
        :sizeway="selectedSizeway"
        @remove="remove"
      )
</template>

<script>
import Models from '@/models'
import { mapFields } from 'vuex-map-fields'
import ListLocations from './list'
import DetailFocus from './detail'
import LocationDetail from './location'

export default {
  components: { ListLocations, DetailFocus, LocationDetail },
  computed: {
    ...mapFields('designs', {
      item: 'selected.location',
      selectedSizeway: 'selected.sizeway',
      selectedColorway: 'selected.colorway',
      items: 'item.locations',
      admin: 'item.admin'
    })
  },
  methods: {
    
    save () {
      // save into the locations array
      let items = this.items.map(a => { return a })
      let index = items.findIndex(el => {
        return item._id === el._id
      })
      if (index !== -1) {
        items.splice(index, 1, item)
      } else {
        items.push(item)
      }
      this.items = items
      this.$store.dispatch('designs/_SAVE')
    },
    remove (item) {
      let items = this.item.map(a => { return a })
      let index = items.findIndex(el => {
        return item._id === el._id
      })
      items.splice(index, 1)
      this.items = items
      // clear the current item
      this.item = {}
      this.$store.dispatch('designs/_SAVE')
    }
  }
}
</script>
