/** Model definition file for the ProductionInstanceDashboard Class **/
import { JobProgress } from '@/models'

export default class ProductionInstanceDashboard {
  constructor ({
    designName = '',
    jobNumber = '',
    jobSubtitle = '',
    jobProgress = {},
    orderDisplayName = '',
    orderStatus = {},
    // priority is 1-5(high)
    priority = { production: 4, inks: 4, screens: 4, art: 4 },
    image = {},
    isNeedsRevision = false,
    isPressCheck = false
  } = {}) {
    this.designName = designName
    this.jobNumber = jobNumber
    this.jobSubtitle = jobSubtitle
    this.jobProgress = new JobProgress(jobProgress)
    this.orderDisplayName = orderDisplayName
    this.orderStatus = orderStatus
    this.priority = priority
    this.image = image
    this.isNeedsRevision = isNeedsRevision
    this.isPressCheck = isPressCheck
  }
}
