import shipments from '../views/shipments-list'
import shipment from '../views/shipments-detail'
import fangamerpickuplist from '../views/fangamerpickup-list'
import fangamerpickupdetail from '../views/fangamerpickup-detail'

export default [
  {
    path: '/shipments/fangamerpickup',
    name: 'fangamer pickup list',
    component: fangamerpickuplist,
    meta: {
      authorize: [
        'Admin',
        'User',
        'ProductionLead',
        'Purchasing',
        'Production'
      ]
    }
  },
  {
    path: '/shipments/fangamerpickup/:id',
    name: 'fangamer pickup detail',
    component: fangamerpickupdetail,
    meta: {
      authorize: [
        'Admin',
        'User',
        'ProductionLead',
        'Purchasing',
        'Production'
      ]
    }
  },
  {
    path: '/shipments',
    name: 'shipments',
    component: shipments,
    meta: {
      authorize: [
        'Admin',
        'User',
        'ProductionLead',
        'Purchasing',
        'Production'
      ]
    }
  },
  {
    path: '/shipments/:id',
    name: 'shipments detail',
    component: shipment,
    meta: {
      authorize: [
        'Admin',
        'User',
        'ProductionLead',
        'Purchasing',
        'Production'
      ]
    }
  },
  
]
