<template lang="pug">
div.pa-0.transparent.text-xs-center(flat)
  
  template(v-if="input")
    span.text-xs-center.px-0
      input.css-input.pink.lighten-5.text-xs-center.py-3(
        v-model.number="quantity"
        @change="changeQuantity"
        @focus="$event.target.select()"
        tabindex=1
      )
  
  template(v-else)
    v-layout(align-center text-center)
      v-flex.px-0.pt-3.pb-1 {{quantity}}

</template>

<script>
export default {
  props: {
    line: { type: Object, required: true },
    input: { type: Boolean, default: false },
    type: { type: String, required: true },
  },

  data: () => ({ quantity: null }),

  methods: {

    changeQuantity () {
      if (isNaN(this.quantity) || this.quantity === '' || this.quantity === null) { this.quantity = 0 }
      
      // prepare payload items
      const line = this.line
      line[this.type] = this.quantity

      // send payload up and initialize
      this.$store.dispatch('productGroups/changeQuantity', { line })
    }

  },
  
  watch: {

    line: {
      handler() {
        this.quantity = this.line[this.type]
      },
      immediate: true,
      deep: true
    }

  }
  
}
</script>
<style lang="stylus" scoped>
.css-input
  border-color #cccccc
  padding 5px
  border-width 0px
  border-style hidden
  text-align center
  width 75%
.css-input focus
  outline none
</style>
