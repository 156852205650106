<template lang="pug">
DetailSmall(:detail="detail")
  template(slot="title") separation file detail.
  template(slot="form")
    v-layout(row)
      v-flex
        v-text-field(
          label="name"
          v-model="name"
        )
        v-text-field(
          label="notes"
          v-model="notes"
        )
    v-layout(row)
      v-flex
        FileInput(
          :item="item"
          :upload="upload"
          @upload="uploadFile"
        )
      v-flex(v-if="item.url")
        v-btn(tag="a" download :href="item.url") download file
  template(slot="controls")
    DetailControls(
      @save="save"
      @cancel="cancel"
      @remove="remove"
    )
</template>

<script>
import { Upload, File } from '@/models'
import { mapFields } from 'vuex-map-fields'
import DetailSmall from '@/components/base/detail-small-modal'
import DetailControls from '@/components/base/detail-controls-simple'
import FileInput from '@/components/base/file-input-fb'

export default {
  components: {
    DetailControls,
    DetailSmall,
    FileInput
  },
  props: ['detail'],
  computed: {
    ...mapFields('designs', {
      design: 'item',
      item: 'fileSeparations',
      upload: 'upload',
      name: 'fileSeparations.name',
      notes: 'fileSeparations.notes'
    })
  },
  methods: {
    async save () {
      this.$store.dispatch('designs/setup')
      if (this.upload.url) {
        const postFile = await this.$store.dispatch('designs/postOneFile', {
          data: this.upload.formData
        })
        this.item.url = postFile.url
        this.item.fileMeta = postFile.fileMeta
      }
      this.$store.commit('designs/itemsUpdate', {
        list: 'item.files.separations',
        item: this.item
      })
      this.upload = new Upload
      this.item = new File
      this.$store.dispatch('designs/_SAVE')
      this.$store.dispatch('designs/cleanup')
      this.cancel()
    },
    async remove () {
      this.$store.commit('designs/itemsRemove', {
        list: 'item.files.separations',
        item: this.item
      })
      this.$store.dispatch('designs/_SAVE')
      this.cancel()
    },
    cancel () { this.$emit('cancel') },

    uploadFile(item) {
      this.$store.commit('designs/upload', item)
    }
  }
}
</script>
