<template lang="pug"> 
v-layout(row)
  v-flex(xs6)
    v-btn(
      @click="action"
      color="pink accent-1"
    ).white--text print ink ticket
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  computed: {
    ...mapFields('dashboards', {
      actions: 'actions'
    }),
    item () { return this.$store.getters['designs/item']}
  },
  methods: {
    async action() {
      const id = this.item._id
      const routeData = this.$router.resolve('/designs/ink-ticket/print/' + id)
      window.open(routeData.href, '_blank')
      this.actions = false
    }
  }
}
</script>
