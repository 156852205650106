<template lang="pug">
v-container()
  ListCollectionSimple(
    :items="items"
    @select="select"
    @add="add"
  )
    template(slot="title") other charges
    template(slot="line1" slot-scope="{ item }")
      v-layout(row).body-1
        v-flex(xs4) {{item.name}}
        v-flex.text-xs-right(xs2) {{item.quantity}}
        v-flex.text-xs-right(xs3) {{item.cost | currency}}
        v-flex.text-xs-right(xs3) {{item.costExtend | currency}}
  DetailSimple(
    :detail="detail"
    :item="item"
    @save="save"
    @remove="remove"
    @cancel="cancel"
  )
</template>

<script>
import { OtherCharge } from '@/models'
import { mapFields } from 'vuex-map-fields'
import ListCollectionSimple from '@/components/base/list-simple-2line'
import DetailSimple from './detail'

export default {
  components: { ListCollectionSimple, DetailSimple },
  data: () => ({
    detail: false,
    item: {}
  }),
  computed: {
    ...mapFields('orders', {
      items: 'item.otherCharges',
      subtotal: 'item.costs.otherChargesSubtotal'
    })
  },
  methods: {
    add () {
      this.item = new OtherCharge()
      this.detail = true
    },
    select (item) {
      this.item = new OtherCharge(item)
      this.detail = true
    },
    save () {
      this.$store.commit('orders/itemsUpdate', {
        list: 'item.otherCharges',
        item: this.item
      })
      this.cancel()
    },
    cancel () {
      this.updateSubtotal()
      this.$store.dispatch('orders/_SAVE')
      this.detail = false
    },
    remove () {
      this.$store.commit('orders/itemsRemove', {
        list: 'item.otherCharges',
        item: this.item
      })
      this.cancel()
    },
    updateSubtotal () {
      let subtotal = 0
      this.items.forEach(item => {
        subtotal += item.costExtend
      })
      this.subtotal = subtotal
      this.$store.dispatch('orders/sumJobCosts')
    }
  }
}
</script>
