<template lang="pug">
v-layout(row)
  v-flex
    v-btn(
      @click="updateSubtotals"
    ) updateSubtotals
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  computed: {
    ...mapFields('orders', {
      item: 'item',
      actions: 'actions'
    })
  },
  data: () => ({ allow: false }),
  methods: {
    async updateSubtotals () {
      await this.$store.dispatch('orders/updateSubtotals')
      this.actions = false
    }
  }
}
</script>

<style>

</style>
