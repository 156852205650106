// utility
import Image from './image'
import Upload from './upload'
import Meta from './Meta'
import File from './File'
import Address from './address'
import Contact from './contact'
import ContactInfo from './contact-info'
import LogItem from './LogItem'
import ApprovalBlock from './ApprovalBlock'

import Comment from './comment'
import { User } from '@/modules/users'

// admin
import { models as administrative } from '@/modules/admin'

// companies
import { Model as Company } from '@/modules/companies'

// orders
import {
  Order,
  OtherCharge,
  Shipment,
  OrderCosts,
  Transaction
} from '@/modules/orders'

// product
import { Product, Subproduct } from '@/modules/products'
import { ProductColor } from '@/modules/product-colors'

// product settings
import { Model as Brand } from '@/modules/brands'
import { Model as ProductTag } from '@/modules/product-tags'
import { Model as SizeCategory } from '@/modules/size-categories'
import { Model as SubproductName } from '@/modules/subproduct-names'
import { Model as Size } from '@/modules/sizes'

// pricing
import Pricing from './pricing'
import { Model as DecorationCode } from '@/modules/decoration-codes'
import { Model as DecorationCategory } from '@/modules/decoration-categories'
import { Tier } from '@/modules/tiers'
import { Model as Decoration } from '@/modules/decorations'

// design
import {
  Design,
  Colorway,
  Sizeway,
  DesignLocation,
  DesignLocationColorway,
  DesignLocationSizeway,
  DesignScreen,
  DesignLaser,
  Placement,
  MockupPage,
  DesignAddOn
} from '@/modules/designs'

// design settings
import { Model as InkColor } from '@/modules/ink-colors'
import { Model as InkType } from '@/modules/ink-types'
import { Model as ScreenSize } from '@/modules/screen-sizes'
import { DesignLocationTemplate } from '@/modules/design-location-templates'
import { Model as DesignLocationAddOn } from '@/modules/design-location-add-ons'

// jobs
import {
  Job,
  JobCosts,
  JobProgress
} from '@/modules/jobs'

import ProductGroupModule from '@/modules/product-groups'

// production instances
import { Press } from '@/modules/presses'
import { ProductionInstanceType } from '@/modules/production-instance-types'
import { ProductionInstance, PiProgress } from '@/modules/production-instances/'

// purchasing
import { VendorCompany } from '@/modules/vendors'
import { PurchaseOrder, PurchaseOrderLine } from '@/modules/purchase-orders'
const { Admin } = administrative
const ProductGroup = ProductGroupModule.models.ProductGroup
const LineItem = ProductGroupModule.models.LineItem

export {
  Meta,
  File,
  Admin,
  User,
  LogItem,
  // utils
  Image,
  Upload,
  Comment,
  Address,
  Contact,
  ContactInfo,
  ApprovalBlock,
  // companies
  Company,
  // orders
  Order,
  OtherCharge,
  Shipment,
  OrderCosts,
  Transaction,
  // jobs
  Job,
  ProductGroup,
  LineItem,
  JobProgress,
  JobCosts,
  // production instances
  ProductionInstance,
  PiProgress,
  Press,
  ProductionInstanceType,
  // products
  Product,
  ProductColor,
  Size,
  ProductTag,
  Subproduct,
  // product settings
  Brand,
  SizeCategory,
  SubproductName,
  // design
  Design,
  DesignLocationColorway,
  DesignLocationSizeway,
  Colorway,
  Sizeway,
  MockupPage,
  DesignAddOn,
  DesignLocation,
  Placement,
  DesignScreen,
  DesignLaser,
  // design settings
  InkColor,
  InkType,
  ScreenSize,
  DesignLocationTemplate,
  DesignLocationAddOn,
  // pricing
  Pricing,
  Tier,
  DecorationCode,
  DecorationCategory,
  Decoration,
  // purchasing
  VendorCompany,
  PurchaseOrder,
  PurchaseOrderLine
}
