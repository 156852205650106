<template lang="pug">
v-layout.line-item.body-1(row px-4)
  v-flex(xs3)
    v-layout(row)
      v-flex
        v-card.pa-0.transparent(flat)
          v-card-text.px-0
            v-avatar(size=28 tile)
              img(:src="line.color.image.url")
      v-flex
        v-card.pa-0.transparent(flat)
          v-card-text.px-0 {{line.color.name}}

  v-flex(xs1).text-xs-center
    v-card.pa-0.transparent(flat)
      v-card-text.px-0.text-uppercase {{line.size.name}}

  v-flex(xs5)
    v-layout(row)
      v-flex(xs3)
        Quantity(:readOnly="readOnly" :line="line" @changeQuantity="changeQuantity")
      v-flex(xs3)
        QuantityOrdered(:readOnly="true" :line="line")
      v-flex(xs3)
        QuantityReceived(:readOnly="true" :line="line")
      v-flex(xs3)
        QuantityFinal(:readOnly="true" :line="line")

  v-flex(xs1).text-xs-right
    v-card.pa-0.transparent(flat)
      v-card-text.px-0 {{line.cost | currency}}

  v-flex(xs2).text-xs-right
    v-card.pa-0.transparent(flat)
      v-card-text.px-0 {{line.costExtend | currency}}

</template>
<script>
import Quantity from './quantity'
import QuantityOrdered from './quantity-ordered'
import QuantityReceived from './quantity-received'
import QuantityFinal from './quantity-final'

export default {
  props: ['line', 'readOnly'],
  methods: {
    changeQuantity (payload) { this.$emit('changeQuantity', payload) }
  },

  components: { Quantity, QuantityOrdered, QuantityReceived, QuantityFinal }
}
</script>

<style lang="stylus" scoped>
.line-item
  border-bottom solid #CFD8DC 1px
</style>
