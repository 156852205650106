import router from '@/router'
import axios from 'axios'

export default {
  async duplicateOrder ({ state, commit, dispatch, rootState }) {
    dispatch('setup')
    // work on the current order
    const order = state.item

    // set off the duplication chain
    const newOrder = await axios.post('/orders/duplicate', { _id: order._id })

    router.push('/orders/' + newOrder.data._id)
    dispatch('cleanup')
    return newOrder
  },

  async findJob ({ state, commit, dispatch, rootState }, jobNumber) {
    dispatch('setup')

    // find corresponding order
    try {
      const order = await axios.get('/orders/job-search/' + jobNumber)
      console.log(order.data)
      dispatch('cleanup')
      router.push('/orders/' + order.data._id)
    } catch {
      dispatch('cleanup')
      return 'corresponding order could not be found'
    }
  },

  async updateSubtotals ({ state, commit, dispatch, rootState }) {
    dispatch('setup')
    const jobs = rootState.jobs.items
    for (const job of jobs) {
      commit('jobs/item', job, { root: true })
      await dispatch('productGroups/_READ_JOB', job._id, { root: true })
      await dispatch('productGroups/updateAll', null, { root: true })
    }
    dispatch('cleanup')
  }

}
