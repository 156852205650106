<template lang="pug">
div
  v-toolbar.transparent(flat dense)
    v-toolbar-title.font-weight-black.title
      slot(name="title")
    v-spacer
    v-btn.pink.accent-1.ml-5(icon @click="add" dark small v-if="addToggle")
      v-icon add
  v-list.transparent(subheader two-line)
    v-list-tile.list-item(
        v-for='item in items'
        :key='item._id'
        @click="select(item)"
        avatar
      )
      v-list-tile-content
        v-list-tile-title
          slot(name="line1" :item="item")
        v-list-tile-sub-title
          slot(name="line2" :item="item")
</template>
<script>
export default {
  props: {
    items: { type: Array },
    addToggle: { type: Boolean, default: true },
  },
  methods: {
    select (item) { this.$emit('select', item) },
    add () { this.$emit('add') }
  }
}
</script>
