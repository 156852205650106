<template lang="pug">
v-container(fluid)
  FwdList(
    :items="itemsSorted"
    @select="select"
    @add="add"
  )
    template(slot="title") jobs
    template(slot="toolbar-actions")
      v-btn.pink--text.text--accent-1(
        flat
        @click="progressToggle = !progressToggle"
      ) toggle progress bar
    template(slot="line1" slot-scope="{ item }")
      v-layout.body-1(row)
        v-flex(xs6) {{item.displayName}}
        v-flex(xs6 v-if="progressToggle")
          JobProgressBar(:progress="item.progress")
        template(v-else)
          v-flex.text-xs-right(xs3) {{item.quantity}}
          v-flex.text-xs-right(xs3) {{item.subtotal | currency}}
    template(slot="line2" slot-scope="{ item }")
      | {{item.design.subtitleText}}
      span.grey--text.text--lighten-2 [tier: {{item.pricing.tier.name}}]

  v-layout.text-xs-right.subheading.font-weight-bold.mt-3(row)
    v-flex(xs3 offset-xs6) {{order.costs.quantity}}
    v-flex(xs3).pr-3 {{order.costs.jobsSubtotal | currency}}

  DetailDesign
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import FwdList from '@/components/base/list-simple-2line-avatar'
import JobProgressBar from '@/components/JobProgressBar'
import DetailDesign from '../../components/design'

export default {
  components: { FwdList, JobProgressBar, DetailDesign },
  data: () => ({
    progressToggle: false
  }),
  computed: {
    ...mapFields('jobs', {
      items: 'items',
      item: 'item',
      search: 'search'

    }),
    ...mapFields('orders', {
      order: 'item',
      company: 'item.company'
    }),
    ...mapFields('designs', {
      detail: 'detail',
      inputMethod: 'input.method'
    }),
    itemsSorted () {
      // sort list by name
      return this.sortByString(this.items, 'displayName')
    }
  },
  methods: {
    async add () {
      // get related designs for selection at the next step
      await this.$store.dispatch('jobs/_LOAD_COMPANY_DESIGNS', this.company)
      // enter a modal dialog to collect the design before creating the job
      this.inputMethod = 'add'
      this.detailToggle()
    },

    select (item) {
      this.$router.push({ path: '/jobs/' + item._id })
    },

    detailToggle () { this.detail = !this.detail }

  }
}
</script>
