<template lang="pug">
v-layout(row)
  v-flex
    v-btn(
      :disabled="!allowDelete"
      @click="deleteOrder"
    ) delete order
  v-flex
    v-checkbox(
      label="allow delete?"
      v-model="allowDelete"
    )
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  computed: {
    ...mapFields('orders', {
      item: 'item',
      actions: 'actions'
    })
  },
  data: () => ({ allowDelete: false }),
  methods: {
    async deleteOrder() {
      await this.$store.dispatch('orders/_REMOVE')
      this.actions = false
      this.$router.go(-1)
    }
  }
}
</script>

<style>

</style>
