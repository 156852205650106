<template lang="pug">
DetailSmall(:detail="detail")
  template(slot="title")
    | other charge detail.
  template(slot="form")
    v-layout(row)
      v-flex
        v-text-field(
          label="name"
          v-model="form.name"
        )
    v-layout(row)
      v-flex(xs6)
        v-text-field(
          label="quantity"
          v-model.number="form.quantity"
        )
      v-flex(xs6 pl-3)
        v-text-field(
          label="cost"
          v-model.number="form.cost"
        )
    
  template(slot="controls")
    v-btn(@click="save")
      v-icon(left) save
      | ok
    v-btn.ml-3(@click="remove")
      v-icon(left) delete
      | remove
    v-btn.ml-3(@click="cancel")
      v-icon(left) cancel
      | cancel
</template>

<script>
import DetailSmall from '@/components/base/detail-small-modal'

export default {
  components: {
    DetailSmall
  },
  props: ['detail', 'item'],
  data: () => ({ form: {} }),
  watch:{
    item: {
      handler () {
        this.form = this.item
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    save () {
      this.$emit('save', this.form)
      this.cancel()
    },
    cancel () { this.$emit('cancel') },
    remove () {
      this.$emit('remove', this.form)
      this.cancel()
    }
  }
}
</script>
