<template lang="pug">
div
  FwdDetailPage(v-if="loaded")
    template(slot="title-prefix") PurchaseOrder: 
    template(slot="title") {{ item.displayName }}
    template(slot="controls")
      Controls
    template(slot="form")
      FwdTabs(
        v-model="tabs"
        :items="tabItems"
      )
  v-dialog(max-width=600 v-model="actions")
    FwdCard
      template(slot="title") actions
      template(slot="content")
        Actions
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import DetailForm from './form'
import LineItems from '../../components/line-items'
import LineItemsFlat from '../../components/line-items-display'
import Receiving from '../../components/receiving'
import Actions from '../../components/actions'
import Controls from './controls'

export default {
  components: {
    Controls,
    Actions
  },
  data: () => ({
    tabItems: [
      { title: 'details', component: DetailForm },
      { title: 'purchasing', component: LineItems },
      { title: 'receiving', component: Receiving },
      { title: 'summary', component: LineItemsFlat },
    ]
  }),
  computed: {
    ...mapFields('purchaseOrders', {
      item: 'item',
      tabs: 'tabs',
      loaded: 'loaded',
      actions: 'actions'
    })
  },
  async created () {
    this.$store.dispatch('purchaseOrders/setup')

    await this.$store.dispatch('purchaseOrders/_READ', this.$route.params.id)
    // load the related jobs
    this.$store.dispatch('purchaseOrders/loadRelatedJobs')

    this.$store.dispatch('purchaseOrders/cleanup')
  }
}
</script>
