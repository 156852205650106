import Vue from 'vue'
// global components
import FwdCard from '@/components/base/FwdCard'
import FwdList from '@/components/base/FwdList'
import FwdDetailPage from '@/components/base/FwdDetailPage'
import FwdTabs from '@/components/base/FwdTabs'
import FwdHeadingLarge from '@/components/base/FwdHeadingLarge'
import FwdModal from '@/components/base/FwdModal'
import FwdControls from '@/components/base/FwdControls'
import VueCompositionApi from '@vue/composition-api'

// global components
Vue.component('FwdCard', FwdCard)
Vue.component('FwdList', FwdList)
Vue.component('FwdTabs', FwdTabs)
Vue.component('FwdDetailPage', FwdDetailPage)
Vue.component('FwdHeadingLarge', FwdHeadingLarge)
Vue.component('FwdModal', FwdModal)
Vue.component('FwdControls', FwdControls)
