<template lang="pug">
  p.subheading company: 
    span.font-weight-bold {{company.name}}
</template>

<script>

export default {
  
  computed: {
    company () { return this.$store.getters['companies/item'] }
  }

}
</script>
