/** Model definition file for the ProductTag Class **/

export default class ProductTag {
  constructor ({
    _id = '',
    name = '',
    category = ''
  } = {}) {
    this._id = _id
    this.name = name
    this.category = category
  }

  get categories () {
    return [
      'fabric',
      'cut',
      'style',
      'category'
    ]
  }
}
