<template lang="pug">
v-container(fluid)
  v-layout(row)
    v-flex(xs6)
      FwdCard
        template(slot="title") tier
        template(slot="content")
          v-select(
            v-model="tier"
            label="tier"
            :items="tiers"
            return-object
            item-text="displayName"
            @change="update"
          )
      FwdCard
        template(slot="title") decoration codes
        template(slot="content")
          ListSimple(
            :items="decorationCodes"
            :toolbar="false"
          )
            template(slot-scope="{ item }")
              v-layout(row)
                v-flex(xs8) {{item.name}}
                v-flex.text-xs-right(xs4) {{item.quantity}}

      FwdCard
        template(slot="title") quantity per hour
        template(slot="content")
          p expected: {{quantityPerHour}}
      //- FwdCard
      //-   template(slot="title") job costs debug
      //-   template(slot="content")
      //-     pre {{ jobCosts }}
      //-     pre {{ pricing }}

    v-flex.pl-3(xs6)
      FwdCard
        template(slot="title") job cost breakdown
        template(slot="content")
          v-layout(row)
            v-flex(xs6) profit per hour
            v-flex.text-xs-right(xs6) {{jobCosts.profitPerHour | currency}}
          v-layout(row)
            v-flex(xs6) setup per piece
            v-flex.text-xs-right(xs6) {{jobCosts.setupCostPerPiece | currency}}
          v-layout(row)
            v-flex(xs6) use cost per piece
            v-flex.text-xs-right(xs6) {{jobCosts.useCost | currency}}
          v-layout(row)
            v-flex(xs6) decoration cost per piece
            v-flex.text-xs-right(xs6) {{jobCosts.decorationCost | currency}}
          v-layout(row)
            v-flex(xs6) total cost:
            v-flex.text-xs-right(xs6) {{totalCost | currency}}

  //- v-layout(row wrap)
  //-   v-flex(xs12 v-for='pg of productGroups' :key='pg._id')
  //-     div(v-for='line of pg.lineItems' :key='line._id') {{ line }}

</template>

<script>
import { mapFields } from 'vuex-map-fields'
import ListSimple from '@/components/base/list-simple'

export default {
  computed: {
    productGroups () { return this.$store.getters['productGroups/items'] },
    tiers () { return this.$store.getters['tiers/items'] },

    ...mapFields('jobs', {
      tier: 'item.pricing.tier',
      decorationCodes: 'item.pricing.decorationCodes',
      quantityPerHour: 'item.pricing.quantityPerHour',
      jobCosts: 'item.pricing.jobCosts',
      pricing: 'item.pricing',
      totalCost: 'item.subtotal'
    })

  },

  methods: {
    update () {
      this.$store.dispatch('productGroups/updateAll')
    }
  },

  components: { ListSimple }
}
</script>
