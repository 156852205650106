import { PurchaseOrderLine } from '@/models'

export default {
  async _CREATE ({ commit, dispatch }, poLine) {
    // prepare instance
    const data = new PurchaseOrderLine(poLine)
    // post new record and add to state
    const item = await dispatch('postOne', { data })
    commit('item', item)
    commit('itemsUpdate', { list: 'items', item: item })
  },

  async _READ ({ commit, dispatch }, _id) {
    dispatch('setup')
    const item = await dispatch('getOne', _id)
    commit('item', item)
    dispatch('cleanup')
  },

  async _READ_ALL ({ commit, dispatch }) {
    dispatch('setup')
    const items = await dispatch('getCollection')
    commit('items', items)
    dispatch('cleanup')
  },

  async _UPDATE ({ state, commit, dispatch }) {
    dispatch('updateOne', { data: state.item })
    // update items array (local state)
    commit('itemsUpdate', { list: 'items', item: state.item })
  },

  async _DELETE ({ state, commit, dispatch }) {
    dispatch('deleteOne', { _id: state.item._id })
    // remove the item from local state
    commit('itemsRemove', { list: 'items', item: state.item })
  }

  
}
