<template lang="pug">
div
  v-text-field(
    outline
    label="tax override"
    v-model.number="taxOverride"
    @change="sumAllCosts"
  )
  span.caption if you need to change the tax amount for this order manually
  v-text-field.mt-5(
    outline
    label="adjustment percentage"
    v-model.number="adjustmentPercentage"
    @change="sumAllCosts"
  )
  span.caption adjust the pre-tax order total by a percentage (positive or negative)
  v-text-field.mt-5(
    outline
    label="adjustment amount"
    v-model.number="adjustmentAmount"
    @change="sumAllCosts"
  )
  span.caption adjust the pre-tax order total by a dollar amount
  
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  computed: {
    ...mapFields('orders', {
      taxOverride: 'item.costs.taxOverride',
      adjustmentPercentage: 'item.costs.adjustmentPercentage',
      adjustmentAmount: 'item.costs.adjustmentAmount'
    })
  },
  methods: {
    sumAllCosts () {
      this.$store.dispatch('orders/sumJobCosts')
      this.$store.dispatch('orders/_SAVE')
    }
  }
}
</script>
