<template lang="pug">
  div
    v-checkbox(
      label="is this a press check?"
      v-model="isPressCheck"
      @change="save"
    )
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  computed: {
    ...mapFields('productionInstances', {
      isPressCheck: 'item.dashboard.isPressCheck'
    })
  },
  methods: {
    save () {
      this.$store.dispatch('productionInstances/_UPDATE')
    }
  }
}
</script>
