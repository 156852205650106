<template lang="pug">
div
  v-dialog(
    ref="dialogDropDead"
    v-model="modalDropDead"
    :return-value.sync="dates.dropDead"
    persistent
    lazy
    full-width
    width="290px"
  )
    template(v-slot:activator="{ on }")
      v-text-field(
        outline
        v-model="dropDeadDisplay"
        label="drop dead date"
        prepend-icon="event"
        readonly
        v-on="on"
      )
    v-date-picker(
      v-model="dates.dropDead"
      scrollable
    )
        v-spacer
        v-btn(flat color="primary" @click="modalDropDead = false") Cancel
        v-btn(flat color="primary" @click="selectDropDead") OK

  v-dialog(
    ref="dialogInHands"
    v-model="modalInHands"
    :return-value.sync="dates.inHands"
    persistent
    lazy
    full-width
    width="290px"
  )
    template(v-slot:activator="{ on }")
      v-text-field(
        outline
        v-model="inHandsDisplay"
        label="in hands date"
        prepend-icon="event"
        readonly
        v-on="on"
      )
    v-date-picker(
      v-model="dates.inHands"
      scrollable
    )
      v-spacer
      v-btn(flat color="primary" @click="modalInHands = false") Cancel
      v-btn(flat color="primary" @click="selectInHands") OK

  v-checkbox(
    v-model="dates.dropDeadIsHard"
    label="is hard?"
    @change="save"
  )
  v-btn(color='error' @click='cleardates' small) clear dates
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import moment from 'moment'
import constants from '@/assets/constants'

export default {
  data: () => ({
    modalDropDead: false,
    modalInHands: false,
    olddropdead: null
  }),
  computed: {
    ...mapFields('orders', {
      item: 'item',
      dates: 'item.dates',
      clientProduct: 'item.clientProduct',
      status: 'item.status'
    }),
    jobIds () {
      const jobs = this.$store.getters['jobs/items']
      return jobs.map(x => x._id)
    },
    dropDeadDisplay () {
      const date = this.dates.dropDead
      return date ? moment(date).format('LL') : ''
    },
    inHandsDisplay () {
      const date = this.dates.inHands
      return date ? moment(date).format('LL') : ''
    }
  },
  methods: {
    cleardates () {
      this.dates = {
        dropDead: null,
        inHands: null,
        dropDeadIsHard: false
      }

      this.save()
    },
    save () {
      this.$store.dispatch('orders/_SAVE')
      this.$store.dispatch('productionInstances/updateDates', {
        dates: this.dates,
        jobIds: this.jobIds
      })
    },
    async selectDropDead () {
      
      this.$store.dispatch('orders/setup')
      this.$refs.dialogDropDead.save(this.dates.dropDead)
      const isfangamer = this.item.company === constants.fangamerid
      if (this.status.name === 'confirmed' && isfangamer) {
        await this.$store.dispatch('orders/spekkiodesignschedulechange', this.clientProduct)
      }
      this.save()
      this.$store.dispatch('orders/cleanup')
    },
    selectInHands () {
      this.$refs.dialogInHands.save(this.dates.inHands)
      this.save()
    }
  },
  created() { this.olddropdead = this.dates.dropDead }
}
</script>
