/** Model definition file for the LineItem Class **/
import uuid from 'uuid/v4'

export default class LineItem {
    constructor({
        _id = uuid(),
        color = {},
        size = {},
        quantity = 0,
        quantityOrdered = 0,
        quantityReceived = 0,
        quantityFinal = 0,
        isOrdered = false,
        po_id = '',
        poNumber = '',
        costProduct = 0,
        costProductTrue = 0,
        cost = 0,
        costOverride = null
    } = {}) {
        this._id = _id
        this.color = color
        this.size = size
        this.quantity = quantity
        this.quantityOrdered = quantityOrdered
        this.quantityReceived = quantityReceived
        this.quantityFinal = quantityFinal
        this.isOrdered = isOrdered
        this.po_id = po_id
        this.poNumber = poNumber
        this.cost = cost
        this.costOverride = costOverride
        this.costProduct = costProduct
        this.costProductTrue = costProductTrue
    }

    get colorName() {
        return this.color.name
    }
    get sizeSortOrder() {
        return this.size.sortOrder
    }
    get sizeName() {
        return this.size.name
    }
    get costExtend() {
        return this.cost * this.quantity
    }
    get costExtendFinal() {
        return this.cost * this.quantityFinal
    }
}
