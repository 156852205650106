import { PurchaseOrderLine } from '@/models'
import uuid from 'uuid/v4'

export default {

  async addPoLinesToPool ({ state, commit, dispatch, rootState }) {
    dispatch('setup')
    const all =[]
    const contract = []
    for (const job of state.items) {
      // conditionally add line items to pool OR PO if they are from a contract job
      if (job.removeProductCost) {
        contract.push(job)
      } else {
        all.push(dispatch('addPoLinesJob', job))
      }
    }
    if(contract.length > 0) { await dispatch('addContractToPo', contract) }
    const complete = await Promise.all(all)
    dispatch('cleanup')
    return complete
  },

  async addPoLinesJob ({ dispatch, rootState }, job) {
    // make sure we have the proper product groups loaded
    await dispatch('productGroups/_READ_JOB', job._id, { root: true })
    // loop through product groups
    for (const productGroup of rootState.productGroups.items) {
      dispatch('addPoLinesProductGroup', { productGroup, job })
    }
  },

  async addContractToPo ({ dispatch, rootState }, jobs) {
    let payload = []
    // loop through jobs
    for (const job of jobs) {
      await dispatch('productGroups/_READ_JOB', job._id, { root: true })
      rootState.productGroups.items.forEach(item => { payload.push({ job, productGroup: item })})
    }
    // loop through product groups
    dispatch('addContractToPoHelper', payload)
  },

  async addPoLinesProductGroup ({ state, commit, dispatch, rootState }, { productGroup, job }) {
    // build PurchaseOrderLines with product info from line items
    const PurchaseOrderLines = []
    const lineItems = productGroup.lineItems.filter(l => l.quantity !== 0)
    for (const line of lineItems) {
      const instance = await dispatch('instancePurchaseOrderLine', job)
      const product = productGroup.product
      instance.lineItem = line
      instance.productName = product.displayName
      instance.product_id = product._id
      instance.vendor = product.vendor._id ? product.vendor : {}
      PurchaseOrderLines.push(instance)
    }
    for (const poLine of PurchaseOrderLines) {
      dispatch('poPoolLines/_CREATE', poLine, { root: true })
    }
  },

  async addContractToPoHelper ({ state, commit, dispatch, rootState }, payload) {
    // process the payload
    const promises = []
    for (const item of payload) {
      promises.push(dispatch('addContractToPoHelperProcessItem', item))
    }
    const PurchaseOrderLines = await Promise.all(promises)
    const results = PurchaseOrderLines
      .reduce((val, x) => val.concat(x), [])
      .filter(x => x.lineItem.quantity > 0)

    await dispatch('purchaseOrders/_CREATE', {
      vendor: { name: `${rootState.companies.item.name} - ${ rootState.orders.item.name } #: ${rootState.orders.item.orderNumber}`},
      lines: results
    }, { root: true })

    await dispatch('purchaseOrders/loadRelatedJobs', null, { root: true })
    await dispatch('purchaseOrders/markOrdered', null, { root: true })
    await dispatch('purchaseOrders/_UPDATE', null, { root: true })
  },
  async addContractToPoHelperProcessItem ({ state, commit, dispatch, rootState }, payload) {
    // build PurchaseOrderLines with product info from line items
    const PurchaseOrderLines = []

    // process the payload
    for (const line of payload.productGroup.lineItems) {
      const instance = await dispatch('instancePurchaseOrderLine', payload.job)
      const product = payload.productGroup.product
      instance.lineItem = line
      instance.lineItem.quantityOrdered = line.quantity
      instance.productName = product.displayName
      instance.product_id = product._id
      instance._id = uuid()
      PurchaseOrderLines.push(instance)
    }

    return PurchaseOrderLines
  },

  async instancePurchaseOrderLine ({ state, rootState }, job) {
    const poLine = new PurchaseOrderLine({
      order_id: rootState.orders.item._id,
      orderName: rootState.orders.item.name,
      orderNumber: rootState.orders.item.orderNumber,
      company_id: rootState.companies.item._id,
      companyName: rootState.companies.item.name,
      job_id: job._id,
      jobName: `${ job.design.name } ${ job.subtitle ? job.subtitle : ''}`,
      jobNumber: job.jobNumber,
    })
    return poLine
  },

  

}