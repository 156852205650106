<template lang="pug">
v-layout.line-item.body-1(row px-4 align-center)
  v-flex(xs3)
    v-layout(row align-center)
      v-flex
        span.px-0
          v-avatar(size=31 tile)
            img(:src="line.color.image.url")
        span.pl-3 {{line.color.name}}

  v-flex(xs1).text-xs-center
    span.px-0.text-uppercase {{line.size.name}}

  v-flex(xs2).text-xs-center {{ line.quantity }}

  v-flex(xs2).text-xs-center {{ line.quantityOrdered }}
  v-flex(xs2).text-xs-right {{ line.costProduct }}
  v-flex(xs2)
    trueCogInput(
      :line='line' 
      :pg='pg' 
      :input='true'
    )

</template>
<script>
import trueCogInput from './trueCogInput'

export default {
  props: ['line', 'pg'],

  components: { trueCogInput }
}
</script>

<style lang="stylus" scoped>
.line-item
  border-bottom solid #FCE4EC 1px
</style>
