<template lang="pug">
v-container
  v-layout.headline(column)
    HeadInfo
    JobDetail

</template>

<script>
import HeadInfo from './head-info'
import JobDetail from './job-detail'

export default {
  components: {
    JobDetail,
    HeadInfo,
  },
  
  async created () {
    this.$store.dispatch('purchaseOrders/setup')
    await this.$store.dispatch('jobs/_LOAD', this.$route.params.id)
    this.$store.dispatch('purchaseOrders/cleanup')
  }
}
</script>
