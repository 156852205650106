<template lang="pug">
div(v-if="selectedLocation._id && locations.length && selectedLocation.template._id && selectedLocation.decoration._id" )
  v-card(flat).pa-3
    Placements
  v-card(flat).pa-3
    Screens
  v-card(flat).pa-3.grey.lighten-4
    v-card-title.title.font-weight-bold production specs
    SpecsScreenprinting(v-if='isscreenprinting')
    SpecsEmbroidery(v-if='isembroidery')

</template>

<script>
import { mapFields } from 'vuex-map-fields'
import Placements from './list-placements'
import Screens from './list-screens'
import SpecsScreenprinting from './specs-screenprinting'
import SpecsEmbroidery from './specs-embroidery'
import constants from '@/assets/constants'

export default {
  components: { Placements, Screens, SpecsScreenprinting, SpecsEmbroidery },
  computed: {
    ...mapFields('designs', {
      locations: 'item.locations',
      selectedLocation: 'selected.location',
      
    }),
    isscreenprinting () {
      const _id = this.selectedLocation.decorationCategory
      return _id === constants.decorationcategory.screenprint || _id === constants.decorationcategory.foil
    },
    isembroidery () { return this.selectedLocation.decorationCategory === constants.decorationcategory.embroidery},
  }
}
</script>
