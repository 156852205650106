<template lang="pug">
DetailSmall(:detail="detail")
  template(slot="title") assign vendor.
  template(slot="form")
    v-layout(row)
      v-flex
        v-select(
          label="vendor"
          v-model="vendor"
          :items="vendors"
          item-text="name"
          return-object
        )
        
  template(slot="controls")
    v-btn(@click="$emit('save', vendor)")
      v-icon(left) save
      | save
    v-btn.ml-3(@click="$emit('cancel')")
      v-icon(left) cancel
      | cancel
</template>

<script>
import DetailSmall from '@/components/base/detail-small-modal'

export default {
  props: ['detail'],
  components: { DetailSmall },
  data: () => ({ vendor: {} }),
  computed: { vendors () { return this.$store.getters['vendors/items'] } },
}
</script>
