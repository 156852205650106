/** Model definition file for the Comment Class **/
import uuid from 'uuid/v4'

export default class Comment {
  constructor ({
    _id = uuid(),
    type = '',
    subject = '',
    text = '',
    owner = '',
    time = '',
    images = [],
    files = []
  } = {}) {
    this._id = _id
    this.type = type
    this.subject = subject
    this.text = text
    this.owner = owner
    this.time = new Date(time)
    this.images = images
    this.files = files
  }
  get types () {
    return ['order request for change', 'order internal note']
  }
}
