import moment from 'moment'
import orderStates from '../helpers/order-states'
import mail from '../helpers/mail-contents'
import sendmail from '../helpers/sendmail'
import pdf from '../helpers/pdf'
import { LogItem } from '@/models'

export default {
  async approveOrder ({ state, dispatch }) {
    const checklist = state.item.statusData.pendingChecklist
    const pendingCompleted = checklist.isDatesOk && checklist.isShippingOk && checklist.isDepositOk
    const approved = state.item.approval.approved
    const isfangamer = state.item.company === '5ce8672394e11431a4ab9e24'

    if (!approved) { await dispatch('revertToQuote') }
    else if (pendingCompleted) { await dispatch('confirmOrder') }
    else { await dispatch('convertToPending')}

    // spekkio
    if (isfangamer) dispatch('sendestimateapprovaltospekkio')
  },

  async convertToPending ({ state, dispatch, rootState }) {
    // TODO: validate email address and send alert if not available
    sendmail(mail.pending(state.item))
      
    // set status [pending]
    state.item.status = orderStates.find(state => state.name = 'pending' )
    state.item.statusData.log.push(new LogItem({
      name: 'order status',
      text: 'moved to pending',
      user: rootState.users.item
    }))

    // lock prices
    state.item.statusData.isPricesLocked = true

    // update order and save
    dispatch('productionInstances/updateOrderStatus', { status: state.item.statusData, order_id: state.item._id }, { root: true })
    dispatch('_SAVE')
  },

  async revertToQuote ({ state, dispatch, rootState }) {
    // TODO: validate email address and send alert if not available
    // set status [pending]
    state.item.status = orderStates.find(state => state.name = 'quote' )
    state.item.statusData.log.push(new LogItem({
      name: 'order status',
      text: 'reverted to quote',
      user: rootState.users.item
    }))

    // update order and save
    dispatch('productionInstances/updateOrderStatus', { status: state.item.statusData, order_id: state.item._id }, { root: true })
    dispatch('_SAVE')
  },

  async revertToEstimate ({ state, dispatch, rootState }) {
    // TODO: validate email address and send alert if not available
    sendmail(mail.revertEstimate(state.item))

    // set status [estimate]
    state.item.status = orderStates.find(state => state.name = 'estimate' )
    state.item.statusData.log.push(new LogItem({
      name: 'order status',
      text: 'reverted to estimate',
      user: rootState.users.item
    }))

    // unlock prices
    state.item.statusData.isPricesLocked = false

    // update order and save
    dispatch('productionInstances/updateOrderStatus', { status: state.item.statusData, order_id: state.item._id }, { root: true })
    dispatch('_SAVE')
  },

   async sendEstimate ({ state, dispatch, rootState }) {
    const mailcontents = mail.estimate(state.item)
    // const attachmentcontent = await pdf.getBase64({
    //   order: state.item, 
    //   company: rootState.companies.item, 
    //   jobs: rootState.jobs.items, 
    //   otherCharges: state.item.otherCharges
    // })
    
    // mailcontents.attachments = [
    //   {
    //     filename: `${state.item.status.name} ${state.item.orderNumber} - ${rootState.companies.item.name} - ${state.item.name}.pdf`,
    //     content: attachmentcontent,
    //     encoding: 'base64'
    //   }
    // ]
    sendmail(mailcontents)
    
    
    // spekkio
    const isfangamer = state.item.company === '5ce8672394e11431a4ab9e24'
    if (isfangamer) dispatch('sendestimatetospekkio')


    dispatch('convertToQuote')
  },

  async convertToQuote ({ state, dispatch, rootState }) {
    // TODO: validate email address and send alert if not available
    // sendmail(mail.quote(state.item))
      
    // set status [quote]
    state.item.status = orderStates.find(state => state.name = 'quote' )
    state.item.statusData.quoteDate = Date.now()
    state.item.statusData.log.push(new LogItem({
      name: 'order status',
      text: 'estimate sent',
      user: rootState.users.item
    }))

    // lock prices
    state.item.statusData.isPricesLocked = true
    dispatch('productionInstances/updateOrderStatus', { status: state.item.statusData, order_id: state.item._id }, { root: true })
    dispatch('_SAVE')
  },


  async allShipmentsComplete ({ state, dispatch, rootState }) {
    // TODO: validate email address and send alert if not available
    sendmail(mail.shipped(state.item))
      
    // convert to invoice
    state.item.status = { name: 'invoice' }
    state.item.statusData.log.push(new LogItem({
      name: 'order status',
      text: 'shipment(s) completed',
      user: rootState.users.item
    }))
    state.item.statusData.isShipped = true

    dispatch('productionInstances/updateOrderStatus', { status: state.item.statusData, order_id: state.item._id }, { root: true })
    dispatch('_SAVE')
  },

  async markShipped ({ state, dispatch, rootState }) {
    const isAllShipped = state.item.shipments.find(shipment => shipment.isShipped)
    if(isAllShipped && state.item.status.name === 'ready to ship') {
      console.log('all shipped!')
      dispatch('allShipmentsComplete')
    } else {
      dispatch('_SAVE')
    }
  },

  async confirmOrder ({ state, dispatch, rootState }) {
    // TODO: validate email address and send alert if not available
    sendmail(mail.confirmed(state.item))
      
    // convert to confirmed order
    state.item.status = orderStates.find(state => state.name = 'confirmed' )
    state.item.statusData.log.push(new LogItem({
      name: 'order status',
      text: 'order has been confirmed',
      user: rootState.users.item
    }))

    // lock prices
    state.item.statusData.isPricesLocked = true

    // remove approval on designs
    dispatch('jobs/removeDesignApproval', null, { root: true  })

    // set a 2 week drop dead date if not assigned
    if (!state.item.dates.dropDead) { state.item.dates.dropDead = moment().add(14, 'days') }

    // order garments
    await Promise.all(
    [
      dispatch('jobs/addPoLinesToPool', { order_id: state.item._id }, { root: true  }),
      dispatch('productionInstances/updateOrderStatus', { status: state.item.statusData, order_id: state.item._id }, { root: true }),
      dispatch('productionInstances/updateToWip', { order_id: state.item._id }, { root: true }),
      dispatch('_SAVE')
    ]
    )
    
    // spekkio
    const isfangamer = state.item.company === '5ce8672394e11431a4ab9e24'
    if (isfangamer) await dispatch('senddropdeadtospekkio')

    // add decoration cost to PIs
    dispatch('productionInstances/updatedecorationcost', { order_id: state.item._id }, { root: true })
  },
  
  async completeOrder ({ state, dispatch, rootState }) {
    // TODO: validate email address and send alert if not available
    sendmail(mail.completed(state.item))
    
    // convert to confirmed order
    state.item.status = orderStates.find(state => state.name = 'ready to ship' )
    state.item.statusData.log.push(new LogItem({
      name: 'order status',
      text: 'order has been completed',
      user: rootState.users.item
    }))

    const isNotShipped = state.item.shipments.find(item => !item.isShipped)
    const isAllShipped = !isNotShipped && state.item.shipments.length > 0
    if (isAllShipped) { dispatch('allShipmentsComplete') }
    dispatch('productionInstances/updateOrderStatus', { status: state.item.statusData, order_id: state.item._id }, { root: true })
    dispatch('productionInstances/updateToComplete', { order_id: state.item._id }, { root: true })
    dispatch('_SAVE')
  },


}