<template lang="pug">
div
  v-text-field(
    label="hoopmaster placement"
    v-model="embhoopplacement"
    @change="save"
  )
  v-text-field(
    label="presser foot height"
    v-model="embpresserfootheight"
    @change="save"
  )
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  computed: {
    ...mapFields('designs', {
      embhoopplacement: 'selected.location.embhoopplacement',
      embpresserfootheight: 'selected.location.embpresserfootheight',
    }),
  },
  methods: {
    save () { this.$store.dispatch('designs/_SAVE') },
  }
}
</script>
