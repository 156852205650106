import list from '../views/list'
import detail from '../views/detail'
import listPool from '../views/list-pool'
import detailPool from '../views/detail-pool'

export default [
  {
    path: '/purchase-orders',
    name: 'purchase-orders list',
    component: list,
    meta: { authorize: ['Admin', 'User', 'Purchasing', 'Production', 'ProductionLead'] }
  },
  {
    path: '/purchase-orders/:id',
    name: 'purchase-order detail',
    component: detail,
    meta: { authorize: ['Admin', 'User', 'Purchasing', 'Production', 'ProductionLead'] }
  },
  {
    path: '/po-pool',
    name: 'po-pool list',
    component: listPool,
    meta: { authorize: ['Admin', 'User', 'Purchasing'] }
  },
  {
    path: '/po-pool/:id',
    name: 'po pool line detail',
    component: detailPool,
    meta: { authorize: ['Admin', 'User', 'Purchasing'] }
  },
]
