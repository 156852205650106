<template lang="pug">
FwdSuccess(
  title="SUCCESS"
  subtitle="your request for changes has been sent.  expect a revised mockup soon."
  image="clint.png"
)
</template>

<script>
import FwdSuccess from '@/components/base/FwdSuccess'
export default {
  components: { FwdSuccess }
}
</script>