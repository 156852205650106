/** Model definition file for the JobCosts Class **/

export default class JobCosts {
  constructor ({
    decorationCost = 0,
    decorationCostOverride = 0,
    screens = 0,
    useCost = 0,
    setupTime = 0,
    setupCost = 0,
    setupCostPerPiece = 0,
    profitPerHour = 0,
    profitCostPerPiece = 0
  } = {}) {
    this.decorationCost = decorationCost
    this.decorationCostOverride = decorationCostOverride
    this.screens = screens
    this.useCost = useCost
    this.setupTime = setupTime
    this.setupCost = setupCost
    this.setupCostPerPiece = setupCostPerPiece
    this.profitPerHour = profitPerHour
    this.profitCostPerPiece = profitCostPerPiece
  }
}
