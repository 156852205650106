import { LogItem } from '@/models'
import axios from 'axios'

const adminList =
    'danc@forwardprinting.com, kmc@forwardprinting.com, armando@forwardprinting.com, billy@forwardprinting.com, max@forwardprinting.com, '

export default {
    async approveMockup({ state, dispatch, rootState }) {
        dispatch('setup')
        // post to server to update:
        // pi's sharing design (wip and not retired)
        // jobs related to these pi's
        // send approval email to admin list
        const request = await axios.post('/designs/approve', {
            _id: state.item._id,
            emailrecipients: adminList,
            displayname: state.item.displayName
        })

        // update approval date
        if (state.item.approval.approved) {
            state.item.approval.date = new Date()
        } else {
            state.item.approval.date = ''
        }

        // log the change
        state.item.log.push(
            new LogItem({
                name: 'status change',
                text:
                    'mockup has been approved.  all active jobs using this design have been notified',
                user: rootState.users.item
            })
        )

        dispatch('_SAVE')

        // spekkio
        const isfangamer = state.item.company._id === '5ce8672394e11431a4ab9e24'
        if (isfangamer) dispatch('sendapprovedboosttospekkio')

        return dispatch('cleanup')
    },

    async requestChangesMockup({ state, dispatch, rootState }) {
        dispatch('setup')
        // post to server to update:
        // pi's sharing design (wip and not retired)
        // jobs related to these pi's
        // send approval email to admin list
        const request = await axios.post('/designs/requestchanges', {
            _id: state.item._id,
            emailrecipients: adminList,
            displayname: state.item.displayName
        })

        // log the change
        state.item.log.push(
            new LogItem({
                name: 'status change',
                text:
                    'client has requested changes to mockup.  all active jobs using this design have been notified',
                user: rootState.users.item
            })
        )

        dispatch('_SAVE')

        return dispatch('cleanup')
    },

    async sendMockup({ state, dispatch, rootState }) {
        dispatch('setup')
        // prepare list of receivers
        let emailrecipients = rootState.orders.item.contact.email
            ? rootState.orders.item.contact.email + ','
            : ''
        emailrecipients += adminList
        if (state.item.contacts.length > 0) {
            state.item.contacts.forEach(contact => {
                emailrecipients += `${contact.email}, `
            })
        }

        // post to server to update:
        // pi's sharing design (wip and not retired)
        // jobs related to these pi's
        // send approval email to admin list
        const request = await axios.post('/designs/sendmockup', {
            _id: state.item._id,
            emailrecipients,
            displayname: state.item.displayName
        })

        // log the change
        state.item.log.push(
            new LogItem({
                name: 'status change',
                text:
                    'mockup has been sent to the point of contact: ' +
                    emailrecipients,
                user: rootState.users.item
            })
        )

        dispatch('_SAVE')

        return dispatch('cleanup')
    }

    // async sendMockup ({ state, dispatch, rootState }) {
    //   dispatch('setup')
    //   // TODO: validate email address and send alert if not available
    //   let to = rootState.orders.item.contact.email ? rootState.orders.item.contact.email + ',' : ''
    //   to += adminList
    //   if (state.item.contacts.length > 0) {
    //     state.item.contacts.forEach(contact => {
    //       to += `${contact.email}, `
    //     })
    //   }

    //   const mail = {
    //     from: 'info@forwardprinting.com', // sender address
    //     to, // list of receivers
    //     subject: 'mockup: ' + state.item.displayName, // Subject line
    //     text: 'Hello!\nPlease follow this link to view the mockup for your project: http://fwdio.forwardprinting.com/client/designs/' + state.item._id // plain text body
    //   }
    //   await axios({
    //     method: 'post',
    //     url: 'designs/sendmail',
    //     data: mail,
    //     headers: { 'Content-Type': 'application/json' }
    //   })

    //   try {
    //     const path = '/jobs/progress/mockup/' + state.item._id
    //     const data = { value: true }
    //     await axios.patch(path, data)
    //     axios.patch('/production-instances/progress/mockup/' + state.item._id, data)

    //   } catch (error) {
    //     console.log('sendmockup failed', error)
    //     return Promise.reject(error)
    //   }

    //   // save to local state
    //   rootState.jobs.item.progress.isMockSent = true

    //   // log the action
    //   state.item.log.push(new LogItem({
    //     name: 'mockup sent',
    //     text: 'mockup has been sent to the point of contact: ' + to,
    //     user: rootState.users.item
    //   }))

    //   dispatch('jobs/_SAVE', null, { root: true })
    //   dispatch('_SAVE')
    //   dispatch('cleanup')
    // },
}
